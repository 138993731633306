<template>
  <v-container fluid style="display: flex; flex-direction: column; height: 100%">
    <v-container fluid style="display: flex; gap: 10px; flex-wrap: wrap; justify-content: center">
      <v-btn primary @click="imprimirPdfProdutos">Imprimir Produtos</v-btn>
      <v-btn primary @click="imprimirPdfProdutosBase64">Visualizar Produtos</v-btn>
      <v-btn primary @click="downloadPdfProdutos">Download Produtos</v-btn>
      <v-btn primary @click="imprimirPdfClientes">Imprimir Clientes</v-btn>
      <v-btn primary @click="imprimirPdfClientesBase64">Visualizar Clientes</v-btn>
      <v-btn primary @click="downloadPdfClientes">Download Clientes</v-btn>
      <v-btn primary @click="pdfDoc = null">Limpar</v-btn>
    </v-container>
    <embed v-if="pdfDoc" :src="pdfDoc" style="width: 100%; height: 100%; border-radius: 4px" />
  </v-container>
</template>

<script>
import { createPdf } from '@/reports/pdfmake/engine';
import defaultReport from '@/reports/pdfmake/template/default.mjs';
import ServiceRelatorios from '@/services/relatoriosApi';

export default {
  name: 'Relatorios',

  data: () => ({
    pdfDoc: null,
  }),

  methods: {
    imprimirPdfProdutos() {
      ServiceRelatorios.produtos().then((data) => {
        const { title, subTitle, companyInfo, columns, dataset } = data;

        const docDefinition = defaultReport({
          title,
          subTitle,
          companyInfo,
          columns,
          dataset,
        });

        createPdf({ docDefinition });
      });
    },

    imprimirPdfProdutosBase64() {
      const params = { download: true, format: 'base64' };

      ServiceRelatorios.produtos({ params }).then((data) => {
        this.pdfDoc = data;
      });
    },

    downloadPdfProdutos() {
      const params = { download: true };

      ServiceRelatorios.produtos({ params, responseType: 'arraybuffer' }).then((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'produtos.pdf';
        link.click();
        link.remove();
      });
    },

    imprimirPdfClientes() {
      ServiceRelatorios.clientes().then((data) => {
        const { title, subTitle, companyInfo, columns, dataset } = data;

        const docDefinition = defaultReport({
          title,
          subTitle,
          companyInfo,
          columns,
          dataset,
        });

        createPdf({ docDefinition });
      });
    },

    imprimirPdfClientesBase64() {
      const params = { download: true, format: 'base64' };

      ServiceRelatorios.clientes({ params }).then((data) => {
        this.pdfDoc = data;
      });
    },

    downloadPdfClientes() {
      const params = { download: true };

      ServiceRelatorios.clientes({ params, responseType: 'arraybuffer' }).then((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'clientes.pdf';
        link.click();
        link.remove();
      });
    },
  },
};
</script>
