import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function createPdf({ docDefinition, mode = 'print' }) {
  let schema = { ...docDefinition };

  if (mode == 'print') {
    pdfMake.createPdf(schema).open();
  }

  if (mode == 'download') {
    pdfMake.createPdf(schema).download();
  }
}
