<template>
  <v-container fluid>
    <v-widget title="Grupo" class="pb-0" style="height: calc(100vh - 136px); border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formGruposubGrupo" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field required placeholder=" " persistent-placeholder outlined autocomplete="off" label="Nome" autofocus v-model.trim="grupo.nome" :rules="[rules.obrigatorio]"></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar flat dark color="secondary" class="mb-4" dense style="border-radius: 4px 4px 0px 0px">
            <v-toolbar-title>SubGrupos</v-toolbar-title>
            <v-spacer />
            <v-btn depressed color="primary darken-1" @click="novoSubGrupo">Novo</v-btn>
          </v-toolbar>

          <v-row dense v-for="(subGrupo, i) in grupo.subGrupos" :key="'p-' + i">
            <v-col cols="12">
              <v-text-field label="Nome" v-model="subGrupo.nome" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details append-icon="delete" @click:append="() => grupo.subGrupos.splice(i, 1)" />
            </v-col>
          </v-row>

          <v-row dense class="pa-5" v-if="grupo.subGrupos.length === 0">
            <v-label>Não há SubGrupos</v-label>
          </v-row>
          <v-col />

          <v-divider />

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="carregando" :disabled="!valid" @click="salvar">Salvar</v-btn>
          </v-container>
        </v-form>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as regras from '@/util/validacaoFormulario';
import constantes from '@/util/constantes';

export default {
  name: 'FormularioGrupo',

  data: () => ({
    valid: false,
    loading: false,
    rules: { ...regras },
    constantes: constantes,
  }),

  computed: {
    ...mapState('grupo', {
      grupo: 'grupo',
      carregando: 'carregando',
    }),
  },

  methods: {
    ...mapMutations('grupo', {
      removerSubgrupo: 'removerSubgrupo',
    }),

    novoSubGrupo() {
      const subGrupos = this.grupo.subGrupos;
      if (!subGrupos.find((subGrupo) => !subGrupo.nome.trim())) {
        this.$store.dispatch('grupo/adicionarSubgrupo', { nome: '' });
      }
    },

    async salvar() {
      if (this.$refs.formGruposubGrupo.validate()) {
        try {
          if (this.grupo._id) {
            await this.$store.dispatch('grupo/atualizar', this.grupo);
          } else {
            await this.$store.dispatch('grupo/salvar', this.grupo);
          }
          this.$router.push({ name: 'grupos' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error, { root: true });
        }
      }
    },

    cancelar() {
      this.$store.commit('grupo/estadoInicial');
      this.$router.push({ name: 'grupos' });
    },
  },

  async created() {
    try {
      this.loading = true;
      const grupo = this.$store.state.grupo.grupo;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!grupo._id || grupo._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um grupo para editar');
        this.$router.push({ name: 'grupos' });
      }
      if (!estaEditando) {
        this.$store.commit('grupo/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error, { root: true });
    } finally {
      this.loading = false;
    }
  },
};
</script>
