import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get('/contas-pagar', { params: options }).then((response) => response.data);
}

function listarPorTipo(options) {
  return axios.get('/contas-pagar/tipos', { params: options }).then((response) => response.data);
}

function listarContasPagarFornecedor(fornecedor, options) {
  return axios.get(`/contas-pagar/fornecedores/${fornecedor._id}?` + qs.stringify(options)).then((response) => response.data);
}

function buscaFornecedores(filtro) {
  return axios.get('/participantes/fornecedores/filtro/' + filtro).then((response) => response.data);
}

function filtrar(options, cliente) {
  return axios.get(`/contas-pagar/${cliente}/filtrar?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(contaPagar) {
  return axios.post(`/contas-pagar`, contaPagar).then((response) => response.data);
}

function atualizar(contaPagar) {
  return axios.put(`/contas-pagar/${contaPagar._id}`, contaPagar).then((response) => response.data);
}

function excluir(contaPagar) {
  return axios.delete(`/contas-pagar/${contaPagar._id}`).then((response) => response.data);
}

function baixar(baixa) {
  return axios.post(`/contas-pagar/baixar`, baixa).then((response) => response.data);
}

function buscar(cfopCompra) {
  return axios.get(`/contas-pagar/buscar?cfopcompra=${cfopCompra}`).then((response) => response.data);
}

export default {
  listar,
  listarPorTipo,
  listarContasPagarFornecedor,
  buscaFornecedores,
  filtrar,
  salvar,
  atualizar,
  excluir,
  baixar,
  buscar,
};
