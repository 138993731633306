<template>
  <div>
    <v-container fluid>
      <v-widget title="Gráficos" noPadding>
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="8"> </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete placeholder=" " persistent-placeholder outlined hide-details autocomplete="off" role="presentation" label="Gráfico" ref="grafico" :items="graficos" v-model="graficoSelecionado" @change="mudarGrafico"></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:widget-content>
          <v-container fluid class="pa-4 pt-0" style="display: flex; flex-direction: column">
            <grafico-vendas-por-mes ref="graficoVendasPorMes" :exibir="exibirGraficoVendasPorMes" @esconder="exibirGraficoVendasPorMes = false" />
            <grafico-vendas-por-intervalo-data ref="graficoVendasPorIntervaloData" :exibir="exibirGraficoVendasPorIntervaloData" @esconder="exibirGraficoVendasPorIntervaloData = false" />
          </v-container>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GraficoVendasPorMes from './vendas/GraficoVendasPorMes';
import GraficoVendasPorIntervaloData from './vendas/GraficoVendasPorIntervaloData';

export default {
  name: 'Graficos',
  components: {
    GraficoVendasPorMes,
    GraficoVendasPorIntervaloData,
  },
  data() {
    return {
      graficoSelecionado: 1,
      graficos: [
        { text: '1. Vendas Por Mês', value: 1 },
        { text: '2. Vendas Por Intervalo de Data', value: 2 },
      ],
      exibirGraficoVendasPorMes: true,
      exibirGraficoVendasPorIntervaloData: false,
    };
  },

  mounted() {
    this.carregarGraficos();
  },

  computed: {
    ...mapState('graficos', {
      carregando: 'carregando',
    }),
  },

  methods: {
    carregarGraficos() {
      this.$refs.graficoVendasPorMes.carregar();
    },

    mudarGrafico(grafico) {
      switch (grafico) {
        case 1:
          this.exibirGraficoVendasPorMes = true;
          this.$refs.graficoVendasPorMes.carregar();
          break;
        case 2:
          this.exibirGraficoVendasPorIntervaloData = true;
          this.$refs.graficoVendasPorIntervaloData.carregar();
          break;
      }
    },
  },
};
</script>
