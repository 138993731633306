<template>
  <div>
    <v-text-field :loading="loading" label="Buscar Cofins" :value="value" append-icon="search" @keyup.enter="abrirDialogo" @input="onInput" @click="abrirDialogo" @click:append="abrirDialogo" data-cy="campo-cofins" dense filled></v-text-field>

    <dialog-buscar-cofins ref="dialogoBuscarCofins" @cofinsSelecionado="adicionarCofinsDialog" :busca="value"></dialog-buscar-cofins>
  </div>
</template>

<script>
import dialogBuscarCofins from '@/components/dialogs/DialogBuscarCofins';

export default {
  name: 'BuscarCofins',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    dialogBuscarCofins,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    abrirDialogo() {
      this.$refs.dialogoBuscarCofins.abrir();
    },

    adicionarCofinsDialog(cofins) {
      this.$emit('cofinsSelecionado', cofins);
    },
  },
};
</script>

<style scoped></style>
