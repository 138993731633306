import constantes from '@/util/constantes';
import coresApi from '@/services/coresApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    cores: [],
    corAtual: {
      codigo: '',
      nome: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalCores: 0,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        const cores = await coresApi.listar(options);
        commit('SET_CORES', cores);
        commit('SET_TOTAL_CORES', cores.length);
      } catch (error) {
        commit('SET_CORES', []);
        commit('setMensagemErro', 'Não foi possível listar as cores', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        const cor = Object.assign({}, state.corAtual);
        const response = await coresApi.salvar(cor);
        commit('ATUALIZAR_COR', response);
        commit('setMensagemSucesso', 'Cor salva com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({ commit }, cor) {
      try {
        commit('SET_CARREGANDO', true);
        const response = await coresApi.atualizar(cor);
        commit('ATUALIZAR_COR', response);
        commit('setMensagemSucesso', 'Cor atualizada com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({ commit }, cor) {
      try {
        commit('SET_CARREGANDO', true);
        await coresApi.excluir(cor._id);
        commit('EXCLUIR_COR', cor._id);
        commit('setMensagemSucesso', 'Cor removida com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CORES(state, cores) {
      state.cores = cores;
      state.carregando = false;
    },
    SET_TOTAL_CORES(state, total) {
      state.totalCores = total;
    },
    SET_COR(state, corAtual) {
      state.corAtual = corAtual;
    },
    EXCLUIR_COR(state, id) {
      let index = state.cores.findIndex((cor) => cor._id == id);
      if (index > -1) {
        state.cores.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.corAtual = {
        codigo: '',
        nome: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.corAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_COR(state, cor) {
      state.cores.unshift(cor);
      state.carregando = false;
    },
    ATUALIZAR_COR(state, cor) {
      let cor_encontrada = state.cores.find((cor_) => cor_.id == cor.id);
      if (cor_encontrada) {
        cor_encontrada = cor;
      }
      state.carregando = false;
    },
    estadoInicial(state) {
      state.corAtual = {
        codigo: '',
        nome: '',
      };
      state.carregando = false;
    },
  },
};
