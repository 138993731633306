import axios from '@/util/axios';

function consultarCadastro(cnpj) {
  return axios.get(`/servicos/consultar-cadastro/${cnpj}`).then((response) => response.data);
}

function consultarStatusServicoNFe() {
  return axios.get('/servicos/nfe/status').then((response) => response.data);
}

function consultarStatusServicoNFCe() {
  return axios.get('/servicos/nfce/status').then((response) => response.data);
}

function consultarStatusServicoMDFe() {
  return axios.get('/servicos/mdfe/status').then((response) => response.data);
}

function consultarDestinadas() {
  return axios.get(`/destinadas/nfe/consultar-destinadas`).then((response) => response.data);
}

export default {
  consultarCadastro,
  consultarStatusServicoNFe,
  consultarStatusServicoNFCe,
  consultarStatusServicoMDFe,
  consultarDestinadas,
};
