<template>
  <v-container grid-list-md fluid>
    <v-widget title="Certificado Digital" class="mb-4">
      <template v-slot:widget-header-action>
        <v-btn v-if="!carregar" depressed color="primary lighten-1" @click="carregarCertificado()">Carregar Novo Certificado</v-btn>
      </template>
      <template v-slot:widget-content>
        <v-row dense>
          <v-col cols="12" sm="3">
            <v-text-field outlined label="CNPJ" :value="session.empresa.certificadoDados.cnpj | formatarCnpj" readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="9">
            <v-text-field outlined label="Solicitante" :value="session.empresa.certificadoDados.razao" readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined label="Numero de Série" :value="session.empresa.certificadoDados.numeroSerial" hideDetails readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined label="Data de Emissão" :value="session.empresa.certificadoDados.emissao | formatarData" hideDetails readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined label="Data de Validade" :value="session.empresa.certificadoDados.validade | formatarData" hideDetails readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined label="Dias de Certificado" :value="session.empresa.certificadoDados.validade | diasRestantes" hideDetails readonly></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-widget>

    <v-widget v-if="carregar" title="Certificado Digital" noDivider>
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formCertificadoPadrao" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="9" dense>
              <v-file-input
                hideDetails
                outlined
                accept=".pfx"
                autofocus
                v-model="certLocal.arquivo"
                label="Certificado A1"
                persistent-placeholder
                placeholder="Selecione o arquivo do certificado A1"
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                :rules="validarArquivo"
              />
            </v-col>
            <v-col cols="12" sm="3" dense>
              <v-text-field hideDetails outlined label="Senha" :rules="validarSenha" persistent-placeholder v-model="certLocal.senha" type="password" required></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:widget-footer-action>
        <v-card-actions class="pt-0">
          <v-btn large v-if="!carregando" depressed @click="carregarCertificado()">Cancelar</v-btn>
          <v-btn large depressed color="primary" :loading="carregando" @click="enviar()" :disabled="!valid">Salvar Certificado</v-btn>
        </v-card-actions>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import empresasApi from '@/services/empresasApi';

export default {
  data: () => ({
    valid: false,
    validarSenha: [(v) => !!v || 'Preenchimento obrigatório'],
    validarArquivo: [(v) => (!!v && v instanceof File) || 'Preenchimento obrigatório'],
    certLocal: {
      arquivo: [],
      senha: '',
    },
    carregar: true,
    carregando: false,
  }),

  computed: {
    ...mapState('autenticacao', { session: 'session' }),
  },

  methods: {
    carregarCertificado() {
      this.carregar = !this.carregar;
      if (!this.carregar) {
        this.certLocal.arquivo = [];
        this.certLocal.senha = '';
      }
    },

    enviar() {
      if (this.certLocal.arquivo instanceof File && this.$refs.formCertificadoPadrao.validate()) {
        const reader = new FileReader();
        const context = this;

        reader.onload = (e) => {
          context.atualizarConteudo(e.target.result);
        };
        reader.readAsBinaryString(this.certLocal.arquivo, 'ASCII');
      }
    },

    atualizarConteudo(certificado) {
      this.carregando = true;
      empresasApi
        .processarCertificado({ arquivo: btoa(certificado), senha: this.certLocal.senha })
        .then((result) => {
          this.carregar = false;
          this.carregando = false;
          this.$store.commit('autenticacao/setSession', {
            empresa: {
              ...this.session.empresa,
              certificadoDados: {
                cnpj: result.solicitante.cnpj,
                emissao: result.emissao,
                numeroSerial: result.numeroSerial,
                razao: result.solicitante.razao,
                validade: result.validade,
              },
            },
          });
        })
        .catch((error) => {
          this.carregando = false;
          this.$store.commit('setMensagemErro', error);
        });
    },
  },

  mounted() {
    this.carregar = !this.session.empresa.certificadoDados.numeroSerial;
  },
};
</script>
