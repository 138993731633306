import Vuex from 'vuex';
import Vue from 'vue';
import localforage from 'localforage';

import modules from './modules';
import tabelasApi from '@/services/tabelasApi';

function processError(error) {
  const errorObject = {
    code: 0,
    message: 'Não foi possível executar a operação',
    stack: null,
  };

  if (typeof error == 'string') {
    errorObject.message = error;
  }

  if (typeof error == 'object') {
    if (error.data) {
      errorObject.message = error.data.message || error.data.motivo;
    } else if (error.status) {
      errorObject.code = error.status;
      errorObject.message = error.statusText;
      errorObject.stack = error.data;
    } else if (error.name) {
      errorObject.code = 0;
      errorObject.message = `${error.name} - ${error.message}`;
      errorObject.stack = error.stack;
    }
  }

  return errorObject;
}

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { ...modules },

  state: {
    carregando: false,
    sair: false,
    version: process.env.VERSION || '0.0.0',
    zoom: 100,

    tabelas: { formasPagamentos: [], unidades: [], csts: [], csosns: [], origens: [], pis: [], cofins: [], bancos: [] },

    snackbar: {
      show: false,
      text: '',
      color: '',
    },

    isDevelopment: !['https://easypdv.anronsoftware.com.br'].includes(window.location.origin),
  },

  getters: {
    unidadesSelect(state) {
      return state.tabelas.unidades.map((unidade) => {
        return {
          text: `${unidade.codigo} - ${unidade.descricao}`,
          value: unidade.codigo,
        };
      });
    },

    origensSelect(state) {
      return state.tabelas.origens.map((origem) => {
        return {
          text: `${origem.codigo} - ${origem.descricao}`,
          value: origem.codigo,
        };
      });
    },

    cstsSelect(state) {
      return state.tabelas.csts.map((cst) => {
        return {
          text: `${cst.codigo} - ${cst.descricao}`,
          value: cst.codigo,
        };
      });
    },

    csosnsSelect(state) {
      return state.tabelas.csosns.map((csosn) => {
        return {
          text: `${csosn.codigo} - ${csosn.descricao}`,
          value: csosn.codigo,
        };
      });
    },

    pisEntradaSelect(state) {
      return state.tabelas.pis
        .filter((pis) => parseInt(pis.codigo, 10) >= 50)
        .map((pis) => {
          return {
            text: `${pis.codigo} - ${pis.descricao}`,
            value: pis.codigo,
          };
        });
    },

    pisSaidaSelect(state) {
      return state.tabelas.pis
        .filter((pis) => parseInt(pis.codigo, 10) < 50)
        .map((pis) => {
          return {
            text: `${pis.codigo} - ${pis.descricao}`,
            value: pis.codigo,
          };
        });
    },

    cofinsEntradaSelect(state) {
      return state.tabelas.cofins
        .filter((cofins) => parseInt(cofins.codigo, 10) >= 50)
        .map((cofins) => {
          return {
            text: `${cofins.codigo} - ${cofins.descricao}`,
            value: cofins.codigo,
          };
        });
    },

    cofinsSaidaSelect(state) {
      return state.tabelas.cofins
        .filter((cofins) => parseInt(cofins.codigo, 10) < 50)
        .map((cofins) => {
          return {
            text: `${cofins.codigo} - ${cofins.descricao}`,
            value: cofins.codigo,
          };
        });
    },

    bancosSelect({ tabelas }) {
      return tabelas.bancos.map((banco) => {
        return {
          text: banco.descricao,
          value: banco.codigo,
        };
      });
    },

    formasPagamentos({ tabelas }) {
      return tabelas.formasPagamentos;
    },

    unidades({ tabelas }) {
      return tabelas.unidades;
    },

    csts({ tabelas }) {
      return tabelas.csts;
    },

    csosns({ tabelas }) {
      return tabelas.csosns;
    },

    origens({ tabelas }) {
      return tabelas.origens;
    },

    pis({ tabelas }) {
      return tabelas.pis;
    },

    cofins({ tabelas }) {
      return tabelas.cofins;
    },

    bancos({ tabelas }) {
      return tabelas.bancos;
    },
  },

  mutations: {
    sair(state) {
      state.sair = !state.sair;
    },

    setCarregando(state, value) {
      state.carregando = value;
    },

    setTabelas(state, value) {
      state.tabelas = value;
    },

    setZoom(state, value) {
      state.zoom = value;
    },

    setMensagemErro(state, error) {
      const errorProcessed = processError(error);
      state.snackbar.text = errorProcessed.message;
      state.snackbar.color = 'red';
      state.snackbar.show = true;
    },

    setMensagemSucesso(state, mensagem) {
      state.snackbar.text = mensagem;
      state.snackbar.color = 'primary';
      state.snackbar.show = true;
    },
  },

  actions: {
    carregarTabelas({ commit }) {
      commit('setCarregando', true);
      try {
        localforage.getItem('tabelas').then((tabelas = null) => {
          if (!tabelas) {
            tabelasApi.carregar().then((data) => {
              data.pis.sort((a, b) => (a.codigo > b.codigo ? 1 : -1));
              data.cofins.sort((a, b) => (a.codigo > b.codigo ? 1 : -1));
              localforage.setItem('tabelas', data);
              commit('setTabelas', data);
            });
          } else {
            commit('setTabelas', tabelas);
          }
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    descarregarTabelas({ commit }) {
      commit('setTabelas', { formasPagamentos: [], unidades: [], csts: [], csosns: [], origens: [], pis: [], cofins: [] });
      localforage.clear();
    },
  },
});
