import estoquesDepositoApi from '@/services/estoquesDepositoApi';

export default {
  namespaced: true,

  state: {
    estoquesDeposito: [],
    detalhesMovimentacao: [],
    carregando: false,
    totalEstoques: 0,
  },

  getters: {},

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregar({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await estoquesDepositoApi.listar(options);
        commit('setEstoquesDeposito', response.estoques);
        commit('setTotalEstoques', response.total);
      } catch (error) {
        commit('setEstoquesDeposito', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async detalharMovimentacao({ commit }, produtoEstoque) {
      try {
        let response = await estoquesDepositoApi.detalharMovimentacao(produtoEstoque);
        commit('setDetalhesMovimentacao', response);
      } catch (error) {
        commit('setDetalhesMovimentacao', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setEstoquesDeposito(state, estoques) {
      state.estoquesDeposito = estoques;
      state.carregando = false;
    },
    setDetalhesMovimentacao(state, detalhes) {
      state.detalhesMovimentacao = detalhes;
      state.carregando = false;
    },
    setTotalEstoques(state, total) {
      state.totalEstoques = total;
    },
  },
};
