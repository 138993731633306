import constantes from '@/util/constantes';
import bancosApi from '@/services/bancosApi';

export default {
  namespaced: true,

  state: {
    bancos: [],
    constantes: constantes,
    carregando: false,
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('setCarregando');
        const data = await bancosApi.listar(options);
        commit('setBancos', data);
      } catch (error) {
        commit('setBancos', []);
        commit('setMensagemErro', 'Não foi possível listar os bancos', { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, status = true) {
      state.carregando = status;
    },
    setBancos(state, bancos) {
      state.bancos = bancos;
      state.carregando = false;
    },
  },
};
