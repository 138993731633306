<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar">
    <v-form ref="formTransferenciaContaCorrente" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Transferência para Conta Corrente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" xs="12" sm="3">
                <campo-data label="Data" v-model="transferencia.data_deposito" :rules="[rules.obrigatorio]" autofocus @keypress.enter="$refs.valor.focus()"></campo-data>
              </v-col>
              <v-col cols="12" xs="12" sm="3">
                <as-campo-valor label="Valor" ref="valor" v-model="transferencia.valor" decimals="2" :rules="[rules.obrigatorio, rules.numeroValido, rules.valorMaiorQueZero]" @keypress.native.enter="$refs.conta.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-select return-object label="Conta Corrente" ref="conta" item-value="_id" item-text="nome" :items="contascorrentes" v-model="transferencia.conta" @keypress.enter="confirmar"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar()" text>Cancelar</v-btn>
          <v-btn color="primary" @click="confirmar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import * as regras from '@/util/validacaoFormulario';
import constantes from '@/util/constantes';
import moment from 'moment';

export default {
  name: 'DialogoTransferenciaContaCorrente',
  props: ['dialog'],
  data() {
    return {
      rules: { ...regras },
      transferencia: {
        data_deposito: moment().format('YYYY-MM-DD'),
        formaPagamento: 'Dinheiro',
        valor: '',
        conta: {},
        tipo: constantes.DEBITO,
      },
    };
  },
  computed: {
    ...mapState('caixa', {
      contascorrentes: 'contascorrentes',
      carregando: 'carregando',
    }),
  },
  methods: {
    fechar() {
      this.$emit('fechar');
      this.limpar();
    },
    confirmar() {
      if (this.$refs.formTransferenciaContaCorrente.validate()) {
        if (!this.transferencia.conta._id) {
          this.$store.commit('setMensagemErro', 'Escolha uma conta');
          setTimeout(() => {
            this.$refs.conta.focus();
          }, 500);
          return false;
        }
        this.$emit('adicionarTransferencia', this.transferencia);
        this.limpar();
      }
    },
    limpar() {
      this.transferencia = {
        data_deposito: moment().format('YYYY-MM-DD'),
        formaPagamento: 'Dinheiro',
        valor: '',
        conta: {},
        tipo: constantes.DEBITO,
      };
    },
  },
  async created() {
    try {
      this.$store.dispatch('caixa/listarContasCorrentes');
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      //
    }
  },
};
</script>
