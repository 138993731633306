export default [
  {
    path: '/manifestos',
    name: 'manifestos',
    component: () => import(/* webpackChunkName: "listaManifestos" */ '@/views/manifestos/ListaManifestos'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_manifestos' },
  },
  {
    path: '/manifestos/novo',
    name: 'formularioManifestoNovo',
    component: () => import(/* webpackChunkName: "formularioManifesto" */ '@/views/manifestos/FormularioManifesto'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_manifestos' },
  },
  {
    path: '/manifestos/editar',
    name: 'formularioManifestoEditar',
    component: () => import(/* webpackChunkName: "formularioManifesto" */ '@/views/manifestos/FormularioManifesto'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_manifestos' },
  },
  {
    path: '/manifestos/damdfe',
    name: 'damdfe',
    component: () => import(/* webpackChunkName: "visualizarDamdfe" */ '@/views/cadastros/DAMDFE'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_manifestosdamfe' },
  },
];
