<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaPagar" lazy-validation>
      <v-container fluid>
        <v-widget title="Conta a Pagar">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="4" sm="3">
                <buscar-participante persistent-placeholder outlined label="Buscar Fornecedor" v-model="cnpjCpfParticipante" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]"></buscar-participante>
              </v-col>
              <v-col cols="8" sm="9">
                <v-text-field persistent-placeholder outlined label="Fornecedor" v-model="contaPagar.participante.razaoSocialNome" readonly ref="razaoSocialNome" @keypress.native.enter="$refs.documento.focus()"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field persistent-placeholder outlined label="Documento" v-model="contaPagar.documento" :rules="[rules.obrigatorio]" ref="documento" @keypress.native.enter="$refs.dataCadastro.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data persistent-placeholder outlined label="Cadastro" v-model="contaPagar.data_cadastro" :rules="[rules.obrigatorio]" ref="dataCadastro" @keypress.native.enter="$refs.dataVencimento.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="">
                <campo-data persistent-placeholder outlined label="Vencimento" v-model="contaPagar.data_vencimento" :rules="[rules.obrigatorio]" ref="dataVencimento" @keypress.native.enter="$refs.valor.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor persistent-placeholder outlined label="Valor" v-model="contaPagar.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="$refs.notaFiscal.focus()"></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field persistent-placeholder outlined label="Nota Fiscal" v-model="contaPagar.nota_fiscal" ref="notaFiscal" @keypress.native.enter="$refs.tipo.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select :items="tipos" v-model="contaPagar.tipo" persistent-placeholder outlined label="Tipo" ref="tipo" @keypress.native.enter="$refs.obs.focus()"></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea name="obs" persistent-placeholder outlined label="Observações" v-model="contaPagar.observacao" ref="obs"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-container fluid>
              <v-row dense>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                  <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioContaPagar',
  data: () => ({
    valid: true,
    constantes: constantes,
    cnpjCpfParticipante: '',
    loading: false,
    rules: { ...regras },
    tipos: ['Boleto', 'Cheque', 'Duplicata', 'Nota Promissória', 'Vale', 'Imposto', 'Outros'],
  }),

  computed: {
    ...mapState('contaPagar', {
      contaPagar: 'contaAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('contaPagar', {
      estaEditando: 'estaEditando',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formContaPagar.validate()) {
        try {
          await this.$store.dispatch('contaPagar/salvar');
          this.$router.push({ name: 'contasPagar' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('contaPagar/estadoInicial');
      this.$router.push({ name: 'contasPagar' });
    },

    adicionarParticipante(participante) {
      this.cnpjCpfParticipante = formatador.formatarCnpj(participante.cnpjCpf);
      this.$store.commit('contaPagar/setParticipante', participante);
    },
  },

  async created() {
    try {
      this.loading = true;
      const conta = this.$store.state.contaPagar.contaAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!conta._id || conta._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
        this.$router.push({ name: 'contasPagar' });
      }

      if (!estaEditando) {
        this.$store.commit('contaPagar/estadoInicial');
      } else {
        this.cnpjCpfParticipante = conta.participante.cnpjCpf;
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
