<template>
  <div>
    <v-text-field :loading="loading" :label="label" ref="codigoBanco" persistent-placeholder outlined v-mask="['###']" :value="value" append-icon="search" @keyup.enter="consultarCodigoBanco($event)" @input="onInput" @click:append="abrirDialogo" />

    <dialog-buscar-banco ref="dialog" @onSelect="onSelect" :value="value" />
  </div>
</template>

<script>
import DialogBuscarBanco from '@/components/dialogs/DialogBuscarBanco';

export default {
  name: 'BuscarBanco',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: 'Código Banco',
    },
  },

  components: {
    DialogBuscarBanco,
  },

  data() {
    return {
      dialogo: false,
      loading: false,
    };
  },

  methods: {
    focus() {
      this.$refs.codigoBanco.focus();
    },

    onInput(value) {
      this.$emit('input', value);
    },

    abrirDialogo() {
      this.$refs.dialog.abrir();
    },

    async consultarCodigoBanco({ target }) {
      const busca = target.value;
      if (busca && busca.trim().length > 0) {
        try {
          this.loading = true;
          const resultado = this.$store.state.tabelas.bancos.filter((banco) => banco.codigo.includes(busca) || banco.descricao.includes(busca));

          if (resultado.length > 0) {
            this.$emit('bancoSelecionado', resultado[0]);
          } else {
            this.$store.commit('setMensagemErro', 'Banco não encontrado');
          }
        } finally {
          this.loading = false;
        }
      } else {
        this.$store.commit('setMensagemErro', 'Código vazio');
      }
    },

    onSelect(banco) {
      this.$emit('bancoSelecionado', banco);
    },
  },
};
</script>
