<template>
  <v-form v-model="valid" ref="formVenda">
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <v-widget title="Venda">
            <div slot="widget-content">
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-autocomplete label="Buscar Cliente" :search-input.sync="filtroCliente" v-model="clienteSelecionado" @input="definirCliente(clienteSelecionado)" :items="clientesBusca" append-icon="search">
                      <v-slide-x-reverse-transition mode="out-in" slot="append-outer"></v-slide-x-reverse-transition>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm9>
                    <v-text-field label="Razão/Nome do Cliente" v-model="venda.cliente.razaoSocialNome" required readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field label="Total Produtos" :value="totalProdutos | formatarMoeda" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field label="Descontos" :value="totalDescontos | formatarMoeda" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field label="Valor Líquido" :value="totalLiquido | formatarMoeda" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-menu :close-on-content-click="false" v-model="menuData" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                      <v-text-field v-model="dataVenda" slot="activator" label="Lançamento" prepend-icon="event" :rules="[() => !!venda.data || 'Data obrigatória']" readonly></v-text-field>
                      <v-date-picker locale="pt-BR" v-model="venda.data" no-title @input="menuData = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-widget>
        </v-flex>
        <v-flex sm12 class="mt-2">
          <v-widget title="Itens da Venda">
            <div slot="widget-content">
              <v-container grid-list-md fluid>
                <v-layout row wrap>
                  <v-flex xs12 sm1>
                    <v-text-field label="Código" v-model="codigoProduto" @keypress.enter="buscarCodigoProduto(codigoProduto)"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-text-field label="Descrição do Produto" v-model="produto.descricao" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-text-field label="Valor" :value="produto.valor | formatarValor" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm1>
                    <v-text-field type="number" min="1" label="Qtd." v-model="produto.quantidade" @input="calcularSubtotal()" :rules="[(v) => (v >= 1 ? true : false) || 'Qtd. inválida']" :readonly="produto.valor == ''"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm1>
                    <v-text-field label="Desc." v-model="produto.desconto" @input="calcularSubtotal()" :rules="validarValor"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-text-field label="Subtotal" :value="produto.subtotal | formatarValor" readonly></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm1>
                    <v-btn icon color="success" @click="adicionarProduto"><v-icon>add</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-divider></v-divider>
              <v-container grid-list-md fluid>
                <v-data-table hide-default-footer item-key="_id" :headers="headers" :items="venda.itens" :loading="carregando">
                  <template v-slot:item="props">
                    <td>{{ props.item.codigo | formatarPadZero(5) }}</td>
                    <td>{{ props.item.descricao }}</td>
                    <td>{{ props.item.unidade }}</td>
                    <td>{{ props.item.valor | formatarMoeda }}</td>
                    <td>{{ props.item.quantidade }}</td>
                    <td>{{ props.item.desconto | formatarMoeda }}</td>
                    <td>{{ props.item.subtotal | formatarMoeda }}</td>
                    <td class="text-xs-center">
                      <v-icon @click="removerItem(props.index)">delete</v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </div>
          </v-widget>
        </v-flex>
      </v-layout>
      <v-layout justify-end>
        <v-btn depressed text @click="limpar">{{ constantes.BOTAO_CANCELAR }}</v-btn>
        <v-btn depressed color="primary" :loading="carregando" @click="enviar" :disabled="!valid || venda.itens.length == 0">{{ textoBtnConfirmar }}</v-btn>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'FormularioVenda',
  data: () => ({
    headers: [
      { text: 'Código', value: 'codigo' },
      { text: 'Descrição', value: 'descricao' },
      { text: 'Und.', value: 'unidade' },
      { text: 'Vlr. Unit.', value: 'valor' },
      { text: 'Qtd.', value: 'quantidade' },
      { text: 'Desc.', value: 'desconto' },
      { text: 'Total', value: 'subtotal' },
      { text: 'Ações', value: 'name', align: 'center', width: '7%', sortable: false },
    ],
    valid: false,
    validarValor: [(v) => (v && v == 0) || v == '0' || (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
    constantes: constantes,
    clienteSelecionado: null,
    filtroCliente: null,
    menuData: false,
    codigoProduto: '',
  }),

  computed: {
    ...mapState('venda', {
      venda: 'vendaAtual',
      produto: 'produtoAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('venda', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      clientesBusca: 'clientesBusca',
      dataVenda: 'dataFormatada',
      totalProdutos: 'totalProdutos',
      totalDescontos: 'totalDescontos',
      totalLiquido: 'totalLiquido',
    }),
  },

  watch: {
    filtroCliente(val) {
      if (!this.carregando && val && val.length > 3) {
        this.$store.dispatch('vendas/consultarClientes', val);
      }
    },
  },

  methods: {
    enviar() {
      if (this.$refs.formVenda.validate()) {
        this.$store.dispatch('vendas/salvar', { router: this.$router });
      }
    },

    definirCliente(idCliente) {
      this.$store.commit('venda/setCliente', idCliente);
    },

    limpar() {
      this.$store.dispatch('vendas/cancelar', { router: this.$router });
    },

    buscarCodigoProduto(codigo) {
      this.$store.dispatch('vendas/buscarCodigoProduto', codigo);
    },

    adicionarProduto() {
      this.codigoProduto = '';
      this.$store.commit('vendas/adicionarItem');
    },

    calcularSubtotal() {
      this.$store.commit('vendas/calcularSubtotal');
    },

    removerItem(index) {
      this.$store.commit('vendas/removerItem', index);
    },
  },
};
</script>

<style scoped>
.container.grid-list-md .layout .flex {
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}
</style>
