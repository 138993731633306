import axios from '@/util/axios';

function listar(options) {
  let url = `/vendas?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url += `&query=${options.query}`;
  }

  return axios.get(url).then((response) => response.data);
}

function consultarClientes(filtro) {
  return axios.get('/participantes/clientes/filtro/' + filtro).then((response) => response.data);
}

function buscarCodigoProduto(codigo) {
  return axios.get('/produtos/codigos/' + codigo).then((response) => response.data);
}

function salvar(venda) {
  return axios.post(`/vendas`, venda).then((response) => response.data);
}

function atualizar(venda) {
  return axios.put(`/vendas`, venda).then((response) => response.data);
}

function excluir(venda) {
  return axios.delete(`/vendas`, { data: { _id: venda._id } }).then((response) => response.data);
}

function acertarVenda(venda, envio) {
  return axios.post('/vendas/' + venda._id + '/acertar', envio).then((response) => response.data);
}

export default {
  listar,
  consultarClientes,
  buscarCodigoProduto,
  salvar,
  atualizar,
  excluir,
  acertarVenda,
};
