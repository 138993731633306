import axios from '@/util/axios';
import configuracoesApi from '@/services/configuracoesApi';
import empresasApi from '@/services/empresasApi';

export default {
  namespaced: true,

  state: {
    configuracoes: [],
    configuracaoAtual: {
      descricao: '',
      config: '',
    },
    empresa: {
      razao_social: '',
      fantasia: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      estado: '',
      cep: '',
      cnpj: '',
      ie: '',
      im: '',
      inscricao_suframa: '',
      cnae: '',
      telefone: '',
      responsavel: '',
      cpf_responsavel: '',
      email_responsavel: '',
      config_sistema: {
        regime_tributario: '',
        tributacao: '',
        tabela_customizável: '',
        margem_lucro: '0,00',
        modo_plano_contas: '',
        juros_multas: {
          dias_tolerancia: '',
          juros: '0,00',
          multa: '0,00',
          multa_recebimentos: true,
        },
        tabela_produtos_primaria: {
          nome: '',
          porcentagem: 0,
        },
        tabela_produtos_secundaria: {
          nome: '',
          porcentagem: 0,
        },
        casas_decimais_valor_produtos: 0,
        casas_decimais_conversao_produtos: 0,
        emissor_nfce: {
          limite_valor: '0,00',
          limite_quantidade: '0,000',
          sistematica_atacadista_tecidos: false,
          token_csc: '',
          id_csc: '',
          token_csc_homologacao: '',
          id_csc_homologacao: '',
          serie: '',
          numero_serie_certificado: '',
          pin: '',
        },
        numeracao_inicial_producao: '',
        numeracao_inicial_homologacao: '',
        ajustar_numeracao: false,
      },
    },
    contador: {
      nome: '',
      cnpj: '',
      cpf: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      estado: '',
      cep: '',
    },
    cidades: [],
    cidadesContador: [],
    carregando: false,
    carregando_cidades: false,
    carregando_cidades_contador: false,
    sistema: {
      regime_tributario: '',
      tributacao: '',
      tabela_customizável: '',
      margem_lucro: '0,00',
      modo_plano_contas: '',
      juros_multas: {
        dias_tolerancia: '',
        juros: '0,00',
        multa: '0,00',
        multa_recebimentos: true,
      },
      tabela_produtos_primaria: {
        nome: '',
        porcentagem: 0,
      },
      tabela_produtos_secundaria: {
        nome: '',
        porcentagem: 0,
      },
      casas_decimais_valor_produtos: 0,
      casas_decimais_conversao_produtos: 0,
      emissor_nfce: {
        limite_valor: '0,00',
        limite_quantidade: '0,000',
        sistematica_atacadista_tecidos: false,
        token_csc: '',
        id_csc: '',
        token_csc_homologacao: '',
        id_csc_homologacao: '',
        serie: '',
        numero_serie_certificado: '',
        pin: '',
      },
      numeracao_inicial_producao: '',
      numeracao_inicial_homologacao: '',
      ajustar_numeracao: false,
    },
    totalConfiguracoes: 0,

    // CONFIGURAÇÕES EM USO
    configAniversariantes: false,
  },

  getters: {
    listaCidades(state) {
      return state.cidades.map((cidade) => {
        return {
          text: cidade.cidade,
          value: cidade.codigo_cidade,
        };
      });
    },
    listaCidadesContador(state) {
      return state.cidadesContador.map((cidade) => {
        return {
          text: cidade.cidade,
          value: cidade.codigo_cidade,
        };
      });
    },
  },

  actions: {
    async listar({ commit, dispatch }) {
      try {
        commit('SET_CARREGANDO');

        // let response = await configuracoesApi.listar();
        const { empresa } = JSON.parse(localStorage.getItem('usuario'));
        dispatch('filtrarCidadesEstado', empresa.estado);

        let response_contador = await empresasApi.carregarContador();
        commit('SET_CONTADOR', response_contador);
        dispatch('filtrarCidadesEstadoContador', response_contador.estado);
      } catch (error) {
        commit('SET_CONFIGURACOES', []);
        commit('setMensagemErro', 'Não foi possível listar as configurações', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        let configuracao = Object.assign({}, state.configuracaoAtual);
        let response = await configuracoesApi.salvar(configuracao);
        commit('ADICIONAR_CONFIGURACAO', response);
        commit('nova');
        commit('setMensagemSucesso', 'Configuração salva com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao salvar a configuração', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async alterarStatus({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        let configuracao = Object.assign({}, state.configuracaoAtual);
        await configuracoesApi.alterarStatus(configuracao);
        commit('setMensagemSucesso', 'Configuração atualizada com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao alterar o status da configuração', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({ commit }, configuracao) {
      try {
        await configuracoesApi.excluir(configuracao._id);
        commit('EXCLUIR_CONFIGURACAO', configuracao._id);
        commit('setMensagemSucesso', 'Cheque removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async filtrarCidadesEstado({ commit }, estado) {
      try {
        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
        commit('setCidades', cidades);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },
    async filtrarCidadesEstadoContador({ commit }, estado) {
      try {
        commit('setCarregandoCidadesContador', true);
        commit('setCidadesContador', []);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
        commit('setCidadesContador', cidades);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
        throw error;
      } finally {
        commit('setCarregandoCidadesContador', false);
      }
    },

    async salvarConfiguracoesEmpresa({ commit }, empresa) {
      try {
        commit('SET_CARREGANDO', true);
        const empresa_atualizada = await empresasApi.atualizar(empresa);
        localStorage.setItem('empresa', JSON.stringify(empresa_atualizada));
        commit('setMensagemSucesso', 'Configurações de Empresa salvas com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao salvar as configurações de empresa', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  mutations: {
    setCidades(state, cidades) {
      state.cidades = cidades;
    },
    setCidade(state, codigo_cidade) {
      const cidade = state.cidades.find((cidade) => cidade.codigo_cidade == codigo_cidade);

      state.empresa.cidade = cidade.cidade;
      state.empresa.codigo_cidade = cidade.codigo_cidade;
      state.empresa.codigo_estado = cidade.codigo_estado;
    },
    setCidadesContador(state, cidades) {
      state.cidadesContador = cidades;
    },
    setCidadeContador(state, codigo_cidade) {
      const cidade = state.cidadesContador.find((cidade) => cidade.codigo_cidade == codigo_cidade);

      state.empresa.contador.cidade = cidade.cidade;
      state.empresa.contador.codigo_cidade = cidade.codigo_cidade;
      state.empresa.contador.codigo_estado = cidade.codigo_estado;
    },
    setEndereco(state, endereco) {
      state.empresa.logradouro = endereco.logradouro;
      state.empresa.bairro = endereco.bairro;
      state.empresa.estado = endereco.uf;
      state.empresa.cidade = endereco.localidade;
      state.empresa.codigo_cidade = endereco.ibge;
    },
    setEnderecoContador(state, endereco) {
      state.contador.logradouro = endereco.logradouro;
      state.contador.bairro = endereco.bairro;
      state.contador.estado = endereco.uf;
      state.contador.cidade = endereco.localidade;
      state.contador.codigo_cidade = endereco.ibge;
    },
    setCarregandoCidades(state, valor) {
      state.carregando_cidades = valor;
    },
    setCarregandoCidadesContador(state, valor) {
      state.carregando_cidades_contador = valor;
    },
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CONFIGURACOES(state, configuracoes) {
      state.configuracoes = configuracoes;
      state.carregando = false;
    },
    SET_TOTAL_CONFIGURACOES(state, total) {
      state.totalConfiguracoes = total;
    },
    SET_CONFIGURACAO_ATUAL(state, configuracao) {
      state.configuracaoAtual = configuracao;
    },
    ATUALIZAR_STATUS(state, atual) {
      state.configuracoes.forEach((configuracao) => {
        if (configuracao._id == atual._id) {
          configuracao.ativo = atual.ativo;
        }
      });
      state.carregando = false;
    },
    nova(state) {
      state.configuracaoAtual = {
        descricao: '',
        config: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.configuracaoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_CONFIGURACAO(state, configuracao) {
      state.configuracoes.unshift(configuracao);
      state.carregando = false;
    },
    EXCLUIR_CONFIGURACAO(state, id) {
      let index = state.configuracoes.findIndex((configuracao) => configuracao._id == id);
      if (index > -1) {
        state.configuracoes.splice(index, 1);
      }
    },

    SET_EMPRESA(state, empresa) {
      state.empresa = {};
      state.empresa = empresa;
    },

    SET_CONTADOR(state, contador) {
      state.contador = {};
      state.contador = contador;
    },
  },
};
