<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
      <v-form v-else ref="formRemessa" lazy-validation>
        <v-container fluid>
          <v-widget title="Cheque">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field label="Número" ref="numero" persistent-placeholder outlined v-model="remessa.numero" :placeholder="!remessa._id ? '#####' : remessa.numero" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <campo-data label="Data" ref="data" persistent-placeholder outlined v-model="remessa.data" :rules="[rules.obrigatorio]" :disabled="remessa._id ? true : false"></campo-data>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select label="Conta Corrente" item-text="nome" item-value="_id" ref="contascorrentes" :items="contascorrentes" persistent-placeholder outlined v-model="remessa.conta" :rules="[rules.obrigatorio]" @contaSelecionada="contaSelecionada"></v-select>
                </v-col>
              </v-row>
              <v-card>
                <v-toolbar flat dark color="primary" dense>
                  <v-toolbar-title>Lista de Cheques</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-data-table fixed-header height="220px" hide-default-footer :headers="headersCheques" :server-items-length="remessa.cheques ? remessa.cheques.length : 0" :items="remessa.cheques">
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-numero">{{ item.numero }}</td>
                        <td>{{ item.emitente }}</td>
                        <td class="">{{ item.banco.descricao }}</td>
                        <td class="">{{ item.cnpjCpf }}</td>
                        <td class="">{{ item.valor | formatarMoeda }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="excluirCheque(item)"> mdi-delete </v-icon>
                            </template>
                            <span>Remover</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-divider class="mt-5 mb-5"></v-divider>
              <v-card>
                <v-toolbar flat dark color="primary" dense>
                  <v-toolbar-title>Cheques em Caixa</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col xs="12" sm="6">
                      <v-text-field flat single-line hide-details clearable solo v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-data-table fixed-header height="220px" hide-default-footer :headers="headersChequesEmCaixa" :server-items-length="remessa.chequesEmCaixa ? remessa.chequesEmCaixa.length : 0" :items="remessa.chequesEmCaixa">
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-numero">{{ item.numero }}</td>
                        <td>{{ item.emitente }}</td>
                        <td class="">{{ item.banco.descricao }}</td>
                        <td class="">{{ item.cnpjCpf }}</td>
                        <td class="">{{ item.valor | formatarMoeda }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="incluirCheque(item)"> mdi-arrow-right-bold </v-icon>
                            </template>
                            <span>Incluir</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-divider class="mt-5 mb-5"></v-divider>
              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-textarea label="Observações" ref="observacoes" rows="6" persistent-placeholder outlined v-model="remessa.observacoes"></v-textarea>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field class="direita" label="Cheques" ref="cheques" persistent-placeholder outlined v-model="remessa.cheques.length" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <as-campo-valor label="Total" ref="total" persistent-placeholder outlined v-model="remessa.valor" readonly></as-campo-valor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-slot:widget-footer-action>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as regras from '@/util/validacaoFormulario';
import constantes from '@/util/constantes';

export default {
  name: 'FormularioRemessaCheques',
  data: () => ({
    loading: false,
    valid: false,
    codigoBanco: '',
    rules: { ...regras },
    constantes: constantes,
    headersCheques: [
      { text: 'Número', value: 'numero', sortable: false, width: '10%', align: 'center' },
      { text: 'Emitente', value: 'emitente', sortable: false, width: '30%' },
      { text: 'Banco', value: 'banco.descricao', sortable: false, width: '20%' },
      { text: 'CNPJ/CPF', value: 'cnpjCpf', sortable: false, width: '25%' },
      { text: 'Valor', value: 'valor', sortable: false, width: '10%', align: 'right' },
      { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
    ],
    headersChequesEmCaixa: [
      { text: 'Número', value: 'numero', sortable: false, width: '10%', align: 'center' },
      { text: 'Emitente', value: 'emitente', sortable: false, width: '30%' },
      { text: 'Banco', value: 'banco.descricao', sortable: false, width: '20%' },
      { text: 'CNPJ/CPF', value: 'cnpjCpf', sortable: false, width: '25%' },
      { text: 'Valor', value: 'valor', sortable: false, width: '10%', align: 'right' },
      { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
    ],
    busca: '',
    options: {
      itemsPerPage: 10,
      page: 1,
    },
  }),

  computed: {
    ...mapState('remessasCheques', {
      remessa: 'remessaAtual',
      contascorrentes: 'contascorrentes',
      // chequesEmCaixa: 'chequesEmCaixa',
      carregando: 'carregando',
    }),

    ...mapGetters('remessasCheques', {
      estaEditando: 'estaEditando',
    }),

    total() {
      let valor_total = this.remessa.cheques.reduce((soma, cheque) => {
        return soma + cheque.valor;
      }, 0);

      return {
        valor: valor_total,
      };
    },
  },

  methods: {
    async salvar() {
      if (this.$refs.formRemessa.validate()) {
        try {
          this.loading = true;
          if (this.remessa.cheques.length > 0) {
            if (this.remessa._id) {
              await this.$store.dispatch('remessasCheques/atualizar', this.remessa);
            } else {
              await this.$store.dispatch('remessasCheques/salvar', this.remessa);
            }
            this.$router.push({ name: 'RemessasChequesLista' });
          } else {
            this.$store.commit('setMensagemErro', 'Adicione pelo menos 1 cheque à remessa');
          }
        } catch (error) {
          throw error;
        } finally {
          this.loading = false;
        }
      }
    },

    async filtrarBusca() {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('remessasCheques/listarChequesEmCaixa', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    limparBusca() {
      this.$store.dispatch('remessasCheques/listarChequesEmCaixa', {});
    },

    contaSelecionada(conta) {
      this.$store.commit('remessasCheques/setConta', conta);
    },

    incluirCheque(cheque) {
      this.$store.commit('remessasCheques/ADICIONAR_CHEQUE_REMESSA', cheque);
      this.$store.commit('remessasCheques/SET_VALOR_TOTAL', this.total);
    },

    excluirCheque(cheque) {
      this.$store.commit('remessasCheques/REMOVER_CHEQUE_REMESSA', cheque);
      this.$store.commit('remessasCheques/SET_VALOR_TOTAL', this.total);
    },

    cancelar() {
      this.$store.commit('remessasCheques/estadoInicial');
      this.$router.push({ name: 'RemessasChequesLista' });
    },
  },

  async created() {
    try {
      this.loading = true;
      const remessa = this.$store.state.remessasCheques.remessaAtual;
      this.$store.commit('remessasCheques/SET_VALOR_TOTAL', this.total);
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!remessa._id || remessa._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um remessa para editar');
        this.$router.push({ name: 'RemessasChequesLista' });
      }
      if (!estaEditando) {
        this.$store.commit('remessasCheques/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}
</style>
