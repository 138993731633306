<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
      <v-form v-else v-model="valid" ref="formMva">
        <v-container fluid>
          <v-widget title="MVA">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <buscar-ncm ref="campoNcm" v-model="mva.ncm.codigo" @ncmSelecionado="ncmSelecionado"></buscar-ncm>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-text-field label="Descrição do NCM" v-model="mva.ncm.descricao"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Mercado Interno" ref="campoMercadoInterno" v-model="mva.mercado_interno" :rules="[rules.percentual, rules.valorPositivo]" @keypress.native.enter="$refs.campoMercadoExterno4.focus()"></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Mercado Externo 4%" ref="campoMercadoExterno4" v-model="mva.mercado_externo_4" :rules="[rules.percentual, rules.valorPositivo]" @keypress.native.enter="$refs.campoMercadoExterno7.focus()"></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Mercado Externo 7%" ref="campoMercadoExterno7" v-model="mva.mercado_externo_7" :rules="[rules.percentual, rules.valorPositivo]" @keypress.native.enter="$refs.campoMercadoExterno12.focus()"></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Mercado Externo 12%" ref="campoMercadoExterno12" v-model="mva.mercado_externo_12" :rules="[rules.percentual, rules.valorPositivo]" @keypress.native.enter="salvar"></as-campo-valor>
                </v-col>
              </v-row>
            </template>
            <template v-slot:widget-footer-action>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import * as regras from '@/util/validacaoFormulario';
import constantes from '@/util/constantes';

export default {
  name: 'FormularioMva',
  data: () => ({
    valid: false,
    rules: { ...regras },
    constantes: constantes,
    loading: false,
  }),

  computed: {
    ...mapState('mva', {
      mva: 'mvaAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('mva', {
      estaEditando: 'estaEditando',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formMva.validate()) {
        try {
          if (this.mva._id) {
            await this.$store.dispatch('mva/atualizar', this.mva);
          } else {
            await this.$store.dispatch('mva/salvar', this.mva);
          }
          this.$router.push({ name: 'mvas' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('mva/estadoInicial');
      this.$router.push({ name: 'mvas' });
    },

    ncmSelecionado(ncm) {
      this.$store.commit('mva/SET_NCM', ncm);
      setTimeout(() => {
        this.$refs.campoMercadoInterno.focus();
      }, 300);
    },
  },

  async created() {
    try {
      this.loading = true;
      const mva = this.$store.state.mva.mvaAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!mva._id || mva._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um mva para editar');
        this.$router.push({ name: 'mvas' });
      }
      if (!estaEditando) {
        this.$store.commit('mva/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
