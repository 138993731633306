<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Conta a Receber</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-2">
          <v-container fluid>
            <v-form v-model="valid" ref="formContaReceber" lazy-validation>
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <buscar-participante label="Buscar Cliente" ref="participante" v-model="cnpjCpfParticipante" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]" :disabled="visualizar"></buscar-participante>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <v-text-field label="Cliente" v-model="conta.participante_razaoSocialNome" readonly :disabled="visualizar"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Documento" v-model="conta.documento" :rules="[rules.obrigatorio]" ref="documento" @keypress.native.enter="$refs.dataCadastro.focus()" :disabled="visualizar"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <campo-data label="Cadastro" v-model="conta.data_cadastro" :rules="[rules.obrigatorio]" ref="dataCadastro" @keypress.native.enter="$refs.dataVencimento.focus()" :disabled="visualizar"></campo-data>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <campo-data label="Vencimento" v-model="conta.data_vencimento" :rules="[rules.obrigatorio]" ref="dataVencimento" @keypress.native.enter="$refs.valor.focus()" :disabled="visualizar"></campo-data>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <as-campo-valor label="Valor" v-model="conta.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="salvar" :disabled="visualizar"></as-campo-valor>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar" :disabled="carregando">{{ !visualizar ? 'Cancelar' : 'Fechar' }}</v-btn>
          <v-btn color="primary" v-show="!visualizar" :loading="carregando" @click="salvar">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'DialogoFormContaReceber',
  data: () => ({
    dialog: false,
    valid: false,
    constantes: constantes,
    cnpjCpfParticipante: '',
    rules: { ...regras },
    conta: {},
    carregando: false,
    visualizar: false,
  }),

  // computed: {
  //   ...mapState('contareceber', {
  //     conta: 'contaAtual',
  //     carregando: 'carregando',
  //     visualizar: 'visualizar',
  //   }),
  // },

  methods: {
    abrir() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.participante.focus();
      }, 500);
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    async salvar() {
      if (this.$refs.formContaReceber.validate()) {
        try {
          if (this.conta.participante._id) {
            if (this.conta._id) {
              await this.$store.dispatch('contareceber/atualizar');
            } else {
              await this.$store.dispatch('contareceber/salvar');
            }
            this.fechar();
          } else {
            this.$store.commit('setMensagemErro', 'Escolha o cliente');
            return false;
          }
        } catch (error) {
          this.$store.commit('setMensagemErro', error.response.data.motivo);
        }
      }
    },

    cancelar() {
      this.$store.commit('contareceber/ESTADO_INICIAL');
      this.fechar();
    },

    adicionarParticipante(participante) {
      this.cnpjCpfParticipante = formatador.formatarCnpj(participante.cnpjCpf);
      this.$store.commit('contareceber/SET_PARTICIPANTE', participante);
      setTimeout(() => {
        this.$refs.documento.focus();
      }, 500);
    },
  },

  async created() {
    try {
      // let conta = this.$store.state.contareceber.contaAtual;
      // let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
      // if (estaEditando && (!conta._id || conta._id === '')) {
      //   this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
      //   this.$router.push({ name: 'contasReceber' });
      // }
      // if (!estaEditando) {
      //   this.$store.commit('contareceber/ESTADO_INICIAL');
      // } else {
      //   this.cnpjCpfParticipante = conta.participante.cnpjCpf;
      // }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      //
    }
  },
};
</script>
