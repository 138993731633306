import ServicePedidosClientes from '@/services/pedidosClientes';
import moment from 'moment';
import { clone } from '@/util/helpers';

const item = {
  numero: '',
  participante: {},
  data: moment().format('YYYY-MM-DD'),
  quantidade: 0,
  empresa: '',
  status: 'Pendente',
  observacao: '',
  valor: 0,
  produtos: [],
};

export default {
  namespaced: true,

  state: {
    item: { ...item },
    itens: [],
    total: 0,
    carregando: false,
  },

  getters: {},

  mutations: {
    SET_ITENS(state, itens) {
      state.itens = itens;
    },

    SET_TOTAL_ITENS(state, total) {
      state.total = total;
    },

    SET_ITEM(state, item) {
      state.item = item;
    },

    ADICIONAR_ITEM(state, item) {
      state.itens.unshift(item);
    },

    ATUALIZAR_ITEM(state, pedidoAtualizado) {
      let index = state.itens.findIndex((pedido) => {
        return pedido._id == pedidoAtualizado._id;
      });

      if (index > -1) {
        state.itens.splice(index, 1, pedidoAtualizado);
      }

      state.item = pedidoAtualizado;
    },

    EXCLUIR_ITEM(state, id) {
      let index = state.itens.findIndex((item) => item._id == id);
      if (index > -1) {
        state.itens.splice(index, 1);
      }
    },

    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },

    ZERAR(state) {
      state.item = clone(item);
      state.item.produtos = [];
    },

    SET_PARTICIPANTE(state, participante) {
      state.item.participante = participante;
    },

    SET_PRODUTO(state, produto_novo) {
      state.item.produtos.push(produto_novo);
    },

    UPDATE_PRODUTO(state, produto_atualizado) {
      let index = state.item.produtos.findIndex((produto) => produto.codigo == produto_atualizado.codigo);
      if (index > -1) {
        state.item.produtos.splice(index, 1, produto_atualizado);
      }
    },

    SET_TOTAIS(state, totais) {
      state.item.valor = totais.valor;
      state.item.quantidade = totais.quantidade;
    },

    EXCLUIR_PRODUTO(state, produto_remover) {
      let index = state.item.produtos.findIndex((produto) => produto.codigo == produto_remover.codigo && produto.quantidade == produto_remover.quantidade);
      if (index > -1) {
        state.item.produtos.splice(index, 1);
      }
    },

    ALTERAR_STATUS(state, status_novo) {
      state.item.status = status_novo;
    },
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await ServicePedidosClientes.listar(options);
        commit('SET_ITENS', response.pedidos_clientes);
        commit('SET_TOTAL_ITENS', response.total);
      } catch (error) {
        commit('SET_ITENS', []);
        commit('setMensagemErro', 'Não foi possível listar os pedidos', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async salvar({ commit, rootState }, item) {
      try {
        commit('SET_CARREGANDO');
        let pedidoNovo = await ServicePedidosClientes.salvar(item);
        commit('ADICIONAR_ITEM', pedidoNovo);
      } catch (error) {
        commit('setMensagemErro', 'Houve um error ao salvar o pedido', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async atualizar({ commit }, item) {
      commit('SET_CARREGANDO');
      let pedidoAtualizado = await ServicePedidosClientes.atualizar(item);
      commit('ATUALIZAR_ITEM', pedidoAtualizado);
      commit('SET_CARREGANDO', false);
    },

    async excluir({ commit }, pedidocliente_id) {
      commit('SET_CARREGANDO');
      await ServicePedidosClientes.excluir(pedidocliente_id);
      commit('EXCLUIR_ITEM', pedidocliente_id);
      commit('SET_CARREGANDO', false);
    },
  },
};
