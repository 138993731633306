<template>
  <v-dialog v-model="dialog" max-width="750px" @keydown.esc="fechar" persistent eager>
    <v-card>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="2" class="text-center">
            <v-progress-circular :size="70" :width="5" indeterminate color="primary"></v-progress-circular>
          </v-col>
          <v-col cols="10" class="text-center" align-self="center">
            <h2>Preparando o terminal. Aguarde...</h2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PreparandoTerminal',

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },
  },
};
</script>

<style scoped></style>
