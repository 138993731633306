<template>
  <v-container fluid>
    <v-widget title="Produto" class="pb-0" style="height: calc(100vh - 136px); border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container grid-list-md fluid class="pa-0" style="overflow: hidden auto; box-sizing: border-box; height: calc(100vh - 268px)">
            <v-row dense class="pt-1">
              <v-col cols="12" sm="8">
                <v-row dense>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      autofocus
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Código"
                      v-model="produto.codigo"
                      v-input-upper-case
                      v-mask="vMaskCustom({ maxLength: 60, maskChar: 'F', pattern: /[a-zA-Z0-9-.]/ })"
                      :rules="[rules.obrigatorio, rules.caracteresEspeciais]"
                      ref="codigo"
                      @keypress.native.enter="$refs.descricao.focus()"
                    />
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-combobox
                      class="nofocus"
                      readonly
                      append-icon="add_box"
                      :items="produto.codigosBarras"
                      multiple
                      @click:append="novoCodigoBarras"
                      small-chips
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Códigos Barras"
                      ref="codigosBarras"
                      v-model="produto.codigosBarras"
                      @keypress.native.enter="$refs.descricao.focus()"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip label small>
                          <span class="pr-2">{{ item }}</span>
                          <v-icon small @click="removerCodigoBarras(item)">clear</v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-text-field
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Descrição"
                      v-model="produto.descricao"
                      v-mask="vMaskCustom({ maxLength: 120, maskChar: 'F', pattern: /[a-zA-Z0-9-.\s]/ })"
                      :rules="[rules.obrigatorio]"
                      ref="descricao"
                      @keypress.native.enter="$refs.descricaoAdicional.focus()"
                    />
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-text-field
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Descrição Adicional"
                      v-model="produto.descricaoAdicional"
                      ref="descricaoAdicional"
                      v-mask="vMaskCustom({ maxLength: 120, maskChar: 'F', pattern: /[a-zA-Z0-9-.\s]/ })"
                      @keypress.native.enter="openMenu($refs.unidade)"
                    />
                  </v-col>

                  <v-col cols="12" sm="8">
                    <v-select
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Unidade"
                      :items="unidades"
                      return-object
                      v-model="produto.unidade"
                      ref="unidade"
                      @keyup.enter="onSelectEnterKey($refs.unidade, () => openMenu($refs.ippt))"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <v-select
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="IPPT"
                      :items="constantes.IPPTs"
                      v-model="produto.ippt"
                      ref="ippt"
                      :rules="[rules.obrigatorio]"
                      @keyup.enter="onSelectEnterKey($refs.ippt, () => $refs.ncm.$refs.ncm.focus())"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'pl-3' : 'pb-3'">
                <v-container fluid class="pa-0" style="border: 1px solid silver; border-radius: 4px">
                  <v-img height="282" v-bind:src="imagemSrc" @click="escolherArquivo" style="cursor: pointer; background-color: whitesmoke; background-size: cover !important; border-radius: 4px 4px 0px 0px">
                    <template v-slot:placeholder>
                      <div style="display: flex; height: 100%; align-items: center; justify-content: center; font-size: large; color: gray">Imagem não disponível</div>
                    </template>
                  </v-img>

                  <v-container class="px-2 pt-2" fluid style="height: 54px; display: flex; flex-direction: row; border-radius: 0px 0px 4px 4px">
                    <input id="inputArquivo" type="file" :multiple="false" hidden @change="onFileSelected" accept=".jpg,.png,.jpeg,.bmp" />
                    <v-btn icon @click="limparImagem">
                      <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn text depressed @click="camera">Câmera<v-icon class="ml-3">photo_camera</v-icon></v-btn>
                    <v-btn text depressed @click="escolherArquivo">Arquivo<v-icon class="ml-3">image</v-icon></v-btn>
                  </v-container>
                </v-container>
              </v-col>

              <v-col cols="12" sm="3">
                <buscar-ncm ref="ncm" v-model="produto.ncm" @onSelect="onSelectNcm" @input="getNcm" :valid="!!ncmDescricao" />
              </v-col>

              <v-col cols="12" sm="9">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Descrição NCM" :rules="[rules.obrigatorio]" required readonly :value="ncmDescricao" />
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Grupo"
                  :items="grupos"
                  item-text="nome"
                  item-value="_id"
                  return-object
                  v-model="produto.grupo"
                  @change="selecionarGrupo"
                  ref="grupo"
                  @keyup.enter="onSelectEnterKey($refs.grupo, () => openMenu($refs.subGrupo))"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="SubGrupo"
                  :items="grupo ? grupo.subGrupos : []"
                  item-text="nome"
                  item-value="_id"
                  v-model="produto.subGrupo"
                  ref="subGrupo"
                  @keyup.enter="$refs.valorCusto.focus()"
                />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Formação de Preço</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Valor de Custo"
                  background-color="grey lighten-3"
                  style="font-weight: 500; font-size: 22px"
                  v-model="produto.valorCusto"
                  fixarDecimais
                  decimais="2"
                  prefix="R$"
                  @input="calcularPrecos"
                  ref="valorCusto"
                  @keypress.native.enter="$refs.lucro.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Lucro"
                  prefix="%"
                  v-model="produto.lucro"
                  fixarDecimais
                  decimais="2"
                  @input="calcularPrecos"
                  ref="lucro"
                  @keypress.native.enter="$refs.ipi.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="IPI"
                  v-model="produto.ipi"
                  fixarDecimais
                  decimais="2"
                  @input="calcularPrecos"
                  prefix="%"
                  ref="ipi"
                  @keypress.native.enter="$refs.ii.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="II"
                  v-model="produto.ii"
                  fixarDecimais
                  decimais="2"
                  @input="calcularPrecos"
                  prefix="%"
                  ref="ii"
                  @keypress.native.enter="$refs.freteTipo.focus()"
                />
              </v-col>

              <v-col cols="12" sm="1">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Tipo"
                  :items="tiposInformacaoFrete"
                  v-model="produto.frete.tipo"
                  @input="calcularPrecos"
                  ref="freteTipo"
                  @keypress.native.enter="$refs.freteValor.focus()"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Frete"
                  :prefix="produto.frete.tipo === 'Percentual' ? '%' : 'R$'"
                  v-model="produto.frete.valor"
                  fixarDecimais
                  decimais="2"
                  @input="calcularPrecos"
                  ref="freteValor"
                  @keypress.native.enter="$refs.outros.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Outros"
                  v-model="produto.outros"
                  fixarDecimais
                  decimais="2"
                  @input="calcularPrecos"
                  prefix="%"
                  ref="outros"
                  @keypress.native.enter="$refs.valor.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="À Vista"
                  background-color="light-green lighten-5"
                  prefix="R$"
                  style="font-weight: 500; font-size: 22px"
                  v-model="produto.valor"
                  fixarDecimais
                  decimais="2"
                  ref="valor"
                  @keypress.native.enter="$refs.valorPrazo.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="À Prazo"
                  background-color="light-green lighten-5"
                  prefix="R$"
                  style="font-weight: 500; font-size: 22px"
                  v-model="produto.valorPrazo"
                  fixarDecimais
                  decimais="2"
                  ref="valorPrazo"
                  @keypress.native.enter="() => (session.empresa.configuracaoTributaria.regimeTributario === '1' ? openMenu($refs.cstCsosn) : openMenu($refs.origem))"
                />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Impostos</v-toolbar-title>
            </v-toolbar>

            <v-toolbar flat dense style="border-radius: 4px 4px 0px 0px; background-color: silver" class="mt-2">
              <v-toolbar-title>ICMS</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col v-if="session.empresa.configuracaoTributaria.regimeTributario !== '1'" cols="12">
                <v-select placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Origem" :items="origens" v-model="produto.icms.origem" ref="origem" />
              </v-col>

              <v-col cols="12" sm="10">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  :label="cstCsosn.toUpperCase()"
                  v-model="produto.icms[cstCsosn]"
                  :items="cstCsosn === 'cst' ? csts : csosns"
                  ref="cstCsosn"
                  @keyup.enter="onSelectEnterKey($refs.cstCsosn, () => $refs.aliquotaIcms.focus())"
                  :rules="[rules.obrigatorio]"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Alíquota"
                  v-model="produto.icms.aliquota"
                  fixarDecimais
                  decimais="2"
                  prefix="%"
                  ref="aliquotaIcms"
                  @keyup.enter="openMenu($refs.pisEntrada)"
                />
              </v-col>

              <v-col v-if="temCest" cols="12" sm="3">
                <buscar-cest ref="cest" v-model="produto.cest" @onSelect="onSelectCest" @input="getCest" :valid="!!cestDescricao" />
              </v-col>

              <v-col v-if="temCest" cols="12" sm="9">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Descrição" required readonly :value="cestDescricao" />
              </v-col>
            </v-row>

            <v-toolbar flat dense style="border-radius: 4px 4px 0px 0px; background-color: silver" class="mt-2">
              <v-toolbar-title>PIS</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="10">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="CST Entrada"
                  v-model="produto.pis.entrada.cst"
                  :items="pisEntrada"
                  ref="pisEntrada"
                  @keyup.enter="onSelectEnterKey($refs.pisEntrada, () => $refs.aliquotaPisEntrada.focus())"
                  :rules="[rules.obrigatorio]"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Alíquota"
                  v-model="produto.pis.entrada.aliquota"
                  fixarDecimais
                  decimais="2"
                  ref="aliquotaPisEntrada"
                  prefix="%"
                  @keyup.enter="openMenu($refs.pisSaida)"
                />
              </v-col>

              <v-col cols="12" sm="10">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="CST Saída"
                  v-model="produto.pis.saida.cst"
                  :items="pisSaida"
                  ref="pisSaida"
                  @keyup.enter="onSelectEnterKey($refs.pisSaida, () => $refs.aliquotaPisSaida.focus())"
                  :rules="[rules.obrigatorio]"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Alíquota"
                  v-model="produto.pis.saida.aliquota"
                  fixarDecimais
                  decimais="2"
                  ref="aliquotaPisSaida"
                  prefix="%"
                  @keyup.enter="openMenu($refs.cofinsEntrada)"
                />
              </v-col>
            </v-row>

            <v-toolbar flat dense style="border-radius: 4px 4px 0px 0px; background-color: silver" class="mt-2">
              <v-toolbar-title>COFINS</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="10">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="CST Entrada"
                  v-model="produto.cofins.entrada.cst"
                  :items="cofinsEntrada"
                  ref="cofinsEntrada"
                  @keyup.enter="onSelectEnterKey($refs.cofinsEntrada, () => $refs.aliquotaCofinsEntrada.focus())"
                  :rules="[rules.obrigatorio]"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Alíquota"
                  v-model="produto.cofins.entrada.aliquota"
                  fixarDecimais
                  decimais="2"
                  ref="aliquotaCofinsEntrada"
                  prefix="%"
                  @keyup.enter="openMenu($refs.cofinsSaida)"
                />
              </v-col>

              <v-col cols="12" sm="10">
                <v-select
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="CST Saída"
                  v-model="produto.cofins.saida.cst"
                  :items="cofinsSaida"
                  ref="cofinsSaida"
                  @keyup.enter="onSelectEnterKey($refs.cofinsSaida, () => $refs.aliquotaCofinsSaida.focus())"
                  :rules="[rules.obrigatorio]"
                />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Alíquota"
                  v-model="produto.cofins.saida.aliquota"
                  fixarDecimais
                  decimais="2"
                  prefix="%"
                  ref="aliquotaCofinsSaida"
                  @keyup.enter="onSelectEnterKey($refs.aliquotaCofinsSaida, () => $refs.fabricante.$refs.fabricante.focus())"
                />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Dados Adicionais</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="8">
                <buscar-fabricante ref="fabricante" v-model="fabricanteNome" @onSelect="onSelectFabricante" />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Código Fabricante" v-model="produto.codigoFabricante" />
              </v-col>

              <v-col cols="12" sm="3">
                <div class="pa-2 px-4" style="display: flex; height: 56px; background-color: silver; border-radius: 4px; font-size: 18px; align-items: center">
                  <span>Fator de Conversão</span>
                  <v-icon class="ml-auto" large>sync_alt</v-icon>
                </div>
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor prepend-inner-icon="south" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Entrada" v-model="produto.fatorConversao.entrada" truncarDecimais decimais="1" />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor prepend-inner-icon="north" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Saída" v-model="produto.fatorConversao.saida" truncarDecimais decimais="1" />
              </v-col>

              <v-col cols="6" sm="5">
                <campo-valor
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Estoque Mínimo"
                  v-model="produto.estoqueMinimo"
                  truncarDecimais
                  decimais="3"
                  ref="estoqueMinimo"
                  @keypress.native.enter="$refs.localizacao.focus()"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field hide-details placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Localização" v-model="produto.localizacao" ref="localizacao" />
              </v-col>
            </v-row>
          </v-container>

          <v-divider />

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <div style="float: left; display: flex; gap: 16px">
              <v-checkbox class="mt-1" label="Ativo" v-model="produto.ativo" color="primary" hide-details />
              <v-checkbox class="mt-1" label="Venda Fracionada" v-model="produto.vendaFracionada" color="primary" hide-details />
            </div>
            <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="loading" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </v-container>
        </v-form>
      </template>

      <template v-if="float" v-slot:widget-header-action>
        <v-icon style="cursor: pointer" @click="$emit('fechar')">close</v-icon>
      </template>
    </v-widget>

    <v-dialog v-model="dialogoCamera" width="auto" persistent>
      <v-widget title="Capturar Imagem" noDivider clo>
        <div slot="widget-content" style="position: relative">
          <video id="player" autoplay width="640px" height="480px" style="border-radius: 4px; background-color: silver" />
          <canvas id="canvas" hidden />
          <v-btn large depressed style="position: absolute; bottom: 16px; right: 16px; opacity: 0.6" @click="capturarImagem">Capturar Imagem</v-btn>
        </div>
        <div slot="widget-header-action">
          <v-btn icon @click="cancelarImagem" style="margin-right: -12px">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-widget>
    </v-dialog>

    <v-dialog v-model="dialogoCodigoBarras" max-width="420px">
      <v-widget title="Código de Barras" noDivider clo>
        <div slot="widget-content" style="position: relative">
          <v-row dense>
            <v-col cols="12">
              <v-form v-model="validCodigoBarras">
                <v-text-field
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Código Barras"
                  v-model="codigoBarras"
                  ref="codigoBarras"
                  @keypress.native.enter="salvarCodigoBarras"
                  counter="20"
                  :rules="[rules.caracteresEspeciais, rules.semEspacos, rules.minimo3Caracteres, rules.maximo20Caracteres]"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <template v-slot:widget-footer-action>
          <v-card-actions class="pt-0">
            <v-btn depressed class="mr-2" @click="dialogoCodigoBarras = false">Cancelar</v-btn>
            <v-btn depressed color="primary" @click="salvarCodigoBarras" :disabled="!validCodigoBarras || codigoBarras.length < 3">Adicionar</v-btn>
          </v-card-actions>
        </template>
      </v-widget>
    </v-dialog>

    <dialog-confirmacao ref="dialogConfirmacao" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';
import { URL_ANRON_API } from '@/util/config';

export default {
  name: 'FormularioProduto',
  components: {
    DialogConfirmacao,
  },

  props: {
    cadastroNfe: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rules: { ...regras },
    constantes,

    tiposInformacaoFrete: [
      { text: 'R$', value: 'Valor' },
      { text: '%', value: 'Percentual' },
    ],

    ncmDescricao: '',
    cestDescricao: '',
    fabricanteNome: '',

    cstCsosn: 'csosn', // Simples / Lucro real ou presumido

    infoError: false,
    valid: false,
    validCodigoBarras: false,
    loading: false,

    codigoBarras: '',
    dialogoCodigoBarras: false,

    imagem: null,
    imagemSrc: '',
    dialogoCamera: false,
  }),

  computed: {
    ...mapState('produto', {
      produto: 'produto',
    }),

    ...mapState('fabricante', {
      fabricantes: 'fabricantes',
    }),

    ...mapState('grupo', {
      grupos: 'grupos',
      grupo: 'grupo',
    }),

    ...mapState('cest', {
      cests: 'cests',
    }),
    ...mapState('autenticacao', {
      session: 'session',
    }),

    ...mapGetters({
      unidades: 'unidadesSelect',
      origens: 'origensSelect',
      csts: 'cstsSelect',
      csosns: 'csosnsSelect',
      pisEntrada: 'pisEntradaSelect',
      pisSaida: 'pisSaidaSelect',
      cofinsEntrada: 'cofinsEntradaSelect',
      cofinsSaida: 'cofinsSaidaSelect',
    }),

    temCest() {
      return constantes.CEST[this.cstCsosn].includes(this.produto.icms[this.cstCsosn]);
    },
  },

  created() {
    try {
      const editando = this.$route.path.indexOf('editar') > -1 ? true : false;

      if (editando && (!this.produto._id || this.produto._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um produto para editar');
        this.$router.push({ name: 'produtos' });
        return;
      }

      if (editando) {
        this.imagemSrc = this.produto.imagem ? `${URL_ANRON_API}/images/${this.produto.imagem}` : '';
      }

      this.$store.dispatch('grupo/listar', {}).then(() => {
        if (editando) {
          const grupo = this.grupos.find((gr) => gr._id === this.produto.grupo);
          this.$store.commit('grupo/setState', { grupo });
        }
      });

      this.$store.dispatch('fabricante/listar', {}).then(() => {
        if (editando) {
          const fabricante = this.fabricantes.find((fabr) => fabr._id === this.produto.fabricante);
          this.fabricanteNome = fabricante.nome;
        }
      });

      if (this.session.empresa.configuracaoTributaria.regimeTributario === '1') {
        this.cstCsosn = 'csosn';
      } else {
        this.cstCsosn = 'cst';
      }

      this.getNcm(this.produto.ncm);
      this.getCest(this.produto.cest);
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.codigo.focus();
      this.$nextTick(() => this.$refs.form.validate());
    });
  },

  methods: {
    vMaskCustom({ maxLength = 10, maskChar = 'X', pattern }) {
      return maskChar !== 'X'
        ? {
            mask: ''.padEnd(maxLength, maskChar),
            tokens: { [maskChar]: { pattern } },
          }
        : ''.padEnd(maxLength, maskChar);
    },

    novoCodigoBarras() {
      this.codigoBarras = '';
      this.dialogoCodigoBarras = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.codigoBarras.focus();
        });
      });
    },

    removerCodigoBarras(cb) {
      this.produto.codigosBarras = this.produto.codigosBarras.filter((item) => item !== cb);
    },

    salvarCodigoBarras() {
      this.dialogoCodigoBarras = false;
      this.produto.codigosBarras.push(this.codigoBarras);
    },

    selecionarGrupo() {
      this.$store.commit('grupo/setState', { grupo: this.produto.grupo });
      this.produto.subGrupo = null;
    },

    onSelectEnterKey(element, callF) {
      this.$nextTick(() => {
        if (!element.isMenuActive && callF) {
          element.blur();
          callF();
        }
      });
    },

    openMenu(ref) {
      ref.focus();
      ref.isMenuActive = true;
    },

    getCest(cest) {
      const found = this.$store.state.tabelas.cests.find((item) => item.cest === cest && cest.length === 7);
      this.cestDescricao = found ? found.descricao : '';
    },

    onSelectCest(cest) {
      if (cest) {
        this.produto.cest = cest.cest;
        this.cestDescricao = cest.descricao;
      } else {
        this.openMenu(this.$refs.pisEntrada);
      }

      this.$nextTick(() => {
        this.$refs.cest.$el.scrollIntoView();
      });
    },

    getNcm(ncm) {
      const found = this.$store.state.tabelas.ncms.find((item) => item.codigo === ncm && ncm.length === 8);
      this.ncmDescricao = found ? found.descricao : '';
    },

    onSelectNcm(ncm) {
      if (ncm) {
        this.produto.ncm = ncm.codigo;
        this.ncmDescricao = ncm.descricao;
      } else {
        this.openMenu(this.$refs.grupo);
      }

      this.$nextTick(() => {
        this.$refs.ncm.$el.scrollIntoView();
      });
    },

    onSelectFabricante(fabricante) {
      if (fabricante) {
        this.produto.fabricante = fabricante._id;
        this.fabricanteNome = fabricante.nome;
      }

      this.$nextTick(() => {
        this.$refs.fabricante.$el.scrollIntoView();
      });
    },

    async salvar() {
      if (this.$refs.form.validate()) {
        this.produto.unidade = this.produto.unidade.value || this.produto.unidade;
        this.produto.grupo = this.produto.grupo._id || this.produto.grupo;

        this.loading = true;
        if (this.produto._id) {
          const result = await this.$store.dispatch('produto/editar', this.imagem);
          this.loading = false;
          if (!result) return;
        } else {
          const result = await this.$store.dispatch('produto/adicionar', this.imagem);
          this.loading = false;
          if (!result) return;
        }

        if (this.float) {
          this.$emit('fechar');
        } else {
          this.$router.push({ name: 'produtos' });
        }
      }
    },

    cancelar() {
      this.$store.dispatch('produto/novo');
      if (this.cadastroNfe) {
        this.$emit('fecharDialogo', 'cancelado');
      } else {
        this.$router.push({ name: 'produtos' });
      }
    },

    calcularPrecos() {
      let despesas = 0;
      despesas += this.produto.valorCusto * (this.produto.ipi / 100);
      despesas += this.produto.valorCusto * (this.produto.ii / 100);
      despesas += this.produto.valorCusto * (this.produto.outros / 100);

      if (this.produto.frete.tipo === 'Valor') {
        despesas += this.produto.frete.valor;
      } else if (this.produto.frete.tipo == 'Percentual') {
        despesas += this.produto.valorCusto * (this.produto.frete.valor / 100);
      }

      const valor = this.produto.valorCusto + this.produto.valorCusto * (this.produto.lucro / 100) + despesas;
      this.produto.valor = Math.floor(valor * 100) / 100;
      this.produto.valorPrazo = this.produto.valor;
    },

    onFileSelected(evento) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.processarImagem(e.target.result);
      };

      reader.readAsDataURL(evento.target.files[0]);
    },

    escolherArquivo() {
      document.getElementById('inputArquivo').click();
    },

    limparImagem() {
      document.getElementById('inputArquivo').value = '';
      this.imagemSrc = '';
      this.imagem = null;
    },

    processarImagem(imagem) {
      const image = new Image();
      const context = this;

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const max_size = 640;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;

        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');

        canvas.remove();

        context.imagem = dataUrl;
        context.imagemSrc = dataUrl;
      };

      image.src = imagem;
    },

    camera() {
      this.dialogoCamera = true;

      this.$nextTick(() => {
        const player = document.getElementById('player');
        player.style.display = 'block';

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          player.srcObject = stream;
        });
      });
    },

    capturarImagem() {
      const player = document.getElementById('player');
      const canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');

      const { height, width } = player.getBoundingClientRect();
      canvas.height = height;
      canvas.width = width;
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      this.processarImagem(canvas.toDataURL('image/jpeg'));

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
      player.srcObject = null;

      player.style.display = 'none';
      this.dialogoCamera = false;
    },

    cancelarImagem() {
      this.dialogoCamera = false;
      const player = document.getElementById('player');

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    },
  },
};
</script>

<style scoped>
.container.grid-list-md .row .flex {
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}

.vermelho {
  color: red !important;
}

.nofocus {
  user-select: none !important;
}
.nofocus >>> input::selection {
  background: transparent;
}
</style>
