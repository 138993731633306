import constantes from '@/util/constantes';
import ibptsApi from '@/services/ibptsApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    itens: [],
    itemAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {},

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async tratarConteudo({ commit }, dados) {
      try {
        commit('setCarregando', true);

        let linhas = dados.split('\n');
        let cabecalhos = linhas[0];
        let keys = cabecalhos.split(';');

        let objetos = [];
        for (var indiceLinha = 1; indiceLinha < linhas.length; indiceLinha++) {
          let linha = linhas[indiceLinha];
          let campos = linha.split(';');
          let objeto = {};
          for (var i = 0; i < keys.length; i++) {
            if (campos[i]) {
              let campoFormatado = campos[i].replace('"', '').replace('"', '').replace('\r', '');
              objeto[keys[i].trim()] = campoFormatado;
            }
          }
          objetos.push(objeto);
        }
        let response = await ibptsApi.tratarConteudo(objetos);
        commit('setItens', response);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async carregarItens({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await ibptsApi.carregarItens();
        commit('setItens', response);
        commit('setCarregando', false);
        commit('setMensagemSucesso', 'IBPTs carregados com sucesso', { root: true });
      } catch (error) {
        commit('setItens', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    detalhar({ commit }, dados) {
      commit('setCarregando', true);
      dados.router.push('/ibpt/detalhar');
      commit('detalhar', dados.item);
      commit('setCarregando', false);
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setItens(state, itens) {
      itens.forEach((item) => {
        state.itens.push(item);
      });
    },
    detalhar(state, item) {
      state.itemAtual = item;
    },
  },
};
