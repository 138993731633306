import axios from '@/util/axios';

function imprimirNota(nota) {
  return axios.post('/notas/danfe', nota).then((response) => response.data);
}

function novoVisualizar(nota) {
  return axios.get(`/empresas/notas/${nota._id}/danfe`).then((response) => response.data);
}

function reprocessarNota(idNota) {
  return axios.post('/notas/processar', idNota).then((response) => response.data);
}

function enviarCartaCorrecao(cartaCorrecao) {
  return axios.post('/servicos/recepcao_evento', cartaCorrecao).then((response) => response.data);
}

function inutilizarIntervalo(inutilizacao) {
  return axios.post('/servicos/inutilizar', inutilizacao).then((response) => response.data);
}

function inutilizarNota(inutilizacao) {
  return axios.post('/notas/inutilizar', inutilizacao).then((response) => response.data);
}

function cancelarNota(dados) {
  return axios.post('/servicos/recepcao_evento', dados).then((response) => response.data);
}

function carregarNotas(options) {
  let url = `/notas?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url += `&query=${options.query}`;
  }
  return axios.get(url).then((response) => response.data);
}

function carregarEmpresa() {
  return axios.get(`/empresas`).then((response) => response.data);
}

function salvar(nota) {
  return axios.post(`/notas`, nota).then((response) => response.data);
}

function atualizar(nota) {
  return axios.put(`/notas`, nota).then((response) => response.data);
}

function excluir(nota) {
  return axios.delete('/notas', { data: { _id: nota._id } }).then((response) => response.data);
}

export default {
  imprimirNota,
  novoVisualizar,
  reprocessarNota,
  enviarCartaCorrecao,
  inutilizarIntervalo,
  inutilizarNota,
  cancelarNota,
  carregarNotas,
  carregarEmpresa,
  salvar,
  atualizar,
  excluir,
};
