<template>
  <v-app>
    <v-main class="green darken-3" style="height: 100%">
      <v-container fluid style="height: 100%">
        <v-card class="green darken-1" style="height: 100px; width: 100%" v-if="!telaVenda">
          <v-card-text class="fill-height white--text d-flex align-center">
            <span class="text-h3">TERMINAL LIVRE</span>
          </v-card-text>
        </v-card>

        <pdv-venda ref="pdvVenda" v-if="telaVenda" />

        <div v-else style="width: 100%; height: calc(100% - 200px)" />

        <v-card tile class="transparent" flat style="width: 100%">
          <v-card-text class="pa-0 fill-height" style="display: flex">
            <v-btn v-show="!telaVenda" small color="primary" class="mr-1 text-capitalize" @mouseup="novaVenda" v-pfc="novaVenda"
              >Nov{{ ['Venda', 'NFe'].includes(venda.tipo) ? 'a' : 'o' }} {{ tiposVenda[venda.tipo] }}<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">F2</v-sheet></v-btn
            >
            <v-btn v-show="telaVenda" small color="primary" class="mr-1 text-capitalize" @click="finalizarVenda">Finalizar {{ tiposVenda[venda.tipo] }}<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">F3</v-sheet></v-btn>
            <v-btn small color="primary" class="mr-1 text-capitalize" @click="consultarProduto">Consultar Produtos<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">F12</v-sheet></v-btn>
            <v-btn v-show="telaVenda && venda.itens.length" small color="primary" class="mr-1 text-capitalize" @click="cancelarItem">Cancelar Item<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">F5</v-sheet></v-btn>
            <v-btn v-show="telaVenda" small color="primary" class="mr-1 text-capitalize" @click="cancelarVenda">Cancelar {{ tiposVenda[venda.tipo] }}<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">F11</v-sheet></v-btn>
            <v-btn v-show="telaVenda && !novoItemInput && modo !== 'Automático'" small color="primary" class="mr-1 text-capitalize" @click="novoItem">Novo Item<v-sheet class="black--text caption pl-2 pr-2 ml-2 rounded">INS</v-sheet></v-btn>

            <v-spacer />

            <v-menu top left>
              <template v-slot:activator="{ on }">
                <v-sheet @click.stop="" v-on="on" style="display: inline-flex; font-size: 16px; width: 40px; justify-content: center; align-items: center; cursor: pointer" color="orange" class="white--text mr-1 rounded">{{ terminal }}</v-sheet>
              </template>
              <v-list dense class="py-0">
                <v-list-item :key="9999 + 10" link :style="terminalTipo === 'Terminal' ? 'background-color: rgba(0,0,0,0.05)' : 'transparent'" @click="() => mudarTerminalTipo('Terminal')">
                  <v-icon class="pr-3" :color="terminalTipo === 'Terminal' ? 'black' : 'rgba(0,0,0,0.05)'">mdi-check</v-icon>
                  <v-list-item-title>Terminal</v-list-item-title>
                </v-list-item>
                <v-list-item :key="9999 + 20" link :style="terminalTipo === 'Caixa' ? 'background-color: rgba(0,0,0,0.05)' : 'transparent'" @click="() => mudarTerminalTipo('Caixa')">
                  <v-icon class="pr-3" :color="terminalTipo === 'Caixa' ? 'black' : 'rgba(0,0,0,0.05)'">mdi-check</v-icon>
                  <v-list-item-title>Caixa</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn v-if="tef.active" @click.stop="TEFAdmin" depressed fab x-small color="purple" class="mr-1" elevation="2">
              <v-icon color="white">mdi-credit-card-settings-outline</v-icon>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="blue" depressed fab x-small v-on="on" elevation="2" @click.stop="abrirQRCode" class="mr-1">
                  <v-icon color="white">mdi-qrcode-scan</v-icon>
                </v-btn>
              </template>
              <span>{{ !fullscreen ? 'QR-Code' : '' }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" depressed fab x-small v-on="on" elevation="2" @click="handleFullScreen()" class="mr-1">
                  <v-icon>{{ !fullscreen ? 'fullscreen' : 'fullscreen_exit' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ !fullscreen ? 'Tela Inteira' : '' }}</span>
            </v-tooltip>

            <v-menu offset-y origin="right bottom" class="elelvation-1" transition="scale-transition">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" depressed fab x-small v-on="on" elevation="2">
                  <v-icon medium>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-card class="elevation-0">
                <v-card-text class="pa-0">
                  <v-btn block large class="ma-0 pa-2 rounded-0" color="amber lighten-4" elevation="0" @click="setDrawerMenu('easy')">
                    <v-icon color="amber darken-3">sticky_note_2</v-icon>
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Easy</b><small>Retaguarda</small></div>
                  </v-btn>

                  <v-divider />
                  <v-btn block large class="ma-0 pa-2 rounded-0" color="teal lighten-5" elevation="0" @click="setDrawerMenu('nfe')">
                    <v-icon color="teal darken-2">file_present</v-icon>
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor NF-e</small></div>
                  </v-btn>

                  <v-divider />
                  <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
                    <v-icon color="teal darken-2">file_present</v-icon>
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor NF-e Depósito</small></div>
                  </v-btn>

                  <v-divider />
                  <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
                    <v-icon color="teal darken-2">file_present</v-icon>
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor MDF-e</small></div>
                  </v-btn>

                  <v-divider />
                  <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
                    <v-icon color="teal darken-2">file_present</v-icon>
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor CT-e</small></div>
                  </v-btn>

                  <v-divider />
                  <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="abrirMinhaConta">
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; flex-direction: column; align-items: start; justify-content: space-between; font-weight: 400; gap: 4px" class="pl-1">
                      <b>Minha Conta</b><small>{{ $store.state.autenticacao.session.usuario.nome }}</small>
                    </div>
                    <v-avatar size="32">
                      <img :src="`${apiUrl}/images/${$store.state.autenticacao.session.usuario.foto}`" :alt="$store.state.autenticacao.session.usuario.nome.charAt(0)" />
                    </v-avatar>
                  </v-btn>

                  <v-divider />

                  <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="sair">
                    <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-1"><b>Sair</b></div>
                    <v-icon color="black">exit_to_app</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-card-text>

          <v-card-text class="pa-0 fill-height my-1" style="display: flex">
            <v-btn x-small color="primary" class="mr-1 text-capitalize" @click="vendas">Orçamentos<v-sheet class="black--text pl-1 pr-1 ml-2 rounded">F7</v-sheet></v-btn>
            <v-btn v-if="terminalTipo === 'Caixa'" x-small color="primary" class="mr-1 text-capitalize" @click="vendas">Vendas<v-sheet class="black--text pl-1 pr-1 ml-2 rounded">F9</v-sheet></v-btn>
            <v-btn x-small color="primary" class="mr-1 text-capitalize" @click="exibirMenu">Menu<v-sheet class="black--text pl-1 pr-1 ml-2 rounded">F10</v-sheet></v-btn>

            <v-spacer />

            <v-sheet
              style="display: inline-flex"
              v-if="!['ANRON', 'CONTADOR'].includes($store.state.autenticacao.session.usuario.nivelAcesso) && $store.state.autenticacao.session.ambiente === 2"
              color="red"
              class="white--text caption pl-2 pr-2 rounded blink"
              >Ambiente de Testes</v-sheet
            >
            <v-sheet style="display: inline-flex" :color="isOnLine ? 'light-green' : 'grey'" class="white--text caption pl-2 pr-2 ml-1 rounded">Internet</v-sheet>
            <v-sheet style="display: inline-flex" :color="isSefazOnLine ? 'light-blue' : 'grey'" class="white--text caption pl-2 pr-2 ml-1 rounded">SEFAZ</v-sheet>
          </v-card-text>
        </v-card>

        <v-card color="primary" style="height: 35px; width: 100%">
          <v-card-text class="pt-1 pb-1 white--text" style="margin-top: 2px">
            <v-row dense>
              <v-col cols="2">
                <span class="text-body-1 font-weight-bold">EasyPDV</span>
                <span class="subtitulo">4.2</span>
              </v-col>

              <v-col cols="2">
                <div class="text-body-1 font-weight-bold titulo">{{ modo }}</div>
                <div class="subtitulo">Modo - F4</div>
              </v-col>

              <v-col cols="3">
                <div class="text-body-1 font-weight-bold titulo">{{ tabela }}</div>
                <div class="subtitulo">Tabela - F6</div>
              </v-col>

              <v-col cols="5" align="end">
                <relogio show-seconds class="text-body-1 font-weight-bold titulo"></relogio>
                <div class="subtitulo">Data e Hora</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>

    <dialog-buscar-produto ref="buscarproduto" @resultado="setProdutoConsulta" />
    <dialogo-menu ref="dialogoMenu" @abrir:dialogo="abrirDialogo"></dialogo-menu>
    <dialogo-pre-venda ref="dialogoPreVenda"></dialogo-pre-venda>
    <dialogo-orcamentos-pendentes ref="dialogoOrcamentosPendentes"></dialogo-orcamentos-pendentes>
    <dialogo-vendas ref="dialogoVendas"></dialogo-vendas>
    <dialogo-cancelar-item ref="dialogoCancelarItem" @rowClick="pdvVendaRowClick" />
    <dialogo-finalizar-venda ref="dialogoFinalizarVenda" @onFinalizarVenda="onFinalizarVenda" />
    <dialog-confirmacao ref="dialogoConfirmacao"></dialog-confirmacao>
    <dialogo-recebimentos ref="dialogoRecebimentos"></dialogo-recebimentos>
    <dialogo-caixa ref="dialogoCaixa"></dialogo-caixa>
    <dialogo-cheques ref="dialogoCheques"></dialogo-cheques>
    <dialogo-clientes ref="dialogoClientes"></dialogo-clientes>

    <v-dialog v-model="dialogoMinhaConta" max-width="800px" @keydown.esc="fecharMinhaConta" persistent scrollable eager>
      <formulario-informacao-usuario @fechar="fecharMinhaConta" />
    </v-dialog>

    <v-dialog v-model="dialogoQRCode" max-width="560px" @keydown.esc="fecharQRCode" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>QR-Code Cupom Fiscal</v-toolbar-title>
          <v-spacer />
          <v-icon style="cursor: pointer" @click="fecharQRCode">close</v-icon>
        </v-toolbar>
        <v-card-text class="pt-6">
          <div v-html="qrcode" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoMudarTipoVenda" max-width="320px" @keydown.esc="() => (dialogoMudarTipoVenda = false)" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Mudar Tipo Venda</v-toolbar-title>
          <v-spacer />
          <v-icon style="cursor: pointer" @click="() => (dialogoMudarTipoVenda = false)">close</v-icon>
        </v-toolbar>
        <v-card-text class="px-4 py-2" style="justify-content: space-between; display: flex">
          <v-radio-group :value="venda.tipo" @change="mudarTipoVenda" read-only>
            <div style="justify-content: space-between; display: flex; flex-direction: column; gap: 20px" @keyup.enter.stop="() => (dialogoMudarTipoVenda = false)">
              <div style="justify-content: space-between; display: flex; flex-direction: column; gap: 12px">
                <v-radio label="Orçamento" ref="DAV" value="DAV" :disabled="venda.pagamentos.length > 0" />
                <v-radio label="Venda" ref="Venda" value="Venda" />
              </div>
              <div style="justify-content: space-between; display: flex; flex-direction: column; gap: 12px">
                <v-radio label="Cupom Fiscal" ref="NFCe" value="NFCe" />
                <v-radio label="Nota Fiscal" ref="NFe" value="NFe" />
              </div>
            </div>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-dialog>

    <preparando-terminal ref="dialodoPrepararTerminal"></preparando-terminal>
  </v-app>
</template>

<script>
import QRCode from 'qrcode-svg';
import { mapState, mapActions } from 'vuex';

import FormularioInformacaoUsuario from '@/views/cadastros/FormularioInformacaoUsuario';
import Util from '@/util';
import { URL_ANRON_API } from '@/util/config';

import DialogoMenu from './DialogoMenu.vue';
import DialogoPreVenda from './DialogoPreVenda.vue';
import DialogoOrcamentosPendentes from './DialogoOrcamentosPendentes.vue';
import DialogoVendas from './DialogoVendas.vue';
import DialogoCancelarItem from './venda/DialogoCancelarItem.vue';
import DialogoFinalizarVenda from './venda/DialogoFinalizarVenda.vue';
import DialogoRecebimentos from './recebimentos/DialogoRecebimentos';
import DialogoCaixa from './caixa/DialogoCaixa';
import DialogoCheques from './cheques/DialogoCheques';
import DialogoClientes from './clientes/DialogoClientes';
import PdvVenda from './venda/PDVVenda.vue';
import PreparandoTerminal from './PreparandoTerminal.vue';
import Relogio from './Relogio.vue';

import DialogBuscarProduto from '../../components/dialogs/DialogBuscarProduto.vue';

export default {
  name: 'PDVLayout',

  components: {
    DialogBuscarProduto,
    DialogoMenu,
    DialogoPreVenda,
    DialogoOrcamentosPendentes,
    DialogoVendas,
    DialogoCancelarItem,
    DialogoFinalizarVenda,
    DialogoRecebimentos,
    DialogoCaixa,
    DialogoCheques,
    DialogoClientes,
    PdvVenda,
    PreparandoTerminal,
    Relogio,
    FormularioInformacaoUsuario,
  },

  data() {
    return {
      telaVenda: false,
      isOnLine: navigator.onLine,
      isSefazOnLine: false,
      terminal: '003',

      fullscreen: false,

      dialogoMinhaConta: false,
      dialogoQRCode: false,
      dialogoMudarTipoVenda: false,

      qrcode: new QRCode({
        content:
          'http://www.sefaz.mt.gov.br/nfce/consultanfce?chNFe=51131003460900000290650010000000031000000031&nVersao=100&tpAmb=2&cDest=02801244147&dhEmi=323031332D31302D32345431363A32313A30332D30333A3030&vNF=1,00&vICMS=0,00&digVal=78764D34764E2B48586A735657516F653474415A547855547764383D&cIdToken=000001&cHashQRCode=7AF4285DA2D18133BEF9F9370AD4A185B2527AFB',
        padding: 1,
        width: 512,
        height: 512,
        color: '#000000',
        background: '#ffffff',
        ecl: 'M',
      }).svg(),

      novoItemInput: false,

      tefTimer: null,
    };
  },

  computed: {
    ...mapState('venda', ['venda', 'modo', 'tabela', 'terminalTipo', 'tiposVenda', 'tef']),
    apiUrl() {
      return URL_ANRON_API;
    },
  },

  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    window.addEventListener('blur', this.detectFocusBlur, true);
    window.addEventListener('focus', this.detectFocusBlur, true);
    window.addEventListener('keydown', this.atalhosVenda, true);
    window.addEventListener('keyup', this.limparKeyState, true);

    this.TEFStatus();

    document.documentElement.style.overflow = 'hidden';
  },

  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    window.removeEventListener('blur', this.detectFocusBlur);
    window.removeEventListener('focus', this.detectFocusBlur);
    window.removeEventListener('keydown', this.atalhosVenda);
    window.removeEventListener('keyup', this.limparKeyState);

    document.documentElement.style.overflowX = 'hidden !important';
    document.documentElement.style.overflow = null;
  },

  methods: {
    ...mapActions('venda', {
      TEFStatus: 'TEFStatus',
      TEFAdmin: 'TEFAdmin',
      TEFAguardaResposta: 'TEFAguardaResposta',
    }),

    mudarTerminalTipo(terminalTipo) {
      this.$store.commit('venda/setTerminalTipo', terminalTipo);
    },

    mudarTipoVenda(tipo) {
      this.$store.commit('venda/setTipo', tipo);
    },

    pdvVendaRowClick(index) {
      this.$refs.pdvVenda.rowClick(index);
    },

    setProdutoConsulta(produto) {
      if (this.modo === 'Manual') {
        this.$refs.pdvVenda?.inputProduto(produto);
      } else {
        this.$refs.pdvVenda?.selecionarProduto(produto);
      }
    },

    detectFocusBlur() {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.id !== 'inputBuscaProdutoVenda') {
        this.novoItemInput = false;
      } else {
        this.novoItemInput = true;
      }
    },

    novoItem() {
      this.$refs?.pdvVenda?.limparProduto();
      this.$refs?.pdvVenda?.$refs?.buscarProdutoPdv?.mostrarInput();
    },

    abrirMinhaConta() {
      this.dialogoMinhaConta = true;
    },

    fecharMinhaConta() {
      this.dialogoMinhaConta = false;
    },

    abrirQRCode() {
      this.dialogoQRCode = true;
    },

    fecharQRCode() {
      this.dialogoQRCode = false;
    },

    updateOnlineStatus(e) {
      const { type } = e;
      this.isOnLine = type === 'online';
    },

    limparKeyState(event) {
      if (this.tef.message) return;

      if (!event.ctrlKey && !event.shiftKey && !event.altKey && !this.telaVenda && this.terminalTipo !== 'Terminal' && !this.dialogoMudarTipoVenda && event.code !== 'F8' && event.code !== 'Escape') {
        this.$store.commit('venda/setTipo', 'NFCe');
      }
    },

    atalhosVenda(event) {
      if (this.tef.message) return;

      if (this.$refs.dialogoFinalizarVenda.processando) return;

      if (this.terminalTipo !== 'Terminal' && !this.dialogoMudarTipoVenda && event.code !== 'F8' && event.code !== 'Escape') {
        if (!this.telaVenda) {
          let tipo = 'NFCe';
          if (event.ctrlKey) {
            tipo = 'Venda';
          } else if (event.shiftKey) {
            tipo = 'DAV';
          } else if (event.altKey) {
            tipo = 'NFe';
          }
          this.$store.commit('venda/setTipo', tipo);
        }
      }

      if (event.code == 'F8') {
        event.preventDefault();
        if (this.terminalTipo !== 'Terminal' && !this.dialogoMudarTipoVenda) {
          this.dialogoMudarTipoVenda = true;
          this.$nextTick(() => {
            this.$refs[this.venda.tipo].$el.firstElementChild.children[1].focus();
          });
        }
      }

      if (this.$refs.dialogoFinalizarVenda.dialog) return;

      if (event.code == 'F2') {
        event.preventDefault();
        this.novaVenda();
      }

      if (event.code == 'F3') {
        event.preventDefault();
        this.finalizarVenda();
      }

      if (event.code == 'F4') {
        event.preventDefault();
        this.mudarModo();
      }

      if (event.code == 'F5') {
        event.preventDefault();
        this.cancelarItem();
      }

      if (event.ctrlKey && event.code === 'F5') {
        event.preventDefault();
        this.encerrar();
      }

      if (event.code == 'F6') {
        event.preventDefault();
        this.mudarTabela();
      }

      if (event.code == 'F7') {
        event.preventDefault();
        this.orcamentosPendentes();
      }

      if (event.code == 'F9') {
        event.preventDefault();
        this.vendas();
      }

      if (event.code == 'F10') {
        event.preventDefault();
        this.exibirMenu();
      }

      if (event.code == 'F11') {
        event.preventDefault();
        this.cancelarVenda();
      }

      if (event.code == 'F12') {
        event.preventDefault();
        this.consultarProduto();
      }

      if (event.code === 'Insert' || (event.code === 'F13' && this.modo !== 'Automático')) {
        event.preventDefault();
        this.novoItem();
      }
    },

    async novaVenda() {
      this.telaVenda = true;
      this.$store.commit('venda/novaVenda');
      this.$store.dispatch('produto/listar');
    },

    finalizarVenda() {
      if (this.venda.itens.length) {
        this.$refs.dialogoFinalizarVenda.abrir();
      }
    },

    onFinalizarVenda() {
      this.telaVenda = false;
      this.$store.commit('venda/cancelarVenda');
    },

    consultarProduto() {
      this.$refs.buscarproduto.abrir();
    },

    vendas() {
      this.$refs.dialogoVendas.abrir();
    },

    exibirMenu() {
      this.$refs.dialogoMenu.abrir();
    },

    finalizarPreVenda() {
      this.$refs.dialogoPreVenda.abrir();
    },

    orcamentosPendentes() {
      this.$refs.dialogoOrcamentosPendentes.abrir();
    },

    mudarTabela() {
      this.$store.commit('venda/setTabela');
    },

    mudarModo() {
      this.$store.commit('venda/setModo');
    },

    async cancelarVenda() {
      const response = await this.$refs.dialogoConfirmacao.open('Atenção!', `Deseja realmente cancelar ${['Venda', 'NFe'].includes(this.venda.tipo) ? 'a' : 'o'} ${this.tiposVenda[this.venda.tipo]} atual?`);
      if (response) {
        this.telaVenda = false;
        this.$store.commit('venda/cancelarVenda', true);
      }
    },

    abrirDialogo(dialogo) {
      this.$refs[dialogo].abrir();
    },

    cancelarItem() {
      if (!this.telaVenda) return;
      this.$refs.dialogoCancelarItem.abrir();
    },

    encerrar() {
      window.close();
    },

    setDrawerMenu(modulo) {
      this.$store.commit('autenticacao/setSession', { modulo });
      this.$router.push('/');
    },

    handleFullScreen() {
      Util.toggleFullScreen();
      setTimeout(() => (this.fullscreen = document.fullscreenElement), 100);
    },

    sair() {
      this.$store.commit('sair');
    },
  },
};
</script>

<style lang="scss" scoped>
.titulo {
  line-height: 1 !important;
}

.subtitulo {
  font-size: 8px;
  line-height: 1 !important;
}

.blink {
  animation: blink 0.5s ease infinite alternate-reverse;
}

@keyframes blink {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
