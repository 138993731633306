import ServiceMovimentoProducao from '@/services/movimentoProducao';
import moment from 'moment';
import { clone } from '@/util/helpers';

const movimento_zerado = {
  data: moment().format('YYYY-MM-DD'),
  produto: {
    insumos: [],
  },
  quantidade: 0,
  empresa: '',
  observacao: '',
};

export default {
  namespaced: true,

  state: {
    movimentoAtual: { ...movimento_zerado },
    movimentos: [],
    total: 0,
    carregando: false,
  },

  getters: {},

  mutations: {
    SET_MOVIMENTOS(state, movimentos) {
      state.movimentos = movimentos;
    },

    SET_TOTAL(state, total) {
      state.total = total;
    },

    SET_MOVIMENTO(state, movimento) {
      state.movimento = movimento;
    },

    ADICIONAR_MOVIMENTO(state, movimento) {
      state.movimentos.unshift(movimento);
    },

    EXCLUIR_MOVIMENTO(state, id) {
      let index = state.movimentos.findIndex((movimento) => movimento._id == id);
      if (index > -1) {
        state.movimentos.splice(index, 1);
      }
    },

    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },

    ZERAR(state) {
      state.movimentoAtual = clone(movimento_zerado);
    },
  },

  actions: {
    async listar({ commit, rootState }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await ServiceMovimentoProducao.listar(options, rootState.empresa._id);
        commit('SET_MOVIMENTOS', response.movimentos);
        commit('SET_TOTAL', response.total);
      } catch (error) {
        commit('SET_MOVIMENTOS', []);
        commit('setMensagemErro', 'Não foi possível listar os movimentos', { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async salvar({ commit, rootState, state }, movimento) {
      try {
        commit('SET_CARREGANDO');
        let novoMovimento = await ServiceMovimentoProducao.salvar(movimento, rootState.empresa._id);
        commit('ADICIONAR_MOVIMENTO', novoMovimento);
        commit('SET_TOTAL', state.total + 1);
      } catch (error) {
        commit('setMensagemErro', 'Houve um error ao salvar a movimentação', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async excluir({ commit, rootState, state }, movimento_id) {
      commit('SET_CARREGANDO');
      await ServiceMovimentoProducao.excluir(movimento_id, rootState.empresa._id);
      commit('EXCLUIR_MOVIMENTO', movimento_id);
      commit('SET_TOTAL', state.total - 1);
      commit('SET_CARREGANDO', false);
    },
  },
};
