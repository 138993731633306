import getHeader from './header/default.mjs';
import getContent from './content/default.mjs';
import getFooter from './footer/default.mjs';

const defaultReport = (options = {}) => {
  const { title, subTitle, companyInfo, columns, dataset, pageOrientation = 'portrait', pageSize = 'A4' } = options;

  let docDefinition = {
    info: {
      title: `${title} - ${companyInfo.fantasia}`,
      author: 'Anron Software',
    },
    pageSize: pageSize,
    pageOrientation: pageOrientation,
    pageMargins: [20, 93, 20, 60],

    header: getHeader({ title, subTitle, companyInfo, columns }),
    content: getContent({ dataset, columns }),
    footer: getFooter(),

    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      subHeader: {
        fontSize: 15,
        bold: true,
      },
      medium: {
        fontSize: 10,
      },
      small: {
        fontSize: 8,
      },
      table: {
        margin: [0, 5, 0, 15],
      },
    },
  };

  return docDefinition;
};

export default defaultReport;
