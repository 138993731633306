import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/dispositivos?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(dispositivo) {
  return axios.post(`/dispositivos`, dispositivo).then((response) => response.data);
}

function atualizar(dispositivo) {
  return axios.put(`/dispositivos/${dispositivo._id}`, dispositivo).then((response) => response.data);
}

function excluir(dispositivo) {
  return axios.delete(`/dispositivos/${dispositivo._id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
