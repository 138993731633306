import moment from 'moment';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import conversor from '@/util/conversor';
import notasDepositoApi from '@/services/notasDepositoApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    empresa: {
      cnpj: '',
      razao: '',
      fantasia: '',
    },
    notas: [],
    notaAtual: {
      numero: '',
      serie: '',
      tipo: '1',
      empresa: '',
      data_emissao: moment().format('YYYY-MM-DD'),
      chave: '',
      modelo: 55,
      finalidade: '1',
      indicador_presenca: '1',
      natureza_operacao: 'TRANSFERENCIA',
      produtos: [],
      forma_pagamento: '0',
      modelo_frete: '9',
      desconto: '0',
      frete: '0',
      seguro: '0',
      outras_despesas: '0',
      notaRef: '',
      total_bc_icms: 0,
      total_bc_icms_st: 0,
      total_icms: 0,
      total_icms_deson: 0,
      total_icms_st: 0,
      total_ipi: 0,
      total_ii: 0,
      total_pis: 0,
      total_cofins: 0,
      total_tributos: 0,
      total_produtos: 0,
      total_nota: 0,
      info_complementar: '',
      dados_adicionais: '',
      status: constantes.STATUS_PROCESSAMENTO,
    },
    clienteAtual: {
      cnpjCpf: '',
      razaoSocialNome: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
      pais: '',
      inscricao: '',
      suframa: '',
      telefone: '',
      email: '',
      observacoes: '',
    },
    produtosNota: [],
    parcelasNota: [],
    produtoAtual: {
      codigo: '',
      codigoBarra: '',
      descricao: '',
      ncm: '',
      unidade: '',
      valor: '',
      estoque: '',
      tributos: '',
      referencia_ibpt: '',
    },
    filiais: [],
    constantes: constantes,
    moment: moment,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },

    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },

    totalVenda(state) {
      let total = 0;
      state.produtosNota.forEach((produto) => {
        total += conversor.stringParaNumero(produto.subtotal);
      });
      return formatador.formatarValor(total);
    },

    totalProdutos(state) {
      let total = 0;
      state.produtosNota.forEach((produto) => {
        total += produto.quantidade;
      });
      return total;
    },

    totalItens(state) {
      return state.produtosNota.length;
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregarEmpresa({ commit, state, rootState }) {
      try {
        commit('setCarregando', true);
        let response = await notasDepositoApi.carregarEmpresa();
        commit('setEmpresa', response[1]);

        if (!state.modoEdicao) {
          commit('setSeriePadrao', response[1]);
        }
      } catch (error) {
        commit('setEmpresa', {});
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async carregarFiliais({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await notasDepositoApi.carregarFiliais();
        commit('setFiliais', response);
      } catch (error) {
        commit('setFiliais', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },

    adicionarProduto({ commit, state, dispatch }, dadosProduto) {
      let qtd = conversor.stringParaNumero(dadosProduto.quantidade);
      let valorProduto = conversor.stringParaNumero(dadosProduto.produto.valor);
      let subtotal = formatador.formatarValor(valorProduto * qtd);

      let produtoExiste = state.produtosNota.find((produto) => produto.codigo == dadosProduto.produto.codigo && produto.valor == formatador.formatarValor(dadosProduto.produto.valor));
      if (produtoExiste) {
        produtoExiste.quantidade += qtd;
        let vlrUnit = conversor.stringParaNumero(produtoExiste.valor);
        produtoExiste.subtotal = formatador.formatarValor(produtoExiste.quantidade * vlrUnit);

        dispatch('calcularImpostos', produtoExiste).then(() => {
          commit('setTotais');
        });
      } else {
        let produtoVendido = {};
        produtoVendido.produto = dadosProduto.produto._id;
        produtoVendido.codigo = dadosProduto.produto.codigo;
        produtoVendido.descricao = dadosProduto.produto.descricao;
        produtoVendido.origem = dadosProduto.produto.origem;
        produtoVendido.sequencia = state.produtosNota.length + 1;
        produtoVendido.valor = formatador.formatarValor(valorProduto);
        produtoVendido.quantidade = qtd;
        produtoVendido.subtotal = subtotal;
        produtoVendido.unidade = dadosProduto.produto.unidade;
        produtoVendido.ncm = dadosProduto.produto.ncm.codigo;

        if (dadosProduto.produto.cest) {
          produtoVendido.cest = dadosProduto.produto.cest.cest;
        }
        produtoVendido.cfop = dadosProduto.cfop;

        produtoVendido.csosn = dadosProduto.produto.csosn;
        produtoVendido.aliq_cred_sn = dadosProduto.produto.aliq_cred_sn || '0,00';
        produtoVendido.tributos = dadosProduto.produto.tributos;
        produtoVendido.referencia_ibpt = dadosProduto.produto.referencia_ibpt;

        produtoVendido.aliq_icms = dadosProduto.produto.aliq_icms;
        produtoVendido.bc_icms = subtotal;

        produtoVendido.aliq_icms_st = dadosProduto.produto.aliq_icms_st || '0,00';
        produtoVendido.bc_icms_st = subtotal;

        produtoVendido.aliq_ii = dadosProduto.produto.aliq_ii || '0,00';
        produtoVendido.bc_ii = subtotal;

        produtoVendido.aliq_ipi = dadosProduto.produto.aliq_ipi || '0,00';
        produtoVendido.bc_ipi = subtotal;

        dispatch('calcularImpostos', produtoVendido)
          .then((produto) => {
            commit('setProdutoVendido', produtoVendido);
            commit('setTotais');
          })
          .catch((err) => {});
      }
    },

    calcularImpostos({ commit, state }, produtoVendido) {
      let percentual = conversor.stringParaNumero(produtoVendido.tributos);
      let subTotal = conversor.stringParaNumero(produtoVendido.subtotal);
      produtoVendido.valor_tributos = formatador.formatarValor((percentual * subTotal) / 100);
      produtoVendido.info_adicional = `Valor Aproximado dos Tributos ${formatador.formatarMoeda(produtoVendido.valor_tributos)} ( ${formatador.formatarPercentual(produtoVendido.tributos)} ) ${produtoVendido.referencia_ibpt}`;

      let aliq_icms = conversor.stringParaNumero(produtoVendido.aliq_icms);
      let bc_icms = conversor.stringParaNumero(produtoVendido.bc_icms);
      produtoVendido.valor_icms = formatador.formatarValor((aliq_icms * bc_icms) / 100);

      let aliq_icms_st = conversor.stringParaNumero(produtoVendido.aliq_icms_st);
      let bc_icms_st = conversor.stringParaNumero(produtoVendido.bc_icms_st);
      produtoVendido.valor_icms_st = formatador.formatarValor((aliq_icms_st * bc_icms_st) / 100);

      let aliq_ii = conversor.stringParaNumero(produtoVendido.aliq_ii);
      let bc_ii = conversor.stringParaNumero(produtoVendido.bc_ii);
      produtoVendido.valor_ii = formatador.formatarValor((aliq_ii * bc_ii) / 100);

      let aliq_ipi = conversor.stringParaNumero(produtoVendido.aliq_ipi);
      let bc_ipi = conversor.stringParaNumero(produtoVendido.bc_ipi);
      produtoVendido.valor_ipi = formatador.formatarValor((aliq_ipi * bc_ipi) / 100);

      if (produtoVendido.csosn == 101) {
        let percentualCredito = produtoVendido.aliq_cred_sn;
        let valor_credito = formatador.formatarValor((percentualCredito * subTotal) / 100);
        produtoVendido.valor_cred_sn = conversor.stringParaNumero(valor_credito);
      } else if (produtoVendido.csosn == 400 || produtoVendido.csosn == 500 || produtoVendido.csosn == 900) {
      }
    },

    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        let nota = Object.assign({}, state.notaAtual);
        nota.desconto = conversor.stringParaNumero(nota.desconto);
        nota.frete = conversor.stringParaNumero(nota.frete);
        nota.seguro = conversor.stringParaNumero(nota.seguro);
        nota.outras_despesas = conversor.stringParaNumero(nota.outras_despesas);

        nota.produtos = state.produtosNota;
        nota.cliente = state.clienteAtual;
        nota.modelo = 55;

        //conversão dos valores em formato de string para númerico
        nota.produtos.forEach((produto) => {
          produto.valor = conversor.stringParaNumero(produto.valor);
          produto.subtotal = conversor.stringParaNumero(produto.subtotal);

          produto.aliq_cred_sn = conversor.stringParaNumero(produto.aliq_cred_sn);
          produto.valor_tributos = conversor.stringParaNumero(produto.valor_tributos);
          produto.tributos = conversor.stringParaNumero(produto.tributos);

          produto.aliq_icms = conversor.stringParaNumero(produto.aliq_icms);
          produto.bc_icms = conversor.stringParaNumero(produto.bc_icms);
          produto.valor_icms = conversor.stringParaNumero(produto.valor_icms);

          produto.aliq_icms_st = conversor.stringParaNumero(produto.aliq_icms_st);
          produto.bc_icms_st = conversor.stringParaNumero(produto.bc_icms_st);
          produto.valor_icms_st = conversor.stringParaNumero(produto.valor_icms_st);

          produto.aliq_ii = conversor.stringParaNumero(produto.aliq_ii);
          produto.bc_ii = conversor.stringParaNumero(produto.bc_ii);
          produto.valor_ii = conversor.stringParaNumero(produto.valor_ii);

          produto.aliq_ipi = conversor.stringParaNumero(produto.aliq_ipi);
          produto.bc_ipi = conversor.stringParaNumero(produto.bc_ipi);
          produto.valor_ipi = conversor.stringParaNumero(produto.valor_ipi);

          produto.desconto = conversor.stringParaNumero(produto.desconto || 0);
          produto.frete = conversor.stringParaNumero(produto.frente || 0);
          produto.seguro = conversor.stringParaNumero(produto.seguro || 0);
          produto.outras = conversor.stringParaNumero(produto.outras || 0);
        });
        nota.total_tributos = conversor.stringParaNumero(nota.total_tributos);
        nota.total_produtos = conversor.stringParaNumero(nota.total_produtos);
        nota.total_nota = conversor.stringParaNumero(nota.total_nota);

        let total = 0;
        state.produtosNota.forEach((produto) => {
          total += produto.subtotal;
        });
        nota.total_produtos = total;
        if (state.modoEdicao) {
          let response = await notasApi.atualizar(notas);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'Nota atualizada com sucesso', { root: true });
        } else {
          delete nota._id;
          let response = await notasApi.salvar(nota);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'Nota cadastrada com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setSeriePadrao(state, empresa) {
      state.notaAtual.serie = empresa.configuracao_tributaria.serie_padrao;
    },

    setCfopTodosProdutosNota(state, cfop) {
      state.produtosNota.forEach((produto) => {
        produto.cfop = cfop;
      });
    },

    setCSOSNTodosProdutosNota(state, csosn) {
      state.produtosNota.forEach((produto) => {
        produto.csosn = csosn;
      });
    },

    setAliqIcmsTodosProdutosNota(state, aliquota) {
      state.produtosNota.forEach((produto) => {
        aliquota = conversor.stringParaNumero(aliquota);
        produto.aliq_icms = aliquota;

        let bc_icms = conversor.stringParaNumero(produto.bc_icms);
        let icms = aliquota * bc_icms;

        produto.valor_icms = formatador.formatarValor(icms);
      });
    },

    setEmpresa(state, empresa) {
      state.empresa = empresa;
      state.carregando = false;
    },

    setFiliais(state, filiais) {
      state.filiais = filiais;
      state.carregando = false;
    },

    setProduto(state, produto) {
      state.produtoAtual = Object.assign({}, produto);
      state.produtoAtual.valor = formatador.formatarValor(produto.valor);
      state.produtoAtual.tributos = 0.0;
      state.produtoAtual.referencia_ibpt = '';
      state.produtoAtual.aliq_icms = formatador.formatarValor(produto.aliq_icms);
      state.produtoAtual.ncm = state.produtoAtual.ncm;

      if (state.produtoAtual.cest) {
        state.produtoAtual.cest = state.produtoAtual.cest.cest;
      }
    },

    resetProduto(state) {
      state.produtoAtual = {
        _id: '',
        codigo: '',
        codigoBarra: '',
        descricao: '',
        ncm: '',
        unidade: '',
        valor: '',
        estoque: '',
        tributos: '',
      };
    },

    setCliente(state, cliente) {
      state.clienteAtual = cliente;
    },

    setProdutoVendido(state, produto) {
      state.produtosNota.push(produto);
    },

    setTotais(state) {
      let desconto = conversor.stringParaNumero(state.notaAtual.desconto);
      let frete = conversor.stringParaNumero(state.notaAtual.frete);
      let seguro = conversor.stringParaNumero(state.notaAtual.seguro);
      let outras = conversor.stringParaNumero(state.notaAtual.outras_despesas);
      let total_produtos = 0;
      let total_bc_icms = 0;
      let total_bc_icms_st = 0;
      let total_icms = 0;
      let total_icms_st = 0;
      let total_ipi = 0;
      let total_ii = 0;

      state.produtosNota.forEach((produto) => {
        total_produtos += conversor.stringParaNumero(produto.subtotal);

        total_bc_icms += conversor.stringParaNumero(produto.bc_icms);
        total_bc_icms_st += conversor.stringParaNumero(produto.bc_icms_st || 0);
        total_icms += conversor.stringParaNumero(produto.valor_icms);
        total_icms_st += conversor.stringParaNumero(produto.valor_icms_st || 0);
        total_ipi += conversor.stringParaNumero(produto.valor_ipi || 0);
        total_ii += conversor.stringParaNumero(produto.valor_ii || 0);
      });

      state.notaAtual.total_nota = formatador.formatarValor(total_produtos - desconto + total_icms_st + frete + seguro + outras + total_ii + total_ipi);
      state.notaAtual.total_bc_icms = total_bc_icms;
      state.notaAtual.total_bc_icms_st = 0;
      state.notaAtual.total_icms = total_icms;
      state.notaAtual.total_icms_st = total_icms_st;
      state.notaAtual.total_ipi = total_ipi;
      state.notaAtual.total_ii = total_ii;
      state.notaAtual.total_produtos = formatador.formatarValor(total_produtos);
      state.notaAtual.total_tributos = formatador.formatarValor(total_icms - total_icms_st + total_ipi + total_ii);
    },

    removerVendido(state, remover) {
      let index = state.produtosNota.findIndex((produto) => produto.codigo === remover.codigo && produto.valor === remover.valor);
      if (index !== -1) {
        state.produtosNota.splice(index, 1);

        let sequencia = 1;
        state.produtosNota.forEach((produto) => {
          produto.sequencia = sequencia;
          sequencia++;
        });
      }
    },

    setCarregando(state, valor) {
      state.carregando = valor;
    },

    nova(state) {
      state.modoEdicao = false;
      state.notaAtual = {
        numero: '',
        serie: '',
        tipo: '1',
        empresa: '',
        data_emissao: moment().format('YYYY-MM-DD'),
        chave: '',
        modelo: 55,
        finalidade: '1',
        indicador_presenca: '1',
        natureza_operacao: 'TRANSFERENCIA',
        produtos: [],
        forma_pagamento: '0',
        modelo_frete: '9',
        desconto: '0',
        frete: '0',
        seguro: '0',
        outras_despesas: '0',
        notaRef: '',
        total_bc_icms: 0,
        total_bc_icms_st: 0,
        total_icms: 0,
        total_icms_deson: 0,
        total_icms_st: 0,
        total_ipi: 0,
        total_ii: 0,
        total_pis: 0,
        total_cofins: 0,
        total_tributos: 0,
        total_produtos: 0,
        total_nota: 0,
        info_complementar: '',
        dados_adicionais: '',
        status: constantes.STATUS_PROCESSAMENTO,
      };
      state.clienteAtual = {
        cnpjCpf: '',
        razaoSocialNome: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
        pais: '',
        inscricao: '',
        suframa: '',
        telefone: '',
        email: '',
        observacoes: '',
      };
      (state.produtosNota = []),
        (state.parcelasNota = []),
        (state.produtoAtual = {
          codigo: '',
          codigoBarra: '',
          descricao: '',
          ncm: '',
          unidade: '',
          valor: '',
          estoque: '',
          tributos: '',
        });
      state.carregando = false;
    },

    editar(state, editar) {
      let copiaNota = Object.assign({}, editar);

      state.modoEdicao = true;
      state.clienteAtual = copiaNota.cliente;

      //formatar campos de valores dos produtos
      let produtos = [];
      for (let produto of copiaNota.produtos) {
        let produtoEdicao = Object.assign({}, produto);
        produtoEdicao.tributos = formatador.formatarValor(produtoEdicao.tributos);
        produtoEdicao.valor_tributos = formatador.formatarValor(produtoEdicao.valor_tributos);
        produtoEdicao.subtotal = formatador.formatarValor(produtoEdicao.subtotal);
        produtoEdicao.valor = formatador.formatarValor(produtoEdicao.valor);

        //icms
        produtoEdicao.bc_icms = formatador.formatarValor(produtoEdicao.bc_icms || 0);
        produtoEdicao.aliq_icms = formatador.formatarValor(produtoEdicao.aliq_icms || 0);
        produtoEdicao.valor_icms = formatador.formatarValor(produtoEdicao.valor_icms || 0);

        //icms st
        produtoEdicao.bc_icms_st = formatador.formatarValor(produtoEdicao.bc_icms_st || 0);
        produtoEdicao.aliq_icms_st = formatador.formatarValor(produtoEdicao.aliq_icms_st || 0);
        produtoEdicao.valor_icms_st = formatador.formatarValor(produtoEdicao.valor_icms_st || 0);

        //ii
        produtoEdicao.bc_ii = formatador.formatarValor(produtoEdicao.bc_ii || 0);
        produtoEdicao.aliq_ii = formatador.formatarValor(produtoEdicao.aliq_ii || 0);
        produtoEdicao.valor_ii = formatador.formatarValor(produtoEdicao.valor_ii || 0);

        //ipi
        produtoEdicao.bc_ipi = formatador.formatarValor(produtoEdicao.bc_ipi || 0);
        produtoEdicao.aliq_ipi = formatador.formatarValor(produtoEdicao.aliq_ipi || 0);
        produtoEdicao.valor_ipi = formatador.formatarValor(produtoEdicao.valor_ipi || 0);

        produtoEdicao.desconto = formatador.formatarValor(produtoEdicao.desconto);
        produtoEdicao.frete = formatador.formatarValor(produtoEdicao.frete);
        produtoEdicao.seguro = formatador.formatarValor(produtoEdicao.seguro);
        produtoEdicao.outras = formatador.formatarValor(produtoEdicao.outras);

        produtos.push(produtoEdicao);
      }
      state.produtosNota = produtos;

      //recuperar informações do transportador
      state.transportador = copiaNota.transportador;

      //recuperar informações da empresa
      state.empresa = copiaNota.empresa;

      //recuperar informações das parcelas de pagamento
      if (copiaNota.parcelas_pagamento && copiaNota.parcelas_pagamento.length > 0) {
        state.parcelasNota = copiaNota.parcelas_pagamento;
      }

      //remover campos que não serão utilizados
      delete copiaNota.cliente;
      delete copiaNota.transportador;
      delete copiaNota.empresa;
      delete copiaNota.produtos;
      delete copiaNota.parcelas_pagamento;

      //formatar campos de valores da nota
      let nota = copiaNota;
      nota.data_emissao = moment(nota.data_emissao).format('YYYY-MM-DD');

      nota.outras_despesas = formatador.formatarValor(nota.outras_despesas);
      nota.desconto = formatador.formatarValor(nota.desconto);
      nota.frete = formatador.formatarValor(nota.frete);
      nota.seguro = formatador.formatarValor(nota.seguro);

      nota.total_bc_icms = formatador.formatarValor(nota.total_bc_icms);
      nota.total_bc_icms_st = formatador.formatarValor(nota.total_bc_icms_st);
      nota.total_icms = formatador.formatarValor(nota.total_icms);
      nota.total_icms_st = formatador.formatarValor(nota.total_icms_st);
      nota.total_ipi = formatador.formatarValor(nota.total_ipi);
      nota.total_tributos = formatador.formatarValor(nota.total_tributos);
      nota.total_produtos = formatador.formatarValor(nota.total_produtos);
      nota.total_nota = formatador.formatarValor(nota.total_nota);

      state.notaAtual = nota;
      state.carregando = false;
    },

    atualizar(state, atual) {
      let index = state.notas.findIndex((nota) => nota._id === atual._id);
      // Vue.set(state.notas, index, atual);
      state.notas.splice(index, 1, atual);
      state.carregando = false;
    },

    estadoInicial(state) {
      state.notaAtual = {
        numero: '',
        serie: '',
        tipo: '1',
        empresa: '',
        data_emissao: moment().format('YYYY-MM-DD'),
        chave: '',
        modelo: 55,
        finalidade: '1',
        indicador_presenca: '1',
        natureza_operacao: 'TRANSFERENCIA',
        produtos: [],
        forma_pagamento: '0',
        modelo_frete: '9',
        desconto: '0',
        frete: '0',
        seguro: '0',
        outras_despesas: '0',
        notaRef: '',
        total_bc_icms: 0,
        total_bc_icms_st: 0,
        total_icms: 0,
        total_icms_deson: 0,
        total_icms_st: 0,
        total_ipi: 0,
        total_ii: 0,
        total_pis: 0,
        total_cofins: 0,
        total_tributos: 0,
        total_produtos: 0,
        total_nota: 0,
        info_complementar: '',
        dados_adicionais: '',
        status: constantes.STATUS_PROCESSAMENTO,
      };
      state.clienteAtual = {
        cnpjCpf: '',
        razaoSocialNome: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
        pais: '',
        inscricao: '',
        suframa: '',
        telefone: '',
        email: '',
        observacoes: '',
      };
      (state.produtosNota = []),
        (state.parcelasNota = []),
        (state.produtoAtual = {
          codigo: '',
          codigoBarra: '',
          descricao: '',
          ncm: '',
          unidade: '',
          valor: '',
          estoque: '',
          tributos: '',
        });
      state.carregando = false;
    },
  },
};
