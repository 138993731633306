import axios from '@/util/axios';

function listar() {
  return axios.get(`/pis?`).then((response) => response.data);
}

function salvar(pis) {
  return axios.post('/pis', pis).then((response) => response.data);
}

function atualizar(pis) {
  return axios.put(`/pis`, pis).then((response) => response.data);
}

function excluir(pis) {
  return axios.delete(`/pis`, { data: { _id: pis._id } }).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
