<template>
  <v-dialog v-if="tef.message" :value="!!tef.message" width="640px" persistent eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Mensagem TEF</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row dense>
          <v-col cols="2">
            <img src="../../assets/credit-card.gif" />
          </v-col>
          <v-col cols="10" class="pl-6" style="align-self: center; display: flex">
            <v-label>{{ tef.message }}</v-label>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn v-if="tef.buttons.no" depressed class="mr-2" @click.native="TEFResposta('no')">Não</v-btn>
        <v-btn v-if="tef.buttons.yes" depressed color="primary darken-1" @click.native="TEFResposta('yes')" width="100px">Sim</v-btn>
        <v-btn v-if="tef.buttons.ok" depressed color="primary darken-1" @click.native="TEFResposta('ok')" width="100px">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'DialogTEF',

  computed: {
    ...mapState('venda', ['tef']),
  },

  methods: {
    ...mapActions('venda', { TEFResposta: 'TEFResposta' }),
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}
@keyframes loader3 {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
