<template>
  <div>
    <v-text-field v-bind="$attrs" v-on="$listeners" ref="input" :value="value" autofocus :readonly="readonly" :class="readonly ? 'nofocus' : ''">
      <template v-slot:append>
        <div style="margin: -10px 0px 0px 0px; height: 42px; display: flex; justify-content: center">
          <v-avatar v-if="venda.cliente._id" dark color="primary" rounded size="42" tile style="cursor: pointer; border-radius: 4px !important" @click="mostrarFoto">
            <img v-if="venda.cliente.foto" :src="fotoSrc" style="object-fit: cover" />
            <span v-else class="white--text text-h5">{{ venda.cliente.razaoSocialNome[0] }}</span>
          </v-avatar>
          <v-icon v-if="!readonly" style="cursor: pointer; padding: 0px 8px 0px 10px" color="primary" size="28" @click="abrirDialogo"> search </v-icon>
          <v-icon v-if="!readonly" style="cursor: pointer" color="primary" size="28" @click="limparCliente"> clear </v-icon>
        </div>
      </template>
    </v-text-field>

    <dialog-buscar-participante ref="dialogBuscarParticipante" @participanteSelecionado="participanteSelecionado" @fechar="dialogo = !dialogo" />

    <v-dialog v-model="dialogoFoto" max-width="560px" @keydown.esc="() => (dialogoFoto = false)" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Foto</v-toolbar-title>
          <v-spacer />
          <v-icon style="cursor: pointer" @click="fecharFoto">close</v-icon>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-img :src="fotoSrc" style="border-radius: 4px">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import formatador from '@/util/formatador';
import { URL_ANRON_API } from '@/util/config';

import DialogBuscarParticipante from '@/components/dialogs/DialogBuscarParticipante';

export default {
  name: 'BuscarParticipanteNome',

  props: {
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogBuscarParticipante,
  },

  data() {
    return {
      dialogo: false,
      dialogoFoto: false,
    };
  },

  computed: {
    ...mapState('venda', ['venda']),

    fotoSrc() {
      return this.venda.cliente.foto ? `${URL_ANRON_API}/images/${this.venda.cliente.foto}` : null;
    },
  },

  methods: {
    abrirDialogo() {
      this.$refs.dialogBuscarParticipante.abrir();
    },

    participanteSelecionado(participante) {
      this.$emit('participanteSelecionado', { ...participante, cnpjCpf: formatador.formatarCnpj(participante.cnpjCpf.replace(/\D+/g, '')), celular: formatador.formatarTelefone(participante.celular.replace(/\D+/g, '')) });
    },

    mostrarFoto() {
      if (this.venda.cliente.foto) {
        this.dialogoFoto = true;
      }
    },

    fecharFoto(e) {
      e.preventDefault();
      e.stopPropagation();
      this.dialogoFoto = false;
    },

    limparCliente() {
      this.$nextTick(() => {
        this.participanteSelecionado({
          _id: null,
          razaoSocialNome: 'CONSUMIDOR',
          cnpjCpf: '',
          email: '',
          celular: '',
        });
      });
    },
  },
};
</script>

<style scoped>
.nofocus {
  pointer-events: none;
  user-select: none;
}
.nofocus >>> img {
  pointer-events: all !important;
}
</style>
