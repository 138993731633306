<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent>
    <v-toolbar flat color="white">
      <v-text-field flat solo full-width single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Cupom"></v-text-field>
    </v-toolbar>
    <v-data-table :headers="headers" :items="cupons" :search="busca" :loading="carregando" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.numero }}</td>
          <td>{{ props.item.serie }}</td>
          <td>{{ props.item.data_emissao | tempoDecorrido }}</td>
          <td>{{ props.item.total_cupom | formatarMoeda }}</td>
          <td class="justify-center layout px-0">
            <v-icon @click="cancelar(props.item)">cancel</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    valid: false,
    busca: '',
    headers: [
      { text: 'Número', value: 'numero' },
      { text: 'Série', value: 'serie' },
      { text: 'Emissão', value: 'data_emissao' },
      { text: 'Total', value: 'total_cupom' },
      { text: 'Ações', value: 'name', align: 'center', sortable: false },
    ],
    dialog: false,
  }),

  computed: {
    ...mapState('cupom', {
      cupons: 'cupons',
      carregando: 'carregando',
    }),
  },

  methods: {
    ...mapActions('cupom', {
      carregarCupons: 'carregar',
    }),

    cancelar(cupom) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('cupom/cancelarCupom', cupom);
        }
      });
    },

    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.$emit('fechar');
    },
  },

  beforeMount() {
    if (this.$store.state.autenticacao.session.empresa.id) {
      this.carregarCupons();
    }
  },
};
</script>
