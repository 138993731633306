import constantes from '@/util/constantes';
import gruposApi from '@/services/gruposApi';

export default {
  namespaced: true,

  state: {
    grupos: [],
    grupo: {
      subGrupos: [],
    },
    constantes: constantes,
    carregando: false,
    totalGrupos: 0,
  },

  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        const response = await gruposApi.listar(options);
        commit('SET_GRUPOS', response.grupos);
        commit('SET_TOTAL_GRUPOS', response.total);
      } catch (error) {
        commit('SET_GRUPOS', []);
        commit('setMensagemErro', 'Não foi possível listar os grupos', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        const grupo = Object.assign({}, state.grupo);
        const response = await gruposApi.salvar(grupo);
        commit('ADICIONAR_GRUPO', response);
        commit('setMensagemSucesso', 'Grupo cadastrado com sucesso', { root: true });
      } catch (error) {
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async atualizar({ commit }, grupo) {
      try {
        commit('SET_CARREGANDO', false);
        await gruposApi.atualizar(grupo);
        commit('ATUALIZAR_GRUPO', grupo);
        commit('setMensagemSucesso', 'Grupo atualizado com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    async excluir({ commit }, grupo) {
      try {
        commit('SET_CARREGANDO', true);
        await gruposApi.excluir(grupo._id);
        commit('EXCLUIR_GRUPO', grupo._id);
        commit('setMensagemSucesso', 'Grupo removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },

    adicionarSubgrupo({ commit }, subGrupo) {
      commit('ADICIONAR_SUBGRUPO', subGrupo);
    },
  },

  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },

    SET_GRUPOS(state, grupos) {
      state.grupos = grupos;
      state.carregando = false;
    },

    SET_TOTAL_GRUPOS(state, total) {
      state.totalGrupos = total;
    },

    SET_GRUPO(state, grupo) {
      state.grupo = grupo;
    },

    EXCLUIR_GRUPO(state, id) {
      const index = state.grupos.findIndex((grupo) => grupo._id == id);
      if (index > -1) {
        state.grupos.splice(index, 1);
      }
      state.carregando = false;
    },

    setGrupos(state, grupos) {
      state.grupos = grupos;
      state.carregando = false;
    },

    setTotalGrupos(state, total) {
      state.totalGrupos = total;
    },

    remover(state, removido) {
      state.grupos = state.grupos.filter((grupo) => grupo._id !== removido._id);
      state.carregando = false;
    },

    removerSubgrupo(state, index) {
      state.grupo.subGrupos.splice(index, 1);
    },

    novo(state) {
      state.modoEdicao = false;
      state.grupo = {
        nome: '',
        subGrupos: [],
      };
      state.carregando = false;
    },

    editar(state, editar) {
      state.modoEdicao = true;
      state.grupo = editar;
      state.carregando = false;
    },

    ADICIONAR_GRUPO(state, grupo) {
      state.grupos.unshift(grupo);
      state.carregando = false;
    },

    ADICIONAR_SUBGRUPO(state, subGrupo) {
      if (state.grupo.subGrupos.length > 0) {
        const nome = state.grupo.subGrupos.find((subGrupo_) => subGrupo_.nome == subGrupo.nome);
        if (!nome) {
          state.grupo.subGrupos.push(subGrupo);
        } else {
          this.commit('setMensagemErro', 'SubGrupo já informado', { root: true });
        }
        return false;
      }
      state.grupo.subGrupos.push(subGrupo);
    },

    ATUALIZAR_GRUPO(state, grupo) {
      let grupo_encontrado = state.grupos.find((grupo_) => grupo_.id == grupo.id);
      if (grupo_encontrado) {
        grupo_encontrado = grupo;
      }
      state.carregando = false;
    },

    estadoInicial(state) {
      state.grupo = {
        nome: '',
        subGrupos: [],
      };
      state.carregando = false;
    },

    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
