<template>
  <v-container fluid>
    <v-widget title="Centro de Custos">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="novo">
                NOVO
              </v-btn>
              <v-btn v-else depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="centrosCustos" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.descricao }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'ListaCentroCustos',

  data() {
    return {
      headers: [
        { text: 'Descrição', value: 'descricao', width: '90%', sortable: false },
        { text: 'Ações', value: '', align: 'center', width: '7%', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('centroCusto', {
      centrosCustos: 'centrosCustos',
      carregando: 'carregando',
      total: 'totalCentroCustos',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async listar() {
      await this.$store.dispatch('centroCusto/listar');
    },

    excluir(centroCusto) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('centroCusto/excluir', centroCusto);
        }
      });
    },

    editar(centro) {
      this.$store.commit('centroCusto/editar', centro);
      this.$router.push({ name: 'formularioCentroCustoEditar' });
    },

    novo() {
      this.$store.commit('centroCusto/novo');
      this.$router.push({ name: 'formularioCentroCustoNovo' });
    },
  },
};
</script>
