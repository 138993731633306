import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get('/pedidos-compra', { params: options }).then((response) => response.data);
}

function salvar(item) {
  return axios.post('/pedidos-compra', item).then((response) => response.data);
}

function atualizar(item) {
  return axios.put(`/pedidos-compra/${item._id}`, item).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/pedidos-compra/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
