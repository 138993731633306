import axios from '@/util/axios';

function listar(params) {
  return axios.get('/produtos', { params }).then((response) => response.data);
}

function adicionar(produto) {
  return axios.post('/produtos', produto).then((response) => response.data);
}

function editar(produto) {
  return axios.patch(`/produtos`, produto).then((response) => response.data);
}

function remover(produtoId) {
  return axios.delete(`/produtos/${produtoId}`).then((response) => response.data);
}

function buscarCodigo(codigo) {
  return axios.get(`/produtos/codigos/${codigo}`).then((response) => response.data);
}

function buscarDescricaoProduto(params) {
  return axios.get(`/produtos/descricao?busca=${params.busca}&proprios=${params.produtosProprios}&ncm=${params.ncm}`).then((resp) => resp.data);
}

function buscarDescricao(descricao) {
  return axios.get(`/produtos/descricao/${descricao}`).then((resp) => resp.data);
}

function carregarPis() {
  return axios.get(`/pis`).then((response) => response.data);
}

function carregarCofins() {
  return axios.get(`/cofins`).then((response) => response.data);
}

function carregarConfiguracoesTributarias() {
  return axios.get('/empresas').then((response) => response.data);
}

function salvarInsumos(produto_id, insumos) {
  return axios.post('/produtos/' + produto_id + '/insumos/salvar', insumos).then((response) => response.data);
}

function ajustarEstoque(produto, ajuste) {
  return axios.post(`/produtos/${produto._id}/ajustarestoque`, ajuste).then((response) => response.data);
}

function carregarInsumos(produto_id) {
  return axios.get('/produtos/' + produto_id + '/insumos').then((response) => response.data);
}

function salvar(produto) {
  return axios.post(`/produtos`, produto).then((response) => response.data);
}

function atualizar(produto) {
  return axios.put(`/produtos`, produto).then((response) => response.data);
}

function excluir(produto) {
  return axios.delete('/produtos', { data: { _id: produto._id } }).then((response) => response.data);
}

function carregarProdutosPDV() {
  return axios.get('/produtos').then((response) => response.data);
}

function carregarNovoCodigo() {
  return axios.get('/produtos/novocodigo').then((response) => response.data);
}

export default {
  listar,
  adicionar,
  editar,
  remover,
  buscarCodigo,
  buscarDescricaoProduto,
  buscarDescricao,
  carregarPis,
  carregarCofins,
  carregarConfiguracoesTributarias,
  salvarInsumos,
  ajustarEstoque,
  carregarInsumos,
  salvar,
  atualizar,
  excluir,
  carregarProdutosPDV,
  carregarNovoCodigo,
};
