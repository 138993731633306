import arquivo from './modules/arquivo';
import autenticacao from './modules/autenticacao';
import banco from './modules/banco';
import caixa from './modules/caixa';
import centroCusto from './modules/centroCusto';
import cest from './modules/cest';
import cfop from './modules/cfop';
import cfopCompraEntrada from './modules/cfopCompraEntrada';
import cheque from './modules/cheque';
import cidadeEstado from './modules/cidadeEstado';
import cofins from './modules/cofins';
import compra from './modules/compra';
import configuracao from './modules/configuracao';
import contaCorrente from './modules/contaCorrente';
import contador from './modules/contador';
import contaPagar from './modules/contaPagar';
import contaReceber from './modules/contaReceber';
import cor from './modules/cor';
import crud from './modules/crud';
import cupom from './modules/cupom';
import cupons from './modules/cupons';
import dashboard from './modules/dashboard';
import dispositivo from './modules/dispositivo';
import emitente from './modules/emitente';
import empresa from './modules/empresa';
import estoque from './modules/estoque';
import estoqueDeposito from './modules/estoqueDeposito';
import fabricante from './modules/fabricante';
import filial from './modules/filial';
import graficos from './modules/graficos';
import grupo from './modules/grupo';
import ibpt from './modules/ibpt';
import importacao from './modules/importacao';
import localEstoque from './modules/localEstoque';
import manifesto from './modules/manifesto';
// import movimentoEstoque from './modules/movimentoEstoque';
import movimentoProducao from './modules/movimentoProducao';
import mva from './modules/mva';
import ncm from './modules/ncm';
import nota from './modules/nota';
import notaDeposito from './modules/notaDeposito';
import notificacao from './modules/notificacao';
import ordensServicos from './modules/ordensServicos';
import participante from './modules/participante';
import pdv from './modules/pdv';
import pedidosClientes from './modules/pedidosClientes';
import pedidosCompra from './modules/pedidosCompra';
import pis from './modules/pis';
import planoContas from './modules/planoContas';
import produto from './modules/produto';
import remessasCheques from './modules/remessasCheques';
import sefaz from './modules/sefaz';
import tamanho from './modules/tamanho';
import transportador from './modules/transportador';
import usuario from './modules/usuario';
import venda from './modules/venda';
import vendedor from './modules/vendedor';

export default {
  arquivo,
  autenticacao,
  banco,
  caixa,
  centroCusto,
  cest,
  cfop,
  cfopCompraEntrada,
  cheque,
  cidadeEstado,
  cofins,
  compra,
  configuracao,
  contaCorrente,
  contador,
  contaPagar,
  contaReceber,
  cor,
  crud,
  cupom,
  cupons,
  dashboard,
  dispositivo,
  emitente,
  empresa,
  estoque,
  estoqueDeposito,
  fabricante,
  filial,
  graficos,
  grupo,
  ibpt,
  importacao,
  localEstoque,
  manifesto,
  // movimentoEstoque,
  movimentoProducao,
  mva,
  ncm,
  nota,
  notaDeposito,
  notificacao,
  ordensServicos,
  participante,
  pdv,
  pedidosClientes,
  pedidosCompra,
  pis,
  planoContas,
  produto,
  remessasCheques,
  sefaz,
  tamanho,
  transportador,
  usuario,
  venda,
  vendedor,
};
