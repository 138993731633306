import axios from '@/util/axios';

function listar(options) {
  return axios.get('/cores', { params: options }).then((response) => response.data);
}

function salvar(cor) {
  return axios.post('/cores', cor).then((response) => response.data);
}

function atualizar(cor) {
  return axios.put(`/cores/${cor._id}`, cor).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/cores/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
