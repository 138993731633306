<template>
  <v-text-field ref="input" v-bind="$attrs" :loading="loading" :value="value" @input="onInput" @change="onChange" @focus="onFocus" maxlength="9" data-cy="cep"></v-text-field>
</template>

<script>
import cepApi from '@/services/cepApi';

export default {
  name: 'CampoCep',
  props: {
    value: {
      type: String,
      default: '',
    },
    buscarcep: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cep: this.value,
      loading: false,
    };
  },

  methods: {
    onInput(cep) {
      this.$emit('input', cep);
    },

    onChange(cep) {
      if (this.buscarcep) {
        this.buscarCep(cep);
      }
    },

    onFocus(e) {
      this.$nextTick(function () {
        e.target.select();
      });
    },

    onKeyUp(e) {
      this.$emit('keyup', e);
    },

    formatarCEP(cep) {
      cep = cep.replace(/\D/g, '');
      cep = cep.replace(/^(\d{5})(\d)/, '$1-$2');
      return cep.substring(0, 9);
    },

    async buscarCep(cep) {
      if (/^[0-9]{5}-[0-9]{3}$/.test(cep)) {
        try {
          this.loading = true;
          const result = await cepApi.consultar(cep);
          if (result && !result.erro) {
            this.$emit('resultado', result);
          }
        } catch (error) {
          this.$emit('resultado', {});
        } finally {
          this.loading = false;
        }
      }
    },

    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style scoped></style>
