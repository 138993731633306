import axios from '@/util/axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import moment from 'moment';
import estoquesApi from '@/services/estoquesApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    movimentos: [],
    movimentoAtual: {
      tipo: '',
      produto: '',
      quantidade: '',
      responsavel: '',
      numero_nota: '',
      valor_compra: '',
      valor_venda: '',
      observacao: '',
      data: moment().format('YYYY-MM-DD'),
    },
    produto: {
      codigo: '',
      descricao: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalMovimentos: 0,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregar({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await estoquesApi.listar(options);
        commit('setMovimentos', response.movimentos);
        commit('setTotalMovimentos', response.total);
      } catch (error) {
        commit('setMovimentos', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        let movimento = Object.assign({}, state.movimentoAtual);

        //conversoes de string para número
        movimento.valor_compra = conversor.stringParaNumero(movimento.valor_compra);
        movimento.valor_venda = conversor.stringParaNumero(movimento.valor_venda);
        movimento.quantidade = conversor.stringParaNumero(movimento.quantidade);
        movimento.produto = state.produto._id;

        if (state.modoEdicao) {
          let response = await estoquesApi.atualizar(movimento);
          if (response.status) {
            commit('setMensagemErro', response.motivo, { root: true });
            commit('setCarregando', false);
          } else {
            commit('atualizar', response);
            commit('setMensagemSucesso', 'Movimento atualizado com sucesso', { root: true });
          }
        } else {
          delete movimento._id;
          let response = await estoquesApi.salvar(movimento);
          if (response.status) {
            commit('setMensagemErro', response.motivo, { root: true });
            commit('setCarregando', false);
          } else {
            commit('adicionar', response.data);
            commit('setMensagemSucesso', 'Movimento cadastrado com sucesso', { root: true });
          }
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async remover({ commit }, movimento) {
      try {
        commit('setCarregando', true);
        await estoquesApi.excluir(movimento);
        commit('remover', movimento);
        commit('setMensagemSucesso', 'Movimento removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setMovimentos(state, movimentos) {
      state.movimentos = movimentos;
      state.carregando = false;
    },
    setTotalMovimentos(state, total) {
      state.totalMovimentos = total;
    },
    setProduto(state, produto) {
      state.produto = produto;
    },
    remover(state, removido) {
      state.movimentos = state.movimentos.filter((item) => item._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.movimentoAtual = {
        tipo: '',
        produto: '',
        quantidade: '',
        responsavel: '',
        numero_nota: '',
        valor_compra: '',
        valor_venda: '',
        observacao: '',
        data: moment().format('YYYY-MM-DD'),
      };
      state.produto = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      editar.data = moment(editar.data).format('YYYY-MM-DD');

      state.produto = editar.produto;
      state.movimentoAtual = editar;
      state.carregando = false;
    },
    adicionar(state, movimento) {
      state.movimentos.push(movimento);
      state.carregando = false;
    },
    atualizar(state, atual) {
      state.movimentos.forEach((movimento) => {
        if (movimento._id === atual._id) {
          movimento = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state) {
      state.movimentoAtual = {
        tipo: '',
        produto: '',
        quantidade: '',
        responsavel: '',
        numero_nota: '',
        valor_compra: '',
        valor_venda: '',
        observacao: '',
        data: moment().format('YYYY-MM-DD'),
      };
      state.produto = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
  },
};
