export default [
  {
    path: '/notas',
    name: 'notas',
    component: () => import(/* webpackChunkName: "listaNotas" */ '@/views/notasfiscais/ListaNotasFiscais'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_notasfiscais' },
  },
  {
    path: '/notas/nova',
    name: 'formularioNotaNova',
    component: () => import(/* webpackChunkName: "formularioNota" */ '@/views/notasfiscais/FormularioNota'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_notasfiscais' },
  },
  {
    path: '/notas/transferencia',
    name: 'formularioNotaDeposito',
    component: () => import(/* webpackChunkName: "formularioNota" */ '@/views/notasfiscais/FormularioNotaDeposito'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'transferir_notasfiscais' },
  },
  {
    path: '/notas/editar',
    name: 'formularioNotaEditar',
    component: () => import(/* webpackChunkName: "formularioNota" */ '@/views/notasfiscais/FormularioNota'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_notasfiscais' },
  },
  {
    path: '/notas/danfe',
    name: 'danfe',
    component: () => import(/* webpackChunkName: "visualizarDanfe" */ '@/views/cadastros/DANFE'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_notasdanfe' },
  },
  {
    path: '/notas/danfe_novo',
    name: 'danfeNovo',
    component: () => import(/* webpackChunkName: "visualizarDanfeNovo" */ '@/views/cadastros/NovoDANFE'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_notasdanfe' },
  },
  {
    path: '/destinadas',
    name: 'destinadas',
    component: () => import(/* webpackChunkName: "listaNotasDestinadas" */ '@/views/fiscal/ListaNotasDestinadas'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_notas_destinadas' },
  },
];
