import { formatarValor, formatarData, formatarDataHora } from '../commonFunctions.mjs';

function getContent(contentData = {}) {
  const { dataset, columns } = contentData;
  const content = [];
  const widths = [];

  columns.forEach((column) => {
    if (column.width.search('px') > -1) {
      widths.push(parseInt(column.width.substr(0, column.width.search('px'))));
    } else {
      widths.push(column.width);
    }
  });

  dataset.forEach((data) => {
    let rowContent = [];
    const keys = Object.keys(data);

    keys.forEach((key, index) => {
      let alignment = '';
      if ('alignment' in columns[index]) {
        alignment = columns[index].alignment;
      }

      let value = data[key];

      if ('format' in columns[index]) {
        if (columns[index].format == 'number') {
          value = formatarValor(value);
        }

        if (columns[index].format == 'data') {
          value = formatarData(value);
        }

        if (columns[index].format == 'datehour') {
          value = formatarDataHora(value);
        }
      }

      rowContent.push({ text: value, style: 'small', alignment: alignment, border: [false, false, false, false] });
    });

    content.push(rowContent);
    rowContent = [];
  });

  return [
    {
      style: 'table',
      table: {
        widths: widths,
        body: content,
      },
      layout: {
        fillColor: function (rowIndex) {
          return rowIndex % 2 === 0 ? '#CCCCCC' : undefined;
        },
      },
    },
  ];
}

export default getContent;
