import Login from '@/views/Login';
import FormularioCertificadoPadrao from '@/views/cadastros/FormularioCertificadoPadrao';
import FormularioInformacaoUsuario from '@/views/cadastros/FormularioInformacaoUsuario';
import Dashboard from '@/views/Dashboard';
import AcessoNaoPermitido from '@/views/AcessoNaoPermitido';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/configuracoes/certificado-digital',
    name: 'formularioCertificadoDigital',
    component: FormularioCertificadoPadrao,
    meta: { requiresAuth: true, nivel: 2 },
  },
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: { requiresAuth: true, nivel: 1 },
  },
  { path: '/home', redirect: '/' },
  {
    path: '/sem-permissao',
    name: 'sem-permissao',
    component: AcessoNaoPermitido,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: '/minha-conta',
    name: 'minhaConta',
    component: FormularioInformacaoUsuario,
    meta: { requiresAuth: true, nivel: 1 },
  },
];
