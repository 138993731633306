import Vue from 'vue';
import Router from 'vue-router';

import RotaGraficos from './graficos';
import RotaMovimentoProducao from './movimentoproducao';
import RotasAdmin from './private/admin';
import RotasBancos from './private/bancos';
import RotasCaixa from './caixa';
import RotasCentroCustos from './centrocustos';
import RotasCFOP from './private/cfop';
import RotasCFOPSCompraEntrada from './cfopscompraentrada';
import RotasCheques from './cheques';
import RotasCofins from './private/cofins';
import RotasCompras from './compras';
import RotasConfiguracoes from './configuracoes';
import RotasContador from './private/contador';
import RotasContasCorrentes from './contascorrentes';
import RotasContasPagar from './contaspagar';
import RotasContasReceber from './contasreceber';
import RotasCores from './cores';
import RotasCrud from './crud';
import RotasDispositivos from './dispositivos';
import RotasEmpresasAdmin from './private/empresasadmin';
import RotasEstoques from './estoques';
import RotasFabricantes from './fabricantes';
import RotasFiliais from './filiais';
import RotasGeral from './geral';
import RotasGrupos from './grupos';
import RotasIBPT from './private/ibpt';
import RotasLocaisEstoque from './locaisestoque';
import RotasManifestos from './manifestos';
import RotasMVAs from './mvas';
import RotasNotas from './notas';
import RotasOrdensServicos from './ordensservicos';
import RotasParticipantes from './participantes';
import RotasPDV from './pdv';
import RotasPedidosClientes from './pedidosclientes';
import RotasPedidosCompra from './pedidoscompra';
import RotasPis from './private/pis';
import RotasPlanosContas from './planoscontas';
import RotasProdutos from './produtos';
import RotasRelatorios from './relatorios';
import RotasRemessasCheques from './remessascheques';
import RotasSistema from './sistema';
import RotasTamanhos from './tamanhos';
import RotasTransportes from './transportes';
import RotasUsuarios from './usuarios';
import RotasVendas from './vendas';
import RotasVendedores from './vendedores';
import RouterGuards from './routerguards';

Vue.use(Router);

const router = new Router({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '*',
      name: 'Erro404',
      component: () => import(/* webpackChunkName: "Erro404" */ '@/views/404'),
      meta: { requiresAuth: true, nivel: 1 },
    },
    ...RotaGraficos,
    ...RotaMovimentoProducao,
    ...RotasAdmin,
    ...RotasBancos,
    ...RotasCaixa,
    ...RotasCentroCustos,
    ...RotasCFOP,
    ...RotasCFOPSCompraEntrada,
    ...RotasCheques,
    ...RotasCofins,
    ...RotasCompras,
    ...RotasConfiguracoes,
    ...RotasContador,
    ...RotasContasCorrentes,
    ...RotasContasPagar,
    ...RotasContasReceber,
    ...RotasCores,
    ...RotasCrud,
    ...RotasDispositivos,
    ...RotasEmpresasAdmin,
    ...RotasEstoques,
    ...RotasFabricantes,
    ...RotasFiliais,
    ...RotasGeral,
    ...RotasGrupos,
    ...RotasIBPT,
    ...RotasLocaisEstoque,
    ...RotasManifestos,
    ...RotasMVAs,
    ...RotasNotas,
    ...RotasOrdensServicos,
    ...RotasParticipantes,
    ...RotasPDV,
    ...RotasPedidosClientes,
    ...RotasPedidosCompra,
    ...RotasPis,
    ...RotasPlanosContas,
    ...RotasProdutos,
    ...RotasRelatorios,
    ...RotasRemessasCheques,
    ...RotasSistema,
    ...RotasTamanhos,
    ...RotasTransportes,
    ...RotasUsuarios,
    ...RotasVendas,
    ...RotasVendedores,
  ],
});

router.beforeEach(RouterGuards);

export default router;
