var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-widget',{attrs:{"title":"Cheques"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"flat":"","single-line":"","hide-details":"","dense":"","clearable":"","solo":"","prepend-inner-icon":"search","label":"Busca","background-color":"grey lighten-3"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filtrarBusca($event)},"click:clear":_vm.limparBusca},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.filtro_ativo),expression:"!filtro_ativo"}],attrs:{"items":_vm.lista_status,"flat":"","single-line":"","hide-details":"","dense":"","solo":"","item-value":'value',"background-color":"grey lighten-3"},on:{"change":function($event){return _vm.filtrarStatus($event)}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.filtro_ativo),expression:"filtro_ativo"}],staticClass:"mr-4",attrs:{"depressed":"","fab":"","color":"primary"},on:{"click":_vm.listar}},'v-icon',attrs,false),on),[_vm._v(" mdi-notification-clear-all ")])]}}])},[_c('span',[_vm._v("Limpar filtro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"depressed":"","fab":"","color":"primary","loading":_vm.carregando},on:{"click":_vm.abrirDialogoFiltro}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtro")])]),(!_vm.isSm)?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.novo}},[_vm._v(" NOVO ")]):_c('v-btn',{attrs:{"depressed":"","fab":"","small":"","color":"primary"},on:{"click":_vm.novo}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)]},proxy:true},{key:"widget-content",fn:function(){return [_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.total,"headers":_vm.headers,"items":_vm.cheques,"loading":_vm.carregando,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatarPadZero")(item.numero,5)))]),_c('td',{staticClass:"text-left"},[_c('span',[_c('b',[_vm._v("CLIENTE: "+_vm._s(item.responsavel.numero)+" - "+_vm._s(item.responsavel.razaoSocialNome))])]),_c('br'),_c('span',[_vm._v(_vm._s(item.emitente))])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.cnpjCpf))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatarData")(item.vencimento)))]),_c('td',{staticClass:"text-center"},[_c('v-chip',{staticStyle:{"cursor":"pointer","color":"white"},attrs:{"color":_vm.definirCor(item.status)},on:{"click":function($event){return _vm.alterarStatus(item)}}},[_vm._v(_vm._s(item.status))])],1),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.valor)))])]),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"top":"","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{key:item._id + 20,on:{"click":function($event){return _vm.editar(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{key:item._id + 40,on:{"click":function($event){return _vm.excluir(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Remover")])],1),_c('v-list-item',{key:item._id + 50,on:{"click":function($event){return _vm.alterarStatus(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("track_changes")])],1),_c('v-list-item-title',[_vm._v("Status")])],1)],1)],1)],1)])]}}])})]},proxy:true}])}),_c('dialog-confirmacao',{ref:"dialogConfirmacao"})],1),_c('dialogo-status-cheque',{attrs:{"dialog":_vm.exibirDialogoStatusCheque,"cheque":_vm.chequeSelecionado},on:{"fechar":function($event){return _vm.fecharDialogoStatus()},"alterarStatus":_vm.confirmarAlterarStatus}}),_c('dialogo-filtro-cheques',{attrs:{"dialog":_vm.exibirDialogoFiltroCheques,"cheque":_vm.chequeSelecionado},on:{"limpar":function($event){_vm.filtro_ativo = false},"fechar":function($event){return _vm.fecharDialogoFiltro()},"filtrar":_vm.filtrarCheques}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }