import DashboardContador from '@/views/DashboardContador';

export default [
  {
    path: '/contador/home',
    name: 'contador-home',
    component: DashboardContador,
    meta: { requiresAuth: true, nivel: 3 },
  },
];
