<template>
  <v-dialog v-model="dialog" max-width="960px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary" dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-toolbar flat color="white">
        <v-text-field
          ref="input"
          flat
          dense
          single-line
          hide-details
          clearable
          @input="onInput"
          @keyup.enter="buscarNomeCliente"
          @keydown.up="goUp"
          @keydown.down="goDown"
          max-width="300px"
          prepend-inner-icon="search"
          placeholder="Digite o nome e tecle Enter"
          @focus="onFocus"
          solo
          background-color="grey lighten-3"
          autofocus
          persistentPlaceholder
        />
      </v-toolbar>

      <v-card-text style="height: 60vh" class="px-4">
        <v-divider />
        <v-data-table hide-default-footer :headers="headers" :search="busca" :items="clientes" item-key="_id" :loading="carregando">
          <template v-slot:item="{ item, index }">
            <tr @click="selecionarParticipante(item)" :style="`cursor: pointer; background-color: ${selectedIndex === index ? '#dcedc8' : 'transparent'}`" class="pt-1">
              <td>{{ item.cnpjCpf }}</td>
              <td>{{ item.razaoSocialNome }}</td>
              <td>{{ item.cidade }} - {{ item.estado }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'CPF / CNPJ', value: 'cnpjCpf', sortable: false },
        { text: 'Nome / Razão Social', value: 'razaoSocialNome', sortable: false },
        { text: 'Cidade - UF', value: 'cidade', sortable: false },
      ],
      busca: '',
      titulo: 'Buscar Cliente/Fornecedor',
      selectedIndex: -1,
      dialog: false,
    };
  },

  computed: {
    ...mapState('participante', {
      clientes: 'participantes',
      carregando: 'carregando',
    }),
  },

  mounted() {
    this.$store.dispatch('participante/listar');
  },

  methods: {
    buscarNomeCliente(event) {
      if (this.clientes.length > 0 && this.selectedIndex >= 0) {
        this.selecionarParticipante(this.clientes[this.selectedIndex]);
        return;
      }

      const nome = event.target.value.trim();

      if (nome) {
        this.$store.dispatch('participante/listar', { busca: nome });
        this.selecionarInput(event);
        this.selectedIndex = 0;
      }
    },

    selecionarParticipante(participante) {
      this.$emit('participanteSelecionado', participante);
      this.fechar();
    },

    abrir() {
      this.dialog = true;
      this.$store.dispatch('participante/listar');
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    onFocus(e) {
      this.selecionarInput(e);
    },

    selecionarInput(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    onInput() {
      this.selectedIndex = -1;
    },

    goUp(e) {
      e.preventDefault();
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
      } else {
        this.selectedIndex = 0;
      }
    },

    goDown(e) {
      e.preventDefault();
      if (this.selectedIndex < this.clientes.length - 1) {
        this.selectedIndex = this.selectedIndex + 1;
      }
    },
  },
};
</script>
