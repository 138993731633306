<template>
  <v-text-field autocomplete="off" :class="{ direita: direita }" v-bind="$attrs" ref="input" v-model="valor" @input="onInput" @focus="onFocus" @blur="onBlur" @keyup="$emit('keyup', $event)"></v-text-field>
</template>

<script>
export default {
  name: 'as-campo-valor',
  props: {
    value: {},
    decimais: {
      type: [String, Number],
      default: 2,
    },
    selectOnFocus: {
      type: Boolean,
      default: true,
    },
    direita: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      valor: '0,00',
      internalChange: false,
      isFocused: false,
    };
  },

  mounted() {
    this.valor = this.formataNumero(this.value, this.decimais);
  },

  methods: {
    onInput(value) {
      this.internalChange = true;
      this.$emit('input', this.valorUS(this.valor));
    },

    onBlur() {
      this.internalChange = false;
      this.isFocused = false;
      this.valor = this.formataNumero(this.value, this.decimais);
      this.$emit('blur', this.valorUS(this.valor));
    },

    onFocus(e) {
      this.isFocused = true;
      if (this.selectOnFocus) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;
      } else {
        setTimeout(() => {
          if (!this.disabled || !this.readonly) {
            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
          }
        }, 0);
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    valorUS(valor) {
      if (valor) {
        return parseFloat(valor.replace(/\./g, '').replace(',', '.'));
      }
      return 0;
    },

    valorBR(valor) {
      if (valor) {
        return valor.toString().replace(/\./g, ',');
      }
    },

    trunc(valor, decimais) {
      decimais = !decimais ? 2 : decimais;
      valor = valor.replace(/\./g, '').replace(',', '.');
      valor = Math.trunc(parseFloat(valor) * Math.pow(10, decimais)) / Math.pow(10, decimais);
      return valor;
    },

    formataNumero(number, decimals, dec_point, thousands_sep) {
      decimals = typeof decimals !== 'undefined' ? decimals : 2;
      dec_point = typeof dec_point !== 'undefined' ? dec_point : ',';
      thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + (Math.round(n * k) / k).toFixed(prec);
        },
        toFixedFix2 = function(n, prec) {
          return '' + (Math.trunc(n * 1000000000000) / 1000000000000).toFixed(prec);
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix2(n, prec) : '' + Math.round(n)).split('.');
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    },
  },

  watch: {
    value(val, oldval) {
      if (!this.internalChange) {
        this.valor = this.formataNumero(val, this.decimais);
      }
    },
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}
</style>
