import cestsApi from '@/services/cestsApi';

export default {
  namespaced: true,

  state: {
    cests: [],
    carregando: false,
  },

  getters: {},

  actions: {
    async buscarDescricaoCest({ commit }, descricao) {
      try {
        commit('setCarregando', true);
        const response = await cestsApi.buscarDescricaoCest(descricao);
        commit('setCests', response);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async buscarCestPorNcm({ commit }, ncm) {
      try {
        commit('setCarregando', true);
        const response = await cestsApi.filtrarPorNcm(ncm);
        commit('setCests', response);
      } catch (error) {
        commit('setMensagemErro', error.message, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCests(state, cests) {
      state.cests = cests;
      state.carregando = false;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
  },
};
