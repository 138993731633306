import axios from '@/util/axios';

function listar(params) {
  return axios.get('/transportadores', { params }).then((response) => response.data);
}

function adicionar(transportadores) {
  return axios.post('/transportadores', transportadores).then((response) => response.data);
}

function editar(transportadores) {
  return axios.patch(`/transportadores`, transportadores).then((response) => response.data);
}

function remover(transportadoresId) {
  return axios.delete(`/transportadores/${transportadoresId}`).then((response) => response.data);
}

export default {
  listar,
  adicionar,
  editar,
  remover,
};
