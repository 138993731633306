<template>
  <v-container fluid class="fillHeight">
    <v-row class="fillHeight">
      <v-col cols="12" class="text-center ma-auto">
        <v-progress-circular color="primary" :size="72" indeterminate :width="7"></v-progress-circular>
        <p class="subtitle-1 mt-3">Carregando ...</p>
        <p class="subtitle-1 mt-3">Dashboard Contador [WIP]</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardContador',
};
</script>

<style scoped>
.fillHeight {
  height: 100%;
}
.fillWidth {
  width: 100%;
}
.positionAbsolute {
  position: absolute;
}
.semDados {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 220px;
  left: calc(50% - 110px);
}
</style>
