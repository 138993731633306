<template>
  <v-app id="login" class="primary" style="background-color: transparent !important">
    <v-main>
      <v-toolbar color="green darken-4" dark>
        <v-toolbar-title>EasyPDV <b>Web</b> </v-toolbar-title>
        <span style="text-align: right; font-weight: 300; margin-left: 8px; font-size: 14px">v{{ $store.state.version }}</span>
        <v-spacer></v-spacer>
        <img src="../assets/easyweb.svg" style="height: 32px" />
      </v-toolbar>

      <div class="ocean" style="background-color: #1b5e20">
        <div class="wave"></div>
        <div class="wave"></div>
      </div>

      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-2 pa-4">
              <v-card-text>
                <div class="text-h5 pb-6">Login</div>
                <br />
                <v-form v-model="valid" ref="formLogin">
                  <v-text-field outlined autofocus autocomplete="off" append-icon="alternate_email" label="Usuário" type="text" :rules="validaEmail" v-model="usuario.email" data-cy="email"></v-text-field>
                  <v-text-field outlined append-icon="lock" label="Senha" type="password" :rules="validaSenha" v-model="usuario.senha" @keypress.enter="validar(usuario)" data-cy="senha"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="pa-4 pt-2">
                <v-btn block large color="primary darken-2 elevation-0" @click="logar(usuario)" :loading="carregando" :disabled="!valid" data-cy="login">Acessar</v-btn>
              </v-card-actions>
            </v-card>

            <v-snackbar data-cy="snackBar" :timeout="snackbar.color == 'red' ? 5000 : 5000" bottom multi-line :color="snackbar.color" v-model="snackbar.show">
              {{ snackbar.text }}

              <template v-slot:action>
                <v-btn dark text @click.native="snackbar.show = false" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <v-footer dark padless fixed>
      <v-card class="flex" flat tile>
        <v-card-title class="py-2 white--text font-weight-light text-center">
          <small class="caption"> &copy; {{ new Date().getFullYear() }} — <strong>Anron Software</strong> - Todos direitos reservados</small>
        </v-card-title>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

import moment from 'moment';
import axios from '@/util/axios';
import { ClientJS } from 'clientjs';
import colors from 'vuetify/es5/util/colors';
import { IS_PROD } from '@/util/config';

export default {
  data() {
    return {
      valid: false,
      validaEmail: [(v) => !!v || 'Informe o E-Mail'],
      validaSenha: [(v) => !!v || 'Informe a Senha'],
      usuario: {
        email: '',
        senha: '',
      },
      carregando: false,
      colors: colors,
    };
  },

  mounted() {
    if (!IS_PROD) {
      this.usuario.email = 'anron@hotmail.com';
      this.usuario.senha = '123';
    }
  },

  methods: {
    validar(usuario) {
      if (this.$refs.formLogin.validate()) {
        this.logar(usuario);
      }
    },

    logar() {
      this.carregando = true;

      const client = new ClientJS();
      const dispositivo = client.getFingerprint();

      const logarUsuario = { ...this.usuario };
      logarUsuario.senha = btoa(logarUsuario.senha);
      logarUsuario.dispositivo = dispositivo;

      axios
        .post('/login', logarUsuario)
        .then(({ data }) => {
          if (data.empresa.modulos?.retaguarda) {
            data.modulo = 'retaguarda';
          } else if (data.empresa.modulos?.notaFiscal) {
            data.modulo = 'notaFiscal';
          } else if (data.empresa.modulos?.notaFiscalDeposito) {
            data.modulo = 'notaFiscalDeposito';
          } else if (data.empresa.modulos?.cupomFiscal) {
            data.modulo = 'cupomFiscal';
          } else if (data.empresa.modulos?.manifesto) {
            data.modulo = 'manifesto';
          } else if (data.empresa.modulos?.conhecimento) {
            data.modulo = 'conhecimento';
          }

          this.$store.commit('autenticacao/setSession', { ...data });
          this.$store.commit('autenticacao/setAmbiente', null);

          if (data?.empresa?.certificadoDados) {
            if (data.empresa.certificadoDados?.validade && moment(data.empresa.certificadoDados?.validade, 'MMM DD hh:mm:ss YYYY GMT').diff(moment(), 'days') > 1 && !['ANRON', 'CONTADOR'].includes(data.nivelAcesso)) {
              this.$store.dispatch('sefaz/consultarStatusServico', false, 'NFe');
            }

            this.$router.replace({ path: '/home' });

            const dias = data.empresa.certificadoDados?.validade ? moment(data.empresa.certificadoDados?.validade, 'MMM DD hh:mm:ss YYYY GMT').diff(moment(), 'days') : 100;

            if (dias <= 30) {
              let mensagem = '';
              if (dias == 0) {
                mensagem = 'Seu certificado vence hoje';
              } else if (dias < 0) {
                mensagem = 'Seu certificado está vencido';
              } else {
                mensagem = 'Seu certificado se vence em ' + dias + ' dias';
              }
              this.$store.commit('setMensagemErro', mensagem);
            }

            if (dias < 0) {
              this.$store.commit('autenticacao/setCertificadoVencido', true);
            } else {
              this.$store.commit('autenticacao/setCertificadoVencido', false);
            }
          } else {
            if (data.usuario.nivelAcesso === 'ANRON') {
              this.$router.replace({ path: '/admin/home' });
            } else if (data.usuario.nivelAcesso === 'CONTADOR') {
              this.$router.replace({ path: '/contador/home' });
            } else {
              this.$router.replace({ path: '/home' });
            }
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            this.$root.$confirmacao.open('Dispositivo', 'Este dispositivo não está autorizado pra uso. Deseja solicitar a permissão ao administrador da empresa?').then((confirmado) => {
              if (confirmado) {
                // Enviar solicitação
              }
            });
          } else {
            this.$store.commit('setMensagemErro', error);
            localStorage.removeItem('session');
          }
        })
        .then(() => {
          this.carregando = false;
        });
    },
  },

  computed: {
    ...mapState({
      snackbar: 'snackbar',
      isDevelopment: 'isDevelopment',
    }),
  },
};
</script>

<style scoped lang="css">
#login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background-color: lightgray !important;
  overflow: hidden;
}

#logo {
  max-width: 280px;
}

#copyright {
  text-align: center;
  font-size: 14px;
  color: white;
}

.ocean {
  z-index: -1;
  height: 30%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1b5e20;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3E%3Cstop stop-color='%238bc34a' stop-opacity='.25' offset='0%25'/%3E%3Cstop stop-color='%231b5e20' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0)'/%3E%3C/svg%3E")
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wavedev {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3E%3Cstop stop-color='%23abbfc9' stop-opacity='.25' offset='0%25'/%3E%3Cstop stop-color='%232f4c59' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0)'/%3E%3C/svg%3E")
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
</style>
