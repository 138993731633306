<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else ref="formCheque" lazy-validation>
        <v-container fluid>
          <v-widget title="Cheque">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="7">
                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <v-text-field label="Número *" persistent-placeholder outlined v-model="cheque.numero" :rules="[rules.obrigatorio]" autofocus @keypress.enter="$refs.agencia.focus()"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Agência *" ref="agencia" persistent-placeholder outlined v-model="cheque.agencia" :rules="[rules.obrigatorio]" @keypress.enter="$refs.contacorrente.focus()"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-text-field label="Conta Corrente *" ref="contacorrente" persistent-placeholder outlined v-model="cheque.conta" :rules="[rules.obrigatorio]" @keypress.enter="$refs.vencimento.focus()"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <campo-data label="Vencimento *" ref="vencimento" persistent-placeholder outlined v-model="cheque.vencimento" :rules="[rules.obrigatorio]" @keypress.enter="$refs.valor.focus()"></campo-data>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <as-campo-valor
                        label="Valor *"
                        ref="valor"
                        persistent-placeholder
                        outlined
                        v-model="cheque.valor"
                        :decimais="2"
                        :rules="[rules.obrigatorio, rules.numeroValido, rules.valorMaiorQueZero]"
                        @keypress.native.enter="$refs.cnpjCpf.focus()"
                      ></as-campo-valor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-text-field label="CNPJ/CPF *" ref="cnpjCpf" persistent-placeholder outlined v-model="cheque.cnpjCpf" v-mask="['###.###.###-##', '##.###.###/####-##']" :rules="[rules.cnpjCpf]" @keypress.enter="$refs.emitente.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field label="Emitente" ref="emitente" persistent-placeholder outlined v-model="cheque.emitente" @keypress.enter="$refs.cidade.focus()"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="3">
                  <v-autocomplete label="Estado *" ref="estado" persistent-placeholder outlined v-model="cheque.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(cheque.estado)" :rules="[rules.obrigatorio]"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete
                    label="Cidade *"
                    ref="cidade"
                    persistent-placeholder
                    outlined
                    v-model="cheque.codigo_cidade"
                    @change="setCidade(cheque.codigo_cidade)"
                    :items="cidades"
                    :rules="[rules.obrigatorio]"
                    :disabled="cidades.length == 0 || $store.state.cheque.carregando_cidade"
                    :loading="$store.state.cheque.carregando_cidade"
                    @keypress.enter="$refs.banco_codigo.focus()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <buscar-banco :label="'Banco'" ref="banco_codigo" persistent-placeholder outlined v-model="cheque.banco.codigo" @bancoSelecionado="bancoSelecionado" @keypress.enter="$refs.cnpjCpf_responsavel.focus()"></buscar-banco>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Nome do Banco" ref="banco_descricao" persistent-placeholder outlined v-model="cheque.banco.descricao" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <buscar-participante
                    :label="'CNPJ/CPF do Responsável *'"
                    ref="cnpjCpf_responsavel"
                    persistent-placeholder
                    outlined
                    v-model="cheque.responsavel.cnpjCpf"
                    @participanteSelecionado="responsavelSelecionado"
                    :rules="[rules.obrigatorio]"
                    @keypress.enter="$refs.observacoes.focus()"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Responsável" ref="razaoSocialNome" persistent-placeholder outlined v-model="cheque.responsavel.razaoSocialNome" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <campo-data label="Cadastro" ref="cadastro" persistent-placeholder outlined v-model="cheque.cadastro" :rules="[rules.obrigatorio]" @keypress.enter="$refs.observacoes.focus()" :disabled="true"></campo-data>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-textarea label="Observações" ref="observacoes" persistent-placeholder outlined v-model="cheque.observacao" @keypress.enter="observacoesEnterFinalizar"></v-textarea>
                </v-col>
              </v-row>
            </template>
            <template v-slot:widget-footer-action>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioCheques',
  data: () => ({
    loading: false,
    valid: false,
    rules: { ...regras },
    constantes: constantes,

    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
  }),

  beforeRouteLeave(to, from, next) {
    this.$store.commit('cheque/setCidades', []);
    next();
  },

  mounted() {
    //
  },

  async created() {
    try {
      this.loading = true;
      const cheque = this.$store.state.cheque.chequeAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!cheque._id || cheque._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um cheque para editar');
        this.$router.push({ name: 'cheques' });
      }

      if (estaEditando && this.cheque.estado) {
        await this.filtrarCidadesEstado(this.cheque.estado);
      }
      if (!estaEditando) {
        this.$store.commit('cheque/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions('cheque', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
    }),

    ...mapMutations('cheque', {
      setCidade: 'setCidade',
    }),

    async salvar() {
      if (this.$refs.formCheque.validate()) {
        try {
          if (!this.cheque.banco._id) {
            this.$store.commit('setMensagemErro', 'Selecione um banco');
            setTimeout(() => {
              this.$refs.banco_codigo.focus();
            }, 100);
            return false;
          }
          if (!this.cheque.responsavel._id) {
            this.$store.commit('setMensagemErro', 'Selecione um responsável');
            setTimeout(() => {
              this.$refs.cnpjCpf_responsavel.focus();
            }, 100);
            return false;
          }

          if (this.cheque._id) {
            await this.$store.dispatch('cheque/atualizar', this.cheque);
          } else {
            await this.$store.dispatch('cheque/salvar', this.cheque);
          }
          this.$router.push({ name: 'cheques' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    observacoesEnterFinalizar(event) {
      if (event.keyCode == 13) {
        if (event.shiftKey) {
          event.stopPropagation();
        } else {
          this.salvar();
        }
      }
    },

    cancelar() {
      this.$store.commit('cheque/estadoInicial');
      this.$router.push({ name: 'cheques' });
    },

    definirCidade(idCidade) {
      this.$store.commit('cheque/setCidade', idCidade);
    },

    bancoSelecionado(banco) {
      this.$store.commit('cheque/setBanco', banco);
      this.$refs.cnpjCpf_responsavel.focus();
    },

    responsavelSelecionado(responsavel) {
      this.$store.commit('cheque/setResponsavel', responsavel);
      this.$refs.observacoes.focus();
    },
  },

  computed: {
    ...mapState('cheque', {
      cheque: 'chequeAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('cheque', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
      cidades: 'listaCidades',
    }),
  },
};
</script>
