<template>
  <div>
    <v-text-field
      v-bind="$attrs"
      :filled="filled"
      :loading="loading"
      :label="label"
      ref="cnpjParticipante"
      v-mask="['###.###.###-##', '##.###.###/####-##']"
      :value="value"
      append-icon="search"
      @keyup.enter="consultarCnpjParticipante($event)"
      @input="onInput"
      @click:append="abrirDialogo"
      @click="abrirDialogo"
    >
    </v-text-field>

    <dialog-buscar-participante ref="dialogBuscarParticipante" @participanteSelecionado="adicionarParticipanteDialog" @fechar="dialogo = !dialogo"></dialog-buscar-participante>
  </div>
</template>

<script>
import DialogBuscarParticipante from '@/components/dialogs/DialogBuscarParticipante';
import participantesApi from '@/services/participantesApi';

export default {
  name: 'BuscarParticipante',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: 'CNPJ/CPF',
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogBuscarParticipante,
  },

  data() {
    return {
      dialogo: false,
      loading: false,
    };
  },

  methods: {
    focus() {
      this.$refs.cnpjParticipante.focus();
    },

    onInput(value) {
      this.$emit('input', value);
    },

    abrirDialogo() {
      this.$refs.dialogBuscarParticipante.abrir();
    },

    async consultarCnpjParticipante(event) {
      let cnpjCpf = event.target.value;
      if (cnpjCpf) {
        try {
          cnpjCpf = cnpjCpf.replace(/[^0-9]*/g, '');
          this.loading = true;
          let resultado = await participantesApi.buscarCnpjCpfCliente(cnpjCpf);
          this.$emit('participanteSelecionado', resultado);
          this.loading = false;
        } catch (error) {
          this.$store.commit('setMensagemErro', error.response.data.motivo);
        } finally {
          this.loading = false;
        }
      } else {
        commit('setMensagemErro', 'CNPJ/CPF Incompleto', { root: true });
      }
    },

    adicionarParticipanteDialog(participante) {
      this.$emit('participanteSelecionado', participante);
    },
  },
};
</script>

<style scoped></style>
