<template>
  <v-dialog v-model="dialog" max-width="85vw" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar flat dark color="primary" dense>
        <v-toolbar-title>Buscar Produto</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <div v-if="dialog" style="height: 70vh; width: 100%; display: flex; flex-direction: row" class="pa-0">
        <div style="width: 100%; padding: 0px 12px 12px; height: 100%" ref="scrollerContainer">
          <v-toolbar flat color="white" class="input-wrapper" style="width: 100%">
            <v-text-field
              ref="input"
              class="px-0"
              dense
              single-line
              hide-details
              clearable
              v-model="busca"
              @input="onInput(busca)"
              max-width="300px"
              prepend-inner-icon="search"
              label="Buscar Produtos"
              @focus="onFocus"
              solo
              flat
              background-color="grey lighten-3"
            />
          </v-toolbar>

          <v-divider />

          <div :style="`display: flex; height: 48px; flex-direction: row; width: calc(100% - ${scrollBarHeight + 12}px); align-items: center; padding-left: 12px`">
            <div style="width: 15%; font-weight: 500">Código</div>
            <div style="width: 55%; font-weight: 500">Descrição</div>
            <div style="width: 15%; font-weight: 500; text-align: right">Estoque</div>
            <div style="width: 15%; font-weight: 500; text-align: right">Valor</div>
          </div>

          <v-divider />

          <v-virtual-scroll v-if="produtosFiltrados.length > 0" :items="produtosFiltrados" item-height="48" :height="scrollerHeight" bench="2" id="scroller">
            <template v-slot="{ item, index }">
              <div :key="index" class="row" @click="selecionaProduto(index)" :style="index === produtoIndex ? 'background-color: #C5E1A5' : ''" @dblclick="selecionarProduto(item)">
                <div style="width: 15%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.codigo }}</div>
                <div style="width: 55%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.descricao }}</div>
                <div style="width: 15%; text-align: right; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.estoque }}</div>
                <div style="width: 15%; text-align: right; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.valor | formatarValor2 }}</div>
              </div>
            </template>
          </v-virtual-scroll>
        </div>

        <div v-if="produtoIndex >= 0 && produtosFiltrados[produtoIndex]" style="min-width: 256px; max-width: 256px; padding: 12px 12px 12px 8px; box-sizing: content-box">
          <div style="border-radius: 4px; background-color: rgba(0, 0, 0, 0.06); height: 100%; width: 100%; padding: 6px 12px 12px; display: flex; flex-direction: column; gap: 8px; position: relative; box-sizing: border-box" class="produtoContainer">
            <div style="flex: 0">
              <small><b>Descrição</b></small
              ><br />
              <span>{{ produtosFiltrados[produtoIndex].descricao }}</span>
            </div>
            <div style="flex: 0">
              <small><b>Código</b></small
              ><br />
              <span>{{ produtosFiltrados[produtoIndex].codigo }}</span>
            </div>
            <div v-if="produtosFiltrados[produtoIndex].codigosBarras && produtosFiltrados[produtoIndex].codigosBarras.length > 0" style="flex: 0">
              <small><b>Códigos de Barras</b></small>
              <br />
              <span>{{ produtosFiltrados[produtoIndex].codigosBarras.join(', ') }}</span>
            </div>
            <div v-if="produtosFiltrados[produtoIndex].codigoFabricante" style="flex: 0">
              <small><b>Código do Fabricante</b></small>
              <br />
              <span>{{ produtosFiltrados[produtoIndex].codigoFabricante }}</span>
            </div>
            <div style="flex: 1; display: flex; flex-direction: row; gap: 12px">
              <div>
                <small><b>NCM</b></small>
                <br />
                <span>{{ produtosFiltrados[produtoIndex].ncm.codigo }}</span
                ><br />
              </div>
              <div v-if="produtosFiltrados[produtoIndex].sit_tributaria_cupom">
                <small><b>Situação Tributária</b></small>
                <br />
                <span>{{ produtosFiltrados[produtoIndex].sit_tributaria_cupom }}</span>
              </div>
            </div>

            <!-- <v-btn v-if="mostrarBotaoCadastro" color="primary" @click="clicouBotaoCadastro">Cadastrar Produto</v-btn> -->

            <div v-if="produtoIndex >= 0 && produtosFiltrados[produtoIndex]" style="flex: none; display: grid">
              <small><b>Visualização</b></small>
              <img
                :src="imagemSrc"
                style="border-radius: 4px; flex: 0; min-height: 64px; max-height: 256px; min-width: 64px; max-width: 256px; margin-top: 2px; height: 100%; width: 100%; object-fit: cover"
                alt="Image não disponível"
                @error="fallbackImage"
              />
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { URL_ANRON_API } from '@/util/config';

export default {
  props: {
    produtosProprios: {
      type: Boolean,
      default: false,
    },
    produtoOculto: {
      type: String,
      default: '',
    },
    mostrarBotaoCadastro: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, width: '20%', align: 'center' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '50%' },
        { text: 'Estoque', value: 'estoque', sortable: false, width: '10%', align: 'center' },
        { text: 'Preço', value: 'valor', sortable: false, width: '20%', align: 'center' },
      ],
      busca: '',
      dialog: false,
      scrollerHeight: 0,
      scrollBarHeight: 0,
      debounce: null,
      blankImage: null,
    };
  },

  computed: {
    ...mapState('produto', ['produtos', 'produto', 'produtoIndex', 'produtosFiltrados', 'carregando']),

    imagemSrc() {
      return this.produtosFiltrados[this.produtoIndex].imagem ? `${URL_ANRON_API}/images/${this.produtosFiltrados[this.produtoIndex].imagem}` : this.blankImage;
    },
  },

  mounted() {
    this.$store.dispatch('produto/listar');
    window.addEventListener('keydown', this.onKeyDown);
    this.blankImage = this.createImage(640, 480);
  },

  unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
  },

  methods: {
    createImage(width, height, backgroundColor = 'white') {
      const blankImage = document.createElement('canvas');
      blankImage.width = width;
      blankImage.height = height;
      const ctx = blankImage.getContext('2d');
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      ctx.textAlign = 'center';
      ctx.font = '40px Roboto';
      ctx.fillStyle = 'silver';
      ctx.fillText('Imagem não disponível', Math.floor(width / 2), Math.floor(height / 2));
      return blankImage.toDataURL();
    },

    onKeyDown(e) {
      if (!this.dialog) return;

      if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
        e.preventDefault();
        e.stopPropagation();

        if (this.produtosFiltrados.length === 0) return;

        if (this.produtoIndex === null) {
          const index = 0;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 78 - el.scrollTop);
          });
          return;
        }

        if (e.code === 'ArrowDown' && this.produtosFiltrados.length - 1 >= this.produtoIndex + 1) {
          const index = this.produtoIndex + 1;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 48 - el.scrollTop);
          });
        }

        if (e.code === 'ArrowUp' && this.produtoIndex - 1 >= 0) {
          const index = this.produtoIndex - 1;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 48 - el.scrollTop);
          });
        }
      } else if (['Enter', 'NumpadEnter'].includes(e.code)) {
        e.preventDefault();
        e.stopPropagation();
        if (this.produtoIndex >= 0) {
          this.selecionarProduto(this.produtosFiltrados[this.produtoIndex]);
        }
      } else if (e.code === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        this.selecionarProduto(null);
      }
    },

    selecionaProduto(index) {
      this.$store.commit('produto/setState', { produtoIndex: index });
    },

    selecionarProduto(produto) {
      this.$emit('resultado', produto ? { ...produto, quantidade: 1 } : null);
      this.fechar();
    },

    onResize() {
      this.$nextTick(() => {
        this.scrollerHeight = Math.floor(this.$refs.scrollerContainer.getBoundingClientRect().height) * 2 - 126;
        const el = this.$refs.scrollerContainer.querySelector('.v-virtual-scroll');
        this.scrollBarHeight = el ? el.offsetWidth - el.clientWidth : 0;
      });
    },

    onInput(value) {
      this.busca = value;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (this.busca?.trim()) {
          const produtosFiltrados = this.produtos.filter((pro) => `${pro.descricao} ${pro.descricaoAdicional} ${pro.valor} ${pro.codigo} ${pro.codigosBarras.join(' ')}`.includes(this.busca));
          this.$store.commit('produto/setState', { produtosFiltrados });
          const indexFound = produtosFiltrados.findIndex((pro) => pro.descricao.startsWith(this.busca) || (pro.descricaoAdicional || '').startsWith(this.busca) || String(pro.codigo).startsWith(this.busca));

          if (indexFound >= 0) {
            this.$store.commit('produto/setState', { produtoIndex: indexFound, produto: produtosFiltrados[indexFound] });

            this.$nextTick(() => {
              // const el = document.getElementById('scroller');
              // el?.scrollBy(0, indexFound * 78 - el.scrollTop);

              const el = this.$refs.scrollerContainer.querySelector('.v-virtual-scroll');
              this.scrollBarHeight = el ? el.offsetWidth - el.clientWidth : 0;
            });
          } else {
            this.$store.commit('produto/setState', { produtoIndex: null, produto: null });
          }
        } else {
          this.$store.commit('produto/setState', { produtosFiltrados: this.produtos, produtoIndex: null, produto: null });
        }
      }, 300);
    },

    abrir() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
        this.onResize();
      });
    },

    fallbackImage(e) {
      e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';
    },

    // buscarProdutoNcm(ncm) {
    //   this.dialog = true;
    //   setTimeout(() => {
    //     this.$refs.input.focus();
    //   }, 500);

    //   const params = {
    //     busca: '',
    //     produtosProprios: false,
    //     ncm: ncm,
    //   };

    //   this.$store.dispatch('produto/buscarDescricaoProduto', params);
    // },

    fechar() {
      this.dialog = false;
      this.busca = '';
      this.$store.commit('produto/setState', { produtosFiltrados: this.produtos, produtoIndex: null, produto: null });
      this.$emit('fechar');
    },

    onFocus(e) {
      this.selecionarInput(e);
    },

    selecionarInput(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    clicouBotaoCadastro() {
      this.$emit('clicouBotaoCadastro');
    },
  },
};
</script>

<style lang="css" scoped>
.input-wrapper >>> div {
  padding: 0px;
}

.input-wrapper >>> .v-input__slot {
  padding: 0px 12px !important;
}

.row {
  display: flex;
  height: 48px;
  flex-direction: row;
  width: 100%;
  padding: 0px 12px;
  margin: 0px;
  align-items: center;
  cursor: default;
}
.row:hover {
  background: #f1f8e9;
}

.produtoContainer {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.produtoContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
