<template>
  <div style="height: calc(100% - 100px); width: 100%">
    <buscar-produto-pdv v-bind:modo="modo" v-bind:tabela="tabela" :produtoDescricao="produto.descricao" ref="buscarProdutoPdv" @resultado="inputProduto" />

    <v-card tile flat class="transparent my-1" style="height: calc(100% - 100px); width: 100%`">
      <v-card-text class="fill-height pa-0" style="border_: 2px solid blue">
        <v-row class="fill-height">
          <v-col cols="8" class="pr-0">
            <v-card class="grey lighten-3 fill-height" ref="tpWrapper">
              <v-toolbar color="green" dense flat>
                <span class="text--body-1 font-bold-weight white--text">Produtos</span>
              </v-toolbar>

              <v-card-text style="max-height: 65vh">
                <v-data-table ref="tabelaProdutos" :headers="headers" :items="venda.itens" hide-default-footer :items-per-page="200" :height="tableHeight" fixed-header dense disable-sort>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="item.id" @mouseover="setHovered(index)" @mouseleave="setHovered(null)" :style="getRowStyle(index)" @click="rowClick(index)">
                        <td style="text-align: center">{{ item.item }}</td>
                        <td>{{ item.codigo }}</td>
                        <td>{{ item.descricao }}</td>
                        <td style="text-align: right">
                          <span v-if="item.vendaFracionada">
                            {{ item.quantidade | valorBR(3) }}
                          </span>
                          <span v-else>
                            {{ item.quantidade | valorBR(0) }}
                          </span>
                        </td>
                        <td style="text-align: right">{{ item.valor | valorBR }}</td>
                        <td style="text-align: right; position: relative">
                          {{ item.subtotal | valorBR }}
                          <div
                            v-if="hoveredIndex === index"
                            @click="cancelarItem(item.item)"
                            style="
                              position: absolute;
                              right: 0px;
                              background-color: red;
                              color: white;
                              height: 100%;
                              width: 80px;
                              top: 0px;
                              padding: 4px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              cursor: pointer;
                              user-select: none;
                              font-weight: 600;
                            "
                          >
                            Cancelar
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card class="grey lighten-3 fill-height">
              <v-toolbar color="green" dense flat>
                <span class="text--body-1 font-bold-weight white--text">Detalhes</span>
              </v-toolbar>
              <v-card-text style="height: calc(100% - 48px)">
                <v-row dense style="height: 100%; flex-direction: column">
                  <v-col cols="12" class="mb-3" :style="modo === 'Automático' ? 'flex: 0; pointer-events: none' : 'flex: 0'" :inert="modo === 'Automático'">
                    <v-text-field
                      v-model="produto.codigo"
                      v-mask="customMask"
                      @keydown="(e) => sanitizeCodigo(e, produto.codigo)"
                      @keydown.enter.native="() => procurarProduto(produto.codigo) && $refs.campoQuantidade.focus()"
                      hide-details
                      placeholder=" "
                      solo
                      flat
                      :style="modo === 'Automático' ? 'font-weight: 600; font-size: 1.5rem; pointer-events: none' : 'font-weight: 600; font-size: 1.5rem'"
                      prefix="Código"
                      background-color="white"
                      class="sololabel grey--text direita"
                    />
                  </v-col>

                  <v-col cols="12" class="mb-3" :style="modo === 'Automático' ? 'flex: 0; pointer-events: none' : 'flex: 0'" :inert="modo === 'Automático'">
                    <campo-valor
                      v-model="produto.quantidade"
                      hide-details
                      placeholder=" "
                      ref="campoQuantidade"
                      solo
                      flat
                      truncarDecimais
                      decimais="3"
                      background-color="white"
                      :style="modo === 'Automático' ? 'font-weight: 600; font-size: 1.5rem; pointer-events: none' : 'font-weight: 600; font-size: 1.5rem'"
                      class="sololabel grey--text"
                      prefix="Quantidade"
                      @focus="onFocus"
                      @keydown.enter.native="$refs.campoValor.focus()"
                      @input="calcularSubtotal"
                      @keydown.esc.native="limparProduto"
                    />
                  </v-col>

                  <v-col cols="12" class="mb-3" :style="modo === 'Automático' ? 'flex: 0; pointer-events: none' : 'flex: 0'" :inert="modo === 'Automático'">
                    <campo-valor
                      v-model="produto.valor"
                      hide-details
                      placeholder=" "
                      ref="campoValor"
                      solo
                      flat
                      fixarDecimais
                      decimais="2"
                      background-color="white"
                      :style="modo === 'Automático' ? 'font-weight: 600; font-size: 1.5rem; pointer-events: none' : 'font-weight: 600; font-size: 1.5rem'"
                      class="sololabel grey--text"
                      prefix="Valor Unitário"
                      @keydown.enter.native="clicarAdicionarProduto"
                      @input="calcularSubtotal"
                      @keydown.esc.native="limparProduto"
                    />
                  </v-col>

                  <v-col cols="12" class="mb-3" style="flex: 0; pointer-events: none" inert>
                    <campo-valor v-model="produto.subtotal" fixarDecimais decimais="2" hide-details placeholder=" " solo flat dark background-color="secondary" style="font-weight: 600; font-size: 1.5rem; user-select: none" prefix="SubTotal" />
                  </v-col>

                  <v-col cols="12" class="mb-3" :style="validarProduto() ? 'flex: 1' : 'flex: 1; filter: grayscale(1); pointer-events: none'">
                    <v-btn v-if="modo !== 'Automático'" ref="adicionarproduto" dark large color="orange" class="px-3 mb-4 text-capitalize" style="width: 100%; display: flex; justify-content: space-between; font-size: 1.2rem" @click="adicionarProduto">
                      <v-icon color="white">mdi-cart-plus</v-icon>
                      Adicionar Produto
                    </v-btn>
                  </v-col>

                  <!-- Automatico -->
                  <v-col v-if="modo === 'Automático'" cols="12" class="mb-3" style="flex: 0">
                    <!-- <v-text-field v-model="produto.codigo" hide-details solo flat dark color="green" background-color="transparent" style="font-weight: 600; font-size: 1.5rem; user-select: none" class="tranparent" /> -->
                    <v-text-field
                      ref="barcode"
                      v-model="produto.codigoBarras"
                      hide-details
                      solo
                      flat
                      dark
                      color="green"
                      background-color="transparent"
                      style="font-weight: 600; font-size: 1.5rem; user-select: none"
                      class="tranparent"
                      @keydown.enter.native="processarCodigoBarras"
                      @keydown.esc.native="limparProduto"
                    />
                  </v-col>

                  <v-col cols="12" class="mb-3" style="flex: none; margin-bottom: -6px !important; pointer-events: none" inert>
                    <campo-valor :value="totalBruto" fixarDecimais decimais="2" hide-details placeholder=" " solo flat dark background-color="primary" style="font-weight: 600; font-size: 2rem; user-select: none !important" prefix="Total" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapGetters, mapState } from 'vuex';
import BuscarProdutoPdv from '../../../components/BuscarProdutoPDV.vue';

const produtoBlank = {
  codigo: '',
  codigoBarras: '',
  descricao: '',
  quantidade: 0,
  valor: 0,
  valorOriginal: 0,
  acrescimo: 0,
  desconto: 0,
  subtotal: 0,
};

export default {
  components: { BuscarProdutoPdv },
  name: 'pdv-venda',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      headers: [
        { text: 'Item', value: 'item', width: '40px', align: 'center' },
        { text: 'Código', value: 'codigo', width: '80px' },
        { text: 'Descrição', align: 'start', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right', width: '80px' },
        { text: 'Valor', value: 'valor', align: 'right', width: '80px' },
        { text: 'SubTotal', value: 'subtotal', align: 'right', width: '80px' },
      ],
      selectedIndex: null,
      hoveredIndex: null,
      tableHeight: 100,

      customMask: {
        mask: 'FFFFFFFFFFFFFFFFFFFFF',
        tokens: {
          F: {
            pattern: /[0-9a-zA-Z\-.]/,
          },
        },
      },

      produto: structuredClone(produtoBlank),

      timer: null,
    };
  },

  computed: {
    ...mapState('venda', ['venda', 'item', 'modo', 'tabela']),
    ...mapState('produto', ['produtos']),
    ...mapState({ zoom: 'zoom' }),
    ...mapGetters('venda', ['totalBruto']),
  },

  created() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('resize', this.onRezise);
  },

  mounted() {
    this.onRezise();
    this.timer = setInterval(() => {
      if (this.modo === 'Automático' && document.querySelector('.v-overlay__scrim') === null) this.$refs.barcode?.focus();
    }, 200);
    this.selectedIndex = this.venda.itens.length ? 0 : null;
  },

  unmounted() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('resize', this.onRezise);
    clearInterval(this.timer);
  },

  methods: {
    processarCodigoBarras() {
      if (this.produto.codigoBarras.includes('*')) {
        const [quantidade, codigo] = this.produto.codigoBarras.split('*');
        const pQuantidade = parseInt(quantidade);
        const produto = this.produtos.find((produto) => String(produto.codigo) === codigo || produto.codigosBarras.find((cb) => String(cb) === codigo));
        if (produto) {
          this.produto = structuredClone(produtoBlank);
          this.produto.codigo = produto.codigo;
          this.produto.descricao = produto.descricao;
          this.produto.valor = produto.valor;
          this.produto.valorOriginal = produto.valor;
          this.produto.quantidade = Number.isInteger(pQuantidade) && pQuantidade > 0 ? pQuantidade : 1;
          this.produto.subtotal = produto.valor;
          this.produto.vendaFracionada = produto.vendaFracionada;
          this.adicionarProduto();
        } else {
          this.limparProduto();
        }
      } else {
        const produto = this.produtos.find((produto) => String(produto.codigo) === this.produto.codigoBarras || produto.codigosBarras.join(' ').includes(this.produto.codigoBarras));
        if (produto) {
          this.produto = structuredClone(produtoBlank);
          this.produto.codigo = produto.codigo;
          this.produto.descricao = produto.descricao;
          this.produto.valor = produto.valor;
          this.produto.valorOriginal = produto.valor;
          this.produto.quantidade = 1;
          this.produto.subtotal = produto.valor;
          this.produto.vendaFracionada = produto.vendaFracionada;
          this.adicionarProduto();
        } else {
          this.limparProduto();
        }
      }
    },

    setHovered(index) {
      this.hoveredIndex = index;
    },

    rowClick(index) {
      this.selectedIndex = index;
    },

    getRowStyle(index) {
      const style = `background-color: ${this.hoveredIndex === index ? 'beige' : 'white'};`;
      if (this.selectedIndex === index) return 'background-color: #dcedc8';
      return style;
    },

    onRezise() {
      this.$nextTick(() => {
        if (this.$refs.tpWrapper) {
          const { height } = this.$refs.tpWrapper.$el.getBoundingClientRect();
          this.tableHeight = height - 80;
        }
      });
    },

    ripple(el) {
      const ev = new Event('mousedown');
      const offset = el.getBoundingClientRect();
      ev.clientX = offset.left + offset.width / 2;
      ev.clientY = offset.top + offset.height / 2;
      el.dispatchEvent(ev);

      setTimeout(function () {
        el.dispatchEvent(new Event('mouseup'));
      }, 100);
    },

    inputProduto(produto) {
      if (produto) {
        this.produto = structuredClone(produtoBlank);
        this.produto.codigo = produto.codigo;
        this.produto.descricao = produto.descricao;
        this.produto.valor = produto.valor;
        this.produto.valorOriginal = produto.valor;
        this.produto.quantidade = 1;
        this.produto.subtotal = produto.valor;
        this.produto.vendaFracionada = produto.vendaFracionada;
      }

      this.$nextTick(() => {
        if (produto?.codigo) {
          this.$nextTick(() => {
            this.$refs.campoQuantidade.$el.querySelectorAll('input')[0].select();
          });
        } else {
          document.activeElement.blur();
        }
      });
    },

    selecionarProduto(produto) {
      if (produto) {
        this.produto = structuredClone(produtoBlank);
        this.produto.codigo = produto.codigo;
        this.produto.descricao = produto.descricao;
        this.produto.valor = produto.valor;
        this.produto.valorOriginal = produto.valor;
        this.produto.quantidade = 1;
        this.produto.subtotal = produto.valor;
        this.produto.vendaFracionada = produto.vendaFracionada;

        if (produto?.codigo) {
          this.$store.commit('venda/setItem', produto);

          this.$nextTick(() => {
            this.$refs.campoQuantidade.focus();
          });
        }
      }
    },

    sanitizeCodigo(e, codigo) {
      if (!/[0-9a-zA-Z\-.]/.test(e.key) && !['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'Escape', 'Ctrl', 'Alt'].includes(e.key)) {
        e.preventDefault();
        return;
      }
      if (!codigo && ['-', '.'].includes(e.key)) {
        e.preventDefault();
        return;
      }
      if (codigo && (codigo[e.target.selectionStart - 1] === '.' || codigo[e.target.selectionStart - 1] === '-') && ['-', '.'].includes(e.key)) {
        e.preventDefault();
      }
    },

    // mongodb://anron:28f42efa52%401f526C4a5c665@192.168.2.2:27018/?authSource=admin&readPreference=primary&directConnection=true&ssl=false

    clicarAdicionarProduto() {
      this.ripple(this.$refs.adicionarproduto.$el);
      this.adicionarProduto();
    },

    procurarProduto(codigo) {
      if (codigo) {
        const produto = this.produtos.find((pro) => String(pro.codigo) === codigo || pro.codigosBarras.find((cb) => String(cb) === codigo));
        if (produto) {
          this.$refs.buscarProdutoPdv.selecionarProduto(produto);
        }
      }
    },

    adicionarProduto() {
      if (this.validarProduto()) {
        this.produto.quantidade = parseFloat(this.produto.quantidade);
        this.produto.subtotal = this.produto.valor * this.produto.quantidade;
        this.produto.acrescimo = this.produto.valorOriginal * this.produto.quantidade;

        if (this.produto.acrescimo < this.produto.subtotal) {
          this.produto.acrescimo = Number(Math.round(this.produto.subtotal - this.produto.acrescimo + 'e2') + 'e-2');
          this.produto.desconto = 0;
        } else if (this.produto.acrescimo > this.produto.subtotal) {
          this.produto.desconto = Number(Math.round(this.produto.acrescimo - this.produto.subtotal + 'e2') + 'e-2');
          this.produto.acrescimo = 0;
        } else {
          this.produto.acrescimo = 0;
          this.produto.desconto = 0;
        }

        this.$store.commit('venda/adicionarItem', { ...this.produto });
        this.selectedIndex = this.venda.itens.length - 1;
        this.limparProduto();

        this.$nextTick(() => this.$refs.tabelaProdutos.$el.querySelector('tbody').lastElementChild.scrollIntoView());
      }
    },

    validarProduto() {
      return this.produto && String(this.produto.codigo).trim() && String(this.produto.descricao).trim() && String(this.produto.quantidade).trim() && String(this.produto.valor).trim();
    },

    cancelarItem(item) {
      this.$refs.dialogConfirmacao.open('Excluir', 'Tem certeza que deseja excluir o item?').then((confirmado) => {
        if (confirmado) {
          // Index
          const index = item - 1;
          if (index > 0) {
            this.rowClick(index - 1);
          } else if (this.venda.itens > 1) {
            this.rowClick(index + 1);
          }
          this.$store.commit('venda/removerItem', index);
        }
      });
    },

    onKeyDown(e) {
      if (document.querySelector('.v-overlay__scrim')) return;

      if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
        e.preventDefault();
        e.stopPropagation();

        // if (this.selectedIndex.length === 0 && this.venda.itens.length > 0) {
        //   this.selectedIndex = this.venda.itens[0];
        //   return;
        // }

        // if (this.venda.itens.length === 0) {
        //   this.selectedIndex = [];
        //   return;
        // }

        // if (this.selectedIndex.length === 0) return;

        // const index = this.venda.itens.findIndex((pro) => pro._id === this.selectedIndex[0]?._id);

        // if (e.code === 'ArrowDown' && index < this.venda.itens.length - 1) {
        //   this.selectedIndex = [this.venda.itens[index + 1]];
        // }

        // if (e.code === 'ArrowUp' && index > 0) {
        //   this.selectedIndex = [this.venda.itens[index - 1]];
        // }
      }
    },

    limparProduto() {
      this.produto = structuredClone(produtoBlank);

      this.$store.commit('venda/setItem', this.produto);

      this.$refs.buscarProdutoPdv.mostrarInput();
    },

    calcularSubtotal() {
      this.produto.subtotal = this.produto.quantidade * this.produto.valor;
    },

    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    focus() {
      setTimeout(() => {
        this.$refs.codigo.focus();
      }, 0);
    },

    setProdutoConsulta(produto) {
      if (this.modo !== 'Automático' && produto) {
        this.selecionarProduto(produto);
      }
    },
  },

  filters: {
    valorBR(valor, decimais = 2) {
      if (valor) {
        return valor.toLocaleString('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: decimais,
          maximumFractionDigits: decimais,
        });
      }

      return '0,00';
    },
  },
  watch: {
    venda: {
      deep: true,
      handler(newVal) {
        localStorage.setItem('venda', JSON.stringify(newVal));
      },
    },
  },
};
</script>

<style lang="css" scoped>
.sololabel >>> .v-text-field__prefix {
  font-size: 1rem;
}
.direita.v-input >>> input {
  text-align: right !important;
}
.tranparent.v-input >>> input {
  color: transparent !important;
}
</style>
