import Relatorios from '@/views/relatorios/Relatorios';

export default [
  {
    path: '/relatorios',
    name: 'relatorios',
    component: Relatorios,
    meta: { requiresAuth: true, nivel: 1 },
  }
];
