<template>
  <div id="appRoot">
    <v-app>
      <Background />
      <dialog-confirmacao v-if="$store.state.sair" ref="dialogSair" />
      <dialog-confirmacao ref="dialogConfirmacao" />
      <DialogTEF />

      <template v-if="!$route.meta.requiresAuth">
        <v-app id="inspire" class="app" style="background-color: transparent !important">
          <router-view />
        </v-app>
      </template>

      <template v-else-if="['/pdv'].includes($route.path)">
        <PDVLayout />
      </template>

      <template v-else>
        <Home />
      </template>

      <v-snackbar data-cy="snackBar" :timeout="snackbar.color == 'red' ? 5000 : 5000" bottom multi-line :color="snackbar.color" v-model="snackbar.show">
        <!-- eslint-disable -->
        <v-card-text v-html="snackbar.text" class="pa-0"></v-card-text>
        <!-- eslint-enable -->

        <template v-slot:action>
          <v-btn dark text @click.native="snackbar.show = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar top center multi-line color="primary" :value="showUpdateMessage" timeout="-1">
        Há uma nova atualização disponível!
        <template v-slot:action>
          <v-btn text @click="updateSoftware"> Atualizar </v-btn>
        </template>
      </v-snackbar>
    </v-app>

    <div
      v-if="isDevelopment"
      style="
        font-family: Roboto, sans-serif;
        line-height: 1.5;
        color: white;
        font-size: 24px;
        position: fixed;
        bottom: 60px;
        right: 12px;
        z-index: 1000;
        font-weight: 700;
        opacity: 0.2;
        border-radius: 4px;
        background-color: red;
        padding: 0px 18px;
        pointer-events: none;
      "
    >
      Development
    </div>
  </div>
</template>

<script>
import PDVLayout from '@/views/pdv/PDVLayout.vue';
import { mapState } from 'vuex';
import Home from './views/Home';
import Background from './components/Background.vue';
import colors from 'vuetify/lib/util/colors';
import DialogTEF from './components/dialogs/DialogTEF.vue';

import eventbus from '@/util/eventbus';

export default {
  name: 'App',

  data: () => ({
    showUpdateMessage: false,
    registration: null,
  }),

  computed: {
    ...mapState({
      usuario: 'usuario',
      snackbar: 'snackbar',
      isDevelopment: 'isDevelopment',
    }),
  },

  created() {
    window.getApp = this;

    if (this.usuario && this.usuario.validadeCertificado && !['ANRON', 'CONTADOR'].includes(this.usuario.nivelAcesso)) {
      this.$store.dispatch('sefaz/consultarStatusServico', false, 'NFe');
      this.$store.dispatch('sefaz/consultarDestinadas');
    }

    this.$store.dispatch('carregarTabelas');
    this.$store.dispatch('cidadeEstado/carregar');
  },

  mounted() {
    eventbus.$on('UPDATE_SOFTWARE', this.updateAvailable);

    // this.sockets.subscribe(this.store.state.autenticacao.empresa.cnpj, (data) => {
    //   if (data) {
    //     this.processarMensagemMiddleware(data);
    //   }
    // });

    this.$root.$confirmacao = this.$refs.dialogConfirmacao;

    // const channel = new BroadcastChannel('tab');

    // const winId = gerarIdentificador();

    // channel.postMessage(String(winId));
  },
  methods: {
    updateSoftware() {
      this.showUpdateMessage = false;
      if (!this.registration || !this.registration.waiting) return false;
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    },

    updateAvailable(event) {
      this.registration = event.detail;
      this.showUpdateMessage = true;
    },
  },

  watch: {
    '$store.state.autenticacao.session.modulo': function (value) {
      switch (value) {
        case 'nfe':
          this.$vuetify.theme.themes.light = {
            primary: colors.teal.base,
            secondary: colors.teal.lighten1,
            accent: colors.blue.base,
            error: colors.deepOrange.base,
            warning: colors.amber.base,
            info: colors.yellow.base,
            success: colors.lightBlue.base,
          };
          break;
        case 'deposito':
          this.$vuetify.theme.themes.light = {
            primary: colors.teal.base,
            secondary: colors.teal.lighten1,
            accent: colors.blue.base,
            error: colors.deepOrange.base,
            warning: colors.amber.base,
            info: colors.yellow.base,
            success: colors.lightBlue.base,
          };
          break;

        default:
          this.$vuetify.theme.themes.light = {
            primary: colors.green.base,
            secondary: colors.lightGreen.base,
            accent: colors.blue.base,
            error: colors.deepOrange.base,
            warning: colors.amber.base,
            info: colors.yellow.base,
            success: colors.lightBlue.base,
          };
          break;
      }
    },

    '$store.state.sair': function (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.dialogSair.open('Logout', 'Tem certeza que deseja sair?').then((confirmado) => {
            if (confirmado) {
              this.$store.dispatch('descarregarTabelas');
              this.$store.dispatch('autenticacao/logout', this.$router);
            }
          });
        });
      }
    },
  },

  components: {
    Home,
    PDVLayout,
    Background,
    DialogTEF,
  },
};
</script>

<style>
.highlight {
  font-weight: 500;
  background-color: gold;
  border-radius: 4px;
}
</style>
