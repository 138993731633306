<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
      <v-form v-model="valid" ref="formMapeamento" lazy-validation>
        <v-widget title="Mapeamento">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-cfop ref="campoCfopCompra" v-model="mapeamento.cfopCompra" @cfopSelecionado="cfopCompraSelecionado"></buscar-cfop>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição do CFOP Compra" v-model="mapeamento.descricaoCfopCompra" :rules="[rules.obrigatorio]" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <buscar-cfop ref="campoCfopEntrada" v-model="mapeamento.cfopEntrada" @cfopSelecionado="cfopEntradaSelecionado"></buscar-cfop>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição do CFOP Entrada" v-model="mapeamento.descricaoCfopEntrada" :rules="[rules.obrigatorio]" readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
            </v-card-actions>
          </template>
        </v-widget>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioCFOPCompraEntrada',
  data: () => ({
    valid: false,
    loading: false,
    constantes: constantes,
    rules: { ...regras },
  }),

  computed: {
    ...mapState('cfopCompraEntrada', {
      mapeamento: 'mapeamentoAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('cfopCompraEntrada', {
      estaEditando: 'estaEditando',
    }),
  },

  methods: {
    consultarCfopCompra(cfop) {
      if (cfop && cfop.length === 4) {
        this.$store.dispatch('cfopCompraEntrada/consultarCfopCompra', cfop);
      }
      return false;
    },
    consultarCfopEntrada(cfop) {
      if (cfop && cfop.length === 4) {
        this.$store.dispatch('cfopCompraEntrada/consultarCfopEntrada', cfop);
      }
      return false;
    },
    async salvar() {
      if (this.$refs.formMapeamento.validate()) {
        try {
          if (this.mapeamento._id) {
            await this.$store.dispatch('cfopCompraEntrada/atualizar', this.mapeamento);
          } else {
            await this.$store.dispatch('cfopCompraEntrada/salvar', this.mapeamento);
          }
          this.$router.push({ name: 'mapeamentos' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },
    cfopCompraSelecionado(cfop) {
      this.$store.commit('cfopCompraEntrada/setCfopCompra', cfop);
      setTimeout(() => {
        this.$refs.campoCfopEntrada.focus();
      }, 300);
    },
    cfopEntradaSelecionado(cfop) {
      this.$store.commit('cfopCompraEntrada/setCfopEntrada', cfop);
    },
    cancelar() {
      this.$store.commit('cfopCompraEntrada/estadoInicial');
      this.$router.push({ name: 'mapeamentos' });
    },
  },

  async created() {
    try {
      this.loading = true;
      const mapeamento = this.$store.state.cfopCompraEntrada.mapeamentoAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!mapeamento._id || mapeamento._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um mapeamento para editar');
        this.$router.push({ name: 'mapeamentos' });
      }
      if (!estaEditando) {
        this.$store.commit('cfopCompraEntrada/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
