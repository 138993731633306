<template>
  <div id="v-widget">
    <v-card :min-height="altura">
      <v-toolbar flat dark color="primary" dense v-if="enableHeader">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <slot name="widget-header-action"></slot>
      </v-toolbar>

      <v-divider v-if="enableHeader"></v-divider>

      <slot name="toolbar"></slot>

      <v-divider v-if="$slots['toolbar']"></v-divider>

      <v-card-text :class="[contentBg, { 'pa-0': noPadding }]">
        <slot name="widget-content"></slot>
      </v-card-text>

      <v-divider class="ml-4 mr-4" v-if="!noDivider && $slots['widget-footer-action']"></v-divider>

      <v-card-actions v-if="$slots['widget-footer-action']">
        <v-spacer />
        <slot name="widget-footer-action"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'v-widget',
  props: {
    title: {
      type: String,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    contentBg: {
      type: String,
      default: 'white',
    },
    altura: {
      type: String,
      default: '',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noDivider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
