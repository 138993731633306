import axios from '@/util/axios';
import axiosCep from 'axios';

function filtrarCidadesEstado(estado) {
  return axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
}

function consultarCertificadoEmitente() {
  return axios.get(`/empresas/certificado`).then((response) => response.data);
}

function salvar(emitente) {
  return axios.put('/empresas', emitente).then((response) => response.data);
}

function processarCertificado(certificado) {
  return axios.put(`/empresas/certificado`, certificado).then((response) => response.data);
}

async function consultarCnae(cnae) {
  return await axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae).then((response) => response.data);
}

function carregarEmitente() {
  return axios.get(`/empresas`).then((response) => response.data);
}

export default {
  filtrarCidadesEstado,
  consultarCertificadoEmitente,
  salvar,
  processarCertificado,
  consultarCnae,
  carregarEmitente,
};
