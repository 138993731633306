<template>
  <div>
    <v-container fluid>
      <v-widget title="Cheques">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="5">
                <v-text-field flat single-line hide-details dense clearable solo v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <!-- Condicional - Existe o mesmo código abaixo -->
              <!-- <v-col v-if="isSm" class="text-right" cols="5" sm="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="listar"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col> -->
              <v-col cols="12" sm="4">
                <v-select v-show="!filtro_ativo" :items="lista_status" flat single-line hide-details dense solo :item-value="'value'" v-model="status" @change="filtrarStatus($event)" background-color="grey lighten-3"></v-select>
              </v-col>
              <v-col class="text-right" cols="12" sm="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="listar"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="cheques" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.numero | formatarPadZero(5) }}</td>
                <td class="text-left">
                  <span
                    ><b>CLIENTE: {{ item.responsavel.numero }} - {{ item.responsavel.razaoSocialNome }}</b></span
                  >
                  <br />
                  <span>{{ item.emitente }}</span>
                </td>
                <td class="text-center">{{ item.cnpjCpf }}</td>
                <td class="text-center">{{ item.vencimento | formatarData }}</td>
                <td class="text-center">
                  <v-chip style="cursor: pointer; color: white" :color="definirCor(item.status)" @click="alterarStatus(item)">{{ item.status }}</v-chip>
                </td>
                <td class="text-center">
                  <span>{{ item.valor | formatarMoeda }}</span>
                </td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 20" @click="editar(item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 40" @click="excluir(item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 50" @click="alterarStatus(item)">
                        <v-list-item-icon><v-icon>track_changes</v-icon></v-list-item-icon>
                        <v-list-item-title>Status</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
      <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
    </v-container>

    <dialogo-status-cheque :dialog="exibirDialogoStatusCheque" :cheque="chequeSelecionado" @fechar="fecharDialogoStatus()" @alterarStatus="confirmarAlterarStatus" />
    <dialogo-filtro-cheques :dialog="exibirDialogoFiltroCheques" :cheque="chequeSelecionado" @limpar="filtro_ativo = false" @fechar="fecharDialogoFiltro()" @filtrar="filtrarCheques" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogoStatusCheque from './DialogoStatusCheque';
import DialogoFiltroCheques from './DialogoFiltroCheques';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  name: 'ListaCheques',
  components: {
    DialogoStatusCheque,
    DialogoFiltroCheques,
    DialogConfirmacao,
  },
  data() {
    return {
      headers: [
        { text: 'Número', value: 'numero', align: 'center' },
        { text: 'Emitente', value: 'emitente', align: 'left' },
        { text: 'CNPJ/CPF', value: 'cnpjCpf', align: 'center' },
        { text: 'Data', value: 'vencimento', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'center' },
        { text: 'Ações', value: 'name', align: 'center', width: '7%', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      status: '',
      lista_status: [
        {
          text: 'Todos',
          value: '',
        },
        {
          text: 'Vencidos',
          value: 'Vencidos',
        },
        {
          text: 'Em Caixa',
          value: 'Em Caixa',
        },
        {
          text: 'Compensados',
          value: 'Compensado',
        },
        {
          text: 'Repassados',
          value: 'Repassado',
        },
        {
          text: 'Devolvidos',
          value: 'Devolvido',
        },
        {
          text: 'Devolvidos Pagos',
          value: 'Devolvido Pago',
        },
        {
          text: 'A Compensar',
          value: 'A Compensar',
        },
      ],
      chequeSelecionado: null,
      exibirDialogoStatusCheque: false,
      exibirDialogoFiltroCheques: false,
      filtro_ativo: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('cheque', {
      cheques: 'cheques',
      cheque: 'chequeAtual',
      carregando: 'carregando',
      total: 'totalCheques',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async listar() {
      this.status = '';
      this.filtro_ativo = false;
      await this.$store.dispatch('cheque/listar');
    },

    async filtrarBusca() {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('cheque/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    filtrarStatus(status) {
      let newOptions = {
        ...this.options,
        query: { status: status },
      };

      this.$store.dispatch('cheque/filtrar', newOptions);
    },

    async filtrarCheques(filtro) {
      let newOptions = {
        ...this.options,
        query: filtro,
      };
      this.exibirDialogoFiltroCheques = false;
      this.$store.dispatch('cheque/filtrar', newOptions);
    },

    editar(cheque) {
      this.$store.commit('cheque/editar', cheque);
      this.$router.push({ name: 'formularioChequeEditar' });
    },

    novo() {
      this.$store.commit('cheque/novo');
      this.$router.push({ name: 'formularioChequeNovo' });
    },

    definirCor(status) {
      if (status === constantes.EM_CAIXA) {
        return 'green';
      } else if (status === constantes.COMPENSADO) {
        return 'blue';
      } else if (status === constantes.REPASSADO) {
        return 'blue';
      } else if (status === constantes.DEVOLVIDO) {
        return 'error';
      } else if (status === constantes.DEVOLVIDO_PAGO) {
        return 'orange';
      }
    },

    excluir(cheque) {
      /* this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('cheque/excluir', cheque);
        }
      }); */

      this.$refs.dialogConfirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('cheque/excluir', cheque);
        }
      });
    },

    abrirDialogoFiltro() {
      this.filtro_ativo = true;
      this.exibirDialogoFiltroCheques = true;
    },

    alterarStatus(cheque) {
      this.chequeSelecionado = cheque;
      this.$store.commit('cheque/SET_CHEQUE_ATUAL', this.chequeSelecionado);
      this.exibirDialogoStatusCheque = true;
    },

    fecharDialogoFiltro() {
      this.exibirDialogoFiltroCheques = false;
    },

    fecharDialogoStatus() {
      this.chequeSelecionado = null;
      this.exibirDialogoStatusCheque = false;
    },

    async confirmarAlterarStatus(status) {
      this.chequeSelecionado = null;
      this.exibirDialogoStatusCheque = false;
      await this.$store.dispatch('cheque/alterarStatus', status);
    },

    limparBusca() {
      this.listar();
    },
  },
};
</script>
