import contadoresApi from '@/services/contadoresApi';

const novoContador = {
  usuario: null,
  cpf: '',
  cnpj: '',
  nome: '',
  crc: '',
  email: '',
  emailAdicional: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  codigoCidade: '',
  estado: '',
  codigoEstado: '',
  pais: '',
  codigoPais: '',
  telefone: '',
  celular: '',
};

export default {
  namespaced: true,

  state: {
    contador: { ...novoContador },
    contadores: [],
    carregando: false,
  },

  mutations: {
    setContadores(state, contadores) {
      state.contadores = contadores;
    },

    setContador(state, dados) {
      state.contador = { ...state.contador, ...dados };
    },

    adicionarContador(state, contador) {
      state.contadores.unshift(contador);
    },

    editarContador(state, contador) {
      const index = state.contadores.findIndex((cont) => cont._id == contador.id);
      if (index >= 0) {
        state.contadores[index] = { ...contador };
      }
    },

    excluirContador(state, contadorId) {
      const index = state.contadores.findIndex((cont) => cont._id == contadorId);
      if (index >= 0) {
        state.contadores.splice(index, 1);
      }
    },

    setCarregando(state, status = true) {
      state.carregando = status;
    },

    estadoInicial(state) {
      state.contador = { ...novoContador };
    },
  },

  getters: {
    contadores(state) {
      return state.contadores.map((contador) => {
        return {
          text: `${contador.nome} - CRC ${contador.crc}`,
          value: contador._id,
        };
      });
    },
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('setCarregando');
        const result = await contadoresApi.listar(options);
        commit('setContadores', result);
      } catch (error) {
        commit('setContadores', []);
        commit('setMensagemErro', 'Não foi possível listar os contadores', { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },

    async adicionar({ commit }, contador) {
      try {
        commit('setCarregando');
        const result = await contadoresApi.adicionar(contador);
        commit('adicionarContador', result);
        commit('setMensagemSucesso', 'Contador salvo com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },

    async editar({ commit }, contador) {
      try {
        commit('setCarregando');
        await contadoresApi.editar(contador, contador._id);
        commit('editarContador', contador);
        commit('setMensagemSucesso', 'Contador atualizado com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },

    async remover({ commit }, contador) {
      try {
        commit('setCarregando');
        await contadoresApi.remover(contador._id);
        commit('excluirContador', contador._id);
        commit('setMensagemSucesso', 'Contador excluído com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },
};
