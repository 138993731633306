import ListaContasReceber from '@/views/contasreceber/ListaContasReceber';
import FormularioContaReceber from '@/views/contasreceber/FormularioContaReceber';
import FormularioBaixaContaReceber from '@/views/contasreceber/FormularioBaixaContaReceber';

export default [
  {
    path: '/movimentacoes/contas-receber',
    name: 'contasReceber',
    component: ListaContasReceber,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_contas_receber' },
  },
  {
    path: '/movimentacoes/contas-receber/nova',
    name: 'formularioContaReceberNova',
    component: FormularioContaReceber,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_contas_receber' },
  },
  {
    path: '/movimentacoes/contas-receber/editar',
    name: 'formularioContaReceberEditar',
    component: FormularioContaReceber,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_contas_receber' },
  },
  {
    path: '/movimentacoes/contas-receber/baixar',
    name: 'formularioBaixarContaReceber',
    component: FormularioBaixaContaReceber,
    meta: { requiresAuth: true, nivel: 1, permissao: 'baixar_contas_receber' },
  },
];
