import ListaOrdensServicos from '@/views/ordensServicos/ListaOrdensServicos';
import FormularioOrdensServicos from '@/views/ordensServicos/FormularioOrdensServicos';

export default [
  {
    path: '/movimentacoes/ordens-servicos',
    name: 'OrdensServicosLista',
    component: ListaOrdensServicos,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_ordensservico' },
  },
  {
    path: '/movimentacoes/ordens-servicos/novo',
    name: 'OrdensServicosNovo',
    component: () => import('@/views/ordensServicos/FormularioOrdensServicos'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_ordensservico' },
  },
  {
    path: '/movimentacoes/ordens-servicos/editar',
    name: 'OrdensServicosEditar',
    component: () => import(/* webpackChunkName: "CrudEditar" */ '@/views/ordensServicos/FormularioOrdensServicos'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_ordensservico' },
  },
];
