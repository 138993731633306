export default [
  {
    path: '/tabelas/ibpt',
    name: 'listaImportarIbpt',
    component: () => import(/* webpackChunkName: "listaImportacaoIBPT" */ '@/views/cadastros/ListaImportarIBPT'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_ibpt' },
  },
  {
    path: '/tabelas/ibpt/detalhar',
    name: 'ibptDetalhar',
    component: () => import(/* webpackChunkName: "formularioIBPT" */ '@/views/cadastros/FormularioIbpt'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'detalhar_ibpt' },
  },
];
