import localforage from 'localforage';

import cidadesEstadosApi from '../../services/cidadesEstadosApi';

const versao = 1;

export default {
  namespaced: true,

  state: {
    paises: [],
    estados: [],
    cidades: [],
    estado: 0,
    pais: 0,
  },

  actions: {
    carregar({ commit, state }) {
      try {
        commit('setState', { carregando: true });

        if (state.paises.length === 0 || state.estados.length === 0 || state.cidades.length === 0) {
          localforage.getItem('cidadesEstadosVersao').then((cidadesEstadosVersao = 0) => {
            if (cidadesEstadosVersao !== versao) {
              cidadesEstadosApi.paises().then((paises) => {
                cidadesEstadosApi.estados().then((estados) => {
                  cidadesEstadosApi.cidades().then((cidades) => {
                    estados.sort((a, b) => (a.nome > b.nome ? 1 : -1));
                    cidades.sort((a, b) => (a.cidade > b.cidade ? 1 : -1));

                    localforage.setItem('paises', paises);
                    localforage.setItem('estados', estados);
                    localforage.setItem('cidades', cidades);
                    localforage.setItem('cidadesEstadosVersao', versao);

                    commit('setState', { paises, estados, cidades });
                  });
                });
              });
            } else {
              localforage.getItem('paises').then((paises) => {
                localforage.getItem('estados').then((estados) => {
                  localforage.getItem('cidades').then((cidades) => {
                    commit('setState', { paises, estados, cidades });
                  });
                });
              });
            }
          });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },
  },

  getters: {
    paises(state) {
      return state.paises.map((pais) => {
        return {
          text: pais.nome,
          value: pais.codigo,
        };
      });
    },

    estados(state) {
      return state.estados.map((estado) => {
        return {
          text: estado.nome,
          value: estado.id,
        };
      });
    },

    cidadesEstado(state) {
      const estado = String(state.estado);
      const cidades = state.cidades.filter((cidade) => cidade.codigo_estado === estado);
      return cidades.map((cidade) => ({
        text: cidade.cidade,
        value: parseInt(cidade.codigo_cidade, 10),
      }));
    },
  },

  mutations: {
    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
