import { gerarIdentificador } from '@/util/helpers';
import constantes from '@/util/constantes';
import vendasApi from '@/services/vendasApi';
import axios from 'axios';

const vendaBlank = () => ({
  identificador: gerarIdentificador(),
  tipo: (localStorage.getItem('terminalTipo') || 'Terminal') === 'Caixa' ? 'NFCe' : 'DAV',
  cliente: {
    _id: null,
    razaoSocialNome: 'CONSUMIDOR',
    cnpjCpf: '',
    email: '',
    celular: '',
    foto: null,
  },
  formasPagamento: 'DINHEIRO',
  vendedor: { _id: null, nome: null },
  acrescimo: 0,
  desconto: 0,
  valorBruto: 0,
  valorLiquido: 0,
  troco: 0,
  observacoes: '',
  pagamentos: [],
  itens: [],
  volumes: {
    quantidade: 0,
    especie: '',
    pesoLiquido: 0,
    pesoBruto: 0,
  },
});

const tefServerApi = 'http://localhost:7770';

const getButtons = (options) => {
  const buttons = {};
  if (options && options.includes('ok')) {
    buttons.ok = true;
  } else if (options && options.includes('yes') && options.includes('no')) {
    buttons.yes = true;
    buttons.no = true;
  }
  return buttons;
};

const itemBlank = {
  codigo: '',
  descricao: '',
  quantidade: 1,
  valor: 0,
  desconto: 0,
  acrescimo: 0,
  subtotal: 0,
};

export default {
  namespaced: true,

  state: {
    carregando: false,
    vendas: [],
    venda: structuredClone({ ...vendaBlank() }),
    itemIndex: null,
    item: structuredClone(itemBlank),
    modo: localStorage.getItem('modo') || 'Manual',
    tabela: localStorage.getItem('tabela') || 'A Vista',
    formasPagamento: (constantes.FORMAS_PAGAMENTO || []).map((fp) => fp.text),
    terminalTipo: localStorage.getItem('terminalTipo') || 'Terminal',
    tiposVenda: { Venda: 'Venda', DAV: 'Orçamento', NFCe: 'Cupom Fiscal', NFe: 'Nota Fiscal' },
    tef: {
      active: false,
      status: null,
      message: null,
      timer: null,
      resposta: null,
      buttons: {},
      pagamentoPendente: null,
      errorCount: 0,
    },
  },

  getters: {
    totalItens(state) {
      return state.venda.itens.reduce((total, item) => total + item.valor * item.quantidade, 0);
    },

    totalDescontos(state) {
      return state.venda.itens.reduce((total, item) => total + item.desconto, 0);
    },

    totalAcrescimos(state) {
      return state.venda.itens.reduce((total, item) => total + item.acrescimo, 0);
    },

    totalLiquido(state) {
      return state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0) - state.venda.desconto + state.venda.acrescimo;
    },

    totalBruto(state) {
      return state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0);
    },

    totalPagamentos(state) {
      return state.venda.pagamentos.reduce((total, pagamento) => total + pagamento.valor, 0);
    },

    saldo(state, getters) {
      const saldo = getters.totalLiquido - getters.totalPagamentos;
      return saldo >= 0 ? saldo : 0;
    },
  },

  mutations: {
    setTEF(state, newValues) {
      state.tef = { ...state.tef, ...newValues };
    },

    setTEFResposta(state, resposta) {
      state.tef = { ...state.tef, message: null, resposta };
    },

    setCarregando(state, valor) {
      state.carregando = valor;
    },

    setModo(state) {
      state.modo = state.modo == 'Manual' ? 'Automático' : 'Manual';
      localStorage.setItem('modo', state.modo);
    },

    setTabela(state) {
      state.tabela = state.tabela == 'A Prazo' ? 'A Vista' : 'A Prazo';
      localStorage.setItem('tabela', state.tabela);
    },

    novaVenda(state) {
      const vendaLocalstorage = JSON.parse(localStorage.getItem('venda') || '{}');
      state.venda = structuredClone({ ...vendaBlank(), ...vendaLocalstorage });
      state.itemIndex = vendaLocalstorage.itens && vendaLocalstorage.itens.length ? 0 : null;
      state.item = structuredClone(itemBlank);
    },

    cancelarVenda(state) {
      localStorage.removeItem('venda');
      state.venda = structuredClone({ ...vendaBlank() });
      state.itemIndex = null;
      state.item = structuredClone(itemBlank);
      state.venda.itens = [];
    },

    setVendas(state, vendas) {
      state.vendas = vendas;
    },

    setVenda(state, venda) {
      state.venda = venda;
      if (venda._id) {
        // Atualizar lista
      }
    },

    adicionarVenda(state, venda) {
      state.vendas = state.vendas.concat(venda);
    },

    setCliente(state, cliente) {
      state.venda.cliente = cliente;
    },

    setItemIndex(state, index) {
      state.itemIndex = index;
    },

    setItem(state, item) {
      state.item = { ...item };
    },

    setTipo(state, value) {
      state.venda.tipo = value;
    },

    setTerminalTipo(state, value) {
      state.terminalTipo = value;
      if (value === 'Terminal' && state.venda.tipo !== 'DAV') {
        state.venda.tipo = 'DAV';
      }
      localStorage.setItem('terminalTipo', value);
    },

    setDescontoAcrescimo(state, valores) {
      state.venda.desconto = valores.desconto;
      state.venda.acrescimo = valores.acrescimo;
    },

    adicionarItem(state, item) {
      state.venda.itens.push({ ...item, item: state.venda.itens.length + 1 });
      state.itemIndex = state.venda.length - 1;
      state.item = structuredClone(itemBlank);
    },

    removerItem(state, index) {
      state.venda.itens.splice(index, 1);
      state.venda.itens.forEach((item, i) => (item.item = i + 1));
    },

    atualizarPagamentosPayload(state, pagamentos) {
      state.venda.pagamentos.forEach((pg) => {
        pg.payload = pagamentos.find((p) => p.nsu === pg.payload.nsu) || pg.payload;
      });
    },

    adicionarPagamento(state, pagamento) {
      state.venda.pagamentos.push(pagamento);

      if (state.pagamentoPendente) state.pagamentoPendente = null;

      state.venda.valorLiquido = state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0) - state.venda.desconto + state.venda.acrescimo;
      state.venda.valorBruto = state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0);
      state.venda.troco = state.venda.pagamentos.reduce((a, b) => a + b.valor, 0) - state.venda.valorLiquido;
      if (state.venda.troco < 0) {
        state.venda.troco = 0;
      } else {
        state.venda.troco = parseFloat(state.venda.troco.toFixed(2));
      }
    },

    removerPagamento(state, index) {
      state.venda.pagamentos.splice(index, 1);
      state.venda.valorLiquido = state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0) - state.venda.desconto + state.venda.acrescimo;
      state.venda.valorBruto = state.venda.itens.reduce((total, item) => total + item.subtotal + item.acrescimo - item.desconto, 0);
      state.venda.troco = state.venda.pagamentos.reduce((a, b) => a + b.valor, 0) - state.venda.valorLiquido;
      if (state.venda.troco < 0) {
        state.venda.troco = 0;
      } else {
        state.venda.troco = parseFloat(state.venda.troco.toFixed(2));
      }
    },

    removerPagamentos(state) {
      state.venda.pagamentos = [];
      state.venda.troco = 0;
    },

    pagamentoPendente(state, pagamento) {
      state.pagamentoPendente = pagamento;
    },

    cancelarPagamentoPendente(state) {
      state.pagamentoPendente = null;
    },
  },

  actions: {
    async carregar({ commit }, vendaId) {
      try {
        commit('setCarregando', true);
        const venda = await vendasApi.carregar(vendaId);
        commit('setVenda', venda);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        commit('novaVenda', 'DAV');
      } finally {
        commit('setCarregando', false);
      }
    },

    async listar({ commit }, options) {
      try {
        commit('setCarregando', true);
        const lista = await vendasApi.listar(options);
        commit('setVendas', lista);
      } catch (error) {
        commit('setVendas', []);
        commit('novaVenda', 'DAV');
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async salvar({ commit, state, dispatch }) {
      let result;

      try {
        commit('setCarregando', true);

        const venda = { ...state.venda, cliente: { ...state.venda.cliente, foto: undefined } };
        venda.pagamentos = venda.pagamentos.map((pag) => {
          switch (pag.tipo) {
            case 'CARTAOCREDITO':
              return {
                tipo: pag.tipo,
                valor: pag.valor,
                parcelas: pag.parcelas,
                valorParcela: pag.valorParcela,
                bandeira: pag.bandeira,
                payload: pag.payload,
              };
            case 'CARTAODEBITO':
              return { tipo: pag.tipo, valor: pag.valor, bandeira: pag.bandeira, payload: pag.payload };
            case 'PROMISSORIA':
              return {
                tipo: pag.tipo,
                valor: pag.valor,
                vencimento: pag.vencimento,
                prazo: pag.prazo,
              };
            case 'BOLETO':
              return {
                tipo: pag.tipo,
                valor: pag.valor,
                vencimento: pag.vencimento,
                prazo: pag.prazo,
              };
            case 'CREDIARIO':
              return {
                tipo: pag.tipo,
                valor: pag.valor,
                parcelas: pag.parcelas,
                valorParcela: pag.valorParcela,
                ultimaParcela: pag.ultimaParcela,
              };
            default:
              return { tipo: pag.tipo, valor: pag.valor };
          }
        });
        venda.itens = venda.itens.map((item) => ({ ...item, codigo: String(item.codigo), vendaFracionada: undefined }));

        const novaVenda = await vendasApi.salvar(venda);
        commit('adicionarVenda', novaVenda);
        dispatch('TEFFin');
        result = novaVenda;
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
      return result;
    },

    async cancelar({ commit, state }) {
      try {
        commit('setCarregando', true);
        const vendaCancelada = vendasApi.cancelar(state.venda);
        commit('setVenda', vendaCancelada);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    fechar({ commit }) {
      commit('novaVenda', 'DAV');
    },

    // Funções TEF
    // -----------------------------------------------------------------------

    TEFStatus({ commit, dispatch }) {
      axios
        .get(tefServerApi + '/tef/status', { timeout: 3000 })
        .then(() => {
          commit('setTEF', { active: true });
          dispatch('TEFMensagem');
        })
        .catch(() => {
          commit('setTEF', { active: false });
        });
    },

    TEFAdmin({ commit }) {
      axios
        .get(tefServerApi + '/tef/adm', { timeout: 1000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR TEF ADMINISTRATIVO', { root: true });
        });
    },

    TEFCrt({ commit }, { valor, parcelas, totalPago, valorVenda, tipo }) {
      axios
        .get(tefServerApi + '/tef/crt?valor=' + String(valor) + '&parcelas=' + String(parcelas || 0) + '&totalpago=' + String(totalPago) + '&valorvenda=' + String(valorVenda) + '&tipo=' + String(tipo || 'C'), { timeout: 1000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR TEF CARTÃO', { root: true });
        });
    },

    TEFChq({ commit }, { valor, totalPago, valorVenda }) {
      axios
        .get(tefServerApi + '/tef/chq?valor=' + String(valor) + '&totalpago=' + String(totalPago) + '&valorvenda=' + String(valorVenda), { timeout: 1000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR TEF CONSULTA CHEQUE', { root: true });
        });
    },

    TEFNcn({ commit, state }, index) {
      const pagamento = state.venda.pagamentos[index];
      axios
        .get(
          tefServerApi +
            '/tef/ncn?valor=' +
            String(pagamento.valor) +
            '&nsu=' +
            pagamento.payload.nsu +
            '&finalizacao=' +
            pagamento.payload.finalizacao +
            '&documentovinculado=' +
            pagamento.payload.documentoVinculado +
            '&rede=' +
            pagamento.payload.rede +
            '&datahora=' +
            pagamento.payload.dataHoraTransacaoHost,
          { timeout: 1000 }
        )
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR TEF CANCELAMENTO', { root: true });
        });
    },

    TEFFin({ commit }) {
      axios
        .get(tefServerApi + '/tef/fin', { timeout: 1000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR FINALIZAR O TEF', { root: true });
        });
    },

    TEFCan({ commit }) {
      axios
        .get(tefServerApi + '/tef/can', { timeout: 1000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          }
        })
        .catch((error) => {
          commit('setMensagemErro', error || 'ERRO AO EXECUTAR CANCELAR O TEF', { root: true });
        });
    },

    TEFMensagem({ commit, dispatch, state }) {
      clearTimeout(state.tef.timer);
      commit('setTEF', { timer: null });

      axios
        .get(tefServerApi + '/tef/msg', { timeout: 2000 })
        .then(({ data }) => {
          if (data.message) {
            commit('setTEF', { message: data.message.toLocaleUpperCase(), buttons: getButtons(data.options), errorCount: 0 });
          } else {
            commit('setTEF', { message: null, buttons: {}, errorCount: 0 });
          }

          if (data.pagamentos) {
            // Remover cancelados
            state.venda.pagamentos.forEach((pg, idx) => {
              if (pg.payload && !data.pagamentos.find((p) => pg.payload.nsu === p.nsu)) {
                commit('removerPagamento', idx);
              }
            });

            // Adicionar pagamentos
            data.pagamentos.forEach((p) => {
              if (!state.venda.pagamentos.find((pg) => pg.payload.nsu === p.nsu)) {
                commit('adicionarPagamento', { ...state.pagamentoPendente, payload: data.pagamentos[data.pagamentos.length - 1] });
              }
            });

            commit('atualizarPagamentosPayload', data.pagamentos);
          }

          commit('setTEF', { timer: setTimeout(() => dispatch('TEFMensagem'), 2000) });
        })
        .catch((error) => {
          if (state.tef.errorCount <= 10) {
            commit('setTEF', { timer: setTimeout(() => dispatch('TEFMensagem'), 2000), errorCount: state.tef.errorCount + 1 });
          } else {
            commit('setTEF', { message: null, buttons: {}, active: false });
            const errorMessage = error.code === 'ECONNABORTED' ? 'Conector TEF não está respondendo, favor reiniciar o computador ou chamar o suporte técnico!' : error.message;
            commit('setMensagemErro', errorMessage || 'ERRO AO EXECUTAR TEF', { root: true });
          }
        });
    },

    TEFResposta({ commit }, resposta) {
      commit('setTEF', { message: null, buttons: {} });
      axios.get(tefServerApi + '/tef/' + resposta, { timeout: 3000 }).catch(() => {
        axios.get(tefServerApi + '/tef/' + resposta, { timeout: 3000 }).catch((error) => {
          const errorMessage = error.code === 'ECONNABORTED' ? 'Conector TEF não está respondendo, favor reiniciar o computador ou chamar o suporte técnico!' : error.message;
          commit('setMensagemErro', errorMessage || 'ERRO AO EXECUTAR TEF', { root: true });
        });
      });
    },

    // async EMITIR_CUPOM({ commit, state }) {
    //   let venda = Object.assign({}, state.venda);

    //   let totalTributos = 0;
    //   let produtosCupom = [];

    //   venda.produtos.forEach((produto) => {
    //     let icms = produto.subtotal * (produto.aliq_icms / 100);
    //     icms = parseFloat(icms.toFixed(2));

    //     let pis = produto.subtotal * (produto.aliq_pis_saida / 100);
    //     pis = parseFloat(pis.toFixed(2));

    //     let cofins = produto.subtotal * (produto.aliq_cofins_saida / 100);
    //     cofins = parseFloat(cofins.toFixed(2));

    //     let tributos = icms + pis + cofins;
    //     tributos = parseFloat(tributos.toFixed(2));

    //     let produtoCupom = {
    //       codigo: produto.codigo,
    //       codigoBarra: produto.codigo_barra,
    //       descricao: produto.descricao,
    //       ncm: produto.ncm.codigo,
    //       cest: produto.cest ? produto.cest.cest : null,
    //       unidade: produto.unidade,
    //       valor: produto.valor,
    //       origem: produto.origem,
    //       cst_icms: produto.cst_icms,
    //       cst_icms_st: '00',
    //       aliq_icms: produto.aliq_icms,
    //       aliq_icms_st: 0,
    //       csosn: produto.csosn,
    //       cfop: '5102',
    //       produto: produto._id, //id do produto
    //       sequencia: produto.item,
    //       quantidade: produto.quantidade,
    //       subtotal: produto.subtotal,
    //       desconto: 0,
    //       cst_pis: produto.cst_pis_saida,
    //       cst_cofins: produto.cst_cofins_saida,
    //       cst_ipi: '00',
    //       aliq_pis: produto.aliq_pis_saida,
    //       aliq_cofins: produto.aliq_cofins_saida,
    //       aliq_ipi: 0,
    //       base_calculo_icms: produto.subtotal,
    //       icms: icms,
    //       base_calculo_icms_st: 0,
    //       icms_st: 0,
    //       base_calculo_pis: produto.subtotal,
    //       pis: pis,
    //       base_calculo_cofins: produto.subtotal,
    //       cofins: cofins,
    //       base_calculo_ipi: 0,
    //       ipi: 0,
    //       valor_tributos: tributos,
    //     };

    //     produtosCupom.push(produtoCupom);
    //     totalTributos += tributos;
    //   });

    //   let formaPagamento = 0; // 1- a vista, 2- indicam pagamento a prazo, vai gerar faturas a prazo no cupom
    //   let pagamentos = [];
    //   venda.pagamentos.forEach((pagamento) => {
    //     if (pagamento.tipo === constantes.CARTAO_CREDITO || pagamento.tipo === constantes.CARTAO_DEBITO) {
    //       let pagamentoCartao = {
    //         adiquirente: pagamento.adiquirente,
    //         autorizacao: pagamento.autorizacao,
    //         cartao: pagamento.tipo,
    //         controle: pagamento.controle,
    //         data: pagamento.data,
    //         forma: constantes.CARTAO,
    //         hora: pagamento.hora,
    //         nsu: pagamento.nsu,
    //         qtdParcelas: pagamento.cartao_parcelas,
    //         valor: pagamento.valor,
    //         comprovanteCliente: pagamento.comprovanteCliente,
    //         comprovanteEstabelecimento: pagamento.comprovanteEstabelecimento,
    //       };
    //       formaPagamento = 1;
    //       pagamentos.push(pagamentoCartao);
    //     } else {
    //       let outrosPagamentos = {
    //         forma: pagamento.tipo,
    //         valor: pagamento.valor,
    //       };
    //       pagamentos.push(outrosPagamentos);
    //     }
    //   });

    //   let cupom = {
    //     identificador: venda.identificador,
    //     numero: 0,
    //     serie: '001', //colocar o terminal como série
    //     tipo: '1', // 0- entrada, 1-saída
    //     finalidade: 1, // 1- emissão de nfc-e normal
    //     data_emissao: moment().format('YYYY-MM-DD').toString(),
    //     cfop: '',
    //     natureza_operacao: 'VENDA',
    //     produtos: produtosCupom,
    //     pagamentos: pagamentos,
    //     forma_pagamento: formaPagamento,
    //     desconto: 0,
    //     total_bc_icms: 0,
    //     total_icms: 0,
    //     total_icms_st: 0,
    //     total_ipi: 0,
    //     total_ii: 0,
    //     total_pis: 0,
    //     total_cofins: 0,
    //     total_produtos: venda.valor_bruto,
    //     total_tributos: totalTributos,
    //     total_cupom: 0,
    //     status: 'pendente',
    //   };

    //   //informações do cliente caso informado
    //   if (venda.cliente._id) {
    //     cupom.cliente = {
    //       _id: venda.cliente._id,
    //       cpf: venda.cliente.cnpjCpf,
    //       nome: venda.cliente.razaoSocialNome,
    //     };
    //   }

    //   delete cupom._id;
    //   try {
    //     let resultado = await cupomApi.cadastrar(cupom);
    //     // if (resultado.status) {
    //     //   commit('setMensagemErro', resultado.motivo, { root: true });
    //     // } else {
    //     //   let resultadoDanfece = await cupomApi.gerarDanfece(resultado._id);
    //     //   let htmlCupom = Buffer.from(resultadoDanfece.htmlCupom, 'base64').toString('UTF-8');
    //     //   let htmlComprovante = Buffer.from(resultadoDanfece.htmlComprovanteCartao, 'base64').toString('UTF-8');

    //     //   ipcRenderer.send('print', htmlCupom);
    //     //   setTimeout(() => {
    //     //     ipcRenderer.send('print', htmlComprovante);
    //     //   }, 8000);

    //     //   commit('setMensagemSucesso', 'Cupom autorizado', { root: true });
    //     // }
    //     commit('setMensagemSucesso', 'Cupom autorizado', { root: true });
    //   } catch (error) {
    //     commit('setMensagemErro', error, { root: true });
    //     throw error;
    //   } finally {
    //     commit('setCarregando', false);
    //   }
    // },
  },
};
