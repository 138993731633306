import constantes from '@/util/constantes';
import usuariosApi from '@/services/usuariosApi';

export default {
  namespaced: true,

  state: {
    usuarios: [],
    constantes: constantes,
    carregando: false,
  },

  actions: {
    async listar({ commit }) {
      try {
        commit('setCarregando', false);
        const usuarios = await usuariosApi.listar();
        commit('setUsuarios', usuarios);
      } catch (error) {
        commit('setUsuarios', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    // async carregarEmpresas({ commit }) {
    //   try {
    //     commit('setCarregando', true);
    //     const empresas = await usuariosApi.carregarEmpresas();
    //     commit('setEmpresas', empresas);
    //   } catch (error) {
    //     commit('setEmpresas', []);
    //     commit('setMensagemErro', error, { root: true });
    //   } finally {
    //     commit('setCarregando', false);
    //   }
    // },

    async salvar({ commit, state }, empresas) {
      try {
        commit('setCarregando', true);
        const usuario = { ...state.usuarioAtual };

        usuario.empresas = empresas.map((empresa) => {
          return { empresa: empresa._id, nivelAcesso: empresa.nivelAcesso, permissoes: empresa.permissoes };
        });

        if (usuario._id) {
          await usuariosApi.atualizar(usuario);
          commit('atualizar', usuario);
          commit('setMensagemSucesso', 'Usuário atualizado com sucesso', { root: true });
        } else {
          delete usuario._id;
          await usuariosApi.salvar(usuario);
          commit('setMensagemSucesso', 'Usuário cadastrado com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async editar({ commit }, dados) {
      try {
        commit('setCarregando', true);
        const params = { foto: dados.foto, nome: dados.nome };
        const result = await usuariosApi.editar(dados.usuarioId, params);
        commit('setMensagemSucesso', 'Dados do usuário alterados com sucesso', { root: true });
        commit('autenticacao/setSession', { usuario: { ...dados.usuario, ...params, foto: result.foto } }, { root: true });
        dados.router.go(-1);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async remover({ commit }, usuario) {
      try {
        commit('setCarregando', true);
        await usuariosApi.remover(usuario._id);
        commit('remover', usuario);
        commit('setMensagemSucesso', 'Usuário removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async alterarSenha({ commit }, dados) {
      let result = false;
      try {
        commit('setCarregando', true);
        const params = { senhaNova: dados.senhaNova, senhaAtual: dados.senhaAtual };
        await usuariosApi.alterarSenha(dados.usuarioId, params);
        commit('setMensagemSucesso', 'Senha alterada com sucesso', { root: true });
        result = true;
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
      return result;
    },

    carregarFoto({ commit, state }, foto) {
      commit('setCarregando', true);
      const reader = new FileReader();
      const context = this;
      reader.onload = (e) => {
        context.commit('autenticacao/setSession', { usuario: { ...state.usuario, foto: e.target.result } });
        context.commit('setCarregando', false);
      };
      reader.readAsDataURL(foto);
    },
  },

  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },

    setChecarCertificado(state) {
      state.checarCertificado = true;
    },

    setUsuarios(state, usuarios) {
      state.usuarios = usuarios;
      state.carregando = false;
    },

    setEmpresas(state, empresas) {
      const empresasRetornadas = [];

      if (state.modoEdicao) {
        empresas.forEach((empresa) => {
          const config = state.usuarioAtual.empresas.find((row) => row.empresa === empresa._id);

          let nova = null;

          if (config) {
            nova = {
              _id: empresa._id,
              cnpj: empresa.cnpj,
              fantasia: empresa.fantasia,
              nivelAcesso: config ? config.nivelAcesso : 'NORMAL',
              permissoes: config ? config.permissoes : [],
            };
          } else {
            nova = {
              _id: empresa._id,
              cnpj: empresa.cnpj,
              fantasia: empresa.fantasia,
              nivelAcesso: 'NORMAL',
              permissoes: [],
            };
          }

          empresasRetornadas.push(nova);
        });
      } else {
        empresas.forEach((empresa) => {
          const nova = {
            _id: empresa._id,
            cnpj: empresa.cnpj,
            fantasia: empresa.fantasia,
            nivelAcesso: 'NORMAL',
            permissoes: [],
          };
          empresasRetornadas.push(nova);
        });
      }

      state.empresas = empresasRetornadas;
      state.carregando = false;
    },

    adicionarPermissaoUsuario(state, params) {
      const { permissao, empresaId } = params;
      const empresa = state.empresas.find((row) => row._id === empresaId);
      empresa.permissoes.push(permissao);
    },

    removerPermissaoUsuario(state, params) {
      const { permissao, empresaId } = params;
      const empresa = state.empresas.find((row) => row._id === empresaId);
      const index = empresa.permissoes.indexOf(permissao);
      empresa.permissoes.splice(index, 1);
    },

    remover(state, removido) {
      state.usuarios = state.usuarios.filter((usuario) => usuario._id !== removido._id);
      state.carregando = false;
    },

    novo(state) {
      state.modoEdicao = false;
      state.usuarioAtual = {
        nome: '',
        email: '',
        senha: '',
      };
      state.carregando = false;
    },

    editar(state, editar) {
      state.modoEdicao = true;
      delete editar.senha;
      state.usuarioAtual = editar;
      state.carregando = false;
    },

    adicionar(state, usuario) {
      state.usuarios.push(usuario);
      state.carregando = false;
    },

    atualizar(state, atual) {
      state.usuarios.forEach((usuario) => {
        if (usuario._id === atual._id) {
          usuario = atual;
        }
      });
      state.carregando = false;
    },

    estadoInicial(state) {
      state.usuarioAtual = {
        nome: '',
        email: '',
        senha: '',
      };
      state.carregando = false;
    },
  },
};
