import axios from '@/util/axios';

function carregarEmpresa() {
  return axios.get(`/empresas`).then((response) => response.data);
}

function carregarFiliais() {
  return axios.get(`/participantes/tipos/Cliente`).then((response) => response.data);
}

function salvar(nota) {
  return axios.post(`/notas`, nota).then((response) => response.data);
}

function atualizar(nota) {
  return axios.put(`/notas`, nota).then((response) => response.data);
}

export default {
  carregarEmpresa,
  carregarFiliais,
  salvar,
  atualizar,
};
