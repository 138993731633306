import axios from '@/util/axios';

function listar() {
  return axios.get(`/configuracoes`).then((response) => response.data);
}

function salvar(configuracao) {
  return axios.post(`/configuracoes`, configuracao).then((response) => response.data);
}

function alterarStatus(configuracao) {
  return axios.put(`/configuracoes/${configuracao._id}/status`).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/configuracoes/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  alterarStatus,
  excluir,
};
