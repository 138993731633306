<template>
  <div>
    <v-text-field :loading="loading" label="Código" ref="codigoProduto" :value="value" append-icon="search" @keyup.enter="consultarCodigoProduto($event)" @input="onInput" @click:append="abrirDialogo"> </v-text-field>

    <dialog-buscar-produto-deposito ref="dialogoBuscarProduto" :dialog="dialogo" @produtoSelecionado="adicionarProdutoDialog"></dialog-buscar-produto-deposito>
  </div>
</template>

<script>
import axios from '@/util/axios';
import DialogBuscarProdutoDeposito from '@/components/dialogs/DialogBuscarProdutoDeposito';

export default {
  name: 'BuscarProdutoDeposito',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    cnpjFilial: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    DialogBuscarProdutoDeposito,
  },

  data() {
    return {
      dialogo: false,
      loading: false,
    };
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    abrirDialogo() {
      if (this.cnpjFilial && this.cnpjFilial !== '') {
        this.$refs.dialogoBuscarProduto.abrir(this.cnpjFilial);
      } else {
        this.$store.commit('setMensagemErro', 'Filial de Destino não informada');
      }
    },

    setFocus() {
      setTimeout(() => {
        this.$refs.codigoProduto.focus();
      }, 300);
    },

    consultarCodigoProduto(event) {
      if (this.cnpjFilial && this.cnpjFilial !== '') {
        let codigo = event.target.value;
        if (codigo && codigo !== '') {
          this.loading = true;
          let empresa = this.$store.state.empresa;
          axios
            .get(`/${empresa._id}/${this.cnpjFilial}/produtos/codigos/${codigo}`)
            .then((response) => {
              this.$emit('produtoSelecionado', response.data);
              this.loading = false;
            })
            .catch((erro) => {
              this.loading = false;
              this.$store.commit('setMensagemErro', erro.response.data.motivo);
            });
        } else {
          this.$store.commit('setMensagemErro', 'Código Inválido');
        }
      } else {
        this.$store.commit('setMensagemErro', 'Filial de Destino não informada');
      }
    },

    adicionarProdutoDialog(produto) {
      this.$emit('produtoSelecionado', produto);
    },
  },
};
</script>

<style scoped></style>
