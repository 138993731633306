import axios from '@/util/axios';
import constantes from '@/util/constantes';
import cofinsApi from '@/services/cofinsApi';

export default {
  namespaced: true,

  state: {
    cofins: [],
    cofinsAtual: {
      codigo: '',
      descricao: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  actions: {
    async carregar({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await cofinsApi.listar(options);
        commit('setCofins', response);
      } catch (error) {
        commit('setCofins', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        let cofins = Object.assign({}, state.cofinsAtual);

        if (state.modoEdicao) {
          let response = await axios.put('/cofins', cofins);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'COFINS atualizado com sucesso', { root: true });
        } else {
          commit('setCarregando', true);
          delete cofins._id;

          let response = await axios.post('/cofins', cofins);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'COFINS cadastrado com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async remover({ commit }, cofins) {
      try {
        commit('setCarregando', true);
        await cofinsApi.excluir(cofins);
        commit('remover', remover);
        commit('setMensagemSucesso', 'COFINS Removido com Sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async buscar({ commit }, cofins_busca) {
      commit('setCarregando', true);

      try {
        let response = await axios.get(`/cofins/buscar?busca=${cofins_busca}`);
        if (response.status === 200) {
          commit('setCofins', response.data);
        }
      } catch (error) {
        commit('setCofins', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },
  },

  mutations: {
    setCofins(state, cofins) {
      state.cofins = cofins;
      state.carregando = false;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    remover(state, removido) {
      state.cofins = state.cofins.filter((cof) => cof._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.cofinsAtual = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.cofinsAtual = editar;
      state.carregando = false;
    },
    adicionar(state, cofins) {
      state.cofins.push(cofins);
      state.carregando = false;
    },
    atualizar(state, atual) {
      state.cofins.forEach((cof) => {
        if (cof._id === atual._id) {
          cof = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state) {
      state.cofinsAtual = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
  },
};
