import ListaLocaisEstoque from '@/views/locaisestoque/ListaLocaisEstoque';
import FormularioLocalEstoque from '@/views/locaisestoque/FormularioLocalEstoque';

export default [
  {
    path: '/tabelas/locaisEstoque',
    name: 'locaisestoque',
    component: ListaLocaisEstoque,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_locaisestoque' },
  },
  {
    path: '/tabelas/locaisEstoque/novo',
    name: 'formularioLocalEstoqueNovo',
    component: FormularioLocalEstoque,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_locaisestoque' },
  },
  {
    path: '/tabelas/locaisEstoque/editar',
    name: 'formularioLocalEstoqueEditar',
    component: FormularioLocalEstoque,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_locaisestoque' },
  },
];
