import axios from '@/util/axios';

async function buscarDescricaoCest(descricao) {
  return axios.get(`/cest/descricao/${descricao}`).then((response) => response.data);
}

function filtrarPorNcm(ncm) {
  return axios.get(`/cest/filtro-ncm/${ncm}`).then((response) => response.data);
}

export default {
  buscarDescricaoCest,
  filtrarPorNcm,
};
