<template>
  <v-app-bar color="primary darken-2" fixed dark app>
    <v-app-bar-nav-icon @click.stop="handleDrawerToggle"></v-app-bar-nav-icon>

    <!-- <v-toolbar-title class="pa-1">{{ this.$store.state.autenticacao.session.empresa.fantasia }}</v-toolbar-title> -->

    <v-spacer v-if="session.usuario.nivelAcesso === 'ANRON'" />
    <v-select v-else-if="session.empresas.length > 1" class="mt-6 px-2" flat full-width background-color="green darken-2" :items="session.empresas.map((row) => row.fantasia)" dense solo></v-select>
    <v-label dark v-else>{{ session.empresa.fantasia }}</v-label>
    <v-spacer v-if="session.empresas.length <= 1" />

    <span v-if="certificadoVencido">Certificado Vencido</span>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="handleFullScreen()">
          <v-icon>{{ !fullscreen ? 'fullscreen' : 'fullscreen_exit' }}</v-icon>
        </v-btn>
      </template>
      <span>{{ !fullscreen ? 'Modo Tela Cheia' : 'Modo Normal' }}</span>
    </v-tooltip>

    <v-menu v-if="!['ANRON', 'CONTADOR'].includes(session.usuario.nivelAcesso)" offset-y origin="right top" class="elelvation-1" :nudge-bottom="14" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon text v-on="{ ...on, ...tooltip }">
              <v-badge :value="quantidadeNotificacoes > 0" color="red" overlap>
                <span slot="badge" v-if="quantidadeNotificacoes > 0">{{ quantidadeNotificacoes }}</span>
                <v-icon medium>notifications</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Notificações</span>
        </v-tooltip>
      </template>
      <notification-list :notificacoes="notificacoes"></notification-list>
    </v-menu>

    <v-menu v-if="!['ANRON', 'CONTADOR'].includes(session.usuario.nivelAcesso)" offset-y origin="right top" class="elelvation-1" :nudge-bottom="4" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon text v-on="{ ...on, ...tooltip }">
              <v-icon medium>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <span>Módulos</span>
        </v-tooltip>
      </template>

      <v-card class="elevation-0">
        <v-card-text class="pa-0">
          <v-btn block large class="ma-0 pa-2 rounded-0" color="green lighten-3" elevation="0" @click="setDrawerMenu('pdv')">
            <v-icon color="green darken-4">shopping_cart</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>PDV</b><small>Terminal de Vendas</small></div>
          </v-btn>

          <v-divider />
          <v-btn block large class="ma-0 pa-2 rounded-0" color="amber lighten-4" elevation="0" @click="setDrawerMenu('easy')">
            <v-icon color="amber darken-3">sticky_note_2</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Easy</b><small>Retaguarda</small></div>
          </v-btn>

          <v-divider />
          <v-btn block large class="ma-0 pa-2 rounded-0" color="teal lighten-5" elevation="0" @click="setDrawerMenu('nfe')">
            <v-icon color="teal darken-2">file_present</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor NF-e</small></div>
          </v-btn>

          <v-divider />
          <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
            <v-icon color="teal darken-2">file_present</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor NF-e Depósito</small></div>
          </v-btn>

          <v-divider />
          <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
            <v-icon color="teal darken-2">file_present</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor MDF-e</small></div>
          </v-btn>

          <v-divider />
          <v-btn block large class="ma-0 pa-2 rounded-0" elevation="0" @click="setDrawerMenu('deposito')">
            <v-icon color="teal darken-2">file_present</v-icon>
            <div style="width: 100%; text-align: left; text-transform: none; display: flex; align-items: center; justify-content: space-between; font-weight: 400; gap: 10px" class="pl-2"><b>Fisc-e</b><small>Emissor CT-e</small></div>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>
<script>
import NotificationList from '@/components/widgets/NotificationList';
import Util from '@/util';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'app-toolbar',
  components: {
    NotificationList,
  },

  data() {
    return { fullscreen: false };
  },

  computed: {
    ...mapState({
      carregando: 'carregando',
      value: 0,
    }),

    ...mapState('autenticacao', {
      session: 'session',
      certificadoVencido: 'certificadoVencido',
    }),

    ...mapGetters('notificacao', {
      notificacoes: 'notificacoesNaoLidas',
    }),

    carregou() {
      if (this.$store.state.notificacao.carregando) {
        return false;
      } else {
        return true;
      }
    },

    quantidadeNotificacoes() {
      const notificacoes = this.$store.getters['notificacao/notificacoesNaoLidas'];
      if (notificacoes) {
        return notificacoes.length;
      } else {
        return 0;
      }
    },

    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
  },

  methods: {
    handleDrawerToggle() {
      window.getApp.$emit('APP_DRAWER_TOGGLED');
    },

    handleFullScreen() {
      Util.toggleFullScreen();
      setTimeout(() => (this.fullscreen = document.fullscreenElement), 100);
    },

    setDrawerMenu(modulo) {
      this.$store.commit('autenticacao/setSession', { modulo });

      if (modulo === 'pdv') this.$router.push('/pdv');
    },
  },

  created() {
    if (this.$store.state.autenticacao.session.usuario.nivelAcesso !== 'ANRON') {
      this.$store.dispatch('notificacao/carregarNaoLidas');
    }
  },
};
</script>
