import axios from '@/util/axios';

function cuponsRecentes() {
  return axios.get('/cupom/recentes').then((response) => response.data);
}

function cadastrar(cupom) {
  return axios.post('/cupom', cupom).then((response) => response.data);
}

function gerarDanfce(idCupom) {
  return axios.post('/cupom/danfce', { id: idCupom }).then((response) => response.data);
}

function cancelar(idCupom) {
  return axios.delete('/cupom', { id: idCupom }).then((response) => response.data);
}

export default {
  cuponsRecentes,
  cadastrar,
  gerarDanfce,
  cancelar,
};
