import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/cfopsCompraEntrada?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(mapeamento) {
  return axios.post(`/cfopsCompraEntrada`, mapeamento).then((response) => response.data);
}

function atualizar(mapeamento) {
  return axios.put(`/cfopsCompraEntrada/${mapeamento._id}`, mapeamento).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/cfopsCompraEntrada/${id}`).then((response) => response.data);
}

function buscar(cfopCompra) {
  return axios.get(`/cfopsCompraEntrada/buscar?cfopcompra=${cfopCompra}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
  buscar,
};
