<template>
  <v-text-field v-bind="$attrs" v-on="$listeners" v-mask="['###.###.###-##', '##.###.###/####-##']" ref="input" :value="value" @input="onInput"> </v-text-field>
</template>

<script>
export default {
  name: 'CampoCpfCnpj',

  props: ['value'],

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped></style>
