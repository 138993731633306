import formatador from '@/util/formatador';
import produtoApi from '@/services/produtosApi';

export default {
  namespaced: true,

  state: {
    vendas: [],
    produtos: [],
    produto_selecionado: {
      codigo: '',
      descricao: '',
      quantidade: 1,
      valor: 0,
      desconto: 0,
      subtotal: 0,
    },
  },

  getters: {
    vendas_autorizadas(state) {
      return state.vendas.filter((venda) => venda.status == 'Autorizado');
    },

    vendas_canceladas(state) {
      return state.vendas.filter((venda) => venda.status == 'Cancelado');
    },

    vendas_total(state, getters) {
      return getters.vendas_autorizadas.reduce((soma, venda) => soma + venda.valor, 0);
    },

    vendas_count(state, getters) {
      return getters.vendas_autorizadas.length;
    },

    valor_total(state, getters) {
      return `${getters.vendas_count} - ${formatador.formatarValor(getters.vendas_total)}`;
    },

    vendas_canceladas_total(state, getters) {
      return getters.vendas_canceladas.reduce((soma, venda) => soma + venda.valor, 0);
    },

    vendas_canceladas_count(state, getters) {
      return getters.vendas_canceladas.length;
    },

    valor_canceladas_total(state, getters) {
      return `${getters.vendas_canceladas_count} - ${formatador.formatarValor(getters.vendas_canceladas_total)}`;
    },
  },

  mutations: {
    SET_PRODUTOS(state, produtos) {
      state.produtos = produtos;
    },

    SET_PRODUTO_SELECIONADO(state, produto) {
      state.produto_selecionado = produto;
    },
  },

  actions: {
    async carregarProdutosPDV({ commit }) {
      const produtos = await produtoApi.carregarProdutosPDV();
      commit('SET_PRODUTOS', produtos);
    },
  },
};
