import axios from '@/util/axios';
import constantes from '@/util/constantes';
import tamanhosApi from '@/services/tamanhosApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    tamanhos: [],
    tamanhoAtual: {
      codigo: '',
      tamanho: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        const response = await tamanhosApi.listar(options);
        commit('SET_TAMANHOS', response);
        commit('SET_TOTAL_TAMANHOS', response.total);
      } catch (error) {
        commit('SET_TAMANHOS', []);
        commit('setMensagemErro', 'Não foi possível listar os tamanhos', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      commit('SET_CARREGANDO', true);

      try {
        const tamanho = Object.assign({}, state.tamanhoAtual);
        const response = await tamanhosApi.salvar(tamanho);
        commit('ATUALIZAR_TAMANHO', response);
        commit('setMensagemSucesso', 'Tamanho salvo com sucesso', { root: true });
      } catch (error) {
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },
    async atualizar({ commit }, tamanho) {
      await tamanhosApi
        .atualizar(tamanho)
        .then(() => {
          commit('ATUALIZAR_TAMANHO', tamanho);
          commit('setMensagemSucesso', 'Tamanho atualizado com sucesso', { root: true });
        })
        .catch((error) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    async excluir({ commit }, tamanho) {
      commit('SET_CARREGANDO', true);
      await tamanhosApi
        .excluir(tamanho._id)
        .then((response) => {
          commit('EXCLUIR_TAMANHO', tamanho._id);
          commit('setMensagemSucesso', 'Tamanho removido com sucesso', { root: true });
        })
        .catch((error) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_TAMANHOS(state, tamanhos) {
      state.tamanhos = tamanhos;
      state.carregando = false;
    },
    SET_TOTAL_TAMANHOS(state, total) {
      state.totalTamanhoes = total;
    },
    SET_TAMANHO(state, tamanhoAtual) {
      state.tamanhoAtual = tamanhoAtual;
    },
    EXCLUIR_TAMANHO(state, id) {
      let index = state.tamanhos.findIndex((tamanho) => tamanho._id == id);
      if (index > -1) {
        state.tamanhos.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.tamanhoAtual = {
        codigo: '',
        tamanho: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.tamanhoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_TAMANHO(state, tamanho) {
      state.tamanhos.unshift(tamanho);
      state.carregando = false;
    },
    ATUALIZAR_TAMANHO(state, tamanho) {
      let tamanho_encontrado = state.tamanhos.find((tamanho_) => tamanho_.id == tamanho.id);
      if (tamanho_encontrado) {
        tamanho_encontrado = tamanho;
      }
      state.carregando = false;
    },
    estadoInicial(state) {
      state.tamanhoAtual = {
        codigo: '',
        tamanho: '',
      };
      state.carregando = false;
    },
  },
};
