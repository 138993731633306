export default [
  {
    path: '/tabelas/bancos',
    name: 'bancos',
    component: () => import(/* webpackChunkName: "listaBancos" */ '@/views/bancos/ListaBancos'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'bancos_acessar' },
  },
  {
    path: '/tabelas/bancos/adicionar',
    name: 'bancosAdicionar',
    component: () => import(/* webpackChunkName: "formularioBanco" */ '@/views/bancos/FormularioBanco'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/tabelas/bancos/editar',
    name: 'bancosEditar',
    component: () => import(/* webpackChunkName: "formularioBanco" */ '@/views/bancos/FormularioBanco'),
    meta: { requiresAuth: true, nivel: 4 },
  },
];
