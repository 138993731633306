<template>
  <v-container fluid>
    <v-widget title="Participante" class="pb-0" style="height: calc(100vh - 136px); border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formParticipante" lazy-validation>
          <v-container grid-list-md fluid class="pa-0" style="overflow: hidden auto; box-sizing: border-box; height: calc(100vh - 268px)">
            <v-row dense class="pt-1">
              <v-col cols="12" sm="8">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="CPF / CNPJ"
                      v-model="participante.cnpjCpf"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :rules="validarCnpjCpf"
                      :loading="carregando"
                      :readonly="carregando"
                      required
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      ref="cnpjCpf"
                      @change="consultarCnpj(participante.cnpjCpf)"
                      @keypress.enter="$refs.rgIe.focus()"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      :label="participante.cnpjCpf.length > 14 ? 'Inscrição Estadual' : 'Registro Geral'"
                      ref="rgIe"
                      v-model="participante.rgIe"
                      :rules="participante.cnpjCpf.length > 14 ? validarIE : []"
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      :required="participante.cnpjCpf.length > 14"
                      autocomplete="off"
                      role="presentation"
                      @keypress.enter="$refs.razaoSocialNome.focus()"
                      @blur="validarInscricaoEstadual"
                    >
                      <template v-slot:append v-if="participante.cnpjCpf.length > 14">
                        <v-tooltip bottom style="cursor: pointer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :color="participante.isento ? 'primary' : 'black'" @click="setIsento" v-bind="attrs" v-on="on" style="margin-top: -8px">
                              <v-icon>mdi-alpha-i-box</v-icon>
                            </v-btn>
                          </template>
                          <span>ISENTO</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Nome / Razão Social"
                      ref="razaoSocialNome"
                      v-model="participante.razaoSocialNome"
                      :rules="validadarRazaoSocialNome"
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      required
                      autocomplete="off"
                      role="presentation"
                      v-input-upper-case
                      @keypress.enter="$refs.nomeFantasiaApelido.focus()"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Nome Fantasia / Apelido"
                      ref="nomeFantasiaApelido"
                      v-model="participante.nomeFantasiaApelido"
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      required
                      @keypress.enter="$refs.tipo.focus()"
                      v-input-upper-case
                    />
                  </v-col>

                  <v-col cols="12" sm="7">
                    <v-select ref="tipo" label="Tipo" v-model="participante.tipo" :items="constantes.TIPOS_CLIENTE_FORNECEDOR" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" :disabled="false" />
                  </v-col>

                  <v-col cols="12" sm="5">
                    <campo-data
                      :label="participante.cnpjCpf.length > 14 ? 'Fundação' : 'Nascimento'"
                      ref="nascimento"
                      v-model="participante.nascimento"
                      :rules="validarNascimento"
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      :messages="idade(participante.nascimento)"
                      @keypress.enter="$refs.limiteCredito.focus()"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'pl-3' : 'pb-3'">
                <v-container fluid class="pa-0" style="border: 1px solid silver; border-radius: 4px">
                  <v-img height="282" v-bind:src="fotoSrc" @click="escolherArquivo" style="cursor: pointer; background-color: whitesmoke; background-size: cover !important; border-radius: 4px 4px 0px 0px">
                    <template v-slot:placeholder>
                      <div style="display: flex; height: 100%; align-items: center; justify-content: center; font-size: large; color: gray">Imagem não disponível</div>
                    </template>
                  </v-img>

                  <v-container class="px-2 pt-2" fluid style="height: 54px; display: flex; flex-direction: row; border-radius: 0px 0px 4px 4px">
                    <input id="inputArquivo" type="file" :multiple="false" hidden @change="onFileSelected" accept=".jpg,.png,.jpeg,.bmp" />
                    <v-btn icon @click="limparFoto">
                      <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn text depressed @click="camera">Câmera<v-icon class="ml-3">photo_camera</v-icon></v-btn>
                    <v-btn text depressed @click="escolherArquivo">Arquivo<v-icon class="ml-3">image</v-icon></v-btn>
                  </v-container>
                </v-container>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="4">
                <v-select ref="restricoes" label="Restrições" v-model="participante.restricoes" :items="constantes.RESTRICOES" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" />
              </v-col>

              <v-col cols="12" sm="2">
                <campo-valor
                  label="Limite de Crédito"
                  ref="limiteCredito"
                  v-model="participante.limiteCredito"
                  :rules="validarValor"
                  fixarDecimais
                  decimais="2"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="participante.cnpjCpf.length > 14 ? $refs.suframa.focus() : $refs.cep.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-text-field
                  label="SUFRAMA"
                  ref="suframa"
                  v-model="participante.suframa"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.cep.focus()"
                  :disabled="participante.cnpjCpf.length < 18"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <campo-data label="Data de Cadastro" v-model="participante.createdAt" disabled readonly placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Informações de Contato</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="4">
                <campo-cep
                  v-mask="'#####-###'"
                  label="CEP"
                  ref="cep"
                  buscarcep
                  @resultado="setEndereco"
                  v-model="participante.cep"
                  append-icon="map"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.logradouro.focus()"
                />
              </v-col>

              <v-col cols="12" sm="8">
                <v-text-field
                  label="Logradouro"
                  ref="logradouro"
                  v-model="participante.logradouro"
                  :rules="validarLogradouro"
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.numero.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field
                  label="Número"
                  ref="numero"
                  v-model="participante.numero"
                  :rules="validarNumero"
                  required
                  data-cy="numero"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.complemento.focus()"
                />
              </v-col>

              <v-col cols="12" sm="5">
                <v-text-field label="Complemento" ref="complemento" v-model="participante.complemento" data-cy="complemento" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" @keypress.enter="$refs.bairro.focus()" />
              </v-col>

              <v-col cols="12" sm="5">
                <v-text-field
                  label="Bairro"
                  ref="bairro"
                  v-model="participante.bairro"
                  :rules="validarBairro"
                  required
                  data-cy="bairro"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.estado.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete label="País" :rules="validarPais" v-model="participante.codigoPais" :items="paises" placeholder=" " autocomplete="off" role="presentation" required persistent-placeholder outlined @change="setPais" />
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  label="Estado"
                  :rules="validarEstado"
                  ref="estado"
                  v-model="participante.codigoEstado"
                  :items="estados"
                  placeholder=" "
                  autocomplete="off"
                  role="presentation"
                  required
                  persistent-placeholder
                  outlined
                  @change="setEstado"
                  @blur="validarInscricaoEstadual"
                />
              </v-col>

              <v-col cols="12" sm="5">
                <v-autocomplete
                  label="Cidade"
                  ref="cidade"
                  v-model="participante.codigoCidade"
                  @change="setCidade"
                  :items="cidadesEstado"
                  :rules="validarCidade"
                  :disabled="cidadesEstado.length === 0"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.telefone.focus()"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  label="Telefone"
                  ref="telefone"
                  v-model="participante.telefone"
                  v-mask="['(##) ####-####']"
                  data-cy="telefone"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.celular.focus()"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  label="Celular"
                  ref="celular"
                  v-model="participante.celular"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  data-cy="celular"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.email.focus()"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  label="E-Mail"
                  ref="email"
                  v-model="participante.email"
                  :rules="validarEmail"
                  data-cy="email"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.observacoes.focus()"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <v-textarea class="pt-0 mt-0" label="Observações" ref="observacoes" v-model="participante.observacoes" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" data-cy="observacoes" />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Informações Adicionais</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="8">
                <v-text-field
                  label="Contato"
                  ref="contato"
                  v-model="participante.dadosAdicionais.contato"
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.localTrabalho.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field label="Profissão" ref="profissao" v-model="participante.dadosAdicionais.profissao" required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" @keypress.enter="$refs.complemento.focus()" />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field label="Conjuge" ref="conjuge" v-model="participante.dadosAdicionais.conjuge" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" @keypress.enter="$refs.bairro.focus()" />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field label="Filiação" ref="filiacao" v-model="participante.dadosAdicionais.filiacao" required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" @keypress.enter="$refs.estado.focus()" />
              </v-col>

              <v-col cols="12" sm="5">
                <v-text-field
                  label="Local Trabalho"
                  ref="localTrabalho"
                  v-model="participante.dadosAdicionais.localTrabalho"
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.estado.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  label="Tipo Residência"
                  ref="tipoResidencia"
                  v-model="participante.dadosAdicionais.tipoResidencia"
                  :items="constantes.TIPOS_RESIDENCIA"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete label="Estado Civil" ref="estadoCivil" v-model="participante.dadosAdicionais.estadoCivil" :items="constantes.ESTADO_CIVIL" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" class="mb-4" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Referências</v-toolbar-title>
              <v-spacer />
              <v-btn depressed color="primary darken-1" @click="novaReferencia">Nova</v-btn>
            </v-toolbar>

            <v-row dense v-for="(referencia, i) in participante.referencias" :key="'r-' + i">
              <v-col cols="12" sm="4">
                <v-text-field label="Nome" v-model="referencia.nome" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details />
              </v-col>

              <v-col cols="12" sm="8">
                <div style="display: flex">
                  <v-text-field label="Observações" v-model="referencia.observacoes" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details />
                  <v-btn icon @click="() => participante.referencias.splice(i, 1)" large class="my-auto ml-2">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="pa-5" v-if="participante.referencias.length === 0">
              <v-label>Não há referencias</v-label>
            </v-row>
            <v-col />

            <v-toolbar flat dark color="secondary" class="mb-4" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Produtos do Contrato</v-toolbar-title>
              <v-spacer />
              <v-btn depressed color="primary darken-1" @click="novoProdutoContrato">Novo</v-btn>
            </v-toolbar>

            <v-row dense v-for="(produto, i) in participante.produtosContrato" :key="'p-' + i">
              <v-col cols="12" sm="8">
                <v-text-field label="Produto" v-model="produto.produto" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details />
              </v-col>

              <v-col cols="12" sm="4">
                <div style="display: flex">
                  <v-text-field label="Número de Série" v-model="produto.numeroSerie" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details />
                  <v-btn icon @click="() => participante.produtosContrato.splice(i, 1)" large class="my-auto ml-2">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="pa-5" v-if="participante.produtosContrato.length === 0">
              <v-label>Não há produtos</v-label>
            </v-row>
            <v-col />
          </v-container>

          <v-divider />

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <div style="float: left">
              <v-checkbox class="mt-1" label="Ativo" v-model="participante.ativo" color="primary" hide-details />
            </div>
            <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </v-container>
        </v-form>
      </template>

      <template v-if="float" v-slot:widget-header-action>
        <v-icon style="cursor: pointer" @click="$emit('fechar')">close</v-icon>
      </template>
    </v-widget>

    <v-dialog v-model="dialogoCamera" width="auto" persistent>
      <v-widget title="Capturar Foto" noDivider clo>
        <div slot="widget-content" style="position: relative">
          <video id="player" autoplay width="640px" height="480px" style="border-radius: 4px; background-color: silver" />
          <canvas id="canvas" hidden />
          <v-btn large depressed style="position: absolute; bottom: 16px; right: 16px; opacity: 0.6" @click="capturarFoto">Capturar Foto</v-btn>
        </div>
        <div slot="widget-header-action">
          <v-btn icon @click="cancelarFoto" style="margin-right: -12px">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-widget>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import { mask } from 'vue-the-mask';
import { URL_ANRON_API } from '@/util/config';

export default {
  name: 'FormularioParticipante',
  directives: {
    mask,
  },

  props: {
    float: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    validarCnpjCpf: [(v) => (v && v.length === 14 ? validador.cpf(v) : v.length === 18 ? validador.cnpj(v) : false) || 'CNPJ / CPF inválido'],
    validarIE: [(v) => !!v || 'Inscrição Estadual obrigatória'],
    validadarRazaoSocialNome: [(v) => !!v || 'Razão Social / Nome obrigatório', (v) => (v && v.length < 200) || 'Tamanho máximo são 200 caracteres'],
    validarCep: [(v) => !!v || 'CEP obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro obrigatório'],
    validarNumero: [(v) => !!v || 'Número obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade obrigatória'],
    validarPais: [(v) => !!v || 'País obrigatório'],
    validarEstado: [(v) => !!v || 'Estado obrigatório'],
    validarEmail: [(v) => (v && validador.email(v)) || 'E-mail inválido'],
    validarNascimento: [(v) => !v || (v && validador.dataNascimento(v)) || 'Data inválida'],
    validarValor: [(v) => !v || (v && v.match('^[0-9.]+(,[0-9]{1,2})?$') !== null) || 'Valor inválido'],
    constantes: constantes,

    valid: false,
    loading: false,
    foto: null,
    fotoSrc: '',
    dialogoCamera: false,
  }),

  computed: {
    ...mapState('participante', {
      participante: 'participante',
      estado: 'estado',
      carregando: 'carregando',
    }),

    ...mapGetters('cidadeEstado', {
      estados: 'estados',
      cidadesEstado: 'cidadesEstado',
      paises: 'paises',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  created() {
    try {
      this.loading = true;
      const estaEditando = this.$route.path.indexOf('editar') > -1 ? true : false;
      if (estaEditando && (!this.participante._id || this.participante._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um cliente/fornecedor para editar');
        this.$router.push({ name: 'participantes' });
        return;
      } else if (estaEditando && this.participante.codigoEstado) {
        this.$store.commit('cidadeEstado/setState', { estado: this.participante.codigoEstado });
      }
      if (estaEditando) {
        this.fotoSrc = this.participante.foto ? `${URL_ANRON_API}/images/${this.participante.foto}` : '';
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.cnpjCpf.focus();
      this.$refs.formParticipante.validate();
    });
  },

  methods: {
    validarInscricaoEstadual() {
      if (this.participante.cnpjCpf.length < 18) return true;
      if (this.participante.rgIe && this.participante.estado) {
        return validador.inscricaoEstadual(`${this.participante.rgIe}-${this.participante.estado}`);
      }
      return this.participante.rgIe === 'ISENTO' || false;
    },

    idade(nascimento) {
      return validador.idade(nascimento || new Date());
    },

    setPais() {
      this.$store.commit('cidadeEstado/setState', { pais: this.participante.codigoPais });
    },

    setEstado() {
      this.$store.commit('cidadeEstado/setState', { estado: this.participante.codigoEstado });
      this.$store.commit('participante/setState', { participante: { ...this.participante, estado: this.$store.state.cidadeEstado.estados.find((estado) => estado.id === this.participante.codigoEstado).nome } });
    },

    setCidade() {
      this.$store.commit('participante/setState', { participante: { ...this.participante, cidade: this.$store.state.cidadeEstado.cidades.find((cidade) => parseInt(cidade.codigo_cidade, 10) === this.participante.codigoCidade).cidade } });
    },

    novaReferencia() {
      const referencias = this.participante.referencias;
      if (!referencias.find((referencia) => !referencia.nome.trim() && !referencia.observacoes.trim())) {
        referencias.push({ nome: '', observacoes: '' });
        this.$store.commit('participante/setState', { participante: { ...this.participante, referencias } });
      }
    },

    novoProdutoContrato() {
      const produtosContrato = this.participante.produtosContrato;
      if (!produtosContrato.find((produto) => !produto.produto.trim() && !produto.numeroSerie.trim())) {
        produtosContrato.push({ produto: '', numeroSerie: '' });
        this.$store.commit('participante/setState', { participante: { ...this.participante, produtosContrato } });
      }
    },

    setEndereco(endereco) {
      this.participante.bairro = endereco.bairro;
      this.participante.complemento = endereco.complemento;
      this.participante.codigoCidade = parseInt(endereco.ibge, 10);
      this.participante.logradouro = endereco.logradouro;
      const estado = this.$store.state.cidadeEstado.estados.find((estado) => estado.sigla === endereco.uf);
      const cidade = this.$store.state.cidadeEstado.cidades.find((cidade) => cidade.estado === endereco.uf && cidade.codigo_cidade === endereco.ibge);
      this.participante.codigoEstado = estado.id;
      this.participante.estado = estado.nome;
      this.participante.cidade = cidade.cidade;
      this.$store.commit('cidadeEstado/setState', { estado: estado.id });
    },

    setIsento() {
      if (this.participante.rgIe !== 'ISENTO') {
        this.participante.rgIe = 'ISENTO';
      } else {
        this.participante.rgIe = '';
      }
    },

    consultarCnpj(cnpj) {
      if (cnpj.length === 18 && !this.carregando) {
        this.$store.dispatch('sefaz/consultarCadastro', cnpj.replace(/\D+/g, '')).then((response) => {
          if (response.status == '111') {
            if (response.empresa.situacao == 0) {
              this.$store.commit('setMensagemErro', 'Contribuinte não habilitado na SEFAZ', { root: true });
            } else {
              const estado = this.$store.state.cidadeEstado.estados.find((estado) => estado.sigla === response.empresa.uf);
              const cidade = this.$store.state.cidadeEstado.cidades.find((cidade) => cidade.estado === response.empresa.uf && cidade.cidade.toLocaleUpperCase() === response.empresa.endereco.cidade.toLocaleUpperCase());

              this.participante.cnae = response.empresa.cnae;
              this.participante.regime = response.empresa.regime;
              this.participante.nascimento = response.empresa.data_inicio_atividade;
              this.participante.nomeFantasiaApelido = response.empresa.fantasia || this.participante.nomeFantasiaApelido;
              this.participante.rgIe = response.empresa.ie;
              this.participante.razaoSocialNome = response.empresa.razao;

              this.participante.logradouro = response.empresa.endereco.logradouro;
              this.participante.bairro = response.empresa.endereco.bairro;
              this.participante.cep = response.empresa.endereco.cep;
              this.participante.numero = response.empresa.endereco.numero;

              this.participante.codigoPais = 1058;
              this.participante.pais = 'Brasil';

              if (estado) {
                this.$store.commit('cidadeEstado/setState', { estado: estado.id });
                this.participante.codigoEstado = estado.id;
                this.participante.estado = estado.nome;
              }

              if (cidade) {
                this.participante.codigoCidade = parseInt(cidade.codigo_cidade, 10);
                this.participante.cidade = cidade.cidade;
              }
            }
          }
        });
      }
    },

    async salvar() {
      if (this.$refs.formParticipante.validate() && this.validarInscricaoEstadual()) {
        if (this.participante._id) {
          const result = await this.$store.dispatch('participante/editar', this.foto);
          if (!result) return;
        } else {
          const result = await this.$store.dispatch('participante/adicionar', this.foto);
          if (!result) return;
        }

        if (this.float) {
          this.$emit('fechar');
        } else {
          this.$router.push({ name: 'participantes' });
        }
      }
    },

    cancelar() {
      if (this.float) {
        this.$emit('fechar');
      } else {
        this.$router.push({ name: 'participantes' });
      }
    },

    onFileSelected(evento) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.processarFoto(e.target.result);
      };

      reader.readAsDataURL(evento.target.files[0]);
    },

    escolherArquivo() {
      document.getElementById('inputArquivo').click();
    },

    limparFoto() {
      document.getElementById('inputArquivo').value = '';
      this.fotoSrc = '';
      this.foto = null;
    },

    processarFoto(foto) {
      const image = new Image();
      const context = this;

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const max_size = 640;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;

        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');

        canvas.remove();

        context.foto = dataUrl;
        context.fotoSrc = dataUrl;
      };

      image.src = foto;
    },

    camera() {
      this.dialogoCamera = true;

      this.$nextTick(() => {
        const player = document.getElementById('player');
        player.style.display = 'block';

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          player.srcObject = stream;
        });
      });
    },

    capturarFoto() {
      const player = document.getElementById('player');
      const canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');

      const { height, width } = player.getBoundingClientRect();
      canvas.height = height;
      canvas.width = width;
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      this.processarFoto(canvas.toDataURL('image/jpeg'));

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
      player.srcObject = null;

      player.style.display = 'none';
      this.dialogoCamera = false;
    },

    cancelarFoto() {
      this.dialogoCamera = false;
      const player = document.getElementById('player');

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    },
  },
};
</script>

<style scoped>
.v-text-field >>> textarea {
  resize: none;
}
.box {
  background: white;
}
.shadow {
  position: relative;
}
.shadow::after {
  z-index: -1;
  content: '';
  position: absolute;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  height: 20%;
  left: 5%;
  right: 5%;
  width: 90%;
  top: 0px;
  border-radius: 60%;
}
</style>
