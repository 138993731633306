<template>
  <v-container fluid>
    <v-widget title="MVAs">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="novo">
                NOVO
              </v-btn>
              <v-btn v-else depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="mvas" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.ncm.codigo }} - {{ item.ncm.descricao }}</td>
              <td>{{ item.mercado_interno | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_4 | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_7 | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_12 | formatarPercentual }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'ListaMva',
  data() {
    return {
      headers: [
        { text: 'NCM', value: 'ncm.descricao' },
        { text: 'M. Interno', value: 'mercado_interno' },
        { text: 'M. Ext. 4%', value: 'mercado_externo_4' },
        { text: 'M. Ext. 7%', value: 'mercado_externo_7' },
        { text: 'M. Ext. 12%', value: 'mercado_externo_12' },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('mva', {
      mvas: 'mvas',
      carregando: 'carregando',
      total: 'totalMvas',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async listar() {
      await this.$store.dispatch('mva/listar', this.options);
    },

    excluir(conta) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('mva/excluir', conta);
        }
      });
    },

    editar(mva) {
      this.$store.commit('mva/editar', mva);
      this.$router.push({ name: 'formularioMvaEditar' });
    },

    novo() {
      this.$store.commit('banco/novo');
      this.$router.push({ name: 'formularioMvaNovo' });
    },
  },
};
</script>
