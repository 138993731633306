<template>
  <v-dialog v-model="dialog" width="700px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Localizar Orçamento</v-toolbar-title>
      </v-toolbar>

      <!-- <v-divider></v-divider> -->

      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field ref="numero" label="Número" placeholder=" " hide-details></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Data" placeholder=" " hide-details disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Valor Líquido" placeholder=" " hide-details disabled></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <span class="grey--text">{{ mensagem_erro }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" text>Cancelar</v-btn>
        <v-btn color="primary" @click="confirmar" width="100px">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogoPreVenda',

  data() {
    return {
      dialog: false,
      mensagem_erro: 'Orçamento não encontrado!',
    };
  },

  methods: {
    abrir() {
      this.dialog = true;
      this.mensagem_erro = '';
      setTimeout(() => {
        this.$refs.numero.focus();
      }, 200);
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    confirmar() {
      this.mensagem_erro = 'Orçamento concluído!';
    },
  },
};
</script>

<style scoped></style>
