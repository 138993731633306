<template>
  <div>
    <dialogo-filtro-cheques :dialog="exibirDialogoFiltroCheques" :cheque="chequeSelecionado" @limpar="filtro_ativo = false" @fechar="fecharDialogoFiltro()" @filtrar="filtrarCheques" />
    <dialogo-form-cheque ref="dialogoNovoCheque" @finalizar="listar" />
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Cheques</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid class="pr-0">
            <v-row dense>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xl="12" sm="3" class="text-right pr-0">
                <v-select :items="lista_status" filled dense hide-details :item-value="'value'" v-model="status" @change="filtrarStatus($event)"></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="3" class="text-right" :class="isSm ? 'pt-2' : ''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark class="mr-2" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="listar"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-btn v-if="!isSm" class="mr-2" color="primary" @click="abrirDialogoFiltro">Filtro</v-btn>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark class="mr-2" v-bind="attrs" v-on="on" :carregando="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn color="primary" @click="novo">Novo</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-data-table :sort-by-text="'Seleção'" :options.sync="options" :server-items-length="total" :headers="headers" :items="cheques" :carregando="carregando">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span>{{ item.emitente }}</span
                  ><br />
                  <span v-if="item.responsavel"
                    ><b>CLIENTE: {{ item.responsavel.numero }} - {{ item.responsavel.razaoSocialNome }}</b></span
                  >
                  <span v-else><b>CLIENTE: &nbsp; Não informado</b></span>
                </td>
                <td>{{ item.cnpjCpf }}</td>
                <td>{{ item.vencimento | formatarData }}</td>
                <td>{{ item.numero | formatarPadZero(5) }}</td>
                <td>
                  <v-chip style="cursor: pointer" :color="definirCor(item.status)" @click="alterarStatus(item)">{{ item.status }}</v-chip>
                </td>
                <td>
                  <span class="font-weight-bold">{{ item.valor | formatarMoeda }}</span>
                </td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 20" @click="editar(item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 40" @click="excluir(item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="primary" @click="imprimir">Imprimir</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fechar">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DialogoFiltroCheques from './DialogoFiltroCheques';
import DialogoFormCheque from './DialogoFormCheque';
import constantes from '@/util/constantes';
import moment from 'moment';

export default {
  name: 'DialogoCheques',
  components: {
    DialogoFiltroCheques,
    DialogoFormCheque,
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Emitente', value: 'emitente' },
        { text: 'CNPJ/CPF', value: 'cnpjCpf' },
        { text: 'Data', value: 'vencimento' },
        { text: 'Número', value: 'numero' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      status: '',
      lista_status: [
        {
          text: 'Todos',
          value: '',
        },
        {
          text: 'Vencidos',
          value: 'Vencidos',
        },
        {
          text: 'Em Caixa',
          value: 'Em Caixa',
        },
        {
          text: 'Compensados',
          value: 'Compensado',
        },
        {
          text: 'Repassados',
          value: 'Repassado',
        },
        {
          text: 'Devolvidos',
          value: 'Devolvido',
        },
        {
          text: 'Devolvidos Pagos',
          value: 'Devolvido Pago',
        },
        {
          text: 'A Compensar',
          value: 'A Compensar',
        },
      ],
      chequeSelecionado: null,
      exibirDialogoFiltroCheques: false,
      filtro_ativo: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('cheque', {
      cheques: 'cheques',
      cheque: 'chequeAtual',
      carregando: 'carregando',
      total: 'totalCheques',
    }),

    isSm() {
      return this.$vuetify.breakpoint.sm;
    },
  },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    async listar() {
      this.filtro_ativo = false;
      await this.$store.dispatch('cheque/listar');
    },

    async filtrarBusca() {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('cheque/listar', newOptions);
    },

    filtrarStatus(status) {
      let newOptions = {
        ...this.options,
        query: { status: status },
      };

      this.$store.dispatch('cheque/filtrar', newOptions);
    },

    async filtrarCheques(filtro) {
      let newOptions = {
        ...this.options,
        query: filtro,
      };
      this.exibirDialogoFiltroCheques = false;
      this.$store.dispatch('cheque/filtrar', newOptions);
    },

    editar(cheque) {
      this.$store.commit('cheque/editar', cheque);
      this.exibirDialogoNovoCheque = true;
    },

    novo() {
      this.$store.commit('cheque/novo');
      this.$refs.dialogoNovoCheque.abrir();
    },

    definirCor(status) {
      if (status === constantes.EM_CAIXA) {
        return 'green';
      } else if (status === constantes.COMPENSADO) {
        return 'blue';
      } else if (status === constantes.REPASSADO) {
        return 'blue';
      } else if (status === constantes.DEVOLVIDO) {
        return 'error';
      } else if (status === constantes.DEVOLVIDO_PAGO) {
        return 'orange';
      }
    },

    excluir(cheque) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('cheque/excluir', cheque);
        }
      });
    },

    abrirDialogoFiltro() {
      this.filtro_ativo = true;
      this.exibirDialogoFiltroCheques = true;
    },

    alterarStatus(cheque) {
      this.chequeSelecionado = cheque;
      this.$store.commit('cheque/SET_CHEQUE_ATUAL', this.chequeSelecionado);
      this.exibirDialogoStatusCheque = true;
    },

    fecharDialogoFiltro() {
      this.exibirDialogoFiltroCheques = false;
    },

    fecharDialogoStatus() {
      this.chequeSelecionado = null;
      this.exibirDialogoStatusCheque = false;
    },

    async confirmarAlterarStatus(status) {
      this.chequeSelecionado = null;
      this.exibirDialogoStatusCheque = false;
      await this.$store.dispatch('cheque/alterarStatus', status);
    },

    limparBusca() {
      this.listar();
    },
  },
};
</script>
