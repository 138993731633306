<template>
  <div>
    <v-widget v-show="exibir" title="Vendas Por Intervalo de Data" content-bg="white" class="pt-4">
      <template v-slot:widget-header-action>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon text dark fab v-bind="attrs" v-on="on" @click="$emit('esconder')"> mdi-notification-clear-all </v-icon>
          </template>
          <span>Esconder</span>
        </v-tooltip>
      </template>

      <div slot="widget-content" class="fillWidth">
        <v-row dense>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" xs="12" sm="6">
                <campo-data placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" hide-details hide-selected chips v-model="filtro.data_inicial"></campo-data>
              </v-col>
              <v-col cols="12" xs="6" sm="6">
                <campo-data placeholder=" " persistent-placeholder outlined hide-details autocomplete="off" role="presentation" hide-selected chips v-model="filtro.data_final"></campo-data>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="10" />

          <v-col class="text-center pt-5" cols="2">
            <v-btn depressed block color="primary" @click="carregar">OK</v-btn>
          </v-col>
        </v-row>

        <v-row v-if="carregando" class="fillHeight">
          <v-col cols="12" class="text-center ma-auto">
            <v-progress-circular color="primary" :size="72" indeterminate :width="7"></v-progress-circular>
            <p class="subtitle-1 mt-3">Carregando ...</p>
          </v-col>
        </v-row>

        <v-row dense v-else>
          <v-col cols="12" xs="12" sm="12" class="">
            <chart
              class="fillWidth"
              v-if="!carregando && vendasPorIntervaloData.length > 0"
              :options="
                graficos.graficoBarra(
                  vendasPorIntervaloData.map((venda) => venda._id),
                  vendasPorIntervaloData.map((venda) => venda.total)
                )
              "
            ></chart>
          </v-col>
        </v-row>
      </div>
    </v-widget>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import graficos from '@/graphs/graphs';
import constantes from '@/util/constantes';
import moment from 'moment';

export default {
  name: 'GraficoVendasPorMes',
  props: ['exibir'],
  data() {
    return {
      constantes: constantes,
      graficos: graficos,
      chartOptionsBar: {
        xAxis: {
          data: [],
        },
      },
      filtro: {
        data_inicial: moment().startOf('month').format('YYYY-MM-DD'),
        data_final: moment().format('YYYY-MM-DD'),
      },
    };
  },

  mounted() {
    this.carregar();
  },

  computed: {
    ...mapState('graficos', {
      vendasPorIntervaloData: 'vendasPorIntervaloData',
      carregando: 'carregando',
    }),
  },

  methods: {
    carregar() {
      this.$store.dispatch('graficos/vendasPorIntervaloData', this.filtro);
    },
  },
};
</script>

<style scoped>
.fillHeight {
  height: 100%;
}
.fillWidth {
  width: 100%;
}
.positionAbsolute {
  position: absolute;
}
.semDados {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 220px;
  left: calc(50% - 110px);
}
</style>
