import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/locais-estoque?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(localEstoque) {
  return axios.post(`/locais-estoque`, localEstoque).then((response) => response.data);
}

function atualizar(localEstoque) {
  return axios.put(`/locais-estoque/${localEstoque._id}`, localEstoque).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/locais-estoque/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
