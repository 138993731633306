import axios from '@/util/axios';

function listar(options) {
  let url = `/estoquesDeposito?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url += `&query=${options.query}`;
  }
  return axios.get(url).then((response) => response.data);
}

function detalharMovimentacao(produtoEstoque) {
  let url = `/estoquesDeposito/filiais/${produtoEstoque.filial._id}/produtos/${produtoEstoque.produto._id}`;
  return axios.get(url).then((response) => response.data);
}

export default {
  listar,
  detalharMovimentacao,
};
