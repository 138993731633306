import importacoesApi from '@/services/importacoesApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    arquivos: [],
    configuracao: {
      importar_clientes: false,
      importar_produtos: false,
      importar_transportadores: false,
    },
    totalArquivos: 0,
    carregando: false,
    exibirProgresso: false,
  },

  //equivalente ao computed properties
  getters: {
    percentualCarregado(state) {
      if (state.totalArquivos !== 0) {
        return (state.arquivos.length / state.totalArquivos) * 100;
      } else {
        return 0;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    carregarXML({ commit }, arquivo) {
      commit('setArquivo', arquivo);
    },
    async importarArquivos({ commit, state }) {
      try {
        commit('setCarregando', true);
        let arquivos = state.arquivos;
        let configuracao = state.configuracao;

        let novosArquivos = [];
        arquivos.forEach((arquivo) => {
          let novo = {
            conteudo: btoa(arquivo.conteudo),
          };
          novosArquivos.push(novo);
        });

        let importacao = {
          arquivos: novosArquivos,
          configuracao: configuracao,
        };
        let response = await importacoesApi.importarArquivos(importacao);
        let relatorio = '';
        if (response.notas) {
          relatorio += 'Notas inportadas: ' + Number(response.notas) + ' ';
        }
        if (response.produtos) {
          relatorio += 'Produtos importados: ' + Number(response.produtos) + ' ';
        }
        if (response.clientes) {
          relatorio += 'Clientes importados: ' + Number(response.clientes) + ' ';
        }
        if (response.transportadores) {
          relatorio += 'Transportadores importados: ' + Number(response.transportadores) + ' ';
        }

        if (relatorio.length !== 0) {
          commit('setMensagemSucesso', relatorio, { root: true });
        } else {
          commit('setMensagemSucesso', 'Nenhuma informação nova importada', { root: true });
        }
        commit('setEstadoInicial');
      } catch (error) {
        commit('setEstadoInicial');
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setArquivo(state, arquivo) {
      state.totalArquivos = arquivo.total;
      state.arquivos.push(arquivo);

      if (state.arquivos.length === arquivo.total) {
        state.exibirProgresso = false;
      } else {
        state.exibirProgresso = true;
      }
    },
    setEstadoInicial(state) {
      state.arquivos = [];
      state.configuracao = {
        importar_clientes: false,
        importar_produtos: false,
        importar_transportadores: false,
      };
      state.totalArquivos = 0;
      state.carregando = false;
      state.exibirProgresso = false;
    },
  },
};
