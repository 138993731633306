import sefazApi from '@/services/sefazApi';
import sistemaApi from '@/services/sistemaApi';

export default {
  namespaced: true,

  state: {
    carregando: false,

    destinadas: [],
    destinadaAtual: {},
    totalDestinadas: 0,

    statusSefazNFe: false,
    statusSefazNFCe: false,
    statusSefazMDFe: false,
  },

  actions: {
    consultarCadastro({ commit }, cnpj) {
      try {
        return sistemaApi.consultarCadastro(cnpj);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao consultar CNPJ', { root: true });
      }
    },

    async carregarDestinadas({ commit }, options) {
      try {
        commit('setCarregando', true);
        const response = await sefazApi.listarDestinadas(options);
        commit('setDestinadas', response.destinadas);
        commit('setTotalDestinadas', response.total);
      } catch (error) {
        commit('setDestinadas', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async downloadNfe({ commit }, nota) {
      try {
        commit('setCarregando', true);
        const consulta = {
          chaveNfe: nota.chave,
        };
        const response = await sefazApi.downloadNfe(consulta);
        if (response.status == 138) {
          commit('setMensagemSucesso', 'Download iniciado', { root: true });

          const a = document.createElement('a');
          a.download = nota.chave + '.xml';
          a.type = 'application/xml';
          a.href = 'data:application/xml;base64,' + response.doc.nota;
          a.click();
        } else {
          commit('setMensagemErro', response.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async manifestacaoDestinatario({ commit }, manifestacao) {
      try {
        commit('setCarregando', true);

        const dadosManifestacao = {
          id: manifestacao.idDestinada,
          idLote: '01',
          tpEvento: manifestacao.tpEvento,
          chaveNfe: manifestacao.chaveNfe,
          verEvento: '1.00',
          justificativa: manifestacao.justificativa,
        };

        const response = await sefazApi.manifestacaoDestinatario(dadosManifestacao);

        if (response.evento) {
          if (response.evento.status == 135) {
            commit('setNotaManifestada', manifestacao.idDestinada);
            commit('setMensagemSucesso', response.evento.motivo, { root: true });
          } else {
            commit('setMensagemErro', response.evento.motivo, { root: true });
          }
        } else {
          commit('setMensagemErro', response.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async consultarStatusServico({ commit, rootState }, notificar, servico = 'NFe') {
      if (rootState.autenticacao.session.empresa.id) {
        let response;

        try {
          commit('setCarregando', true, { root: true });

          switch (servico) {
            case 'NFe':
              response = await sistemaApi.consultarStatusServicoNFe();
              break;
            case 'NFCe':
              response = await sistemaApi.consultarStatusServicoNFCe();
              break;
            case 'MDFe':
              response = await sistemaApi.consultarStatusServicoMDFe();
              break;

            default:
              response = await sistemaApi.consultarStatusServicoNFe();
              break;
          }

          commit('setCarregando', false, { root: true });

          if (notificar) {
            commit('setMensagemSucesso', response.motivo, { root: true });
          }

          if (response.status == 107) {
            commit('setStatusServico' + servico, true);
          } else {
            commit('setStatusServico' + servico, false);
          }
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setStatusServico' + servico, false);
        } finally {
          commit('setCarregando', false, { root: true });
        }
      }
    },

    async consultarDestinadas({ commit, state }) {
      if (state.session.empresa.id) {
        try {
          commit('setCarregando', true, { root: true });
          await sistemaApi.consultarDestinadas();
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
        } finally {
          commit('setCarregando', false, { root: true });
        }
      }
    },
  },

  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },

    setDestinadas(state, destinadas) {
      state.destinadas = destinadas;
      state.carregando = false;
    },

    setTotalDestinadas(state, total) {
      state.totalDestinadas = total;
    },

    setNotaManifestada(state, idDestinada) {
      const notaDestinada = state.destinadas.find((destinada) => destinada._id == idDestinada);
      if (notaDestinada) {
        notaDestinada.status = 'manifestada';
      }
    },

    setStatusServicoNFe(state, data) {
      state.statusSefaz = data;
    },

    setStatusServicoNFCe(state, data) {
      state.statusSefaz = data;
    },

    setStatusServicoMDFe(state, data) {
      state.statusSefazMDFe = data;
    },
  },
};
