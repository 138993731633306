<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Buscar Cofins</v-toolbar-title>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-text-field
          ref="input"
          flat
          filled
          rounded
          dense
          single-line
          hide-details
          clearable
          v-model="busca_local"
          @keyup.enter="buscarCofins(busca_local)"
          max-width="300px"
          prepend-inner-icon="search"
          label="Buscar Cofins"
          @focus="onFocus"
        ></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px" class="pt-1">
        <v-data-table hide-default-footer :headers="headers" :items="cofins" item-key="_id" :loading="carregando" :options.sync="options">
          <template #item="{ item }">
            <tr @click="selecionarCofins(item)" style="cursor: pointer">
              <td>{{ item.codigo }}</td>
              <td>{{ item.descricao }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="fechar()" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'dialogBuscarCofins',

  props: {
    busca: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      busca_local: '',
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, width: '15%' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '85%' },
      ],
      dialog: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  computed: {
    ...mapState('cofins', {
      cofins: 'cofins',
      carregando: 'carregando',
    }),
  },

  mounted() {
    this.$store.commit('cofins/setCofins', []);
  },

  watch: {
    busca(value) {
      this.busca_local = value;
    },
  },

  methods: {
    ...mapActions('cofins', {
      buscarCofins: 'buscar',
    }),

    selecionarCofins(cofins) {
      this.$emit('cofinsSelecionado', cofins);
      this.fechar();
    },

    abrir() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);

      if (this.busca) {
        this.buscarCofins(this.busca);
      }
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    onFocus(e) {
      this.selecionarInput(e);
    },

    selecionarInput(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },
  },
};
</script>
