<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Recebimentos</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-participante :filled="true" :label="'Cliente'" ref="busca_participante" v-model="cliente.cnpjCpf" @participanteSelecionado="clienteSelecionado" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="cliente.razaoSocialNome" filled label="Nome Cliente" placeholder=" " ref="cliente_nome" readonly hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select v-model="filtro.exibir" filled label="Exibir" :items="itens_exibir" @change="filtrarPorTipo" hide-details></v-select>
              </v-col>
            </v-row>
            <v-data-table v-model="contas_selecionadas" :headers="headers" :items="contas" disable-sort hide-default-footer disable-pagination fixed-header height="380px" item-key="documento" single-select :loading="carregando">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.documento }}</td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-account-circle-outline </v-icon>
                      </template>
                      <span>{{ item.participante.razaoSocialNome }}</span>
                    </v-tooltip>
                  </td>
                  <td>{{ item.data_cadastro | formatarData }}</td>
                  <td>{{ item.data_vencimento | formatarData }}</td>
                  <td>{{ item.data_pagamento | formatarData }}</td>
                  <td class="text-right">
                    <b>{{ item.valor | formatarValor }}</b>
                  </td>
                  <td class="text-right">
                    <b>{{ item.valor_pago | formatarValor }}</b>
                  </td>
                  <td class="text-right">
                    <b>{{ item.valor_restante | formatarValor }}</b>
                  </td>
                  <td class="text-center">
                    <v-menu top right transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                      </template>
                      <v-list dense>
                        <v-list-item v-if="item.valor_restante > 0" :key="item._id + 10" @click="baixar(item)">
                          <v-list-item-icon><v-icon>monetization_on</v-icon></v-list-item-icon>
                          <v-list-item-title>Baixar</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.valor_restante == 0 || item.valor_restante > 0" :key="item._id + 20" @click="visualizar(item)">
                          <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                          <v-list-item-title>Visualizar</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.valor_pago == 0" :key="item._id + 30" @click="editar(item)">
                          <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.valor_restante > 0" :key="item._id + 40" @click="excluir(item)">
                          <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                          <v-list-item-title>Remover</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="nova">Nova</v-btn>
          <v-btn color="primary" @click="fechar">Imprimir Extrato</v-btn>
          <!-- <v-btn color="primary" @click="liquidar">Liquidação</v-btn> -->
          <span class="ml-4" v-if="total_contas_selecionadas > 0">Selecionado: {{ total_contas_selecionadas }} ({{ soma_contas_selecionadas | formatarValor }})</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fechar">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogo-liquidacao ref="dialogoLiquidacao"></dialogo-liquidacao>
    <dialogo-form-conta-receber ref="dialogoFormContaReceber"></dialogo-form-conta-receber>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DialogoLiquidacao from './DialogoLiquidacao';
import DialogoFormContaReceber from './DialogoFormContaReceber';

export default {
  name: 'DialogoRecebimentos',
  components: {
    DialogoLiquidacao,
    DialogoFormContaReceber,
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Documento', value: 'documento' },
        { text: 'Cliente', value: 'participante.razaoSocialNome' },
        { text: 'Emissão', value: 'data_emissao' },
        { text: 'Vencimento', value: 'data_vencimento' },
        { text: 'Pagamento', value: 'data_pagamento' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Valor Pago', value: 'valor_pago', align: 'right' },
        { text: 'Restante', value: 'valor_restante', align: 'right' },
        { text: 'Opções', value: 'opcoes', width: '60px', align: 'center' },
      ],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      itens_exibir: ['Todos', 'Em Aberto', 'Vencidos', 'Pagos', 'Pagos Parcialmente'],
      filtro: {
        cliente: '',
        exibir: 'Todos',
      },
      contas_selecionadas: [],
      // vendas: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('contaReceber', {
      contas: 'contasReceber',
      cliente: 'clienteAtual',
      carregando: 'carregando',
    }),

    total_contas_selecionadas() {
      return this.contas_selecionadas.length;
    },

    soma_contas_selecionadas() {
      return this.contas_selecionadas.reduce((soma, conta) => {
        return soma + conta.valor_restante;
      }, 0);
    },
  },

  methods: {
    abrir() {
      this.listar();
      this.zerar_dialogo();
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    async listar() {
      await this.$store.dispatch('contareceber/listar', this.options);
    },

    limparBuscaCliente() {
      this.$store.commit('contareceber/SET_CLIENTE', {});
      this.listar();
    },

    async filtrarPorTipo() {
      let newOptions = {
        ...this.options,
        query: this.filtro,
      };
      if (this.cliente._id) {
        this.$store.dispatch('contareceber/filtrarPorCliente', newOptions);
      } else {
        await this.$store.dispatch('contareceber/listar', newOptions);
      }
    },

    filtrarPorCliente() {
      let newOptions = {
        ...this.options,
        ...this.filtro,
      };
      this.$store.dispatch('contareceber/filtrarPorCliente', newOptions);
    },

    nova() {
      this.$store.commit('contareceber/NOVA');
      this.$store.commit('contareceber/SET_CLIENTE', {});
      this.$refs.dialogoFormContaReceber.abrir();
    },

    visualizar(conta) {
      this.$store.commit('contareceber/VISUALIZAR');
      this.$store.commit('contareceber/SET_CONTA', conta);
      this.$refs.dialogoFormContaReceber.abrir();
    },

    editar(conta) {
      this.$store.commit('contareceber/EDITAR');
      this.$store.commit('contareceber/SET_CONTA', conta);
      this.$refs.dialogoFormContaReceber.abrir();
    },

    excluir(conta) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.commit('contareceber/SET_CONTA', conta);
          this.$store.dispatch('contareceber/excluir', conta);
        }
      });
    },

    baixar(conta) {
      // if (this.total_contas_selecionadas > 0) {
      this.$store.commit('contareceber/SET_CONTA', conta);
      this.$refs.dialogoLiquidacao.abrir();
      // }
    },

    zerar_dialogo() {
      this.$store.commit('contareceber/SET_CLIENTE', {});
      this.filtro = {
        cliente: '',
        cliente_nome: '',
        exibir: 'Todos',
      };
      this.contas_selecionadas = [];
    },

    clienteSelecionado(cliente) {
      this.$store.commit('contareceber/SET_CLIENTE', cliente);
      this.filtrarPorCliente();
    },
  },
};
</script>

<style lang="scss" scoped></style>
