<template>
  <div>
    <v-text-field v-bind="$attrs" label="Código" ref="input" autocomplete="off" :loading="loading" :value="value" @keydown.enter="consultarCodigoProduto($event)" @input="onInput" @click:append="abrirDialogo" @focus="onFocus" append-icon="mdi-magnify" />

    <dialog-buscar-produto ref="dialogoBuscarProduto" :dialog="dialogo" @produtoSelecionado="adicionarProdutoDialog" />
  </div>
</template>

<script>
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import produtoApi from '@/services/produtosApi';

export default {
  name: 'BuscarProduto',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    abrirAoFocar: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogBuscarProduto,
  },

  data() {
    return {
      dialogo: false,
      loading: false,
    };
  },

  computed: {
    produtos() {
      return this.$store.state.produto.itens;
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },

    abrirDialogo() {
      this.$refs.dialogoBuscarProduto.abrir();
    },

    focus() {
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);
    },

    async consultarCodigoProduto(event) {
      const codigo = event.target.value;
      if (codigo && codigo !== '') {
        try {
          this.loading = true;
          const produto = await this.buscarProdutoNoStorePorCodigo(codigo);
          this.loading = false;
          this.$emit('selecionarProduto', produto);
        } catch (error) {
          this.loading = false;
          this.$store.commit('setMensagemErro', error.response.data.motivo);
        }
      } else {
        this.$store.commit('setMensagemErro', 'Código Inválido');
      }
    },

    adicionarProdutoDialog(produto) {
      this.$emit('selecionarProduto', produto);
    },

    async buscarProdutoNoStorePorCodigo(codigo) {
      let produto = this.produtos.find((produto) => produto.codigo == codigo);
      if (!produto) {
        produto = await produtoApi.buscarCodigo(codigo);
      }
      return produto;
    },
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}
</style>
