import axios from '@/util/axios';

function produtos(params) {
  return axios.get(`/relatorios/produtos/listagem`, params).then((response) => response.data);
}

function clientes(params) {
  return axios.get('/relatorios/clientes/listagem', params).then((response) => response.data);
}

export default {
  produtos,
  clientes,
};
