<template>
  <v-dialog v-model="dialog" width="700px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Localizar Orçamento</v-toolbar-title>
      </v-toolbar>

      <!-- <v-divider></v-divider> -->

      <v-card-text class="pa-0">
        <v-data-table :headers="headers" :items="orcamentos" disable-sort disable-pagination hide-default-footer dense fixed-header height="290px">
          <template v-slot:item.valor="{ item }">
            {{ item.valor | formatarValor }}
          </template>

          <template v-slot:item.opcoes="{ item }">
            <v-btn icon>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-pdf-box</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="fechar">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogoOrcamentosPendentes',

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Número', value: 'numero' },
        { text: 'Data', value: 'data' },
        { text: 'Terminal', value: 'terminal', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Opções', value: 'opcoes', width: '150px', align: 'center' },
      ],
      orcamentos: [
        { numero: 1, data: '08/12/2020', terminal: '001', valor: 74.4657 },
        { numero: 2, data: '08/12/2020', terminal: '002', valor: 47.0 },
        { numero: 3, data: '08/12/2020', terminal: '001', valor: 25.0 },
        { numero: 4, data: '08/12/2020', terminal: '002', valor: 73.0 },
        { numero: 1, data: '08/12/2020', terminal: '001', valor: 74.0 },
        { numero: 2, data: '08/12/2020', terminal: '002', valor: 47.0 },
        { numero: 3, data: '08/12/2020', terminal: '001', valor: 25.0 },
        { numero: 4, data: '08/12/2020', terminal: '002', valor: 73.0 },
        { numero: 1, data: '08/12/2020', terminal: '001', valor: 74.0 },
        { numero: 2, data: '08/12/2020', terminal: '002', valor: 47.0 },
        { numero: 3, data: '08/12/2020', terminal: '001', valor: 25.0 },
        { numero: 4, data: '08/12/2020', terminal: '002', valor: 73.0 },
        { numero: 1, data: '08/12/2020', terminal: '001', valor: 74.0 },
        { numero: 2, data: '08/12/2020', terminal: '002', valor: 47.0 },
        { numero: 3, data: '08/12/2020', terminal: '001', valor: 25.0 },
        { numero: 4, data: '08/12/2020', terminal: '002', valor: 73.0 },
      ],
    };
  },

  methods: {
    abrir() {
      this.dialog = true;
      this.mensagem_erro = '';
      setTimeout(() => {
        this.$refs.numero.focus();
      }, 200);
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    confirmar() {
      this.mensagem_erro = 'Orçamento concluído!';
    },
  },
};
</script>

<style scoped></style>
