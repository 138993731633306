import ListaMovimentosEstoque from '@/views/estoque/ListaMovimentosEstoque';

export default [
  {
    path: '/movimentacoes/estoquesDeposito',
    name: 'estoquesDeposito',
    component: () => import(/* webpackChunkName: "listaEstoqueDeposito" */ '@/views/estoqueDeposito/ListarEstoqueDeposito'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_estoquedeposito' },
  },

  {
    path: '/movimentacoes/estoques',
    name: 'estoqueSimples',
    component: () => import(/* webpackChunkName: "listaEstoqueSimples" */ '@/views/estoques/ListarEstoqueSimples'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_estoquesimples' },
  },

  {
    path: '/movimentacoes/estoques/novo',
    name: 'formularioEstoqueNovo',
    component: () => import(/* webpackChunkName: "formularioEstoque" */ '@/views/estoques/FormularioEstoqueSimples'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_movimentosestoque' },
  },

  {
    path: '/movimentacoes/estoques/editar',
    name: 'formularioEstoqueEditar',
    component: () => import(/* webpackChunkName: "formularioEstoque" */ '@/views/estoques/FormularioEstoqueSimples'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_movimentosestoque' },
  },

  {
    path: '/movimentosEstoque',
    name: 'movimentoEstoque',
    component: ListaMovimentosEstoque,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_movimentosestoque' },
  },
];
