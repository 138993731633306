import axios from '@/util/axios';
import constantes from '@/util/constantes';
import cfopCompraEntradaApi from '@/services/cfopCompraEntradaApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    mapeamentos: [],
    mapeamentoAtual: {
      cfopCompra: '',
      descricaoCfopCompra: '',
      cfopEntrada: '',
      descricaoCfopEntrada: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await cfopCompraEntradaApi.listar(options);
        commit('SET_MAPEAMENTOS', response);
        commit('SET_TOTAL_MAPEAMENTOS', response.total);
      } catch (error) {
        commit('SET_MAPEAMENTOS', []);
        commit('setMensagemErro', 'Não foi possível listar os CFOPs', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    consultarCfopCompra({ commit }, cfop) {
      commit('SET_CARREGANDO', true);
      axios
        .get('/cfop/' + cfop)
        .then((response) => {
          commit('setCfopCompra', response.data);
        })
        .catch((error) => {
          commit('setCfopCompra', []);
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    consultarCfopEntrada({ commit }, cfop) {
      commit('SET_CARREGANDO', true);
      axios
        .get('/cfop/' + cfop)
        .then((response) => {
          commit('setCfopEntrada', response.data);
        })
        .catch((error) => {
          commit('setCfopEntrada', []);
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    async salvar({ commit, state }) {
      commit('SET_CARREGANDO', true);

      try {
        let cfopCompraEntrada = Object.assign({}, state.mapeamentoAtual);
        let response = await cfopCompraEntradaApi.salvar(cfopCompraEntrada);
        commit('ATUALIZAR_MAPEAMENTO', response);
        commit('setMensagemSucesso', 'Mapeamento Salvo com Sucesso', { root: true });
      } catch (error) {
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },
    async atualizar({ commit }, mapeamento) {
      await cfopCompraEntradaApi
        .atualizar(mapeamento)
        .then((response) => {
          commit('ATUALIZAR_MAPEAMENTO', mapeamento);
          commit('setMensagemSucesso', 'Mapeamento Atualizado com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    async excluir({ commit }, mapeamento) {
      commit('SET_CARREGANDO', true);
      await cfopCompraEntradaApi
        .excluir(mapeamento._id)
        .then((response) => {
          commit('EXCLUIR_MAPEAMENTO', mapeamento._id);
          commit('setMensagemSucesso', 'Mapeamento Removido com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_MAPEAMENTOS(state, mapeamentos) {
      state.mapeamentos = mapeamentos;
      state.carregando = false;
    },
    SET_TOTAL_MAPEAMENTOS(state, total) {
      state.totalMvas = total;
    },
    SET_MAPEAMENTO(state, mapeamentoAtual) {
      state.mapeamentoAtual = mapeamentoAtual;
    },
    EXCLUIR_MAPEAMENTO(state, id) {
      let index = state.mapeamentos.findIndex((mapeamento) => mapeamento._id == id);
      if (index > -1) {
        state.mapeamentos.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.mapeamentoAtual = {
        cfopCompra: '',
        descricaoCfopCompra: '',
        cfopEntrada: '',
        descricaoCfopEntrada: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.mapeamentoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_MAPEAMENTO(state, mapeamento) {
      state.mapeamentos.unshift(mapeamento);
      state.carregando = false;
    },
    ATUALIZAR_MAPEAMENTO(state, mapeamento) {
      let mapeamento_encontrado = state.mapeamentos.find((mapeamento_) => mapeamento_.id == mapeamento.id);
      if (mapeamento_encontrado) {
        mapeamento_encontrado = mapeamento;
      }
      state.carregando = false;
    },
    setCfopCompra(state, cfop) {
      state.mapeamentoAtual.cfopCompra = cfop.cfop;
      state.mapeamentoAtual.descricaoCfopCompra = cfop.descricao;
      state.carregando = false;
    },
    setCfopEntrada(state, cfop) {
      state.mapeamentoAtual.cfopEntrada = cfop.cfop;
      state.mapeamentoAtual.descricaoCfopEntrada = cfop.descricao;
      state.carregando = false;
    },
    estadoInicial(state, router) {
      state.mapeamentoAtual = {
        cfopCompra: '',
        descricaoCfopCompra: '',
        cfopEntrada: '',
        descricaoCfopEntrada: '',
      };
      state.carregando = false;
    },
  },
};
