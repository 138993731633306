import constantes from '@/util/constantes';
import cfopsApi from '@/services/cfopsApi';

export default {
  namespaced: true,

  state: {
    itens: [],
    itemAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalCfops: 0,
  },

  getters: {
    //
  },

  actions: {
    async buscarDescricaoCfop({ commit }, cfop) {
      try {
        commit('setCarregando');
        const response = await cfopsApi.buscarDescricaoCfop(cfop);
        commit('setCfops', response);
      } catch (error) {
        commit('setCfops', []);
        commit('setMensagemErro', 'error ao buscar CFOP', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async tratarConteudo({ commit }, dados) {
      try {
        commit('setCarregando', true);
        const cfopEntradaCompra = constantes.CFOP_ENTRADA_COMPRA;
        const cfopEntradaConsignacao = constantes.CFOP_ENTRADA_CONSIGNACAO;
        const cfopEntradaDevolucao = constantes.CFOP_ENTRADA_DEVOLUCAO;
        const cfopEntradaRemessaIndust = constantes.CFOP_ENTRADA_REMESSA_INDUSTRIALIZACAO;
        const cfopEntradaTransferencia = constantes.CFOP_ENTRADA_TRANSFERENCIA;

        const cfopSaidaVenda = constantes.CFOP_SAIDA_VENDA;
        const cfopSaidaConsignacao = constantes.CFOP_SAIDA_CONSIGNACAO;
        const cfopSaidaDevolucao = constantes.CFOP_SAIDA_DEVOLUCAO;
        const cfopSaidaRemessaIndust = constantes.CFOP_SAIDA_REMESSA_INDUSTRIALIZACAO;
        const cfopSaidaTransferencia = constantes.CFOP_SAIDA_TRANSFERENCIA;

        const linhas = dados.split('\n');
        const cabecalhos = linhas[0];
        const keys = cabecalhos.split(',');

        const objetos = [];
        for (var indiceLinha = 1; indiceLinha < linhas.length; indiceLinha++) {
          const linha = linhas[indiceLinha];
          const campos = linha.split(',');
          const objeto = {};
          for (var i = 0; i < keys.length; i++) {
            if (i === 5) {
              objeto['indDevol'] = campos[i];
            } else {
              objeto[keys[i]] = campos[i];
            }
          }

          if (objeto.cfop.charAt(0) === '1' || objeto.cfop.charAt(0) === '2' || objeto.cfop.charAt(0) === '3') {
            if (cfopEntradaCompra.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'COMPRA';
            } else if (cfopEntradaConsignacao.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'CONSIGNACAO';
            } else if (cfopEntradaDevolucao.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'DEVOLUCAO';
            } else if (cfopEntradaRemessaIndust.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'REMESSA';
            } else if (cfopEntradaTransferencia.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'TRANSFERENCIA';
            } else {
              objeto.natureza = '';
            }
          } else if (objeto.cfop.charAt(0) === '5' || objeto.cfop.charAt(0) === '6' || objeto.cfop.charAt(0) === '7') {
            if (cfopSaidaVenda.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'VENDA';
            } else if (cfopSaidaConsignacao.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'CONSIGNACAO';
            } else if (cfopSaidaDevolucao.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'DEVOLUCAO';
            } else if (cfopSaidaRemessaIndust.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'REMESSA';
            } else if (cfopSaidaTransferencia.find((cfop) => cfop === objeto.cfop)) {
              objeto.natureza = 'TRANSFERENCIA';
            } else {
              objeto.natureza = '';
            }
          }
          objetos.push(objeto);
        }

        let response = await cfopsApi.tratarConteudo(objetos);
        commit('setItens', response);
        commit('setCarregando', false);
        commit('setMensagemSucesso', 'CFOPs cadastrados com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async carregarItens({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await cfopsApi.carregarItens();
        commit('setItens', response);
      } catch (error) {
        commit('setItens', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    detalhar({ commit }, dados) {
      commit('setCarregando', true);
      dados.router.push('/cfop/detalhar');
      commit('detalhar', dados.item);
      commit('setCarregando', false);
    },
  },

  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setCfops(state, itens) {
      state.itens = itens;
      state.totalCfops = itens.length;
    },
    setItens(state, itens) {
      itens.forEach((item) => {
        state.itens.push(item);
      });
    },
    detalhar(state, item) {
      state.itemAtual = item;
    },
  },
};
