import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/es5/locale/pt';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      pt: {
        ...pt,
        dataTable: {
          ...pt.dataTable,
          itemsPerPageText: 'Registros',
        },
      },
    },
    current: 'pt',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.green.base,
        secondary: colors.lightGreen.base,
        accent: colors.blue.base,
        error: colors.deepOrange.base,
        warning: colors.amber.base,
        info: colors.yellow.base,
        success: colors.lightBlue.base,
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
