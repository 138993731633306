import localforage from 'localforage';

import transportadoresApi from '@/services/transportadoresApi';

const getTransportadores = (transportadores, options) => {
  let transportadoresOk = transportadores;
  transportadoresOk.sort((a, b) => (a.razaoSocialNome > b.razaoSocialNome ? 1 : -1));

  localforage.setItem('transportadores', transportadoresOk);

  if (options && options.busca) {
    transportadoresOk = transportadoresOk.filter((transportador) => `${transportador.razaoSocialNome} ${transportador.rgIe} ${transportador.cnpjCpf}`.toLocaleUpperCase().includes(options.busca.toLocaleUpperCase()));
  }

  if (options && options.itemsPerPage && options.page) {
    const from = options.itemsPerPage * (options.page - 1);
    const to = options.itemsPerPage * (options.page - 1) + options.itemsPerPage;
    transportadoresOk = transportadoresOk.filter((transportador, idx) => idx >= from && idx <= to);
  }

  return transportadoresOk;
};

const transportadorBlank = {
  cnpjCpf: '',
  rgIe: 'ISENTO',
  razaoSocialNome: '',
  veiculos: [],
  codigoAntt: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  codigoCidade: null,
  estado: '',
  codigoEstado: null,
  pais: '',
  codigoPais: 1058,
};

export default {
  namespaced: true,

  state: {
    transportadores: [],
    transportador: structuredClone(transportadorBlank),
    carregando: false,
  },

  getters: {
    transportadoresSelect() {
      return this.transportadores.map((transportador) => ({ text: transportador.razaoSocialNome, value: transportador._id }));
    },
  },

  actions: {
    listar({ commit }, options) {
      try {
        commit('setState', { carregando: true });

        localforage.getItem('timestampTransportadores').then((timestamp = null) => {
          transportadoresApi.listar({ timestamp: timestamp || undefined }).then((response) => {
            localforage.getItem('transportadores').then((transportadores) => {
              let transportadoresOk = [...(transportadores || [])];

              response.transportadores.forEach((transportador) => {
                const index = transportadoresOk.findIndex((p) => p._id === transportador._id);

                if (index < 0) {
                  transportadoresOk.push(transportador);
                } else {
                  transportadoresOk[index] = transportador;
                }
              });

              localforage.setItem('timestampTransportadores', response.timestamp);
              transportadoresOk = transportadoresOk.filter((transportador) => !response.deletados.includes(transportador._id));
              transportadoresOk = getTransportadores(transportadoresOk, options);
              commit('setState', { transportadores: transportadoresOk });
            });
          });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    async adicionar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          transportadoresApi
            .adicionar({
              ...state.transportador,
              veiculos: state.transportador.veiculos.filter((veiculo) => veiculo.placa.trim() && veiculo.uf),
            })
            .then((transportador) => {
              localforage.getItem('transportadores').then((transportadores) => {
                const transportadoresOk = transportadores.concat([transportador]);
                getTransportadores(transportadoresOk);
                commit('setState', { transportadores: state.transportadores.concat([transportador]), carregando: false });
                commit('setMensagemSucesso', 'Transportador cadastrado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async editar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          transportadoresApi
            .editar({
              ...state.transportador,
              veiculos: state.transportador.veiculos.filter((veiculo) => veiculo.placa.trim() && veiculo.uf),
            })
            .then((transportador) => {
              localforage.getItem('transportadores').then((transportadores) => {
                const transportadoresOk = transportadores;
                const index = transportadoresOk.findIndex((p) => p._id === transportador._id);

                if (index >= 0) transportadoresOk[index] = transportador;

                getTransportadores(transportadoresOk);
                commit('setState', { transportadores: state.transportadores.map((part) => (part._id === transportador._id ? transportador : part)), carregando: false });
                commit('setMensagemSucesso', 'Transportador atualizado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async remover({ commit }, transportadorId, options) {
      try {
        commit('setState', { carregando: true });
        await transportadoresApi.remover(transportadorId);

        localforage.getItem('transportadores').then((transportadores) => {
          let transportadoresOk = transportadores.filter((t) => t._id !== transportadorId);
          transportadoresOk = getTransportadores(transportadoresOk, options);
          commit('setState', { transportadores: transportadoresOk });
          commit('setMensagemSucesso', 'Transportador removido com sucesso', { root: true });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    novo({ commit }) {
      commit('setState', { transportador: structuredClone(transportadorBlank) });
    },
  },

  mutations: {
    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
