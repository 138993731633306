const Menu = [
  { cabecalho: 'Início', nivel: 1 },
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1,
    permissao: true,
  },
  { cabecalho: 'Cadastros', nivel: 1, permissao: 'exibir_cadastros' },
  {
    nome: 'Estoques',
    titulo: 'Estoques',
    path: '/movimentacoes/estoquesDeposito',
    icone: 'mdi-home-city',
    grupo: 'Cadastros',
    nivel: 1,
    permissao: 'acessar_estoquedeposito',
  },
  { cabecalho: 'Fiscal', nivel: 1, permissao: 'exibir_fiscal' },
  {
    titulo: 'Notas Fiscais',
    grupo: 'Fiscal',
    nome: 'Nota',
    icone: 'chrome_reader_mode',
    path: '/notas',
    nivel: 1,
    permissao: 'acessar_notasfiscais',
  },
];

export default Menu;
