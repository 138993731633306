<template>
  <v-form>
    <div id="themeSetting">
      <v-card>
        <v-card-title primary-title>Logotipo da Empresa</v-card-title>
        <v-card-media height="300" v-bind:src="empresa.logotipo">
          <v-layout column wrap>
            <v-card-title>
              <v-spacer></v-spacer>
              <input id="inputArquivo" type="file" :multiple="false" hidden @change="onFileSelected" />
              <v-btn icon @click="escolherArquivo()">
                <v-icon>edit</v-icon>
              </v-btn>
            </v-card-title>
          </v-layout>
        </v-card-media>
        <v-card-text>
          <v-layout column>
            <v-flex>
              <v-subheader class="px-1 my-2">Opções de Cor</v-subheader>
              <div class="color-option">
                <v-layout wrap>
                  <label class="color-option--label flex xs6 sm4 pa-1" v-for="(option, index) in themeColorOptions" :key="index">
                    <input type="radio" name="color" v-bind:value="option.key" v-model="themeColor" />
                    <span class="color-option--item bg">
                      <span class="overlay">
                        <span class="material-icons">check</span>
                      </span>
                      <span class="color-option--item--header sideNav" :class="option.value.sideNav"></span>
                      <span class="color-option--item--header mainNav" :class="option.value.mainNav"></span>
                      <span class="sideMenu" :class="option.value.sideManu"></span>
                    </span>
                  </label>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-layout justify-end>
            <v-btn depressed @click="cancelar()">{{ constantes.BOTAO_CANCELAR }}</v-btn>
            <v-btn depressed color="primary" :loading="carregando" @click="enviar()">Salvar</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </div>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import colors from 'vuetify/es5/util/colors';
import temas from '@/util/temas';
import constantes from '@/util/constantes';

export default {
  data: () => ({
    themeColor: 'green',
    colors: colors,
    constantes: constantes,
  }),

  computed: {
    ...mapState('empresa', {
      empresa: 'empresa',
      carregando: 'carregando',
    }),

    themeColorOptions() {
      return temas;
    },
  },

  methods: {
    onFileSelected(evento) {
      const arquivo = evento.target.files[0];
      if (arquivo) {
        this.$store.dispatch('empresa/carregarLogo', arquivo);
      }
    },

    escolherArquivo() {
      document.getElementById('inputArquivo').click();
    },

    enviar() {
      this.$store.dispatch('empresa/alterarPersonalizacao', this.themeColor);
    },

    cancelar() {
      this.$router.go(-1);
    },
  },

  watch: {
    themeColor: {
      handler(val) {
        this.$vuetify.theme.primary = this.colors[val].base;
      },
      immediate: true,
    },
  },

  created() {
    let empresa = this.$store.state.empresa;
    this.themeColor = empresa.tema;
    this.$store.dispatch('empresa/carregarEmpresa', empresa._id);
  },
};
</script>

<style lang="stylus" scoped>
.color-option
  &--label
    position: relative
    display: block
    cursor: pointer
    & input[type="radio"]
      display:none
      &+span
        position: relative
        &>.overlay
          display: none;
          position: absolute
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.3);
          text-align: center;
          line-height: 30px;
          color: #fff;
      &:checked+span>.overlay
        display:block
    & .bg
      background-color: #f1f1f1
  &--item
    overflow: hidden;
    display: block;
    box-shadow: 0 0 2px rgba(0,0,0,.1);
    margin-bottom: 15px;
    &--header
      height: 10px
    &>span
      display: block;
      float: left;
      width: 50%;
      height: 20px;
</style>
