<template>
  <div>
    <v-container fluid>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="formPlanoContas" lazy-validation>
        <v-container fluid>
          <v-widget title="Plano de Contas">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field label="Código" v-model="planoconta.codigo" v-mask="'#.##.###'" :rules="[rules.obrigatorio, rules.formatoPlanoContas]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-text-field label="Descrição" v-model="planoconta.descricao" :rules="[rules.obrigatorio]" required></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:widget-footer-action>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
              </v-card-actions>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioPlanoContas',
  data: () => ({
    valid: false,
    // validarCodigo: [v => !!v || "Código Obrigatório", v => (v && v.length === 6) || 'Código Inválido'],
    // validarDescricao: [v => !!v || "Descrição Obrigatória"],
    constantes: constantes,
    rules: { ...regras },
    loading: false,
  }),

  computed: {
    ...mapState('planoContas', {
      planoconta: 'planocontaAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('planoContas', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formPlanoContas.validate()) {
        try {
          if (this.planoconta._id) {
            await this.$store.dispatch('planoContas/atualizar', this.planoconta);
          } else {
            await this.$store.dispatch('planoContas/salvar', this.planoconta);
          }
          this.$router.push({ name: 'planoscontas' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('planoContas/estadoInicial');
      this.$router.push({ name: 'planoscontas' });
    },
  },

  async created() {
    try {
      this.loading = true;
      const planoconta = this.$store.state.planoContas.planocontaAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!planoconta._id || planoconta._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um plano de conta para editar');
        this.$router.push({ name: 'planoscontas' });
      }
      if (!estaEditando) {
        this.$store.commit('planoContas/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
