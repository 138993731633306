export default [
  {
    path: '/tabelas/cofins',
    name: 'cofins',
    component: () => import(/* webpackChunkName: "listaCofins" */ '@/views/cofins/ListaCOFINS'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_cofins' },
  },
  {
    path: '/tabelas/cofins/adicionar',
    name: 'cofinsAdicionar',
    component: () => import(/* webpackChunkName: "formularioCofins" */ '@/views/cofins/FormularioCOFINS'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/tabelas/cofins/editar',
    name: 'cofinsEditar',
    component: () => import(/* webpackChunkName: "formularioCofins" */ '@/views/cofins/FormularioCOFINS'),
    meta: { requiresAuth: true, nivel: 4 },
  },
];
