import Graficos from '@/views/graficos/Graficos';

export default [
  {
    path: '/graficos',
    name: 'graficos',
    component: Graficos,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_graficos' },
  },
];
