import axios from '@/util/axios';

function carregarManifestos(options) {
  let url = `/manifestos?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url = `${url}&query=${options.query}`;
  }

  return axios.get(url).then((response) => response.data);
}

function consultarCidadeCarga(nome) {
  return axios.get(`/cidades/${nome}`).then((response) => response.data);
}

function consultarCidadeDescarga(nome) {
  return axios.get(`/cidades/${nome}`).then((response) => response.data);
}

function reprocessarManifesto(idManifesto) {
  return axios.post(`/manifestos/processar`, idManifesto).then((response) => response.data);
}

function cancelarManifesto(manifesto) {
  return axios.post(`/servicos/mdfe/evento`, manifesto).then((response) => response.data);
}

function adicionarCondutor(condutor) {
  return axios.post(`/servicos/mdfe/evento`, condutor).then((response) => response.data);
}

function encerrarManifesto(manifesto) {
  return axios.post(`/servicos/mdfe/evento`, manifesto).then((response) => response.data);
}

function salvar(manifesto) {
  return axios.post(`/manifestos`, manifesto).then((response) => response.data);
}

function atualizar(manifesto) {
  return axios.put(`/manifestos`, manifesto).then((response) => response.data);
}

export default {
  carregarManifestos,
  consultarCidadeCarga,
  consultarCidadeDescarga,
  reprocessarManifesto,
  cancelarManifesto,
  adicionarCondutor,
  encerrarManifesto,
  salvar,
  atualizar,
};
