import axios from '@/util/axios';

function produtosVendidos(periodo) {
  return axios.post('/dashboard/notas/produtos/periodo', periodo).then((response) => response.data);
}

function quantidadeNotas(periodo) {
  return axios.post('/dashboard/notas/periodo', periodo).then((response) => response.data);
}

function quantidadeManifestos(periodo) {
  return axios.post('/dashboard/manifestos/periodo', periodo).then((response) => response.data);
}

function consultarQuantidadeProdutos() {
  return axios.get('/dashboard/produtos/quantidade').then((response) => response.data);
}

function consultarQuantidadeParticipantes() {
  return axios.get('/dashboard/participantes/quantidade').then((response) => response.data);
}

export default {
  produtosVendidos,
  quantidadeNotas,
  quantidadeManifestos,
  consultarQuantidadeProdutos,
  consultarQuantidadeParticipantes,
};
