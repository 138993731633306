<template>
  <div>
    <v-container fluid>
      <v-widget title="Remessa de Cheques">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field flat single-line hide-details clearable solo v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right pt-2">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo">
                  NOVA
                </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="remessas" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <span
                    ><b>{{ item.numero }}</b></span
                  >
                </td>
                <td>{{ item.data | formatarData }}</td>
                <td>{{ item.conta.agencia }}</td>
                <td>{{ item.conta.numero }}</td>
                <td>{{ item.conta.nome }}</td>
                <td class="text-center font-weight-bold">{{ item.cheques.length }}</td>
                <td class="text-right font-weight-bold">
                  <span>{{ item.valor | formatarMoeda }}</span>
                </td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 20" @click="editar(item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 40" @click="excluir(item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
      <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  name: 'ListaRemessasCheques',
  components: {
    DialogConfirmacao
  },
  data() {
    return {
      headers: [
        { text: 'Número', value: 'emitente' },
        { text: 'Data', value: 'data' },
        { text: 'Agência', value: 'agencia' },
        { text: 'Conta', value: 'conta' },
        { text: 'Nome', value: 'conta_nome' },
        { text: 'Cheques', value: 'remessas', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Ações', value: 'name', align: 'center', width: '7%', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      remessaSelecionado: null,
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('remessasCheques', {
      remessas: 'remessas',
      remessa: 'remessaAtual',
      carregando: 'carregando',
      total: 'totalRemessasCheques',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async listar() {
      this.filtro_ativo = false;
      await this.$store.dispatch('remessasCheques/listar');
    },

    async filtrarBusca() {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('remessasCheques/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    async editar(remessa) {
      this.$store.commit('remessasCheques/SET_REMESSA_ATUAL', remessa);
      await this.$store.dispatch('remessasCheques/listarChequesEmCaixa', {});
      await this.$store.dispatch('remessasCheques/listarContasCorrentes');
      this.$store.commit('remessasCheques/editar', this.remessa);
      this.$router.push({ name: 'RemessasChequesEditar' });
    },

    async novo() {
      this.$store.commit('remessasCheques/nova');
      this.$router.push({ name: 'RemessasChequesNovo' });
      await this.$store.dispatch('remessasCheques/listarChequesEmCaixa', {});
      await this.$store.dispatch('remessasCheques/listarContasCorrentes');
    },

    excluir(remessa) {
      this.$refs.dialogConfirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('remessasCheques/excluir', remessa);
        }
      });
    },

    limparBusca() {
      this.listar();
    },
  },
};
</script>
