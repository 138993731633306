import moment from 'moment';
import constantes from '@/util/constantes';
import { clone } from '@/util/helpers';
import contasreceberApi from '@/services/contasReceberApi';

const conta_zerada = {
  documento: '',
  participante: {
    _id: '',
    razaoSocialNome: '',
  },
  data_cadastro: moment().format('YYYY-MM-DD'),
  data_vencimento: '',
  valor: 0,
  tipo: 'Duplicata',
  empresa: '',
  recebimentos: [],
};

export default {
  namespaced: true,

  state: {
    contasReceber: [],
    contasReceberCliente: [],
    contaAtual: { ...conta_zerada },
    clientes: [],
    clienteAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalContasReceber: 0,
  },

  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO', true);
        let response = await contasreceberApi.listar(options);
        commit('SET_CONTAS_A_RECEBER', response.contas);
        commit('SET_TOTAL_CONTAS_A_RECEBER', response.total);
      } catch (error) {
        commit('SET_CONTAS_A_RECEBER', []);
        commit('setMensagemErro', 'error ao listar as Contas a Receber', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async filtrar({ commit, state }, options) {
      try {
        commit('SET_CARREGANDO', true);
        let response = await contasreceberApi.filtrar(options, state.clienteAtual);
        commit('SET_CONTAS_A_RECEBER', response.filtro);
        commit('SET_CLIENTE', response.cliente);
        commit('SET_TOTAL_CONTAS_A_RECEBER', response.total);
      } catch (error) {
        commit('SET_CONTAS_A_RECEBER', []);
        commit('setMensagemErro', 'error ao carregar as Contas a Receber do cliente', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        let contaReceber = Object.assign({}, state.contaAtual);
        delete contaReceber._id;
        await contasreceberApi.salvar(contaReceber);
        commit('setMensagemSucesso', 'Conta a Receber cadastrada com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao salvar a Conta a Receber', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        let contaReceber = Object.assign({}, state.contaAtual);
        let response = await contasreceberApi.atualizar(contaReceber);
        commit('ATUALIZAR', response);
        commit('setMensagemSucesso', 'Conta a Receber atualizada com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao realizar a baixa', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvarBaixa({ commit, state }, recebimento) {
      try {
        commit('SET_CARREGANDO', true);
        recebimento.conta_a_receber = state.contaAtual._id;
        let response = await contasreceberApi.salvarBaixa(state.contaAtual, recebimento);
        commit('ATUALIZAR', response);
        commit('setMensagemSucesso', 'Conta a Receber atualizada com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao realizar a baixa', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluirRecebimento({ commit, state }, recebimento) {
      try {
        commit('SET_CARREGANDO');
        let response = await contasreceberApi.excluirRecebimento(state.contaAtual, recebimento);
        commit('ATUALIZAR', response);
        commit('REMOVER_PAGAMENTO', recebimento);
        commit('setMensagemSucesso', 'Recebimento excluído com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao remover recebimento', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({ commit }, conta) {
      try {
        commit('SET_CARREGANDO');
        await contasreceberApi.excluir(conta._id);
        commit('EXCLUIR_CONTA', conta._id);
        commit('setMensagemSucesso', 'Conta a Receber excluida com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao excluir a Conta a Receber', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  mutations: {
    SET_CARREGANDO(state, valor) {
      state.carregando = valor;
    },
    SET_CONTAS_A_RECEBER(state, contas) {
      state.contasReceber = contas;
    },
    SET_PARTICIPANTE(state, participante) {
      state.contaAtual.participante = participante;
    },
    SET_CLIENTE(state, cliente) {
      state.clienteAtual = cliente;
    },
    SET_TOTAL_CONTAS_A_RECEBER(state, total) {
      state.totalContasReceber = total;
    },
    EXCLUIR_CONTA(state, conta_excluida_id) {
      let index = state.contasReceber.findIndex((contaReceber) => {
        return contaReceber._id == conta_excluida_id;
      });

      if (index > -1) {
        state.contasReceber.splice(index, 1);
      }
    },
    NOVO(state) {
      state.modoEdicao = false;
      state.contaAtual = { ...conta_zerada };
      state.carregando = false;
    },
    EDITAR(state, contaReceber) {
      state.modoEdicao = true;
      state.contaAtual = clone(contaReceber);
      state.carregando = false;
    },
    ADICIONAR(state, contaReceber) {
      state.contasReceber.unshift(contaReceber);
      state.carregando = false;
    },
    ATUALIZAR(state, contaAtualizada) {
      const index = state.contasReceber.findIndex((contaReceber) => {
        return contaReceber._id == contaAtualizada._id;
      });

      if (index > -1) {
        state.contasReceber.splice(index, 1, contaAtualizada);
      }

      state.contaAtual = contaAtualizada;
    },
    ESTADO_INICIAL(state) {
      state.contaAtual = { ...conta_zerada };
      state.carregando = false;
    },
    REMOVER_PAGAMENTO(state, pagamento) {
      const index = state.contaAtual.recebimentos.findIndex((_pagamento) => _pagamento.id == pagamento.id);
      if (index > -1) {
        state.contaAtual.recebimentos.splice(index, 1);
      }
    },
  },
};
