<template>
  <div>
    <v-dialog v-model="dialog" max-width="780px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Filtro</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-form ref="formFiltro" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="9">
                  <v-autocomplete persistent-placeholder outlined label="Fornecedor" :search-input.sync="filtroFornecedor" v-model="fornecedorSelecionado" @input="definirFornecedor(fornecedorSelecionado)" :items="fornecedoresBusca" append-icon="search">
                    <v-slide-x-reverse-transition mode="out-in" slot="append-outer"></v-slide-x-reverse-transition>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select label="Exibir" persistent-placeholder outlined v-model="filtro.exibir" :items="itens_exibir" hide-details></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn depressed class="mr-2" @click="limpar">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar" :disabled="carregando">Cancelar</v-btn>
          <v-btn depressed color="primary" :loading="carregando" @click="filtrar">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'DialogoFiltro',
  data() {
    return {
      dialog: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      itens_exibir: ['Todas', 'Em Aberto', 'Vencidos', 'Pagos', 'Pagos Parcialmente', 'Boleto', 'Duplicata', 'Cheque', 'Nota Promissória', 'Vale', 'Imposto', 'Outros'],
      filtro: {
        cliente: '',
        exibir: 'Todas',
      },
      filtroFornecedor: null,
      fornecedorSelecionado: null,
    };
  },
  watch: {
    filtroFornecedor(val) {
      if (!this.carregando && val && val.length > 3) {
        this.$store.dispatch('contaPagar/buscaFornecedores', val);
      }
    },
  },
  computed: {
    ...mapState('contaPagar', {
      contas: 'contasPagar',
      fornecedores: 'fornecedores',
      fornecedor: 'fornecedorAtual',
      carregando: 'carregando_filtro',
    }),

    ...mapGetters('contaPagar', {
      fornecedoresBusca: 'fornecedoresBusca',
    }),
  },
  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
    },

    limpar() {
      this.$store.commit('contaPagar/SET_FORNECEDOR', '');
      (this.filtro = {
        fornecedor: '',
        exibir: 'Todas',
      }),
        this.$emit('limpar');
      this.dialog = false;
    },

    definirFornecedor(fornecedor) {
      this.filtro.cliente = fornecedor;
      this.$store.commit('contaPagar/SET_FORNECEDOR', fornecedor);
    },

    async filtrar() {
      if (this.fornecedor) {
        let newOptions = {
          ...this.options,
          ...this.filtro,
        };
        this.$emit('filtrar', newOptions);
        this.fechar();
      } else {
        this.$store.commit('setMensagemErro', 'Escolha um fornecedor');
      }
    },
  },
};
</script>

<style></style>
