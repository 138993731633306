<template>
  <div>
    <v-dialog v-model="dialog" :width="venda.tipo === 'DAV' ? '760px' : '1150px'" @keydown.esc="fechar" persistent scrollable eager>
      <v-card :disabled="processando">
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Finalizar {{ tiposVenda[venda.tipo] }}</v-toolbar-title>
          <v-spacer />
          <v-icon style="cursor: pointer" @click="fechar" :disabled="processando">close</v-icon>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-row>
            <v-col :sm="venda.tipo === 'DAV' ? 12 : 6" style="display: flex; flex-direction: column">
              <v-form ref="form" style="display: flex; flex-direction: column; height: 100%" :disabled="processando">
                <v-row dense class="mb-3">
                  <v-col cols="12" sm="12">
                    <buscar-participante-nome
                      v-model="venda.cliente.razaoSocialNome"
                      @input="setNull"
                      ref="cliente"
                      label="Cliente"
                      hide-details
                      @participanteSelecionado="selecionarParticipante"
                      @keydown="onClienteKeyDown"
                      @keyup.enter="$refs.cnpjCpf.focus()"
                      placeholder=" "
                      outlined
                      background-color="white"
                      persistent-placeholder
                      :readonly="venda.pagamentos.length > 0"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <campo-cpf-cnpj
                      v-model="venda.cliente.cnpjCpf"
                      ref="cnpjCpf"
                      label="CPF / CNPJ"
                      hide-details
                      placeholder=" "
                      outlined
                      background-color="white"
                      persistent-placeholder
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :rules="validarCnpjCpf"
                      @keyup.enter="$refs.celular.focus()"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="venda.cliente.celular"
                      label="Celular"
                      ref="celular"
                      placeholder=" "
                      outlined
                      hide-details
                      background-color="white"
                      persistent-placeholder
                      v-mask="['(##) ####-####', '(##) #####-####']"
                      :rules="validarCelular"
                      @keyup.enter="$refs.email.focus()"
                    />
                  </v-col>
                  <v-col cols="12" sm="7">
                    <v-text-field
                      v-model="venda.cliente.email"
                      label="Email"
                      placeholder=" "
                      outlined
                      hide-details
                      background-color="white"
                      persistent-placeholder
                      @keyup.enter="
                        () => {
                          $refs.vendedor.focus();
                          $refs.vendedor.isMenuActive = true;
                        }
                      "
                      :rules="validarEmail"
                      ref="email"
                    />
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-select
                      v-model="venda.vendedor._id"
                      :items="vendedores"
                      label="Vendedor"
                      hide-details
                      placeholder=" "
                      outlined
                      background-color="white"
                      persistent-placeholder
                      ref="vendedor"
                      @change="selecionarVendedor"
                      @keyup.enter="onSelectEnterKey($refs.desconto.$el.firstElementChild.children[1])"
                    />
                  </v-col>
                </v-row>

                <v-row dense class="mb-3">
                  <v-col cols="12" sm="3" :class="venda.pagamentos.length > 0 ? 'disabled' : ''">
                    <v-radio-group :readonly="venda.pagamentos.length > 0" v-model="descontoAcrescimo" @change="calcularAcrescimoDesconto('valor')" hideDetails dense class="ma-0 pa-0" row style="padding-top: 4px !important">
                      <v-radio value="Acrescimo" label="Acréscimo" ref="acrescimo" @keyup.enter="$refs.percentual.focus()" />
                      <v-radio value="Desconto" label="Desconto" ref="desconto" @keyup.enter="$refs.percentual.focus()" />
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" sm="4" :class="venda.pagamentos.length > 0 ? 'disabled' : ''">
                    <campo-valor
                      v-model="percentual"
                      label="Percentual"
                      hide-details
                      @input="calcularAcrescimoDesconto('percentual')"
                      placeholder=" "
                      outlined
                      background-color="white"
                      style="font-size: 1.4rem"
                      persistent-placeholder
                      suffix="%"
                      fixarDecimais
                      decimais="2"
                      :readonly="venda.pagamentos.length > 0"
                      ref="percentual"
                      @keyup.enter="$refs.percentualValor.focus()"
                    />
                  </v-col>

                  <v-col cols="12" sm="5" :class="venda.pagamentos.length > 0 ? 'disabled' : ''">
                    <campo-valor
                      v-model="percentualValor"
                      label="Valor"
                      hide-details
                      @input="calcularAcrescimoDesconto('valor')"
                      placeholder=" "
                      outlined
                      background-color="white"
                      style="font-size: 1.4rem"
                      persistent-placeholder
                      fixarDecimais
                      decimais="2"
                      :readonly="venda.pagamentos.length > 0"
                      ref="percentualValor"
                      @keyup.enter="percentualValorEnterKey"
                    />
                  </v-col>

                  <v-col v-if="venda.tipo === 'DAV'" cols="12" :sm="venda.tipo === 'DAV' && venda.formasPagamento === 'CARTAOCREDITO' && parcelasCartao ? 7 : 12">
                    <v-select
                      v-model="venda.formasPagamento"
                      :items="(tabelas.formasPagamentos || []).filter((fp) => (!fp.cliente || venda.cliente._id) && !!fp.tef === !!tef.active).map((fp) => ({ value: fp.codigo, text: fp.descricao.toUpperCase() }))"
                      label="Formas de Pagamento"
                      outlined
                      persistent-placeholder
                      hide-details
                      :menu-props="{ maxHeight: 254 }"
                      ref="formasPagamento"
                      @change="checkParcelasCartaoCredito"
                      @keyup.enter="formasPagamentoEnterKey"
                    />
                  </v-col>

                  <v-col v-if="venda.tipo === 'DAV' && venda.formasPagamento === 'CARTAOCREDITO' && parcelasCartao" cols="12" sm="5">
                    <v-select v-model="parcelasCartao" :items="parcelasCartaoCredito" label="Parcelas" outlined persistent-placeholder hide-details :menu-props="{ maxHeight: 254 }" ref="parcelasCartao" @keyup.enter="formasPagamentoEnterKey">
                      <template v-slot:item="{ item }">
                        <v-chip label>{{ item.value }} x</v-chip>
                        <div style="width: 100%; text-align: right">{{ item.text }}</div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div style="display: flex; justify-content: space-between; align-items: center; gap: 8px">
                          <v-chip label style="flex-shrink: 0">{{ item.value + 'x' }}</v-chip>
                          <div style="width: 100%; text-align: right">{{ item.text }}</div>
                        </div>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6" class="nofocus">
                    <campo-valor
                      v-model="totalBruto"
                      label="Valor Total"
                      hide-details
                      readonly
                      placeholder=" "
                      outlined
                      background-color="grey lighten-3"
                      style="font-weight: 600; font-size: 1.4rem; user-select: none !important"
                      persistent-placeholder
                      fixarDecimais
                      decimais="2"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" class="nofocus">
                    <campo-valor
                      v-model="totalLiquido"
                      label="Valor Líquido"
                      hide-details
                      placeholder=" "
                      outlined
                      background-color="light-green lighten-5"
                      style="font-weight: 600; font-size: 1.4rem; user-select: none !important"
                      persistent-placeholder
                      fixarDecimais
                      decimais="2"
                      readonly
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col v-if="venda.tipo === 'NFe'" cols="12" sm="2">
                    <campo-valor v-model="venda.volumes.quantidade" label="Volumes" hide-details placeholder=" " outlined persistent-placeholder fixarDecimais decimais="0" ref="volumes" @keyup.enter="$refs.especie.focus()" :rules="validarNotZero" />
                  </v-col>

                  <v-col v-if="venda.tipo === 'NFe'" cols="12" sm="4">
                    <v-text-field v-model="venda.volumes.especie" label="Espécie" placeholder=" " outlined hide-details background-color="white" persistent-placeholder ref="especie" @keyup.enter="$refs.pesoBruto.focus()" :rules="validarNotEmpty" />
                  </v-col>

                  <v-col v-if="venda.tipo === 'NFe'" cols="12" sm="3">
                    <campo-valor
                      v-model="venda.volumes.pesoBruto"
                      label="Peso Bruto"
                      hide-details
                      placeholder=" "
                      outlined
                      persistent-placeholder
                      truncarDecimais
                      decimais="3"
                      ref="pesoBruto"
                      @keyup.enter="$refs.pesoLiquido.focus()"
                      :rules="validarNotZero"
                    />
                  </v-col>

                  <v-col v-if="venda.tipo === 'NFe'" cols="12" sm="3">
                    <campo-valor
                      v-model="venda.volumes.pesoLiquido"
                      label="Peso Líquido"
                      hide-details
                      placeholder=" "
                      outlined
                      persistent-placeholder
                      truncarDecimais
                      decimais="3"
                      ref="pesoLiquido"
                      @keyup.enter="$refs.observacoes.focus()"
                      :rules="validarNotZero"
                    />
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-textarea class="textarea-full-height" v-model="venda.observacoes" label="Observações" hide-details placeholder=" " outlined background-color="white" persistent-placeholder ref="observacoes" @keydown="clearBlankLines" />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>

            <v-col v-if="venda.tipo !== 'DAV'" sm="6" class="pl-0" style="display: flex">
              <v-card outlined style="display: flex; flex-direction: column">
                <v-toolbar color="light-green lighten-1" dense flat dark>
                  <v-toolbar-title>Pagamentos</v-toolbar-title>
                </v-toolbar>

                <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
                  <v-data-table :headers="headers" :items="venda.pagamentos" disable-sort hide-default-footer disable-pagination fixed-header height="240px" hide-default-header no-data-text="NÃO HÁ PAGAMENTOS" @click:row="selectRow">
                    <template v-slot:[`item.valor`]="{ item, index }">
                      <v-btn v-if="index === rowSelected && (!item.payload || !item.payload.cnfEnviado)" depressed small class="mr-4" @click="removerPagamento">Cancelar</v-btn>
                      {{ item.valor | formatarValor2 }}
                    </template>

                    <template v-slot:[`item.tipo`]="{ item }">
                      {{ tabelas.formasPagamentos.find((fp) => fp.codigo === item.tipo).descricao.toUpperCase() }}
                    </template>
                  </v-data-table>

                  <v-divider />

                  <div class="pa-4" style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 12px; margin-top: auto; max-height: 206px; overflow: hidden">
                    <v-btn
                      v-for="(formaPagamento, i) in tabelas.formasPagamentos.filter((fp) => !fp.tef || (fp.tef && tef.active)) || []"
                      :key="i"
                      color="primary"
                      @click="adicionarPagamento(formaPagamento.codigo)"
                      depressed
                      :disabled="saldo <= 0 || (formaPagamento.cliente && !venda.cliente._id)"
                      style="min-width: fit-content; flex: 1"
                      :ref="`pag${i}`"
                      >{{ formaPagamento.descricao }}</v-btn
                    >
                  </div>

                  <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 12px; justify-content: center">
                    <v-row class="pa-4 pt-0 mt-auto" style="display: flex; flex-direction: row" dense>
                      <v-col cols="12" sm="6" class="nofocus">
                        <campo-valor :value="saldo" label="Saldo" hide-details readonly outlined style="font-weight: 600; font-size: 1.4rem; user-select: none !important" fixarDecimais decimais="2" />
                      </v-col>
                      <v-col cols="12" sm="6" class="nofocus">
                        <campo-valor
                          :value="totalPagamentos"
                          label="Total Pagamentos"
                          hide-details
                          readonly
                          outlined
                          style="font-weight: 600; font-size: 1.4rem; user-select: none !important"
                          background-color="light-blue lighten-5"
                          fixarDecimais
                          decimais="2"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-row>
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="2" class="px-0">
              <v-chip v-if="venda.troco > 0" label style="font-size: 20px; height: 36px !important" class="py-4" color="green lighten-4">
                Troco <b class="pl-4">{{ venda.troco | formatarValor2 }}</b>
              </v-chip>
            </v-col>
            <v-col cols="12" sm="4" class="pl-0" style="display: flex; justify-content: right">
              <v-btn @click="fechar" depressed class="mr-2 px-4">Cancelar</v-btn>
              <v-btn color="primary" @click="finalizarVenda" :disabled="vendaValida()" :loading="processando" depressed class="px-4" ref="btnFim">Finalizar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-confirmacao ref="dialogoConfirmacao"></dialog-confirmacao>

    <informar-pagamento-venda ref="informarPagamentoVenda" :tipoPagamento="tipoPagamento" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import validador from '@/util/validador';
import formatador from '@/util/formatador';

import vendedoresApi from '@/services/vendedoresApi';

import InformarPagamentoVenda from './InformarPagamentoVenda';

export default {
  name: 'DialogoFinalizarVenda',

  components: {
    InformarPagamentoVenda,
  },

  data() {
    return {
      dialog: false,
      vendedores: [],
      headers: [
        { text: 'Tipo', value: 'tipo', width: '70%' },
        { text: 'Valor', value: 'valor', align: 'right' },
      ],
      pagamentos: [],
      processando: false,

      validarCnpjCpf: [
        (v) => {
          const t = v.replace(/\D+/g, '');
          if (!t) return true;
          if (t.length === 11) {
            return validador.cpf(t);
          } else if (t.length === 14) {
            return validador.cnpj(t);
          } else {
            return false;
          }
        },
      ],
      validarEmail: [(v) => (v && validador.email(v)) || !v],
      validarCelular: [(v) => (v && v.replace(/\D+/g, '').length === 11) || !v],
      validarNotZero: [(v) => (v && v > 0) || false],
      validarNotEmpty: [(v) => !!v.trim()],

      parcelasCartao: null,

      percentual: 0,
      percentualValor: 0,

      descontoAcrescimo: 'Desconto',

      tipoPagamento: 'DINHEIRO',

      rowSelected: null,

      aguardandoTEF: false,
    };
  },

  computed: {
    ...mapState('venda', ['venda', 'tiposVenda', 'tef']),
    ...mapState(['tabelas']),
    ...mapGetters('venda', ['saldo', 'totalBruto', 'totalLiquido', 'totalPagamentos']),

    parcelasCartaoCredito() {
      return this.tabelas.parcelamentoCartaoCredito.filter((tab) => tab.ate <= this.totalLiquido).map((tab) => ({ value: tab.parcelas, text: formatador.formatarValor2(parseFloat((this.totalLiquido / tab.parcelas).toFixed(2))) }));
    },
  },

  mounted() {
    window.addEventListener('keydown', this.atalhosPagamentos, true);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.atalhosPagamentos);
  },

  methods: {
    ...mapActions('venda', {
      TEFNcn: 'TEFNcn',
      TEFCan: 'TEFCan',
    }),

    selecionarVendedor() {
      this.venda.vendedor.nome = this.vendedores.find((v) => v.value === this.venda.vendedor._id).text;
    },

    clearBlankLines(e) {
      if (e.key === 'Enter' && !e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.btnFim.$el.focus();
      } else if (e.key === 'Enter' && e.ctrlKey) {
        this.venda.observacoes = this.venda.observacoes + '\n';
      }
    },

    atalhosPagamentos(event) {
      if (this.tef.message) return;

      if (this.dialog && event.code.startsWith('Digit') && event.ctrlKey) {
        const btnPag = this.$refs[`pag${parseInt(event.key, 10) - 1}`];
        if (btnPag) {
          btnPag[0].$el.click();
        }
      }
    },

    vendaValida() {
      const valido = this.$refs.form ? this.$refs.form.validate() : false;
      if (!valido) return true;
      if (this.saldo !== 0 && this.venda.tipo !== 'DAV') return true;
      return false;
    },

    selectRow(value, item) {
      this.rowSelected = item.index;
      this.$refs.form.validate();
    },

    checkParcelasCartaoCredito() {
      if (this.parcelasCartaoCredito.length > 0 && this.venda.formasPagamento === 'CARTAOCREDITO') {
        this.parcelasCartao = this.parcelasCartaoCredito[0].value;
      } else {
        this.parcelasCartao = null;
      }
    },

    onSelectEnterKey(element) {
      const menu = document.querySelector('[role="listbox"]');
      if (menu && ['', 'none'].includes(menu.parentElement.style.display)) {
        this.$refs.vendedor.blur();
        element.focus();
      }
    },

    percentualValorEnterKey() {
      if (this.$refs.formasPagamento) {
        this.$refs.formasPagamento.focus();
        this.$refs.formasPagamento.isMenuActive = true;
      } else if (this.$refs.volumes) {
        this.$refs.volumes.focus();
      } else {
        this.$refs.observacoes.focus();
      }
    },

    formasPagamentoEnterKey() {
      this.$refs.formasPagamento.blur();
      this.$nextTick(() => {
        if (this.$refs.parcelasCartao && !this.$refs.parcelasCartao.isFocused) {
          this.$refs.parcelasCartao.focus();
          this.$refs.parcelasCartao.isMenuActive = true;
        } else {
          this.$refs.observacoes.focus();
        }
      });
    },

    setNull() {
      this.$store.commit('venda/setCliente', { ...this.venda.cliente, _id: null });
    },

    async abrir() {
      await this.carregarVendedores();
      this.$store.dispatch('participante/listar');
      this.$store.commit('venda/removerPagamentos');
      this.dialog = true;
      this.aguardandoTEF = false;
      this.$refs.form.validate();

      this.percentualValor = this.venda.desconto > 0 ? this.venda.desconto : this.venda.acrescimo;
      this.percentual = parseFloat(((this.percentualValor * 100) / this.totalBruto).toFixed(2));
      this.descontoAcrescimo = this.venda.desconto > 0 ? 'Desconto' : 'Acrescimo';
    },

    fechar() {
      if (this.processando) return;

      this.processando = true;

      const aviso = this.venda.pagamentos.some((fp) => ['CARTAOCREDITO', 'CARTAODEBITO'].includes(fp.tipo)) ? 'Todos as transações de Cartão de Crédito/Débito serão canceladas!' : null;

      if (this.venda.pagamentos.length > 0) {
        this.$refs.dialogoConfirmacao.open('Atenção!', `Deseja realmente cancelar o processo de pagamento?`, { aviso }).then((result) => {
          if (result) {
            if (aviso) {
              this.aguardandoTEF = 'cancelar';
              this.TEFCan();
            } else {
              this.dialog = false;
              this.processando = false;
            }
          }
        });
      } else {
        this.dialog = false;
        this.processando = false;
        this.$emit('fechar');
      }
    },

    calcularAcrescimoDesconto(calculo) {
      if (calculo === 'percentual') {
        if (this.percentual >= 100 || this.percentual < 0) {
          this.percentual = 0;
          this.$refs.percentualValor.focus();
          this.$nextTick(() => this.$refs.percentual.focus());
        }
        this.percentualValor = parseFloat(((this.totalBruto * this.percentual) / 100).toFixed(2));
      } else {
        if (this.percentualValor >= this.totalBruto) {
          this.percentualValor = 0;
          this.$refs.percentual.focus();
          this.$nextTick(() => this.$refs.percentualValor.focus());
        }
        this.percentual = parseFloat(((this.percentualValor * 100) / this.totalBruto).toFixed(2));
      }
      if (this.descontoAcrescimo === 'Desconto') {
        this.$store.commit('venda/setDescontoAcrescimo', { desconto: this.percentualValor, acrescimo: 0 });
      } else {
        this.$store.commit('venda/setDescontoAcrescimo', { desconto: 0, acrescimo: this.percentualValor });
      }
    },

    finalizarVenda() {
      if (this.processando) return;

      this.processando = true;

      this.$store.dispatch('venda/salvar').then(() => {
        const tef = this.venda.pagamentos.some((fp) => ['CARTAOCREDITO', 'CARTAODEBITO'].includes(fp.tipo));

        if (tef) {
          this.aguardandoTEF = 'finalizar';
          this.TEFFin();
        } else {
          this.$emit('onFinalizarVenda');
          this.dialog = false;
          this.processando = false;
          this.$emit('fechar');
        }
      });
    },

    adicionarPagamento(tipoPagamento) {
      this.tipoPagamento = tipoPagamento;
      this.$refs.informarPagamentoVenda.abrir();
    },

    removerPagamento() {
      if (this.venda.pagamentos[this.rowSelected].payload) {
        this.TEFNcn(this.rowSelected);
      } else {
        this.$store.commit('venda/removerPagamento', this.rowSelected);
      }

      this.rowSelected = null;
    },

    selecionarParticipante(participante) {
      if (participante) {
        const mappedParticipante = {
          _id: participante._id,
          razaoSocialNome: participante.razaoSocialNome,
          cnpjCpf: participante.cnpjCpf,
          email: participante.email,
          celular: participante.celular,
          foto: participante.foto,
        };
        this.$store.commit('venda/setCliente', mappedParticipante);
        this.$nextTick(() => {
          this.$refs.cnpjCpf.focus();
        });
      }
    },

    async carregarVendedores() {
      const resultado = await vendedoresApi.listar();
      this.vendedores = resultado.vendedores.map((vendedor) => {
        return { text: vendedor.nome, value: vendedor._id };
      });
      if (this.vendedores.length) {
        this.venda.vendedor._id = this.vendedores[0].value;
      }
    },

    onClienteKeyDown(event) {
      if (event.code === 'Insert' || event.code === 'F13') {
        event.preventDefault();
        this.$refs.cliente.abrirDialogo();
      }
    },
  },
  watch: {
    venda: {
      deep: true,
      handler(newVal) {
        if (this.aguardandoTEF && newVal.pagamentos.length === 0) {
          if (this.aguardandoTEF === 'finalizar') {
            this.$emit('onFinalizarVenda');
          }
          this.aguardandoTEF = false;
          this.dialog = false;
          this.processando = false;
          this.$emit('fechar');
        }
      },
    },
  },
};
</script>

<style lang="css" scoped>
.v-text-field >>> textarea {
  resize: none;
}
.v-select >>> .v-chip {
  border-radius: 4px;
  margin: 0px !important;
}
.v-select >>> .v-select__selections {
  gap: 6px;
}
.textarea-full-height {
  height: 100%;
}
.textarea-full-height >>> div {
  height: 100% !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.7;
}
.disabled >>> input::selection {
  background: transparent;
}
.nofocus {
  pointer-events: none !important;
  user-select: none !important;
}
.nofocus >>> input::selection {
  background: transparent;
}
</style>
