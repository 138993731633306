<template>
  <div>
    <dialogo-sangria-suprimento :dialog="dialogoSangriaSuprimento" :tipo="tipoLancamento" @adicionarLancamento="gerarLancamento" @fechar="fecharDialogoSangriaSuprimento" />
    <dialogo-transferencia-conta-corrente :dialog="dialogoTransferenciaContaCorrente" @adicionarTransferencia="gerarLancamento" @fechar="fecharDialogoTransferencia" />
    <dialogo-detalhamento-caixa :dialog="dialogoDetalhamentoCaixa" :detalhamento="detalhamento" @fechar="fecharDialogoDetalhamentoCaixa" />

    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Caixa</v-toolbar-title>
        </v-toolbar>
        <v-toolbar-title flat color="white">
          <v-container fluid>
            <v-row>
              <v-col cols="12" xs="12" sm="5">
                <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="6" sm="3" class="text-right pr-0">
                <campo-data flat hide-details hide-selected chips dense v-model="dataMovimentacao"></campo-data>
              </v-col>
              <v-col cols="12" xs="6" sm="4" class="text-right">
                <v-btn v-if="!isSm" class="mr-1" color="primary" @click="abrirDialogoDetalhamentoCaixa">Detalhamento</v-btn>
                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark class="mr-4" v-bind="attrs" v-on="on" @click="abrirDialogoDetalhamentoCaixa">
                      mdi-format-list-text
                    </v-icon>
                  </template>
                  <span>Detalhamento</span>
                </v-tooltip>
                <v-menu bottom right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn @click.stop="" v-on="on" color="primary">Lançamento</v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item :key="9999 + 10" @click="abrirDialogoSangriaSuprimento(constantes.SANGRIA)">
                      <v-list-item-icon><v-icon>remove</v-icon></v-list-item-icon>
                      <v-list-item-title>Sangria</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="9999 + 20" @click="abrirDialogoSangriaSuprimento(constantes.SUPRIMENTO)">
                      <v-list-item-icon><v-icon>add</v-icon></v-list-item-icon>
                      <v-list-item-title>Suprimento</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="9999 + 30" @click="abrirDialogoTransferencia()">
                      <v-list-item-icon><v-icon>mdi-redo</v-icon></v-list-item-icon>
                      <v-list-item-title>Transf. Conta Corrente</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" class="text-right">
                <v-chip dark color="primary" class="mr-2 mb-2">
                  <span>Entradas &nbsp;</span>
                  <span class="mr-2">:</span>
                  <h2 class="font-weight-bold">&nbsp; {{ creditosTotal() | formatarMoeda }}</h2>
                </v-chip>
                <v-chip dark color="red" class="mr-2 mb-2">
                  <span>Saídas &nbsp;</span>
                  <span class="mr-2">:</span>
                  <h2 class="font-weight-bold">&nbsp; {{ debitosTotal() | formatarMoeda }}</h2>
                </v-chip>
                <v-chip dark color="orange" class="mr-2 mb-2">
                  <span>Saldo &nbsp;</span>
                  <span class="mr-2">:</span>
                  <h2 class="font-weight-bold">&nbsp; {{ saldoTotal() | formatarMoeda }}</h2>
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-data-table style="padding-bottom: 20px" hide-default-footer :options.sync="options" :server-items-length="total" :headers="headers" :items="movimentosCaixa" :search="busca" :loading="carregando">
            <template v-slot:item="{ item }">
              <tr>
                <td style="width: 190px"><i class="fa fa-calendar fa-1x"></i> {{ item.data | formatarDataHora }}</td>
                <td style="padding-left: 0 !important">
                  <b>{{ item.descricao }}</b
                  ><br />
                </td>
                <td>{{ item.formaPagamento }}</td>
                <td v-if="item.recebimento">Baixa CR</td>
                <td v-else-if="item.pagamento">Pgto. CP</td>
                <td v-else-if="item.venda">Recbto. Venda</td>
                <td v-else-if="item.compra">Recbto. Compra</td>
                <td v-else>{{ item.tipo === constantes.CREDITO ? 'Suprimento' : 'Sangria' }}</td>
                <td class="text-right ">
                  <span class="mr-2"
                    ><b>{{ item.valor | formatarMoeda }}</b>
                  </span>
                  <span v-if="item.tipo == 'Crédito'" class="primary--text font-weight-bold">C</span>
                  <span v-if="item.tipo == 'Débito'" class="red--text font-weight-bold">D</span>
                </td>
                <td class="text-center" style="20px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="excluir(item._id)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <td colspan="100%"></td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="primary" @click="imprimir">Imprimir</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fechar">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import DialogoDetalhamentoCaixa from './DialogoDetalhamentoCaixa';
import DialogoTransferenciaContaCorrente from './DialogoTransferenciaContaCorrente';
import DialogoSangriaSuprimento from './DialogoSangriaSuprimento';
import constantes from '@/util/constantes';
import moment from 'moment';

export default {
  name: 'DialogoCaixa',
  components: {
    DialogoSangriaSuprimento,
    DialogoTransferenciaContaCorrente,
    DialogoDetalhamentoCaixa,
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Data', value: 'data' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Forma Pgto.', value: 'formaPagamento' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: '', value: 'name', align: 'center', width: '12px' },
      ],
      headFormaPagamentos: [
        { text: 'Tipo', value: 'tipo', sortable: false },
        { text: 'Créditos', value: 'creditos', sortable: false },
        { text: 'Débitos', value: 'debitos', sortable: false },
        { text: 'Saldo', value: 'saldo', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      dataMovimentacao: '',
      dialogoTransferenciaContaCorrente: false,
      dialogoSangriaSuprimento: false,
      tipoLancamento: '',
      dialogoDetalhamentoCaixa: false,
      detalhamento: {
        vendas: [],
        recebimentos: [],
        lancamentos: [],
      },
    };
  },

  watch: {
    dataMovimentacao(v) {
      if (v && v != '') {
        this.filtrarData(v);
      }
    },
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('caixa', {
      movimentosCaixa: 'movimentosCaixa',
      carregando: 'carregando',
      total: 'totalMovimentosCaixa',
    }),

    isSm() {
      return this.$vuetify.breakpoint.sm;
    },
  },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    async listar() {
      this.$store.dispatch('caixa/listar', this.options);
    },

    filtrarData(data) {
      let dataFiltro = moment(data).format('YYYY-MM-DD');
      this.$store.commit('caixa/filtroData', dataFiltro);
      this.listar();
    },

    filtrar(event) {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };
      this.$store.dispatch('caixa/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    limparBusca() {
      this.listar();
    },

    abrirDialogoSangriaSuprimento(tipo) {
      this.tipoLancamento = tipo;
      this.dataMovimentacao = moment().format('YYYY-MM-DD');
      this.dialogoSangriaSuprimento = true;
    },

    abrirDialogoTransferencia() {
      this.dataMovimentacao = moment().format('YYYY-MM-DD');
      this.dialogoTransferenciaContaCorrente = true;
    },

    abrirDialogoDetalhamentoCaixa() {
      let filtroVendas = [];
      this.movimentosCaixa.forEach((movimento) => {
        if (typeof movimento.venda !== 'undefined') {
          filtroVendas.push(movimento);
        }
      });
      this.detalhamento.vendas = filtroVendas.length > 0 ? filtroVendas : [];

      let filtroRecebimentos = [];
      this.movimentosCaixa.forEach((movimento) => {
        if (typeof movimento.recebimento !== 'undefined') {
          filtroRecebimentos.push(movimento);
        }
      });
      this.detalhamento.recebimentos = filtroRecebimentos.length > 0 ? filtroRecebimentos : [];

      let filtroLancamentos = [];
      this.movimentosCaixa.forEach((movimento) => {
        let tipo = movimento.tipo;
        if (tipo == constantes.CREDITO || tipo == constantes.DEBITO) {
          filtroLancamentos.push(movimento);
        }
      });
      this.detalhamento.lancamentos = filtroLancamentos.length > 0 ? filtroLancamentos : [];

      this.dialogoDetalhamentoCaixa = true;
    },

    creditosTotal() {
      let movimentos = this.$store.getters['caixa/movimentosCreditos'](constantes.CREDITO);
      return movimentos.reduce((total, movimento) => total + movimento.valor, 0);
    },

    debitosTotal() {
      let movimentos = this.$store.getters['caixa/movimentosDebitos'](constantes.DEBITO);
      return movimentos.reduce((total, movimento) => total + movimento.valor, 0);
    },

    saldoTotal() {
      return this.creditosTotal() - this.debitosTotal();
    },

    gerarLancamento(lancamento) {
      if (lancamento.tipo === constantes.DEBITO) {
        this.$store.dispatch('caixa/debitar', lancamento);
      } else if (lancamento.tipo === constantes.CREDITO) {
        this.$store.dispatch('caixa/creditar', lancamento);
      }
      this.dialogoSangriaSuprimento = false;
      this.dialogoTransferenciaContaCorrente = false;
    },

    excluir(id) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('caixa/excluir', id);
        }
      });
    },

    fecharDialogoSangriaSuprimento() {
      this.tipoLancamento = '';
      this.dialogoSangriaSuprimento = false;
    },

    fecharDialogoTransferencia() {
      this.dialogoTransferenciaContaCorrente = false;
    },

    fecharDialogoDetalhamentoCaixa() {
      this.dialogoDetalhamentoCaixa = false;
    },
  },

  created() {
    this.dataMovimentacao = moment().format('YYYY-MM-DD');
    this.filtrarData(this.dataMovimentacao);
  },
};
</script>
