<template>
  <div>
    <v-dialog v-model="dialog" max-width="960px" min-height="300px" scrollable persistent>
      <v-card>
        <v-toolbar flat dense dark color="primary">
          <v-toolbar-title class="white--text">Configurações</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="abaAtiva" grow>
            <v-tab :key="1">Empresa</v-tab>
            <v-tab :key="2">Sistema</v-tab>
            <v-tab :key="3">Outras configurações</v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="abaAtiva">
            <v-divider></v-divider>
            <v-tab-item eager>
              <v-container grid-list-md fluid>
                <v-row dense>
                  <v-col cols="12" sm="7">
                    <v-text-field label="Razão Social" ref="razao_social" v-model="empresa.razao" @keypress.enter="$refs.nome_fantasia.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-text-field label="Nome Fantasia" ref="nome_fantasia" v-model="empresa.fantasia" @keypress.enter="$refs.cnpjCpf.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      label="CNPJ/CPF"
                      ref="cnpjCpf"
                      v-model="empresa.cnpj"
                      @change="consultarCnpj(empresa.cnpj)"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :rules="[rules.cnpjCpf]"
                      data-cy="cnpjCpf"
                      @keypress.enter="$refs.inscricao_estadual.focus()"
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Inscrição Estadual *" ref="inscricao_estadual" v-model="empresa.ie" data-cy="inscricao" @keypress.enter="$refs.inscricao_municipal.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Inscrição Municipal" ref="inscricao_municipal" v-model="empresa.im" data-cy="incricao_municipal" @keypress.enter="$refs.cep.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <campo-cep v-mask="'#####-###'" label="CEP" ref="cep" buscarcep @resultado="setEndereco" v-model="empresa.cep" append-icon="map" @keypress.enter="$refs.logradouro.focus()" filled dense></campo-cep>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Logradouro" ref="logradouro" v-model="empresa.logradouro" @keypress.enter="$refs.numero.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field label="Número" ref="numero" v-model="empresa.numero" @keypress.enter="$refs.bairro.focus()" filled dense> </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Bairro" ref="bairro" v-model="empresa.bairro" @keypress.enter="$refs.estado.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-autocomplete label="Estado *" ref="estado" v-model="empresa.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(empresa.estado)" @keypress.enter="$refs.cidade.focus()" filled dense></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      label="Cidade *"
                      ref="cidade"
                      v-model="empresa.codigo_cidade"
                      @change="setCidade(empresa.codigo_cidade)"
                      :items="cidades"
                      :disabled="cidades.length == 0 || $store.state.configuracao.carregando_cidades"
                      :loading="$store.state.configuracao.carregando_cidades"
                      @keypress.enter="$refs.complemento.focus()"
                      filled
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Complemento" ref="complemento" v-model="empresa.complemento" @keypress.enter="$refs.telefone.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Telefone" ref="telefone" v-model="empresa.telefone" v-mask="'(##)####-####'" data-cy="telefone" @keypress.enter="$refs.inscricao_suframa.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Inscrição Suframa" ref="inscricao_suframa" v-model="empresa.inscricao_suframa" data-cy="inscricao_suframa" @keypress.enter="$refs.cnae.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field label="CNAE" ref="cnae" v-model="empresa.cnae" data-cy="cnae" @keypress.enter="$refs.responsavel.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="5">
                    <v-text-field label="Responsável" ref="responsavel" v-model="empresa.responsavel" data-cy="responsavel" @keypress.enter="$refs.cpf_responsavel.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="CPF" ref="cpf_responsavel" v-model="empresa.cpf_responsavel" v-mask="['###.###.###-##']" data-cy="cpf_responsavel" @keypress.enter="$refs.email_responsavel.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="E-mail" ref="email_responsavel" v-model="empresa.email_responsavel" data-cy="email_responsavel" @keypress.enter="$refs.contador_nome.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="5">
                    <h3>Contador</h3>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Nome" ref="contador_nome" v-model="contador.nome" @keypress.enter="$refs.contador_cnpjCpf.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="CNPJ" ref="contador_cnpjCpf" v-model="contador.cnpj" v-mask="['##.###.###/####-##']" data-cy="cnpjCpf" @keypress.enter="$refs.contador_cpf.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="CPF" ref="contador_cpf" v-model="contador.cpf" v-mask="['###.###.###-##']" data-cy="contador_cpf" @keypress.enter="$refs.contador_crc.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field label="CRC" ref="contador_crc" v-model="contador.crc" data-cy="contador_crc" @keypress.enter="$refs.contador_cep.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <campo-cep v-mask="'#####-###'" label="CEP" ref="contador_cep" buscarcep @resultado="setEnderecoContador" v-model="contador.cep" append-icon="map" @keypress.enter="$refs.contador_logradouro.focus()" filled dense></campo-cep>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Logradouro" ref="contador_logradouro" v-model="contador.logradouro" @keypress.enter="$refs.contador_numero.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-text-field label="Número" ref="contador_numero" v-model="contador.numero" @keypress.enter="$refs.contador_bairro.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Bairro" ref="contador_bairro" v-model="contador.bairro" @keypress.enter="$refs.contador_estado.focus()" filled dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      label="Estado *"
                      ref="contador_estado"
                      v-model="contador.estado"
                      :items="constantes.ESTADOS_AUTOCOMPLETE"
                      @change="filtrarCidadesEstadoContador(contador.estado)"
                      @keypress.enter="$refs.contador_cidade.focus()"
                      filled
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      label="Cidade *"
                      ref="contador_cidade"
                      v-model="contador.codigo_cidade"
                      @change="setCidadeContador(contador.codigo_cidade)"
                      :items="cidadesContador"
                      :disabled="cidadesContador.length == 0 || $store.state.configuracao.carregando_cidades_contador"
                      :loading="$store.state.configuracao.carregando_cidades_contador"
                      @keypress.enter="$refs.contador_complemento.focus()"
                      filled
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-text-field label="Complemento" ref="contador_complemento" v-model="contador.complemento" @keypress.enter="$refs.contador_telefone.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field label="Telefone" ref="contador_telefone" v-model="contador.telefone" v-mask="'(##)#####-####'" data-cy="contador_telefone" @keypress.enter="$refs.contador_email.focus()" filled dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-text-field label="E-mail" ref="contador_email" v-model="contador.email" data-cy="contador_email" filled dense></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item eager>
              <v-row dense class="pt-4">
                <v-col cols="12" sm="6">
                  <v-select label="Código do Regime Tributário" ref="regime_tributario" v-model="empresa.config_sistema.regime_tributario" :items="constantes.REGIMES_TRIBUTARIOS" @keypress.native.enter="$refs.tributacao.focus()" filled dense></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select label="Tributação" ref="tributacao" v-model="empresa.config_sistema.tributacao" :items="tributacoes" @keypress.native.enter="$refs.tabela_customizavel.focus()" filled dense></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-text-field label="Tabela Customizável" ref="tabela_customizavel" v-model="empresa.config_sistema.tabela_customizavel" @keypress.native.enter="$refs.margem_lucro.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <as-campo-valor label="Margem Lucro %" ref="margem_lucro" v-model="empresa.config_sistema.margem_lucro" :decimais="2" @keypress.native.enter="$refs.modo_plano_contas.focus()" filled dense></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-select
                    label="Modo de Execução Plano de Contas"
                    ref="modo_plano_contas"
                    v-model="empresa.config_sistema.modo_plano_contas"
                    :items="modosPlanoContas"
                    @keypress.native.enter="$refs.tabela_produtos_primaria_nome.focus()"
                    filled
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" sm="3">
                  <v-text-field
                    label="Produtos-Tabela Primária"
                    ref="tabela_produtos_primaria_nome"
                    v-model="empresa.config_sistema.tabela_produtos_primaria.nome"
                    @keypress.native.enter="$refs.tabela_produtos_primaria_porcentagem.focus()"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <as-campo-valor
                    label="Produtos-Tabela Primária %"
                    v-model="empresa.config_sistema.tabela_produtos_primaria.porcentagem"
                    :decimais="0"
                    ref="tabela_produtos_primaria_porcentagem"
                    @keypress.native.enter="$refs.tabela_produtos_secundaria_nome.focus()"
                    filled
                    dense
                  ></as-campo-valor>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field
                    label="Produtos-Tabela Secundária"
                    ref="tabela_produtos_secundaria_nome"
                    v-model="empresa.config_sistema.tabela_produtos_secundaria.nome"
                    @keypress.native.enter="$refs.tabela_produtos_secundaria_porcentagem.focus()"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <as-campo-valor
                    label="Produtos-Tabela Secundária %"
                    v-model="empresa.config_sistema.tabela_produtos_secundaria.porcentagem"
                    ref="tabela_produtos_secundaria_porcentagem"
                    @keypress.native.enter="$refs.dias_tolerancia.focus()"
                    :decimais="0"
                    filled
                    dense
                  ></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="5">
                  <h3>Juros e Multas</h3>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field label="Dias Tolerância" v-model="empresa.config_sistema.juros_multas.dias_tolerancia" ref="dias_tolerancia" @keypress.native.enter="$refs.juros.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Juros %" ref="juros" v-model="empresa.config_sistema.juros_multas.juros" :decimais="2" @keypress.native.enter="$refs.multas.focus()" filled dense></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <as-campo-valor label="Multas %" ref="multas" v-model="empresa.config_sistema.juros_multas.multas" @keypress.native.enter="$refs.limite_valor.focus()" :decimais="2" filled dense></as-campo-valor>
                </v-col>
                <v-col class="pl-4 pt-4" cols="12" sm="3">
                  <v-checkbox class="mt-0 pt-2" label="Multa em recebimentos" v-model="empresa.config_sistema.juros_multas.multa_recebimentos" color="primary" hide-details filled dense></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="5">
                  <h3>Emissor de Cupom Fiscal Eletrônico - NFC-e</h3>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <as-campo-valor label="Limite de Valor" ref="limite_valor" v-model="empresa.config_sistema.emissor_nfce.limite_valor" :decimais="2" @keypress.native.enter="$refs.limite_qtd.focus()" filled dense></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="4">
                  <as-campo-valor label="Limite de Quantidade" ref="limite_qtd" v-model="empresa.config_sistema.emissor_nfce.limite_quantidade" @keypress.native.enter="$refs.token_csc.focus()" :decimais="3" filled dense></as-campo-valor>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox class="mt-0 pt-2" label="Sistemática para atacadista de tecidos e artigos de aviamentos" v-model="empresa.config_sistema.emissor_nfce.sistematica_atacadista_tecidos" color="primary" hide-details filled dense></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field label="Token CSC Produção" ref="token_csc" v-model="empresa.config_sistema.emissor_nfce.token_csc" @keypress.native.enter="$refs.id_csc.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field label="ID CSC" ref="id_csc" v-model="empresa.config_sistema.emissor_nfce.id_csc" @keypress.native.enter="$refs.token_csc_homologacao.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Token CSC Homologação" ref="token_csc_homologacao" v-model="empresa.config_sistema.emissor_nfce.token_csc_homologacao" @keypress.native.enter="$refs.id_csc_homologacao.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field label="ID CSC" ref="id_csc_homologacao" v-model="empresa.config_sistema.emissor_nfce.id_csc_homologacao" @keypress.native.enter="$refs.serie.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field label="Série" ref="serie" v-model="empresa.config_sistema.emissor_nfce.serie" @keypress.native.enter="$refs.numero_serie_certificado_a1.focus()" filled dense></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="5">
                  <v-text-field label="Número de Série do Certif. A1" ref="numero_serie_certificado_a1" v-model="empresa.config_sistema.emissor_nfce.numero_serie_certificado_a1" @keypress.native.enter="$refs.pin.focus()" filled dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field label="PIN" ref="pin" v-model="empresa.config_sistema.emissor_nfce.pin" @keypress.native.enter="$refs.numeracao_inicial_producao.focus()" filled dense></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="5">
                  <h3>Numerações iniciais</h3>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Numeração Inicial Produção"
                    ref="numeracao_inicial_producao"
                    v-model="empresa.config_sistema.emissor_nfce.numeracao_inicial_producao"
                    @keypress.native.enter="$refs.numeracao_inicial_homologacao.focus()"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Numeração Inicial Homologação" ref="numeracao_inicial_homologacao" v-model="empresa.config_sistema.emissor_nfce.numeracao_inicial_homologacao" filled dense></v-text-field>
                </v-col>
                <v-col class="pl-4 pt-2" cols="12" sm="2">
                  <v-checkbox class="mt-0 pt-2" label="Ajustar" v-model="empresa.config_sistema.ajustar_numeracao" color="primary" hide-details filled dense></v-checkbox>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item eager>
              <v-row dense>
                <v-col class="pt-3" cols="12" sm="12">
                  <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" prepend-inner-icon="search" label="Busca"></v-text-field>
                </v-col>
              </v-row>
              <v-container grid-list-md fluid>
                <v-data-table :options.sync="options" height="350px" :search="busca" hide-default-footer :headers="headers" :items="configuracoes" :loading="carregando" mobile-breakpoint="0">
                  <template v-slot:item="props">
                    <tr>
                      <td>{{ props.item.descricao }}</td>
                      <td class="text-center">
                        <v-checkbox class="mt-0 pt-2" v-model="props.item.ativo" color="primary" hide-details @change="alterarStatus(props.item)"></v-checkbox>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar">Cancelar</v-btn>
          <v-btn color="primary" @click="salvar">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import * as regras from '@/util/validacaoFormulario';
import constantes from '@/util/constantes';

export default {
  name: 'DialogoConfiguracoes',
  data: () => ({
    dialog: false,
    rules: regras,
    constantes: constantes,
    headers: [
      { text: 'Descrição', value: 'descricao', width: '90%' },
      { text: 'Ativo', value: 'acoes', width: '10%' },
    ],
    options: {
      itemsPerPage: 50,
    },
    busca: '',
    abaAtiva: 0,
    exibirDialogoNovaConfiguracao: false,
    tributacoes: [
      {
        text: 'Escrituração de operações com incidencia exclusivamente no regime não cumulativo',
        value: '0',
      },
      {
        text: 'Escrituração de operações com incidencia exclusivamente no regime cumulativo',
        value: '1',
      },
      {
        text: 'Escrituração de operações com incidencia exclusivamente no regime cumulativo e não cumulativo',
        value: '2',
      },
    ],
    modosPlanoContas: [
      { text: 'Interligado ao fornecedor', value: '1' },
      { text: 'Execução direta no Contas a Pagar', value: '2' },
      {
        text: 'Interligado ao fornecedor com execução direta no Contas a Pagar',
        value: '3',
      },
    ],
  }),

  computed: {
    ...mapState('configuracao', {
      configuracoes: 'configuracoes',
      configuracao: 'configuracaoAtual',
      empresa: 'empresa',
      contador: 'contador',
      sistema: 'sistema',
      carregando: 'carregando',
    }),

    ...mapGetters('configuracao', {
      cidades: 'listaCidades',
      cidadesContador: 'listaCidadesContador',
    }),
  },

  methods: {
    ...mapActions('configuracao', {
      filtrarCidadesEstado: 'filtrarCidadesEstado',
      filtrarCidadesEstadoContador: 'filtrarCidadesEstadoContador',
    }),

    ...mapMutations('configuracao', {
      setCidade: 'setCidade',
      setCidadeContador: 'setCidadeContador',
    }),

    abrirDialogo() {
      this.abaAtiva = 0;
      this.dialog = true;
    },

    consultarCnpj(cnpj) {
      if (cnpj.length === 18 && !this.carregando) {
        let cnpjConsulta = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('/', '').replace('-', '');
        this.$store.dispatch('sefaz/consultarCadastro', cnpjConsulta);
      }
    },

    setEndereco(endereco) {
      this.$store.commit('configuracao/setEndereco', endereco);
      this.filtrarCidadesEstado(this.empresa.estado);
      this.$refs.numero.focus();
    },

    setEnderecoContador(endereco) {
      this.$store.commit('configuracao/setEnderecoContador', endereco);
      this.filtrarCidadesEstadoContador(this.contador.estado);
      this.$refs.contador_numero.focus();
    },

    async salvar() {
      await this.$store.dispatch('configuracao/salvarConfiguracoesEmpresa', this.empresa);
      this.fechar();
    },

    async fechar() {
      this.abaAtiva = 0;
      this.dialog = false;
    },

    async listar() {
      await this.$store.dispatch('configuracao/listar');
    },

    async alterarStatus(configuracao) {
      this.$store.commit('configuracao/SET_CONFIGURACAO_ATUAL', configuracao);
      await this.$store.dispatch('configuracao/alterarStatus', this.configuracao);
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.listar();
      }
    },
  },

  async created() {
    try {
      this.mesSelecionado = this.mes_aniversariantes;
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    }
  },
};
</script>
