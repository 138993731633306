<template>
  <div>
    <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Fabricante" append-icon="search" :value="value" @click:append="abrirDialogo" clearable readonly />

    <dialog-buscar-fabricante ref="dialogo" @onSelect="onSelect" @fechar="onSelect" />
  </div>
</template>

<script>
import DialogBuscarFabricante from '@/components/dialogs/DialogBuscarFabricante';

export default {
  name: 'BuscarFabricante',

  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    DialogBuscarFabricante,
  },

  methods: {
    abrirDialogo() {
      this.$refs.dialogo.abrir();
    },

    onSelect(row) {
      this.$emit('onSelect', row);
    },
  },
};
</script>

<style scoped></style>
