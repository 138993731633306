import axios from '@/util/axios';
import moment from 'moment';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import contasPagarApi from '@/services/contasPagarApi';

export default {
  namespaced: true,

  state: {
    contasPagar: [],
    contasPagarFornecedor: [],
    contaAtual: {
      participante: {},
      documento: '',
      data_cadastro: moment().format('YYYY-MM-DD'),
      data_vencimento: '',
      valor: 0,
      valor_restante: 0,
      valor_pago: 0,
      nota_fiscal: '',
      tipo: 'Duplicata',
      observacao: '',
    },
    fornecedorAtual: {},
    fornecedores: [],
    fornecedorAtual: {},
    constantes: constantes,
    tipo: '',
    modoEdicao: false,
    carregando: false,
    carregando_filtro: false,
    totalContasPagar: 0,
  },

  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    fornecedoresBusca(state) {
      let fornecedorFiltro = [];
      state.fornecedores.forEach((fornecedor) => {
        fornecedorFiltro.push({ text: fornecedor.razaoSocialNome, value: fornecedor._id });
      });
      return fornecedorFiltro;
    },
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await contasPagarApi.listar(options);
        commit('setContasPagar', response.contas);
        commit('SET_TOTAL_CONTAS_A_PAGAR', response.total);
      } catch (error) {
        commit('setContasPagar', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async listarPorTipo({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await contasPagarApi.listarPorTipo(options);
        commit('setContasPagar', response.contas);
        commit('SET_TOTAL_CONTAS_A_PAGAR', response.total);
      } catch (error) {
        commit('setContasPagar', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async carregarContasPagarFornecedor({ commit, rootState }, options) {
      try {
        commit('setCarregando', true);
        let response = await contasPagarApi.listarContasPagarFornecedor(state.fornecedorAtual, options);
        commit('setContasPagarFornecedor', response.contas);
        commit('SET_TOTAL_CONTAS_A_PAGAR', response.total);
      } catch (error) {
        commit('setContasPagarFornecedor', []);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async buscaFornecedores({ commit }, filtro) {
      try {
        commit('setCarregandoFiltro', true);
        let response = await contasPagarApi.buscaFornecedores(filtro);
        commit('setFornecedores', response);
      } catch (error) {
        commit('setFornecedores', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregandoFiltro', false);
      }
    },
    async filtrar({ commit, state }, options) {
      try {
        commit('setCarregandoFiltro', true);
        let response = await contasPagarApi.filtrar(options, state.fornecedorAtual);
        commit('setContasPagar', response.filtro);
        commit('SET_TOTAL_CONTAS_A_PAGAR', response.total);
      } catch (error) {
        commit('setContasPagar', []);
        commit('setMensagemErro', 'error ao carregar as Contas a Pagar do fornecedor', { root: true });
        throw error;
      } finally {
        commit('setCarregandoFiltro', false);
      }
    },
    async salvar({ commit, state, rootState }) {
      try {
        commit('setCarregando', true);
        let contaPagar = Object.assign({}, state.contaAtual);

        if (state.modoEdicao) {
          let response = await contasPagarApi.atualizar(contaPagar);
          if (response.status) {
            commit('setMensagemErro', response.motivo, { root: true });
            commit('setCarregando', false);
          } else {
            commit('atualizar', response);
            commit('setMensagemSucesso', 'Conta a Pagar atualizada com sucesso', { root: true });
          }
        } else {
          delete contaPagar._id;
          let response = await contasPagarApi.salvar(contaPagar);
          if (response.status) {
            commit('setMensagemErro', response.motivo, { root: true });
            commit('setCarregando', false);
          } else {
            commit('adicionar', response);
            commit('setMensagemSucesso', 'Conta a Pagar cadastrada com sucesso', { root: true });
          }
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async remover({ commit, rootState }, contaPagar) {
      try {
        commit('setCarregando', true);
        let remover = await contasPagarApi.excluir(contaPagar);
        commit('remover', remover);
        commit('setMensagemSucesso', 'Conta a Pagar excluída com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvarBaixaContaPagar({ commit, rootState, state }, dados) {
      let pagamentos = dados.pagamentos;
      let contasPagar = dados.contasPagar;
      let baixa = {
        contasPagar: contasPagar,
        formasPagamento: pagamentos,
      };

      try {
        commit('setCarregando', true);
        let response = await contasPagarApi.baixar(baixa);
        if (response.status) {
          commit('setMensagemErro', response.motivo, { root: true });
          commit('setCarregando', false);
        } else {
          response.forEach((contaPagar) => {
            commit('atualizar', contaPagar);
          });
          commit('setCarregando', false);
          commit('setMensagemSucesso', 'Conta a Pagar atualizada com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, valor = true) {
      state.carregando = valor;
    },
    setCarregandoFiltro(state, valor = true) {
      state.carregando_filtro = valor;
    },
    setContasPagar(state, contas) {
      state.contasPagar = contas;
      state.carregando = false;
    },
    setContasPagarFornecedor(state, contas) {
      state.contasPagarFornecedor = contas;
      state.carregando = false;
    },
    setParticipante(state, participante) {
      state.contaAtual.participante = participante;
    },
    SET_FORNECEDOR(state, fornecedor) {
      state.fornecedorAtual = fornecedor;
      state.carregando = false;
    },
    setFornecedores(state, fornecedores) {
      state.fornecedores = fornecedores;
      state.carregando = false;
    },
    SET_TOTAL_CONTAS_A_PAGAR(state, total) {
      state.totalContasPagar = total;
    },
    setTipo(state, tipo) {
      state.tipo = tipo;
    },
    remover(state, removido) {
      state.contasPagar = state.contasPagar.filter((contaPagar) => contaPagar._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.contaAtual = {
        participante: {},
        documento: '',
        data_cadastro: moment().format('YYYY-MM-DD'),
        data_vencimento: '',
        valor: 0,
        valor_restante: 0,
        valor_pago: 0,
        nota_fiscal: '',
        tipo: 'Duplicata',
        observacao: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      // editar.valor = formatador.formatarValor(editar.valor);
      // editar.data_vencimento = moment(editar.data_vencimento).format('YYYY-MM-DD');
      state.modoEdicao = true;
      state.contaAtual = editar;
      state.carregando = false;
    },
    adicionar(state, contaPagar) {
      state.contasPagar.push(contaPagar);
      state.carregando = false;
    },
    atualizar(state, atual) {
      let index = state.contasPagar.findIndex((conta) => {
        return conta._id == atual._id;
      });

      if (index > -1) {
        state.contasPagar.splice(index, 1, atual);
      }

      state.contaAtual = atual;
      state.carregando = false;
    },
    estadoInicial(state) {
      state.contaAtual = {
        participante: {},
        documento: '',
        data_cadastro: moment().format('YYYY-MM-DD'),
        data_vencimento: '',
        valor: 0,
        valor_restante: 0,
        valor_pago: 0,
        nota_fiscal: '',
        tipo: 'Duplicata',
        observacao: '',
      };
      state.carregando = false;
    },
    estadoInicialBaixa(state) {
      state.contasPagarFornecedor = [];
      state.fornecedorAtual = {};
      state.carregando = false;
    },
  },
};
