import constantes from '@/util/constantes';
import dispositivosApi from '@/services/dispositivosApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    dispositivos: [],
    dispositivoAtual: {
      chave: '',
      nome: '',
      navegador: '',
      versao_navegador: '',
      sistema_operacional: '',
      versao_sistema_operacional: '',
      observacao: '',
      data_geracao: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregar({ commit, rootState }) {
      try {
        commit('setCarregando', true);
        let response = await dispositivosApi.listar();
        commit('setDispositivos', response);
      } catch (error) {
        commit('setDispositivos', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    lerInformacoes({ commit }) {
      commit('setCarregando', true);

      let client = new ClientJS();
      let chave = client.getFingerprint();
      let navegador = client.getBrowser();
      let versao_navegador = client.getBrowserVersion();
      let sistema_operacional = client.getOS();
      let versao_sistema_operacional = client.getOSVersion();

      let dispositivo = {
        chave: chave,
        nome: sistema_operacional + ' ' + chave,
        navegador: navegador,
        versao_navegador: versao_navegador,
        sistema_operacional: sistema_operacional,
        versao_sistema_operacional: versao_sistema_operacional,
        observacao: '',
      };

      commit('setDispositivo', dispositivo);
    },

    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        if (state.modoEdicao) {
          let response = await dispositivosApi.atualizar(state.dispositivoAtual);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'Dispositivo atualizado com sucesso', { root: true });
        } else {
          let response = await dispositivosApi.salvar(state.dispositivoAtual);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'Dispositivo cadastrado com sucesso', { root: true });
        }
      } catch (error) {
        commit('setCarregando', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },

    async excluir({ commit }, dispositivo) {
      try {
        commit('setCarregando', true);
        await dispositivosApi.excluir(dispositivo);
        commit('excluir', dispositivo);
        commit('setMensagemSucesso', 'Dispositivo removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setDispositivos(state, dispositivos) {
      state.dispositivos = dispositivos;
      state.carregando = false;
    },
    setDispositivo(state, dispositivo) {
      state.dispositivoAtual = dispositivo;
      state.carregando = false;
    },
    excluir(state, removido) {
      state.dispositivos = state.dispositivos.filter((dispositivo) => dispositivo._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.dispositivoAtual = {
        chave: '',
        nome: '',
        navegador: '',
        versao_navegador: '',
        sistema_operacional: '',
        versao_sistema_operacional: '',
        observacao: '',
        data_geracao: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.dispositivoAtual = editar;
      state.carregando = false;
    },
    adicionar(state, dispostivo) {
      state.dispositivos.push(dispostivo);
      state.carregando = false;
    },
    atualizar(state, atual) {
      state.dispositivos.forEach((dispositivo) => {
        if (dispositivo._id === atual._id) {
          dispositivo = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state) {
      state.dispositivoAtual = {
        chave: '',
        nome: '',
        navegador: '',
        versao_navegador: '',
        sistema_operacional: '',
        versao_sistema_operacional: '',
        observacao: '',
        data_geracao: '',
      };
      state.carregando = false;
    },
  },
};
