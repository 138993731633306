import ListaGrupo from '@/views/grupos/ListaGrupo';
import FormularioGrupo from '@/views/grupos/FormularioGrupo';

export default [
  {
    path: '/cadastros/grupos',
    name: 'grupos',
    component: ListaGrupo,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_grupos' },
  },
  {
    path: '/cadastros/grupos/novo',
    name: 'formularioGrupoNovo',
    component: FormularioGrupo,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_grupos' },
  },
  {
    path: '/cadastros/grupos/editar',
    name: 'formularioGrupoEditar',
    component: FormularioGrupo,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_grupos' },
  },
];
