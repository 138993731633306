export default [
  {
    path: '/movimentacoes/pedidosclientes',
    name: 'PedidosClientesLista',
    component: () => import('@/views/pedidosclientes/ListaPedidosClientes'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_pedidosclientes' },
  },
  {
    path: '/movimentacoes/pedidosclientes/novo',
    name: 'PedidosClientesNovo',
    component: () => import('@/views/pedidosclientes/FormularioPedidosClientes'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_pedidosclientes' },
  },
  {
    path: '/movimentacoes/pedidosclientes/editar',
    name: 'PedidosClientesEditar',
    component: () => import(/* webpackChunkName: "CrudEditar" */ '@/views/pedidosclientes/FormularioPedidosClientes'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_pedidosclientes' },
  },
];
