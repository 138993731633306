import PDVLayout from '@/views/pdv/PDVLayout';

export default [
  {
    path: '/pdv',
    name: 'PDVLayout',
    component: PDVLayout,
    meta: { requiresAuth: true, nivel: 1, permissao: '' },
  },
];
