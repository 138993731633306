export default [
  {
    path: '/cadastros/participantes',
    name: 'participantes',
    component: () => import(/* webpackChunkName: "listaParticipantes" */ '@/views/participantes/ListaParticipantes'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'listarParticipantes' },
  },
  {
    path: '/cadastros/participantes/novo',
    name: 'formularioParticipanteNovo',
    component: () => import(/* webpackChunkName: "formularioParticipante" */ '@/views/participantes/FormularioParticipante'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionarParticipante' },
  },
  {
    path: '/cadastros/participantes/editar',
    name: 'formularioParticipanteEditar',
    component: () => import(/* webpackChunkName: "formularioParticipante" */ '@/views/participantes/FormularioParticipante'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'editarParticipante' },
  },
];
