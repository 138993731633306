import constantes from '@/util/constantes';
import moment from 'moment';
import aniversariantesApi from '@/services/aniversariantesApi';
import dashboardApi from '../../services/dashboardApi';

export default {
  namespaced: true,

  state: {
    produtos: [],
    quantidadeProdutos: '...',
    quantidadeParticipantes: '...',
    movimentosNotas: [],
    movimentosManifestos: [],
    constantes: constantes,
    carregando: false,
    carregandoAniversariantes: false,
    aniversariantes: [],
    totalAniversariantes: '...',
    mesAniversariantes: moment().month() + 1,
  },

  getters: {},

  actions: {
    async produtosVendidos({ commit }, periodo) {
      commit('setCarregando', true);

      try {
        const produtos = await dashboardApi.produtosVendidos(periodo);
        const produtosGrafico = [];
        produtos.forEach((produto) => {
          const novo = {
            descricao: produto.descricao,
            quantidade: produto.quantidade,
            total: produto.valor * produto.quantidade,
          };
          produtosGrafico.push(novo);
        });
        commit('setProdutos', produtosGrafico);
      } catch {
        commit('setProdutos', []);
      } finally {
        commit('setCarregando', false);
      }
    },

    async quantidadeNotas({ commit }, periodo) {
      try {
        commit('setCarregando', true);
        const movimentos = await dashboardApi.quantidadeNotas(periodo);
        commit('setMovimentoNotas', movimentos);
        commit('setCarregando', false);
      } catch {
        commit('setMovimentoNotas', []);
      } finally {
        commit('setCarregando', false);
      }
    },

    async quantidadeManifestos({ commit }, periodo) {
      try {
        commit('setCarregando', true);
        const movimentos = await dashboardApi.quantidadeManifestos(periodo);
        commit('setMovimentoManifestos', movimentos);
        commit('setCarregando', false);
      } catch {
        commit('setMovimentoManifestos', []);
      } finally {
        commit('setCarregando', false);
      }
    },

    async consultarQuantidadeProdutos({ commit }) {
      try {
        commit('setCarregando', true);
        const quantidade = await dashboardApi.consultarQuantidadeProdutos();
        commit('setQuantidadeProdutos', quantidade);
        commit('setCarregando', false);
      } catch {
        commit('setQuantidadeProdutos', []);
      } finally {
        commit('setCarregando', false);
      }
    },

    async consultarQuantidadeParticipantes({ commit }) {
      try {
        commit('setCarregando', true);
        const quantidade = await dashboardApi.consultarQuantidadeProdutos();
        commit('setQuantidadeParticipantes', quantidade);
        commit('setCarregando', false);
      } catch {
        commit('setQuantidadeParticipantes', []);
      } finally {
        commit('setCarregando', false);
      }
    },

    async aniversariantes({ commit, state }, options) {
      try {
        commit('setCarregandoAniversariantes');
        const response = await aniversariantesApi.listar(options, state.mesAniversariantes);
        commit('setAniversariantes', response.aniversariantes);
        commit('setTotalAniversariantes', response.total);
      } catch {
        commit('setAniversariantes', []);
      } finally {
        commit('setCarregandoAniversariantes', false);
      }
    },
  },

  mutations: {
    setProdutos(state, produtos) {
      state.produtos = produtos;
    },
    setQuantidadeProdutos(state, quantidade) {
      state.quantidadeProdutos = quantidade.quantidade;
    },
    setQuantidadeParticipantes(state, quantidade) {
      state.quantidadeParticipantes = quantidade.quantidade;
    },
    setMovimentoNotas(state, movimentos) {
      state.movimentosNotas = movimentos;
    },
    setMovimentoManifestos(state, movimentos) {
      state.movimentosManifestos = movimentos;
    },
    setCarregando(state, valor = true) {
      state.carregando = valor;
    },

    setCarregandoAniversariantes(state, valor = true) {
      state.carregandoAniversariantes = valor;
    },
    setAniversariantes(state, aniversariantes) {
      state.aniversariantes = aniversariantes;
    },
    setTotalAniversariantes(state, total) {
      state.totalAniversariantes = total;
    },
    setMesAniversariantes(state, mes) {
      state.mesAniversariantes = mes;
    },
  },
};
