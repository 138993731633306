const Menu = [
  { cabecalho: 'Início', nivel: 1 },
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1,
    permissao: true,
  },
  { cabecalho: 'Fiscal', nivel: 1, permissao: 'exibir_fiscal' },
  {
    titulo: 'Notas Fiscais',
    grupo: 'Fiscal',
    nome: 'Nota',
    icone: 'chrome_reader_mode',
    path: '/notas',
    nivel: 1,
    permissao: 'acessar_notasfiscais',
  },
  {
    titulo: 'Conhecimento de Transporte',
    grupo: 'Fiscal',
    nome: 'Manifestos',
    icone: 'cached',
    path: '/conhecimento-transporte',
    nivel: 1,
    permissao: 'conhecimentoTransporte',
  },
];

export default Menu;
