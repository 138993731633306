var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-widget',{attrs:{"title":"Contas a Pagar"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"flat":"","single-line":"","hide-details":"","dense":"","clearable":"","solo":"","prepend-inner-icon":"search","label":"Busca","background-color":"grey lighten-3"},on:{"click:clear":_vm.limparBusca,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.listar.apply(null, arguments)}},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.filtro_ativo),expression:"!filtro_ativo"}],attrs:{"flat":"","single-line":"","hide-details":"","dense":"","solo":"","items":_vm.itens_exibir,"background-color":"grey lighten-3"},on:{"change":_vm.listarPorTipo},model:{value:(_vm.filtro.exibir),callback:function ($$v) {_vm.$set(_vm.filtro, "exibir", $$v)},expression:"filtro.exibir"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.filtro_ativo),expression:"filtro_ativo"}],staticClass:"mr-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.limparFiltro}},'v-icon',attrs,false),on),[_vm._v(" mdi-notification-clear-all ")])]}}])},[_c('span',[_vm._v("Limpar filtro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"depressed":"","fab":"","color":"primary","loading":_vm.carregando},on:{"click":_vm.abrirDialogoFiltro}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtro")])]),(!_vm.isSm)?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.novo}},[_vm._v(" NOVA ")]):_c('v-btn',{attrs:{"depressed":"","fab":"","small":"","color":"primary"},on:{"click":_vm.novo}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)]},proxy:true},{key:"widget-content",fn:function(){return [_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.total,"headers":_vm.headers,"items":_vm.contas,"search":_vm.busca,"loading":_vm.carregando,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.documento))]),_c('td',{staticClass:"text-center"},[(props.item.participante)?_c('span',[_vm._v(_vm._s(_vm._f("formatarCnpj")(props.item.participante.cnpjCpf)))]):_vm._e(),_c('span',[_vm._v(" ")])]),_c('td',[(props.item.participante)?_c('span',[_vm._v(_vm._s(props.item.participante.razaoSocialNome))]):_vm._e(),_c('span',[_vm._v(" ")])]),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":_vm.verificarVencimento(props.item.data_vencimento)}},[_vm._v(_vm._s(_vm._f("formatarData")(props.item.data_vencimento)))])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(props.item.valor)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(props.item.valor_pago)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(props.item.valor_restante)))]),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"top":"","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{key:props.item._id + 10,on:{"click":function($event){return _vm.baixar(props.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("monetization_on")])],1),_c('v-list-item-title',[_vm._v("Pagar")])],1),_c('v-list-item',{key:props.item._id + 20,on:{"click":function($event){return _vm.editar(props.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{key:props.item._id + 40,on:{"click":function($event){return _vm.remover(props.item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Remover")])],1)],1)],1)],1)])]}}])})]},proxy:true}])}),_c('dialog-confirmacao',{ref:"dialogConfirmacao"})],1),_c('dialogo-filtro',{ref:"dialogoFiltro",on:{"filtrar":_vm.filtrar,"limpar":_vm.limparFiltro}}),_c('dialog-pagamento',{ref:"dialogoPagamento"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }