<template>
  <v-container fluid>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaReceber" lazy-validation>
      <v-container fluid>
        <v-widget title="Conta a Receber">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-participante persistent-placeholder outlined label="Cliente" v-model="cnpjCpfParticipante" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]"></buscar-participante>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field persistent-placeholder outlined label="Nome" v-model="contaReceber.participante.razaoSocialNome" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field persistent-placeholder outlined label="Documento" v-model="contaReceber.documento" :rules="[rules.obrigatorio]" ref="documento" @keypress.native.enter="$refs.dataCadastro.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data persistent-placeholder outlined label="Cadastro" v-model="contaReceber.data_cadastro" :rules="[rules.obrigatorio]" ref="dataCadastro" @keypress.native.enter="$refs.dataVencimento.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data persistent-placeholder outlined label="Vencimento" v-model="contaReceber.data_vencimento" :rules="[rules.obrigatorio]" ref="dataVencimento" @keypress.native.enter="$refs.valor.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor persistent-placeholder outlined label="Valor" v-model="contaReceber.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="salvar"></as-campo-valor>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-container fluid>
              <v-row dense>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
                  <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioContaReceber',
  data: () => ({
    loading: false,
    valid: false,
    constantes: constantes,
    cnpjCpfParticipante: '',
    rules: { ...regras },
  }),

  computed: {
    ...mapState('contaReceber', {
      contaReceber: 'contaAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('contaReceber', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formContaReceber.validate()) {
        try {
          if (this.contaReceber._id) {
            await this.$store.dispatch('contaReceber/atualizar');
          } else {
            await this.$store.dispatch('contaReceber/salvar');
          }
          this.$router.push({ name: 'contasReceber' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('contaReceber/ESTADO_INICIAL');
      this.$router.push({ name: 'contasReceber' });
    },

    adicionarParticipante(participante) {
      this.cnpjCpfParticipante = formatador.formatarCnpj(participante.cnpjCpf);
      this.$store.commit('contaReceber/SET_PARTICIPANTE', participante);
      setTimeout(() => {
        this.$refs.documento.focus();
      }, 500);
    },
  },

  async created() {
    try {
      this.loading = true;

      const conta = this.$store.state.contaReceber.contaAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!conta._id || conta._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
        this.$router.push({ name: 'contasReceber' });
      }

      if (!estaEditando) {
        this.$store.commit('contaReceber/ESTADO_INICIAL');
      } else {
        this.cnpjCpfParticipante = conta.participante.cnpjCpf;
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
