var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"1024px","persistent":"","scrollable":"","eager":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.fechar.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_vm._v("Recebimentos")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('buscar-participante',{ref:"busca_participante",attrs:{"filled":true,"label":'Cliente'},on:{"participanteSelecionado":_vm.clienteSelecionado},model:{value:(_vm.cliente.cnpjCpf),callback:function ($$v) {_vm.$set(_vm.cliente, "cnpjCpf", $$v)},expression:"cliente.cnpjCpf"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{ref:"cliente_nome",attrs:{"filled":"","label":"Nome Cliente","placeholder":" ","readonly":"","hide-details":""},model:{value:(_vm.cliente.razaoSocialNome),callback:function ($$v) {_vm.$set(_vm.cliente, "razaoSocialNome", $$v)},expression:"cliente.razaoSocialNome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"filled":"","label":"Exibir","items":_vm.itens_exibir,"hide-details":""},on:{"change":_vm.filtrarPorTipo},model:{value:(_vm.filtro.exibir),callback:function ($$v) {_vm.$set(_vm.filtro, "exibir", $$v)},expression:"filtro.exibir"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contas,"disable-sort":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"380px","item-key":"documento","single-select":"","loading":_vm.carregando},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.documento))]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.participante.razaoSocialNome))])])],1),_c('td',[_vm._v(_vm._s(_vm._f("formatarData")(item.data_cadastro)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatarData")(item.data_vencimento)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatarData")(item.data_pagamento)))]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm._f("formatarValor")(item.valor)))])]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm._f("formatarValor")(item.valor_pago)))])]),_c('td',{staticClass:"text-right"},[_c('b',[_vm._v(_vm._s(_vm._f("formatarValor")(item.valor_restante)))])]),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"top":"","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(item.valor_restante > 0)?_c('v-list-item',{key:item._id + 10,on:{"click":function($event){return _vm.baixar(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("monetization_on")])],1),_c('v-list-item-title',[_vm._v("Baixar")])],1):_vm._e(),(item.valor_restante == 0 || item.valor_restante > 0)?_c('v-list-item',{key:item._id + 20,on:{"click":function($event){return _vm.visualizar(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("Visualizar")])],1):_vm._e(),(item.valor_pago == 0)?_c('v-list-item',{key:item._id + 30,on:{"click":function($event){return _vm.editar(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Editar")])],1):_vm._e(),(item.valor_restante > 0)?_c('v-list-item',{key:item._id + 40,on:{"click":function($event){return _vm.excluir(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Remover")])],1):_vm._e()],1)],1)],1)])]}}]),model:{value:(_vm.contas_selecionadas),callback:function ($$v) {_vm.contas_selecionadas=$$v},expression:"contas_selecionadas"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.nova}},[_vm._v("Nova")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fechar}},[_vm._v("Imprimir Extrato")]),(_vm.total_contas_selecionadas > 0)?_c('span',{staticClass:"ml-4"},[_vm._v("Selecionado: "+_vm._s(_vm.total_contas_selecionadas)+" ("+_vm._s(_vm._f("formatarValor")(_vm.soma_contas_selecionadas))+")")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.fechar}},[_vm._v("Fechar")])],1)],1)],1),_c('dialogo-liquidacao',{ref:"dialogoLiquidacao"}),_c('dialogo-form-conta-receber',{ref:"dialogoFormContaReceber"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }