<template>
  <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Vendas</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-card-text class="pa-3 pt-0">
        <v-data-table :headers="headers" :items="vendas" disable-sort hide-default-footer disable-pagination fixed-header height="420px">
          <template v-slot:[`item.valor`]="{ item }">
            {{ item.valor | formatarValor }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status == 'Autorizado'" small color="primary">{{ item.status }}</v-chip>
            <v-chip v-if="item.status == 'Cancelado'" small dark color="red">{{ item.status }}</v-chip>
          </template>

          <template v-slot:[`item.opcoes`]="{}">
            <v-menu left transition="slide-x-transition">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">more_vert</v-icon>
              </template>
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Visualizar</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Imprimir</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cancelar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text class="pa-3">
        <v-divider />
        <v-row dense class="pt-3">
          <v-col cols="12" sm="3">
            <campo-data v-model="data" dense ref="data" label="Data" placeholder=" " hide-details outlined />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select v-model="terminal" dense :items="terminais" label="Terminal" hide-details outlined />
          </v-col>
          <v-col cols="12" sm="3">
            <campo-valor :prefix="String(vendas_canceladas_count)" :value="vendas_canceladas_total" dense label="Canceladas" placeholder=" " disabled hide-details outlined fixarDecimais />
          </v-col>
          <v-col cols="12" sm="3">
            <campo-valor :prefix="String(vendas_count)" :value="vendas_total" dense label="Total" placeholder=" " disabled hide-details outlined fixarDecimais />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { hoje, gerarTerminais } from '@/util/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      homologacao: false,
      data: null,
      terminal: '001',
      terminais: gerarTerminais(),
      // vendas: [],
      headers: [
        { text: 'Número', value: 'numero' },
        { text: 'Data', value: 'data' },
        { text: 'CPF / CNPJ', value: 'cpfcnpj' },
        { text: 'Nome / Razão Social', value: 'razao' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Status', value: 'status' },
        { text: 'Opções', value: 'opcoes', width: '50px', align: 'center' },
      ],
    };
  },

  computed: {
    ...mapState({
      vendas: (state) => state.pdv.vendas,
    }),

    ...mapGetters('pdv', ['vendas_autorizadas', 'vendas_canceladas', 'vendas_total', 'vendas_count', 'valor_total', 'vendas_canceladas_total', 'vendas_canceladas_count', 'valor_canceladas_total']),
  },

  methods: {
    abrir() {
      this.dialog = true;
      this.data = hoje('us');
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },
  },
};
</script>

<style lang="scss" scoped></style>
