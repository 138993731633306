import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/mvas?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(mva) {
  return axios.post(`/mvas`, mva).then((response) => response.data);
}

function atualizar(mva) {
  return axios.put(`/mvas/${mva._id}`, mva).then((response) => response.data);
}

function excluir(mva) {
  return axios.delete(`/mvas/${mva._id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
