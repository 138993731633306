<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-form ref="formFechamento" lazy-validation>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Fechamento</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4" style="min-height: 499px" height="100%">
          <v-tabs v-model="abaAtiva">
            <v-tab :key="1">OS</v-tab>
            <v-tab :key="2">Solução</v-tab>
            <v-tab :key="3">Faturamento</v-tab>
            <v-spacer></v-spacer>
          </v-tabs>

          <v-tabs-items v-model="abaAtiva">
            <v-divider></v-divider>
            <v-tab-item :key="1 + 9999" eager>
              <v-container grid-list-md fluid>
                <v-row dense>
                  <v-col cols="4" xs="4" sm="4">
                    <v-text-field label="Número" ref="numero" persistent-placeholder outlined v-model="ordem_servico.numero"></v-text-field>
                  </v-col>
                  <v-col cols="8" xs="8" sm="8">
                    <v-text-field label="Cliente" ref="cliente" persistent-placeholder outlined v-model="ordem_servico.participante.razaoSocialNome"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field label="Técnico" ref="tecnico" :rules="[rules.obrigatorio]" persistent-placeholder outlined v-model="ordem_servico.tecnico"></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3">
                    <v-text-field label="Placa" ref="placa" v-mask="'AAA-#A##'" persistent-placeholder outlined v-model="ordem_servico.placa"></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3">
                    <as-campo-valor label="Valor" ref="valor" persistent-placeholder outlined v-model="ordem_servico.valor" :decimais="2" readonly></as-campo-valor>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1 + 100" eager>
              <v-container grid-list-md fluid>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea label="Solução" ref="solucao" persistent-placeholder outlined v-model="fechamento.solucao" :rules="[rules.obrigatorio]"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="2 + 100" eager>
              <v-container grid-list-md fluid>
                <v-form ref="formDocumentos" lazy-validation>
                  <v-row dense>
                    <v-col cols="12" xs="12" sm="3">
                      <campo-data label="Data Fechamento" ref="data_fechamento" persistent-placeholder outlined v-model="fechamento.data_fechamento" :rules="[rules.obrigatorio]" @keypress.native.enter="$refs.formapagamento.focus()"></campo-data>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6">
                      <v-select label="Forma de Pagamento" ref="formapagamento" :items="['À Vista', 'A Prazo']" persistent-placeholder outlined v-model="fechamento.faturamento.formapagamento" @change="trocarFormaPagamento"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3">
                      <as-campo-valor label="Valor Faturamento" ref="valor_faturamento" persistent-placeholder outlined v-model="fechamento.faturamento.valorTotal" :decimais="2" readonly></as-campo-valor>
                    </v-col>
                  </v-row>
                  <v-row v-show="fechamento.faturamento.formapagamento == 'A Prazo'" dense>
                    <v-col cols="10" xs="10" sm="11">
                      <v-row>
                        <v-col cols="6" xs="12" sm="4">
                          <v-text-field label="Documento" ref="documento" persistent-placeholder outlined v-model="fechamento.faturamento.documento.documento" :rules="[rules.obrigatorio]" @keypress.native.enter="$refs.data_documento.focus()"></v-text-field>
                        </v-col>
                        <v-col cols="6" xs="12" sm="4">
                          <campo-data label="Data" ref="data_documento" persistent-placeholder outlined v-model="fechamento.faturamento.documento.data_documento" :rules="[rules.obrigatorio]" @keypress.native.enter="$refs.valor_documento.focus()"></campo-data>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4">
                          <as-campo-valor label="Valor" ref="valor_documento" persistent-placeholder outlined v-model="fechamento.faturamento.documento.valor_documento" :decimais="2" :rules="[rules.obrigatorio]" @keypress.native.enter="adicionarDocumentoAPrazo"></as-campo-valor>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="" cols="2" xs="2" sm="1">
                      <v-row class="justify-center align-center">
                        <v-col float-sm-right class="text-center pt-5 pl-0" cols="3" xs="6" sm="2">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="primary" dark class="float-sm-center mt-3" v-bind="attrs" v-on="on" :disabled="carregando" @click="adicionarDocumentoAPrazo"> mdi-plus </v-icon>
                            </template>
                            <span>Adicionar</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pa-0">
                      <v-data-table
                        fixed-header
                        height="220px"
                        v-show="fechamento.faturamento.documentos.length > 0 && fechamento.faturamento.formapagamento == 'A Prazo'"
                        :headers="headersFaturamento"
                        hide-default-footer
                        :server-items-length="fechamento.faturamento.documentos.length"
                        :items="fechamento.faturamento.documentos"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td class="font-weight-bold">{{ item.documento }}</td>
                            <td class="text-center">{{ item.data_documento | formatarData }}</td>
                            <td class="text-right font-weight-bold">{{ item.valor_documento | formatarValor }}</td>
                            <td class="text-center">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="primary" dark v-bind="attrs" v-on="on" @click="excluirDocumento(item._id)"> mdi-delete </v-icon>
                                </template>
                                <span>Excluir</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-btn depressed class="mr-2" @click="fechar" :disabled="carregando">Cancelar</v-btn>
                <v-btn depressed color="primary" :disabled="carregando" :loading="carregando" @click="confirmar">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '@/util/constantes';
  import formatador from '@/util/formatador';
  import moment from 'moment';

  export default {
    name: 'DialogoFechamento',

    props: ['dialog'],

    data() {
      return {
        valid: false,
        rules: { ...regras },
        constantes: constantes,
        headersFaturamento: [
          { text: 'Documento', value: 'documento', sortable: false, width: '45%' },
          { text: 'Data', value: 'data_documento', sortable: false, width: '25%' },
          { text: 'Valor', value: 'valor_documento', sortable: false, width: '25%', align: 'right' },
          { text: 'Ações', value: 'acoes', align: 'center', sortable: false, width: '5%' },
        ],
        abaAtiva: 0,
        fechamento: {
          solucao: '',
          data_fechamento: '',
          faturamento: {
            formapagamento: 'À Vista',
            parcelas: 0,
            documento: {
              documento: '',
              data_documento: '',
              valor_documento: '0,00',
            },
            documentos: [],
            contador: 0,
            valorTotal: '0,00',
          },
        },
      };
    },

    computed: {
      ...mapState('ordensServicos', {
        ordem_servico: 'ordemServicoAtual',
        carregando: 'carregando',
      }),
    },

    methods: {
      fechar() {
        this.$emit('fechar');
        this.limpar();
      },
      trocarFormaPagamento(formapagamento) {
        if (formapagamento == 'À Vista') {
          this.limpar();
        }
        this.fechamento.faturamento.documentos = [];
      },
      limpar() {
        this.fechamento.faturamento = {
          formapagamento: 'À Vista',
          parcelas: 0,
          documento: {
            documento: 'OS-1',
            data_documento: moment().add(30, 'days').format('YYYY-MM-DD'),
            valor_documento: '0,00',
          },
          documentos: [],
          contador: 0,
          valorTotal: '0,00',
        };
      },
      adicionarDocumentoAPrazo() {
        if (this.$refs.formDocumentos.validate()) {
          let contador = this.fechamento.faturamento.contador;
          if (contador == 0) {
            contador = 1;
          } else {
            contador = 1 + contador;
          }
          this.$refs.documento.focus();
          this.fechamento.faturamento.documentos.push(this.fechamento.faturamento.documento);
          this.fechamento.faturamento.documento = {
            documento: `OS-${contador == 0 ? 1 : 1 + contador}`,
            data_documento: moment(this.fechamento.faturamento.documento.data_documento).add(30, 'days').format('YYYY-MM-DD'),
            valor_documento: '0,00',
          };

          setTimeout(() => {
            this.$refs.valor_documento.focus();
            this.fechamento.faturamento.contador = this.fechamento.faturamento.contador + 1;
          }, 500);
        }
      },
      excluirDocumento(id) {
        let index = this.fechamento.faturamento.documentos.findIndex((documento) => documento._id == id);
        if (index > -1) {
          this.fechamento.faturamento.documentos.splice(index, 1);
        }
        this.fechamento.faturamento.contador = this.fechamento.faturamento.contador - 1;
        let contador = this.fechamento.faturamento.contador;
        let loop = this.fechamento.faturamento.documentos.map((documento, index) => {
          documento.documento = `OS-${index == 0 ? 1 : 1 + index}`;
          return documento;
        });
        this.fechamento.faturamento.documentos = loop;
        this.fechamento.faturamento.documento.documento = `OS-${contador == 0 ? 1 : 1 + contador}`;
      },
      confirmar() {
        if (this.$refs.formFechamento.validate()) {
          this.$emit('finalizar', this.fechamento);
          this.limpar();
        } else {
          if (!this.fechamento.solucao) {
            this.abaAtiva = 1;
          } else {
            this.abaAtiva = 2;
          }
        }
      },
    },

    watch: {
      abaAtiva: function (val) {
        switch (val) {
          case 0:
            setTimeout(() => {
              this.$refs.tecnico.focus();
            }, 50);
            break;
          case 1:
            setTimeout(() => {
              this.$refs.solucao.focus();
            }, 50);
            break;
          case 2:
            setTimeout(() => {
              this.$refs.data_fechamento.focus();
            }, 50);
            break;
        }
      },
      'fechamento.faturamento.documentos': function (val) {
        if (val.length > 0 && this.fechamento.faturamento.formapagamento == 'A Prazo') {
          let valor_total = 0;
          if (this.fechamento.faturamento.formapagamento == 'A Prazo') {
            valor_total = this.fechamento.faturamento.documentos.reduce((soma, documento) => {
              return soma + formatador.formatarValorUS(documento.valor_documento);
            }, 0);
            this.fechamento.faturamento.valorTotal = valor_total;
          } else {
            this.fechamento.faturamento.valorTotal = this.ordem_servico.valor;
          }
        } else if (this.fechamento.faturamento.formapagamento == 'A Prazo') {
          this.fechamento.faturamento.valorTotal = '0,00';
        } else {
          this.fechamento.faturamento.valorTotal = this.ordem_servico.valor;
        }
      },
      dialog(val) {
        if (val) {
          this.abaAtiva = 0;
          if (this.ordem_servico.status == 'Concluída') {
            this.fechamento.data_fechamento = this.ordem_servico.fechamento;
            this.fechamento.solucao = this.ordem_servico.solucao;
            this.fechamento.faturamento.formapagamento = this.ordem_servico.faturamento.formapagamento;
            this.fechamento.faturamento.documentos = this.ordem_servico.faturamento.documentos;
            this.fechamento.faturamento.parcelas = this.ordem_servico.faturamento.parcelas;
            let contador = this.ordem_servico.faturamento.documentos.length;
            this.fechamento.faturamento.documento = {
              documento: `OS-${contador == 0 ? 1 : 1 + contador}`,
              data_documento: moment().add(30, 'days').format('YYYY-MM-DD'),
              valor_documento: '0,00',
            };
          } else {
            this.fechamento.data_fechamento = this.ordem_servico.agendamento;
            this.fechamento.faturamento.documento.data_documento = moment().add(30, 'days').format('YYYY-MM-DD');
            this.fechamento.faturamento.documento.documento = 'OS-1';
          }
          setTimeout(() => {
            this.$refs.solucao.focus();
          }, 1000);
        }
      },
    },
  };
</script>
