import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/tamanhos?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(tamanho) {
  return axios.post(`/tamanhos`, tamanho).then((response) => response.data);
}

function atualizar(tamanho) {
  return axios.put(`/tamanhos/${tamanho._id}`, tamanho).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/tamanhos/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
