<template>
  <v-container fluid>
    <v-widget title="Clientes / Fornecedores">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca" />
            </v-col>
            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
              <v-btn v-else depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-if="float" v-slot:widget-header-action>
        <v-icon style="cursor: pointer" @click="$emit('fechar')">close</v-icon>
      </template>

      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :server-items-length="participantes.length" :headers="headers" :items="participantes" :loading="carregando" mobile-breakpoint="0" :height="float ? '60vh' : '100%'">
          <template v-slot:item="props">
            <tr>
              <td class="text-truncate">{{ props.item.razaoSocialNome }}</td>
              <td class="text-center" v-if="props.item.ativo"><v-chip class="ma-2" label dark small color="green">Ativo</v-chip></td>
              <td class="text-center" v-else><v-chip class="ma-2" label dark small color="red">Inativo</v-chip></td>
              <td class="text-no-wrap">{{ props.item.cnpjCpf }}</td>
              <td>{{ props.item.cidade }}/{{ props.item.estado }}</td>
              <td class="text-center">
                <v-menu bottom left transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id" @click="editar(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item :key="props.item._id + 15" @click="mudarStatus(props.item)" data-cy="mudarStatus">
                      <v-list-item-icon v-if="!props.item.ativo"><v-icon>mdi-check</v-icon></v-list-item-icon>
                      <v-list-item-icon v-else><v-icon>mdi-cancel</v-icon></v-list-item-icon>
                      <v-list-item-title v-if="!props.item.ativo">Ativar</v-list-item-title>
                      <v-list-item-title v-else>Inativar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>

    <v-dialog v-if="dialog" v-model="dialog" max-width="1024px" @keydown.esc="dialog = false" persistent scrollable eager>
      <formulario-participante @fechar="dialog = false" :float="float" />
    </v-dialog>

    <dialog-confirmacao ref="dialogConfirmacao" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';
import FormularioParticipante from './FormularioParticipante.vue';

export default {
  name: 'ListaParticipantes',

  props: {
    float: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogConfirmacao,
    FormularioParticipante,
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Nome / Razão Social', value: 'razaoSocialNome', sortable: true },
        { text: 'Status', value: 'ativo', sortable: false, align: 'center' },
        { text: 'CPF / CNPJ', value: 'cnpjCpf', sortable: false },
        { text: 'Local', value: 'cidade', sortable: false },
        { text: '', value: 'name', align: 'center', width: '32px', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('participante', {
      participantes: 'participantes',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    computedOptions() {
      return { itemsPerPage: this.options.itemsPerPage, page: this.options.page, busca: (this.busca && this.busca.trim()) || null };
    },
  },

  methods: {
    listar() {
      this.$store.dispatch('participante/listar', this.computedOptions);
    },

    editar(participante) {
      this.$store.commit('participante/setState', { participante });
      if (this.float) {
        this.dialog = true;
      } else {
        this.$router.push({ name: 'formularioParticipanteEditar' });
      }
    },

    novo() {
      this.$store.dispatch('participante/novo');
      if (this.float) {
        this.dialog = true;
      } else {
        this.$router.push({ name: 'formularioParticipanteNovo' });
      }
    },

    limparBusca() {
      this.busca = null;
      this.listar();
    },

    filtrar(event) {
      this.listar();
      this.$nextTick(() => event.target.select());
    },

    mudarStatus(participante) {
      const texto = participante.ativo ? 'Tem certeza que deseja inativar este Cliente / Fornecedor?' : 'Tem certeza que deseja ativar este Cliente / Fornecedor?';

      this.$refs.dialogConfirmacao.open('Ativar / Inativar', texto).then((confirmado) => {
        if (confirmado) {
          this.$store.commit('participante/setState', { participante: { ...participante, ativo: !participante.ativo } });
          this.$store.dispatch('participante/editar', this.computedOptions);
        }
      });
    },
  },
};
</script>
