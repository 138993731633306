<template>
  <div>
    <v-dialog v-model="dialogo" max-width="1000px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Detalhamento Produto</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab x-small color="primary" class="lighten-1" @click="mudarProdutoTela('primeiro')" :disabled="produtoAtual.indice_original == 0">
            <v-icon dark>mdi-arrow-collapse-left</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('anterior')" :disabled="produtoAtual.indice_original == 0">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('proximo')" :disabled="produtoAtual.indice_original == compra.itens.length - 1">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('ultimo')" :disabled="produtoAtual.indice_original == compra.itens.length - 1">
            <v-icon>mdi-arrow-collapse-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-banner single-line v-show="produtoAtual.produto == undefined && !produtoAtual.informativo">
          <v-icon slot="icon" color="error" size="36">mdi-alert</v-icon>
          <b class="red--text">Produto não cadastrado</b>
        </v-banner>
        <v-banner single-line v-show="produtoAtual.informativo">
          <v-icon slot="icon" color="blue" size="36">mdi-information</v-icon>
          <b class="blue--text">Produto informativo</b>
        </v-banner>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="Código" persistent-placeholder outlined v-model="produtoAtual.codigo" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Descrição" persistent-placeholder outlined v-model="produtoAtual.descricao" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="Código Fornecedor" persistent-placeholder outlined v-model="produtoAtual.codigo_entrada" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="Unidade" persistent-placeholder outlined v-model="produtoAtual.unidade" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="NCM" persistent-placeholder outlined v-model="produtoAtual.ncm" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="Fator Conversão" persistent-placeholder outlined :value="produtoAtual.fator_conversao.entrada + '/' + produtoAtual.fator_conversao.saida" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-btn color="primary" :disabled="produtoAtual.produto != undefined || produtoAtual.informativo" block large @click="abrirDialogoCadastroProduto">Cadastrar Produto</v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="produtoAtual.quantidade" :decimais="2" ref="quantidade" @input="calcularSubtotalProduto" @keydown.native.enter="$refs.valor_custo.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" persistent-placeholder outlined v-model="produtoAtual.valor_custo" :decimais="2" ref="valor_custo" @input="calcularSubtotalProduto" @keydown.native.enter="$refs.descontos.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Subtotal" persistent-placeholder outlined v-model="produtoAtual.subtotal" :decimais="2" ref="subtotal" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Descontos" persistent-placeholder outlined :decimais="2" ref="descontos" @keydown.native.enter="$refs.seguro.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Seguro" persistent-placeholder outlined :decimais="2" ref="seguro" @keydown.native.enter="$refs.frete.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Frete" persistent-placeholder outlined :decimais="2" ref="frete" @keydown.native.enter="$refs.base_calculo_icms.focus()"></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor
                label="Base Cálculo ICMS"
                persistent-placeholder outlined
                v-model="produtoAtual.base_calculo_icms"
                :decimais="2"
                ref="base_calculo_icms"
                @keydown.native.enter="$refs.aliquota_icms.focus()"
                @blur="recalcularImpostos"
              ></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Alíquota ICMS" persistent-placeholder outlined v-model="produtoAtual.aliquota_icms" :decimais="2" ref="aliquota_icms" @keydown.native.enter="$refs.valor_icms.focus()" @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor ICMS" persistent-placeholder outlined v-model="produtoAtual.valor_icms" :decimais="2" ref="valor_icms" @keydown.native.enter="$refs.base_calculo_ii.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo II" persistent-placeholder outlined v-model="produtoAtual.base_calculo_ii" :decimais="2" ref="base_calculo_ii" @keydown.native.enter="$refs.valor_ii.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor II" persistent-placeholder outlined v-model="produtoAtual.valor_ii" :decimais="2" ref="valor_ii" @keydown.native.enter="$refs.aduaneira.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Aduaneira" persistent-placeholder outlined v-model="produtoAtual.aduaneira" :decimais="2" ref="aduaneira" @keydown.native.enter="$refs.base_calculo_icms_st.focus()"></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor
                label="Base Cálculo ICMS ST"
                persistent-placeholder outlined
                v-model="produtoAtual.base_calculo_icms_st"
                :decimais="2"
                ref="base_calculo_icms_st"
                @keydown.native.enter="$refs.aliquota_icms_st.focus()"
                @blur="recalcularImpostos"
              ></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Alíquota ICMS ST" persistent-placeholder outlined v-model="produtoAtual.aliquota_icms_st" :decimais="2" ref="aliquota_icms_st" @keydown.native.enter="$refs.valor_icms_st.focus()" @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor ICMS ST" persistent-placeholder outlined v-model="produtoAtual.valor_icms_st" :decimais="2" ref="valor_icms_st" @keydown.native.enter="$refs.base_calculo_ipi.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo IPI" persistent-placeholder outlined v-model="produtoAtual.base_calculo_ipi" :decimais="2" ref="base_calculo_ipi" @keydown.native.enter="$refs.aliquota_ipi.focus()" @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="1">
              <as-campo-valor label="Alíquota IPI" persistent-placeholder outlined v-model="produtoAtual.aliquota_ipi" :decimais="2" ref="aliquota_ipi" @keydown.native.enter="$refs.valor_ipi.focus()" @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor IPI" persistent-placeholder outlined v-model="produtoAtual.valor_ipi" :decimais="2" ref="valor_ipi" @keydown.native.enter="$refs.icms_sn.focus()"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="1">
              <as-campo-valor label="ICMS-SN" persistent-placeholder outlined v-model="produtoAtual.icms_sn" :decimais="2" ref="icms_sn"></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select label="CST" persistent-placeholder outlined v-model="produtoAtual.cst_csosn" :items="constantes.CST_ICMS" ref="cst_csosn"></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-text-field label="CFOP" persistent-placeholder outlined v-model="produtoAtual.cfop" ref="cfop" maxlength="4" @keydown.native.enter="buscarCfop" @blur="buscarCfop"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field label="Descrição CFOP" persistent-placeholder outlined v-model="produtoAtual.cfop_descricao" ref="cfop_descricao" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <buscar-pis ref="pis" persistent-placeholder outlined v-model="produtoAtual.pis" @pisSelecionado="pisSelecionado"></buscar-pis>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Descrição PIS" persistent-placeholder outlined v-model="produtoAtual.pis_descricao" ref="pis_descricao" readonly>
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="replicarPis" icon>
                        <v-icon>mdi-check-all</v-icon>
                      </v-btn>
                    </template>
                    Aplicar PIS a todos os produtos
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor PIS" persistent-placeholder outlined v-model="produtoAtual.valor_pis" :decimais="2" ref="valor_pis"></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <buscar-cofins ref="cofins" persistent-placeholder outlined v-model="produtoAtual.cofins" @cofinsSelecionado="cofinsSelecionado"></buscar-cofins>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Descrição COFINS" persistent-placeholder outlined v-model="produtoAtual.cofins_descricao" ref="cofins_descricao" readonly>
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="replicarCofins" icon>
                        <v-icon>mdi-check-all</v-icon>
                      </v-btn>
                    </template>
                    Aplicar COFINS a todos os produtos
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor COFINS" persistent-placeholder outlined v-model="produtoAtual.valor_cofins" :decimais="2" ref="valor_cofins"></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row dense>
              <v-col>
                <v-checkbox v-model="produtoAtual.informativo" class="mt-0" v-show="!compra.informativa" label="Produto Informativo, dispensa cadastro, não contabiliza no estoque" hide-details></v-checkbox>
              </v-col>
              <v-col class="text-right">
                <v-btn depressed color="primary" @click="fecharDialogo">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoCadastroProduto" max-width="1000px" scrollable eager persistent>
      <formulario-produto ref="formCadastroProduto" :cadastro-nfe="true" @fecharDialogo="fecharDialogoCadastroProduto"></formulario-produto>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import FormularioProduto from '@/views/produtos/FormularioProduto';
  import constantes from '@/util/constantes';
  import BuscarPis from '@/components/BuscarPis';
  import BuscarCofins from '@/components/BuscarCofins';

  export default {
    name: 'DialogoEditarProdutoCompra',

    components: {
      FormularioProduto,
      BuscarPis,
      BuscarCofins,
    },

    data() {
      return {
        dialogo: false,
        dialogoCadastroProduto: false,
        produto: {},
        constantes: constantes,
      };
    },

    methods: {
      abrir() {
        this.dialogo = true;
        setTimeout(() => {
          this.$refs.quantidade.focus();
        }, 500);
      },
      calcularSubtotalProduto() {
        this.produtoAtual.subtotal = this.produtoAtual.quantidade * this.produtoAtual.valor_custo;
      },
      fecharDialogo() {
        this.dialogo = false;
      },
      mudarProdutoTela(botao_clicado) {
        let produto = {};
        let ultimo_indice = this.compra.itens.length - 1;

        if (botao_clicado == 'primeiro') {
          produto = this.compra.itens[0];
          produto.indice_original = 0;
        } else if (botao_clicado == 'ultimo') {
          produto = this.compra.itens[ultimo_indice];
          produto.indice_original = ultimo_indice;
        } else if (botao_clicado == 'anterior') {
          let indice_atual = this.produtoAtual.indice_original;
          let indice_anterior = indice_atual - 1;
          let novo_indice = indice_anterior < 0 ? 0 : indice_anterior;

          produto = this.compra.itens[novo_indice];
          produto.indice_original = novo_indice;
        } else if (botao_clicado == 'proximo') {
          let indice_atual = this.produtoAtual.indice_original;
          let ultimo_indice = this.compra.itens.length - 1;
          let proximo_indice = indice_atual + 1;
          let novo_indice = proximo_indice > ultimo_indice ? ultimo_indice : proximo_indice;

          produto = this.compra.itens[novo_indice];
          produto.indice_original = novo_indice;
        }

        this.$store.commit('compra/setProduto', produto);
      },
      abrirDialogoCadastroProduto() {
        this.$refs.formCadastroProduto.preencherDadosProdutoNfe(this.produtoAtual);
        this.dialogoCadastroProduto = true;
      },
      fecharDialogoCadastroProduto(status) {
        if (status == 'cadastrado') {
          this.produtoAtual.codigo = this.produtoNfe.codigo;
          this.produtoAtual.descricao = this.produtoNfe.descricao;
          this.produtoAtual.unidade = this.produtoNfe.unidade;
          this.produtoAtual.ncm = this.produtoNfe.ncm.codigo;
          this.produtoAtual.produto = this.produtoNfe._id;
        }

        this.dialogoCadastroProduto = false;
      },
      buscarCfop() {
        this.$store.dispatch('compra/buscarCfop', this.produtoAtual.cfop);
      },
      recalcularImpostos() {
        this.$store.commit('compra/recalcularImpostos');
      },
      buscarPis() {
        // this.$store.dispatch('pis/buscar', this.produtoAtual.pis);

        let pis = this.constantes.PIS.find((pis_atual) => {
          return pis_atual.value == this.produtoAtual.pis;
        });

        this.produtoAtual.pis_descricao = pis.text;
      },
      pisSelecionado(pisSelecionado) {
        this.$store.commit('compra/setPis', pisSelecionado);
      },
      cofinsSelecionado(cofinsSelecionado) {
        this.$store.commit('compra/setCofins', cofinsSelecionado);
      },
      buscarCofins() {
        let cofins = this.constantes.COFINS.find((cofins_atual) => {
          return cofins_atual.value == this.produtoAtual.cofins;
        });

        this.produtoAtual.cofins_descricao = cofins.text;
      },
      replicarPis() {
        this.$store.commit('compra/setPisTodosProdutos');
      },
      replicarCofins() {
        this.$store.commit('compra/setCofinsTodosProdutos');
      },
    },

    computed: {
      ...mapState('compra', {
        compra: 'compraAtual',
        carregando: 'carregando',
        produtoAtual: 'produtoAtual',
      }),

      ...mapState('produto', {
        produtoNfe: 'produtoNfe',
      }),
    },
  };
</script>
