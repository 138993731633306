import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store/store';
import VueCookies from 'vue-cookies';
import '@/plugins/echarts';
import 'font-awesome/css/font-awesome.css';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import '@/components/index';
import '@/util/filtros';
import '@/assets/scrollbar.css';
import 'font-awesome/css/font-awesome.css';
import VuePfc from 'vuepfc';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import './registerServiceWorker';

Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.use(VuePfc);
Vue.use(VueVirtualScroller);

Vue.directive('input-upper-case', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase();
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase());
    });
  },
});

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
  window.app = app;
}
