import ListaContasPagar from '@/views/contaspagar/ListaContasPagar';
import FormularioContaPagar from '@/views/contaspagar/FormularioContaPagar';
import FormularioBaixaContaPagar from '@/views/contaspagar/FormularioBaixaContaPagar';

export default [
  {
    path: '/movimentacoes/contas-pagar',
    name: 'contasPagar',
    component: ListaContasPagar,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_contas_pagar' },
  },
  {
    path: '/movimentacoes/contas-pagar/nova',
    name: 'formularioContaPagarNova',
    component: FormularioContaPagar,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_contas_pagar' },
  },
  {
    path: '/movimentacoes/contas-pagar/editar',
    name: 'formularioContaPagarEditar',
    component: FormularioContaPagar,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_contas_pagar' },
  },
  {
    path: '/movimentacoes/contas-pagar/baixar',
    name: 'formularioBaixarContaPagar',
    component: FormularioBaixaContaPagar,
    meta: { requiresAuth: true, nivel: 1, permissao: 'baixar_contas_pagar' },
  },
];
