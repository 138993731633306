export default [
  {
    path: '/admin/empresas',
    name: 'adminEmpresas',
    component: () => import(/* webpackChunkName: "listaEmpresasAdmin" */ '@/views/empresas/ListarEmpresasAdmin'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/admin/empresa/adicionar',
    name: 'adminEmpresaAdicionar',
    component: () => import(/* webpackChunkName: "formularioEmpresa" */ '@/views/empresas/FormularioEmpresa'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/empresa/editar',
    name: 'empresaEditar',
    component: () => import(/* webpackChunkName: "formularioEmpresa" */ '@/views/empresas/FormularioEmpresa'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/admin/contadores',
    name: 'adminContadores',
    component: () => import(/* webpackChunkName: "listarContadoresAdmin" */ '@/views/contadores/ListarContadoresAdmin'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/admin/contador/adicionar',
    name: 'adminContadorAdicionar',
    component: () => import(/* webpackChunkName: "formularioContador" */ '@/views/contadores/FormularioContador'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/contador/editar',
    name: 'contadorEditar',
    component: () => import(/* webpackChunkName: "formularioContador" */ '@/views/contadores/FormularioContador'),
    meta: { requiresAuth: true, nivel: 4 },
  },
];
