import axios from '@/util/axios';
import constantes from '@/util/constantes';
import graficosApi from '@/services/graficosApi';

export default {
  namespaced: true,

  state: {
    vendasPorMes: [],
    vendasPorIntervaloData: [],
    constantes: constantes,
    carregando: false,
  },

  getters: {},

  actions: {
    async vendasPorMes({ commit }, periodo) {
      try {
        commit('setCarregando');
        let response = await graficosApi.vendasPorMes(periodo);
        commit('setVendasPorMes', response);
      } catch (error) {
        commit('setVendasPorMes', []);
        commit('setMensagemErro', 'Falha ao carregar o gráfico', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async vendasPorIntervaloData({ commit }, periodo) {
      try {
        commit('setCarregando');
        let response = await graficosApi.vendasPorIntervaloData(periodo);
        commit('setVendasPorIntervaloData', response);
      } catch (error) {
        commit('setVendasPorIntervaloData', []);
        commit('setMensagemErro', 'Falha ao carregar o gráfico', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, valor = true) {
      state.carregando = valor;
    },
    setVendasPorMes(state, vendas) {
      state.vendasPorMes = vendas;
    },
    setVendasPorIntervaloData(state, vendas) {
      state.vendasPorIntervaloData = vendas;
    },
  },
};
