<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Novo Cheque</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row style="margin-top: 50px" v-if="carregando">
            <v-col cols="12" class="text-center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>

          <v-form v-else ref="formCheque" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="7">
                  <v-row dense>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Número" ref="numero" v-model="cheque.numero" :rules="[rules.obrigatorio]" @keypress.enter="$refs.agencia.focus()"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field label="Agência" ref="agencia" v-model="cheque.agencia" :rules="[rules.obrigatorio]" @keypress.enter="$refs.contacorrente.focus()"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Conta Corrente" ref="contacorrente" v-model="cheque.conta" :rules="[rules.obrigatorio]" @keypress.enter="$refs.vencimento.focus()"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <campo-data label="Vencimento" ref="vencimento" v-model="cheque.vencimento" :rules="[rules.obrigatorio]" @keypress.enter="$refs.valor.focus()"></campo-data>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <as-campo-valor label="Valor" ref="valor" v-model="cheque.valor" :decimais="2" :rules="[rules.obrigatorio, rules.numeroValido, rules.valorMaiorQueZero]" @keypress.native.enter="$refs.cnpjCpf.focus()"></as-campo-valor>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-text-field label="CNPJ/CPF" ref="cnpjCpf" v-model="cheque.cnpjCpf" v-mask="['###.###.###-##', '##.###.###/####-##']" :rules="[rules.obrigatorio]" @keypress.enter="$refs.emitente.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field label="Emitente" ref="emitente" v-model="cheque.emitente" @keypress.enter="$refs.cidade.focus()"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4">
                  <v-text-field label="Cidade" ref="cidade" v-model="cheque.cidade" :rules="[rules.obrigatorio]" @keypress.enter="$refs.estado.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field label="Estado" ref="estado" v-model="cheque.estado" :rules="[rules.obrigatorio]" @keypress.enter="$refs.banco_codigo.focus()"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <buscar-banco :label="'Banco'" ref="banco_codigo" v-model="cheque.banco.codigo" @bancoSelecionado="bancoSelecionado" @keypress.enter="$refs.cnpjCpf_responsavel.focus()"></buscar-banco>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Nome do Banco" ref="banco_descricao" v-model="cheque.banco.descricao" readonly></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <buscar-participante
                    :label="'CNPJ/CPF do Responsável'"
                    ref="cnpjCpf_responsavel"
                    v-model="cheque.responsavel.cnpjCpf"
                    @participanteSelecionado="responsavelSelecionado"
                    :rules="[rules.obrigatorio]"
                    @keypress.enter="$refs.observacoes.focus()"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Responsável" ref="razaoSocialNome" v-model="cheque.responsavel.razaoSocialNome" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <campo-data label="Cadastro" ref="cadastro" v-model="cheque.cadastro" :rules="[rules.obrigatorio]" @keypress.enter="$refs.observacoes.focus()" :disabled="true"></campo-data>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-textarea label="Observações" ref="observacoes" v-model="cheque.observacao" @keypress.enter="observacoesEnterFinalizar"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelar" :disabled="carregando">Cancelar</v-btn>
          <v-btn color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'DialogoFormCheque',
  components: {
    //
  },

  data() {
    return {
      dialog: false,
      rules: { ...regras },
      constantes: constantes,
    };
  },

  computed: {
    ...mapState('cheque', {
      cheque: 'chequeAtual',
      carregando: 'carregando',
    }),
  },

  methods: {
    abrir() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.numero.focus();
      }, 100);
    },

    fechar() {
      this.$store.commit('cheque/estadoInicial');
      this.dialog = false;
    },

    async salvar() {
      if (this.$refs.formCheque.validate()) {
        try {
          if (!this.cheque.banco._id) {
            this.$store.commit('setMensagemErro', 'Selecione um banco');
            setTimeout(() => {
              this.$refs.banco_codigo.focus();
            }, 100);
            return false;
          }
          if (!this.cheque.responsavel._id) {
            this.$store.commit('setMensagemErro', 'Selecione um responsável');
            setTimeout(() => {
              this.$refs.cnpjCpf_responsavel.focus();
            }, 100);
            return false;
          }

          if (this.cheque._id) {
            await this.$store.dispatch('cheque/atualizar', this.cheque);
          } else {
            await this.$store.dispatch('cheque/salvar', this.cheque);
          }
          this.$emit('finalizar');
          this.fechar();
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    observacoesEnterFinalizar(event) {
      if (event.keyCode == 13) {
        if (event.shiftKey) {
          event.stopPropagation();
        } else {
          this.salvar();
        }
      }
    },

    cancelar() {
      this.$store.commit('cheque/estadoInicial');
      this.dialog = false;
    },

    definirCidade(idCidade) {
      this.$store.commit('cheque/setCidade', idCidade);
    },

    bancoSelecionado(banco) {
      this.$store.commit('cheque/setBanco', banco);
      this.$refs.cnpjCpf_responsavel.focus();
    },

    responsavelSelecionado(responsavel) {
      this.$store.commit('cheque/setResponsavel', responsavel);
      this.$refs.observacoes.focus();
    },
  },
};
</script>
