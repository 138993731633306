function getHeader(headerData = {}) {
  const { title, subTitle, companyInfo, columns } = headerData;
  const headerWidths = [];

  const formatedColumns = columns.map((column) => {
    if (column.width.search('px') > -1) {
      headerWidths.push(parseInt(column.width.substr(0, column.width.search('px'))));
    } else {
      headerWidths.push(column.width);
    }

    return {
      text: column.text,
      bold: true,
      style: 'small',
      fillColor: '#CCCCCC',
      alignment: column.alignment ? column.alignment : 'left',
    };
  });

  return [
    {
      margin: [20, 20, 20, 0],
      table: {
        widths: ['65%', '35%'],
        body: [
          [
            { text: companyInfo.fantasia + '\n', style: 'subHeader', border: [false, false, false, false] },
            { text: title, border: [false, false, true, true], bold: true },
          ],
          [
            { text: companyInfo.logradouro + ' - ' + companyInfo.cidade + ' - ' + companyInfo.uf, style: 'small', border: [false, false, false, false] },
            { rowSpan: 2, text: subTitle, style: 'medium', border: [false, false, true, true], bold: true },
          ],
          [{ text: companyInfo.cep + ' / ' + companyInfo.telefone, style: 'small', border: [false, false, false, false] }, ''],
        ],
      },
    },
    {
      margin: [20, 0, 20, 0],
      columns: [
        [
          {
            style: 'table',
            width: headerWidths,
            table: {
              margin: [0, 0, 0, 0],
              widths: headerWidths,
              body: [formatedColumns],
            },
          },
        ],
      ],
    },
  ];
}

export default getHeader;
