import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/vendedores?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(vendedor) {
  return axios.post(`/vendedores`, vendedor).then((response) => response.data);
}

function atualizar(vendedor) {
  return axios.put(`/vendedores/${vendedor._id}`, vendedor).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/vendedores/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
