<template>
  <v-card class="elevation-0">
    <v-card-text class="pa-0">
      <v-list two-line class="pa-0">
        <template v-for="(notificacao, index) in notificacoes">
          <v-list-item :key="notificacao._id + '2000'" @click="visualizarNotificacao(notificacao)" v-if="index < 5">
            <v-list-item-avatar v-if="notificacao.tipo == 'Aviso'" color="warning">
              <v-icon dark>fas fa-info</v-icon>
            </v-list-item-avatar>

            <v-list-item-avatar v-else-if="notificacao.tipo == 'Informativo'" color="accent">
              <v-icon dark>fas fa-info</v-icon>
            </v-list-item-avatar>

            <v-list-item-avatar v-else color="red">
              <v-icon dark>fas fa-exclamation</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="notificacao.titulo"></v-list-item-title>
              <v-list-item-subtitle v-html="tempoDecorrido(notificacao.created_at)" style="text-transform: capitalize;"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="notificacao._id + '3000'"></v-divider>
        </template>

        <v-list-item v-if="notificacoes.length === 0">
          <v-list-item-content>
            <v-list-item-title style="width: 100%; text-align: center">Não há novas notificações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="notificacoes.length === 0"></v-divider>
      </v-list>

      <v-btn block depressed class="ma-0 pa-2" @click="abrirHistorico()">Exibir Histórico</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import util from "@/util/conversor";

export default {
  props: ["notificacoes"],

  data: () => ({}),

  methods: {
    visualizarNotificacao(notificacao) {
      this.$store.dispatch("notificacao/marcarLida", notificacao);
      this.$store.commit("notificacao/setNotificacao", notificacao);
    },

    tempoDecorrido(data) {
      return util.tempoDecorrido(data);
    },

    abrirHistorico() {
      this.$store.dispatch("notificacao/carregarTodas");
    },
  },
};
</script>

<style lang="css">
.scroll-area {
  position: relative;
  margin: auto;
  height: 550px;
}
</style>
