<template>
  <div>
    <v-text-field :loading="loading" label="Buscar CFOP" ref="codigoCFOP" :value="value" append-icon="search" @keyup.enter="consultarCodigoCFOP($event)" @input="onInput" @click:append="abrirDialogo" data-cy="campo-cfop" maxlength="8"></v-text-field>

    <dialog-buscar-cfop ref="dialogoBuscarCFOP" @cfopSelecionado="adicionarCFOPDialog" :busca="value" />
  </div>
</template>

<script>
import DialogBuscarCfop from '@/components/dialogs/DialogBuscarCfop';
import cfopApi from '@/services/cfopApi';

export default {
  name: 'BuscarCFOP',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    DialogBuscarCfop,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    focus() {
      this.$refs.codigoCFOP.focus();
    },

    onInput(value) {
      this.$emit('input', value);
    },

    abrirDialogo() {
      this.$refs.dialogoBuscarCFOP.abrir();
    },

    async consultarCodigoCFOP(event) {
      let cfop = event.target.value;
      if (cfop && cfop.length === 8) {
        try {
          let resultado = await cfopApi.buscar(cfop);
          if (resultado.length > 0) {
            this.$emit('cfopSelecionado', resultado[0]);
          } else {
            this.$store.commit('setMensagemErro', 'CFOP não encontrado');
          }
        } catch (error) {
          this.$store.commit('setMensagemErro', error.response.data.motivo);
        } finally {
          this.loading = false;
        }
      } else {
        this.$refs.dialogoBuscarCFOP.abrir();
      }
    },

    adicionarCFOPDialog(cfop) {
      this.$emit('cfopSelecionado', cfop);
    },
  },
};
</script>

<style scoped></style>
