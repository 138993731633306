<template>
  <div>
    <v-container fluid>
      <v-widget title="Notas de Entrada">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field flat single-line hide-details clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" label="Busca" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right pt-2 pt-sm-3">
                <v-btn v-if="!isSm" depressed color="primary" @click="importarXml({ router: $router })"> NOVA </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="importarXml({ router: $router })">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :headers="headers" :items="compras" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr :class="!props.item.concluida ? 'nao_concluida' : ''">
                <td class="text-center">{{ props.item.numero }} - {{ props.item.serie }}</td>
                <td>{{ props.item.fornecedor.razaoSocialNome }}</td>
                <td class="text-center">{{ props.item.data | formatarData }}</td>
                <td class="text-center">{{ props.item.data_entrada | formatarData }}</td>
                <td class="text-center">{{ props.item.quantidade_total_produtos | formatarValor }}</td>
                <td class="text-center">{{ props.item.total | formatarMoeda }}</td>
                <td class="justify-center layout px-0 text-center">
                  <v-menu top right transition="slide-x-transition" @click.native.stop="">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item @click="editarCompra({ compra: props.item, router: $router })" data-cy="editar">
                        <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="remover(props.item)" data-cy="editar">
                        <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: 'ListaCompras',

    data() {
      return {
        headers: [
          { text: 'Nota', value: 'numero', sortable: false, align: 'center' },
          { text: 'Fornecedor', value: 'fornecedor.razaoSocialNome', sortable: false },
          { text: 'Data', value: 'data', sortable: false, align: 'center' },
          { text: 'Cadastro', value: 'data_entrada', sortable: false, align: 'center' },
          { text: 'Quantidade', value: 'quantidade_total_produtos', sortable: false, align: 'center' },
          { text: 'Valor', value: 'total', sortable: false, align: 'center' },
          { text: 'Ações', value: 'name', align: 'center', sortable: false, align: 'center' },
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1,
        },
      };
    },

    watch: {
      options: {
        handler() {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('compra', {
        compras: 'compras',
        carregando: 'carregando',
      }),

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      },
    },

    methods: {
      ...mapActions('compra', {
        editarCompra: 'editar',
        novaCompra: 'novo',
        importarXml: 'importar',
      }),

      listar() {
        this.$store.dispatch('compra/carregar', this.options);
      },

      filtrar(event) {
        let newOptions = {
          ...this.options,
          query: this.busca,
        };

        this.$store.dispatch('compra/carregar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      remover(item) {
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
          if (confirmado) {
            this.$store.dispatch('compra/remover', item);
          }
        });
      },

      limparBusca() {
        this.listar();
      },
    },
  };
</script>

<style scoped>
  .nao_concluida {
    color: darkgray;
  }
</style>
