const Menu = [
  { cabecalho: 'Início', nivel: 1 },
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1,
    permissao: true,
  },
  { cabecalho: 'Fiscal', nivel: 1, permissao: 'exibir_fiscal' },
  {
    titulo: 'Notas Fiscais',
    grupo: 'Fiscal',
    nome: 'Nota',
    icone: 'chrome_reader_mode',
    path: '/notas',
    nivel: 1,
    permissao: 'acessar_notasfiscais',
  },
  {
    titulo: 'Manifesto de Documentos',
    grupo: 'Fiscal',
    nome: 'Manifestos',
    icone: 'far fa-file',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/manifestos',
    nivel: 1,
    permissao: 'acessar_manifestos',
  },
];

export default Menu;
