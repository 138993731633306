import localforage from 'localforage';

import constantes from '@/util/constantes';
import produtosApi from '../../services/produtosApi';

const getProdutos = (produtos, options) => {
  let produtosOk = produtos;
  produtosOk.sort((a, b) => (a.descricao > b.descricao ? 1 : -1));

  localforage.setItem('produtos', produtosOk);

  if (options && options.busca) {
    produtosOk = produtosOk.filter((produto) => `${produto.codigo} ${produto.descricao}`.toLocaleUpperCase().includes(options.busca.toLocaleUpperCase()));
  }

  if (options && options.itemsPerPage && options.page) {
    const from = options.itemsPerPage * (options.page - 1);
    const to = options.itemsPerPage * (options.page - 1) + options.itemsPerPage;
    produtosOk = produtosOk.filter((produto, idx) => idx >= from && idx <= to);
  }

  return produtosOk;
};

const produtoBlank = {
  codigo: '',
  codigosBarras: [],
  descricao: '',
  descricaoAdicional: '',
  unidade: null,
  ncm: null,
  cest: null,
  grupo: null,
  subGrupo: null,
  fabricante: null,
  codigoFabricante: '',
  fatorConversao: {
    entrada: 1,
    saida: 1,
  },
  valorCusto: 0,
  lucro: 0,
  ipi: 0,
  ii: 0,
  frete: { tipo: 'Valor', valor: 0 },
  outros: 0,
  valorPrazo: 0,
  valor: 0,
  vendaFracionada: false,
  ativo: true,
  iat: 'Truncamento',
  ippt: 'Terceiros',
  imagem: null,
  estoque: 0,
  estoqueMinimo: 0,
  localizacao: [],
  icms: {
    origem: '',
    cst: '',
    csosn: '',
    aliquota: 0,
  },
  pis: {
    entrada: {
      cst: '',
      aliquota: 0,
    },
    saida: {
      cst: '',
      aliquota: 0,
    },
  },
  cofins: {
    entrada: {
      cst: '',
      aliquota: 0,
    },
    saida: {
      cst: '',
      aliquota: 0,
    },
  },
  insumos: [],
};

export default {
  namespaced: true,

  state: {
    produtos: [],
    produto: structuredClone(produtoBlank),
    produtoIndex: null,
    produtosFiltrados: [],
    carregando: false,
    constantes,
  },

  actions: {
    listar({ commit }, options) {
      try {
        commit('setState', { carregando: true });

        localforage.getItem('timestampProdutos').then((timestamp = null) => {
          produtosApi.listar({ timestamp: timestamp || undefined }).then((response) => {
            localforage.getItem('produtos').then((produtos) => {
              let produtosOk = [...(produtos || [])];

              response.produtos.forEach((produto) => {
                const index = produtosOk.findIndex((p) => p._id === produto._id);

                if (index < 0) {
                  produtosOk.push(produto);
                } else {
                  produtosOk[index] = produto;
                }
              });

              localforage.setItem('timestampProdutos', response.timestamp);
              produtosOk = produtosOk.filter((produto) => !response.deletados.includes(produto._id));
              produtosOk = getProdutos(produtosOk, options);
              commit('setState', { produtos: produtosOk });
            });
          });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    async adicionar({ commit, state }, imagem) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          produtosApi
            .adicionar({ ...state.produto, imagem: imagem || undefined })
            .then((produto) => {
              localforage.getItem('produtos').then((produtos) => {
                const produtosOk = produtos.concat([produto]);
                getProdutos(produtosOk);
                commit('setState', { produtos: state.produtos.concat([produto]), carregando: false });
                commit('setMensagemSucesso', 'Produto cadastrado com sucesso', { root: true });
                resolve(true);
              });
            })
            .catch((error) => {
              commit('setMensagemErro', error, { root: true });
              commit('setState', { carregando: false });
              resolve(false);
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async editar({ commit, state }, imagem) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          produtosApi.editar({ ...state.produto, imagem: imagem || undefined, estoque: undefined }).then((produto) => {
            localforage
              .getItem('produtos')
              .then((produtos) => {
                const produtosOk = produtos;
                const index = produtosOk.findIndex((p) => p._id === produto._id);

                if (index >= 0) produtosOk[index] = produto;

                getProdutos(produtosOk);

                commit('setState', { produtos: state.produtos.map((pro) => (pro._id === produto._id ? produto : pro)), carregando: false });
                commit('setMensagemSucesso', 'Produto atualizado com sucesso', { root: true });
                resolve(true);
              })
              .catch((error) => {
                commit('setMensagemErro', error, { root: true });
                commit('setState', { carregando: false });
                resolve(false);
              });
          });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async remover({ commit }, produtoId) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          produtosApi.remover(produtoId).then(() => {
            localforage
              .getItem('produtos')
              .then((produtos) => {
                const produtosOk = produtos.filter((produto) => produto._id !== produtoId);

                getProdutos(produtosOk);

                commit('setState', { produtos: produtosOk, carregando: false });
                commit('setMensagemSucesso', 'Produto removido com sucesso', { root: true });
                resolve(true);
              })
              .catch((error) => {
                commit('setMensagemErro', error, { root: true });
                commit('setState', { carregando: false });
                resolve(false);
              });
          });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    novo({ commit }, regimeTributario) {
      const produto = structuredClone(produtoBlank);

      produto.pis.entrada.cst = '50';
      produto.pis.saida.cst = '01';

      produto.cofins.entrada.cst = '50';
      produto.cofins.saida.cst = '01';

      produto.icms.cst = '00';
      produto.icms.origem = '0';
      produto.icms.aliquota = 18;

      produto.icms.csosn = '101';

      // Simples Nacional
      if (regimeTributario === '1') {
        produto.pis.entrada.aliquota = 0;
        produto.cofins.saida.aliquota = 0;

        produto.pis.entrada.cst = '99';
        produto.pis.saida.cst = '49';

        produto.cofins.entrada.cst = '99';
        produto.cofins.saida.cst = '49';
      }

      // Lucro Presumido
      if (regimeTributario === '2') {
        produto.pis.entrada.aliquota = 0.65;
        produto.cofins.saida.aliquota = 3;
      }

      // Lucro Real
      if (regimeTributario === '3') {
        produto.pis.entrada.aliquota = 1.65;
        produto.cofins.saida.aliquota = 7.6;
      }

      commit('setState', { produto: structuredClone(produtoBlank) });
    },
  },

  mutations: {
    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  // preencherDadosProdutoNfe(produtoNfe) {
  //   this.produto.codigo = produtoNfe.codigo_entrada;
  //   this.produto.descricao = produtoNfe.descricao;
  //   this.produto.unidade = produtoNfe.unidade;
  //   this.produto.ncm.codigo = produtoNfe.ncm;
  //   this.produto.referencia_fabricante = produtoNfe.codigo_entrada;
  //   this.produto.valor_custo = produtoNfe.valor_custo;
  //   this.produto.iat = 'T';
  //   this.produto.ippt = 'T';
  //   this.$refs.campoNcm.consultarCodigoNcm(produtoNfe.ncm);
  // },
};
