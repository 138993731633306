<template>
  <v-container fluid>
    <v-widget title="Vendedores">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col cols="10" sm="5">
              <v-text-field
                filled
                class="px-0"
                flat
                solo
                single-line
                hide-details
                dense
                clearable
                v-model="busca"
                @click:clear="limparBusca"
                @keyup.enter="filtrarVendedores($event)"
                max-width="300px"
                prepend-inner-icon="search"
                background-color="grey lighten-3"
                label="Busca"
              />
            </v-col>
            <v-col cols="2" sm="7" class="text-right">
              <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
              <v-btn v-else depressed fab small color="primary" @click="novo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="vendedores" :search="busca" :loading="carregando" mobile-breakpoint="0">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.nome }}</td>
              <td>{{ props.item.comissao | formatarPercentual }}</td>
              <td>
                <v-chip small label v-if="props.item.ativo === true" color="green" text-color="white"><strong>Ativo</strong></v-chip>
                <v-chip small label v-if="!props.item.ativo" color="red" text-color="white"><strong>Inativo</strong></v-chip>
              </td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="excluir(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
    <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  components: {
    DialogConfirmacao,
  },
  data() {
    return {
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Comissão', value: 'comissao' },
        { text: 'Status', value: 'ativo' },
        { text: '', value: 'name', align: 'center', width: '32px', sortable: false },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('vendedor', {
      vendedores: 'vendedores',
      carregando: 'carregando',
      total: 'totalVendedores',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    ...mapActions('vendedor', {
      editarVendedor: 'editar',
      novoVendedor: 'novo',
    }),

    listar() {
      this.$store.dispatch('vendedor/listar', this.options);
    },

    limparBusca() {
      this.listar();
    },

    filtrarVendedores(event) {
      const newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('vendedor/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    excluir(vendedor) {
      this.$refs.dialogConfirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('vendedor/excluir', vendedor);
        }
      });
    },

    editar(vendedor) {
      this.$store.commit('vendedor/editar', vendedor);
      this.$router.push({ name: 'formularioVendedorEditar' });
    },

    novo() {
      this.$store.commit('vendedor/novo');
      this.$router.push({ name: 'formularioVendedorNovo' });
    },
  },
};
</script>
