<template>
  <v-layout column class="ma-0">
    <v-card class="pa-4 ma-2 pb-0">
      <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" prepend-inner-icon="search" label="Procurar Notificação" style="border-radius: 24px"></v-text-field>
      <v-radio-group class="mb-4" hide-details row v-model="filtro">
        <v-radio label="Todas" value="todas" class="ma-1"></v-radio>
        <v-radio label="Lidas" value="lidas" class="ma-1"></v-radio>
        <v-radio label="Não Lidas" value="nao_lidas" class="ma-1"></v-radio>
      </v-radio-group>
    </v-card>
    <v-container grid-list-lg class="pa-2 ma-0 overflow-y-auto custom-scroll scroll-margin" fill-height fluid style="overflow: hidden; align-items: baseline">
      <v-list two-line class="pa-0" style="width: 100%">
        <v-list-item-group mandatory color="black">
          <template v-for="(notificacao, index) in notificacoesExibidas">
            <v-divider v-if="index > 0" :key="notificacao._id"></v-divider>
            <v-list-item :key="notificacao._id + '100'" @click="detalharNotificacao(notificacao)">
              <v-layout column class="ma-0">
                <v-layout row class="ma-0">
                  <v-list-item-icon class="ma-auto mr-4">
                    <span class="white--text headline">
                      <v-list-item-avatar class="ma-0" v-if="notificacao.tipo == 'Aviso'" color="warning">
                        <v-icon dark>fas fa-info</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-avatar class="ma-0" v-else-if="notificacao.tipo == 'Informativo'" color="accent">
                        <v-icon dark>fas fa-info</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-avatar class="ma-0" v-else color="red">
                        <v-icon dark>fas fa-exclamation</v-icon>
                      </v-list-item-avatar>
                    </span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ notificacao.titulo }}</v-list-item-title>
                    <v-list-item-subtitle style="text-transform: capitalize;">{{ notificacao.created_at | tempoDecorrido }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                    <v-list-item-action class="ma-0">{{ notificacao.created_at | formatarDataHora }}</v-list-item-action>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-icon v-if="notificacao.lida" dark color="grey" v-on:click.stop="marcarComoNaoLida(notificacao)">drafts</v-icon>
                    <v-icon v-else dark color="black" v-on:click.stop="marcarComoLida(notificacao)">markunread</v-icon>
                  </v-list-item-action>
                </v-layout>
                <v-container v-if="notificacao._id === exibirNotificacao._id" :class="$vuetify.breakpoint.smAndUp ? 'ml-7 pl-7 pt-0' : 'ml-0 pl-0 pt-0 pr-0'">
                  <v-divider class="mb-4"></v-divider>
                  <label class="pr-6" v-for="(linha, idx) in notificacao.conteudo.split('|')" :key="idx">{{ linha }}<br /></label>
                </v-container>
              </v-layout>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  props: ['notificacoes'],

  data: () => ({
    busca: '',
    filtro: 'todas',
    exibirNotificacao: { _id: 0 },
  }),

  computed: {
    notificacoesExibidas() {
      let exibidas = [];

      let notificacoesFiltro = [];
      if (this.filtro === 'todas') {
        notificacoesFiltro = this.notificacoes;
      } else if (this.filtro === 'lidas') {
        notificacoesFiltro = this.notificacoes.filter((notificacao) => {
          return notificacao.lida === true;
        });
      } else if (this.filtro === 'nao_lidas') {
        notificacoesFiltro = this.notificacoes.filter((notificacao) => {
          return notificacao.lida === false;
        });
      }

      if (this.busca) {
        if (this.busca == '') {
          return notificacoesFiltro;
        } else {
          notificacoesFiltro.forEach((notificacao) => {
            if (notificacao.titulo.toLowerCase().indexOf(this.busca.toLowerCase()) !== -1 || notificacao.conteudo.toLowerCase().indexOf(this.busca.toLowerCase()) !== -1) {
              exibidas.push(notificacao);
            }
          });
          return exibidas;
        }
      } else {
        return notificacoesFiltro;
      }
    },
  },

  methods: {
    detalharNotificacao(notificacao) {
      if (this.exibirNotificacao._id === notificacao._id) {
        this.exibirNotificacao = { _id: 0 };
      } else {
        this.exibirNotificacao = notificacao;
      }
      if (this.filtro !== 'nao_lidas') {
        this.$store.dispatch('notificacao/marcarLida', notificacao);
      }
      this.$store.commit('notificacao/detalharNotificacao', notificacao);
    },

    chatStatusColor(notificacao) {
      if (notificacao.lida) {
        return 'green';
      } else {
        return 'red';
      }
    },

    marcarComoLida(notificacao) {
      this.$store.dispatch('notificacao/marcarLida', notificacao);
    },

    marcarComoNaoLida(notificacao) {
      this.$store.dispatch('notificacao/marcarNaoLida', notificacao);
    },
  },
};
</script>
