<template>
  <v-app>
    <dialog-visualizar-notificacao />
    <dialog-visualizar-todas-notificacoes />

    <dialog-sangria-suprimento ref="dialogSangriaSuprimento" />
    <dialog-listar-cupom ref="dialogListarCupoms" />

    <app-drawer />
    <app-toolbar />

    <v-main class="grey lighten-4">
      <router-view />
    </v-main>

    <v-footer class="white pa-2 elevation-5" inset app>
      <div class="caption mr-auto" style="max-width: 60%">&copy; {{ new Date().getFullYear() }} Anron Software</div>
      <v-sheet v-if="!['ANRON', 'CONTADOR'].includes($store.state.autenticacao.session.usuario.nivelAcesso) && $store.state.autenticacao.session.ambiente === 2" color="red" class="white--text caption pl-2 pr-2 rounded">Ambiente de Testes</v-sheet>

      <v-menu v-if="!['ANRON', 'CONTADOR'].includes($store.state.autenticacao.session.usuario.nivelAcesso)" bottom right transition="slide-x-transition">
        <template v-slot:activator="{ on }">
          <v-btn @click.stop="" v-on="on" icon text x-small class="ml-1">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item dense @click="mudarAmbiente">
            <v-list-item-title>{{ $store.state.autenticacao.session.ambiente === 2 ? 'Desativar' : 'Ativar' }} ambiente de testes</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="consultarStatus('NFe')">
            <v-list-item-title>Consultar Status NF-e</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="consultarStatus('NFCe')">
            <v-list-item-title>Consultar Status NFC-e</v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="consultarStatus('MDFe')">
            <v-list-item-title>Consultar Status MDF-e</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-footer>

    <app-fab />

    <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import AppDrawer from '@/views/layouts/AppDrawer';
import AppToolbar from '@/views/layouts/AppToolbar';
import AppFab from '@/views/layouts/AppFab';
import DialogVisualizarNotificacao from '@/components/dialogs/DialogVisualizarNotificacao';
import DialogVisualizarTodasNotificacoes from '@/components/dialogs/DialogVisualizarTodasNotificacoes';
import DialogSangriaSuprimento from '@/views/dialogs/DialogSangriaSuprimento';
import dialogListarCupom from '@/views/dialogs/DialogListarCupom';

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    DialogVisualizarNotificacao,
    DialogVisualizarTodasNotificacoes,
    DialogSangriaSuprimento,
    dialogListarCupom,
  },

  computed: {
    ...mapState({
      isDevelopment: 'isDevelopment',
    }),

    empresaLogada() {
      return this.$store.getters.empresa.fantasia;
    },

    itemsAcessiveis() {
      const usuario = this.$store.state.autenticacao.session.usuario;

      if (usuario) {
        if (usuario.nivelAcesso === 'NORMAL') {
          const elementos = [];
          this.items.forEach((elemento) => {
            if (elemento.nivel === 1) {
              elementos.push(elemento);
            }
          });
          return elementos;
        } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
          const elementos = [];
          this.items.forEach((elemento) => {
            if (elemento.nivel === 1 || elemento.nivel === 2) {
              elementos.push(elemento);
            }
          });
          return elementos;
        }
      }
      return [];
    },
  },

  methods: {
    mudarAmbiente() {
      this.$refs.dialogConfirmacao.open('Atenção', 'Essa ação irá recarregar o sistema! Tem certeza que deseja continuar?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('descarregarTabelas');
          this.$store.commit('autenticacao/setAmbiente', true);
        }
      });
    },

    consultarStatus(servico) {
      this.$store.dispatch('sefaz/consultarStatusServico', true, servico);
    },

    // processarMensagemMiddleware(mensagem) {
    //   if (mensagem.operacao) {
    //     if (mensagem.operacao === 'StatusServico') {
    //       if (mensagem.status == 107) {
    //         this.$store.commit('setStatusServicoNFe', true);
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     }
    //     if (mensagem.operacao === 'StatusServicoMDFe') {
    //       if (mensagem.status == 107) {
    //         this.$store.commit('setStatusServicoMDFe', true);
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'RetAutorizacaoNFe') {
    //       if (mensagem.status == 100) {
    //         this.$store.commit('nota/atualizarNota', { id: mensagem.id, status: constantes.STATUS_AUTORIZADA, retornoSefaz: mensagem.retornoSefaz });
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'RetAutorizacaoMDFe') {
    //       if (mensagem.status == 100) {
    //         this.$store.commit('manifesto/atualizarManifesto', { id: mensagem.id, status: constantes.STATUS_AUTORIZADA, retornoSefaz: mensagem.retornoSefaz });
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'RecepcaoEventoNFe') {
    //       if (mensagem.status == 128) {
    //         if (mensagem.tpEvento === 'CANCELAMENTO') {
    //           this.$store.commit('nota/adicionarEventoNota', { id: mensagem.id, status: constantes.STATUS_CANCELADA, retornoSefaz: mensagem.retornoSefaz });
    //           this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //         } else if (mensagem.tpEvento === 'CORRECAO') {
    //           this.$store.commit('nota/adicionarEventoNota', { id: mensagem.id, status: undefined, retornoSefaz: mensagem.retornoSefaz });
    //           this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //         }
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'RecepcaoEventoMDFe') {
    //       if (mensagem.status == 128) {
    //         if (mensagem.tpEvento === 'CANCELAMENTO') {
    //           this.$store.commit('manifesto/adicionarEventoManifesto', { id: mensagem.id, status: constantes.STATUS_CANCELADA, retornoSefaz: mensagem.retornoSefaz });
    //           this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //         } else if (mensagem.tpEvento === 'ENCERRAMENTO') {
    //           this.$store.commit('manifesto/adicionarEventoManifesto', { id: mensagem.id, status: constantes.STATUS_ENCERRADO, retornoSefaz: mensagem.retornoSefaz });
    //           this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //         } else if (mensagem.tpEvento === 'INCLUSAO') {
    //           this.$store.commit('manifesto/adicionarEventoManifesto', { id: mensagem.id, status: undefined, retornoSefaz: mensagem.retornoSefaz });
    //           this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //         }
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'InutilizarNFe') {
    //       if (mensagem.status == 102) {
    //         this.$store.dispatch('nota/carregarNotas', { itemsPerPage: 10, page: 1 });
    //         let inut = mensagem.retornoSefaz.inutilizacao;
    //         this.$store.commit('setMensagemSucesso', `Faixa de Numeração ${inut.nInicio} a ${inut.nFim} da série ${inut.serie} inutilizada`);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'ConsultarDestinadasNFe') {
    //       if (mensagem.status == 138) {
    //         this.$store.dispatch('sefaz/carregarDestinadas', { itemsPerPage: 10, page: 1, status: 'pendente' });
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     } else if (mensagem.operacao === 'RecepcaoEventoNFeAN') {
    //       if (mensagem.status == 128) {
    //         this.$store.dispatch('sefaz/carregarDestinadas', { itemsPerPage: 10, page: 1, status: 'pendente' });
    //         this.$store.commit('setMensagemSucesso', mensagem.motivo);
    //       } else {
    //         this.$store.commit('setMensagemErro', mensagem.motivo);
    //       }
    //     }
    //   }
    // },
  },
};
</script>

<style lang="stylus" scoped>
.setting-fab
  top:50%!important;
  right:0;
  border-radius:0
.page-wrapper
  min-height:calc(100vh - 64px - 50px - 81px );
</style>
