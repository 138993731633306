export default [
  {
    path: '/tabelas/cfop',
    name: 'cfop',
    component: () => import(/* webpackChunkName: "listaImportacaoCFOP" */ '@/views/cadastros/ListaImportarCFOP'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_cfop' },
  },
  {
    path: '/tabelas/cfop/detalhar',
    name: 'cfopDetalhar',
    component: () => import(/* webpackChunkName: "formularioCFOP" */ '@/views/cadastros/FormularioCFOP'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'detalhar_cfop' },
  },
];
