<template>
  <v-container fluid>
    <v-form v-model="valid" ref="formFabricante">
      <v-widget title="Fabricante" class="pb-0">
        <template v-slot:widget-content>
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="Nome" v-model.trim="fabricante.nome" :rules="[rules.obrigatorio]" autofocus @keypress.enter="salvar" required placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" />
            </v-col>
          </v-row>

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="loading" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </v-container>
        </template>
      </v-widget>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioFabricante',
  data: () => ({
    valid: false,
    rules: { ...regras },
    loading: false,
  }),

  computed: {
    ...mapState('fabricante', {
      fabricante: 'fabricante',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formFabricante.validate()) {
        this.loading = true;
        if (this.fabricante._id) {
          const result = await this.$store.dispatch('fabricante/editar');
          this.loading = false;
          if (!result) return;
        } else {
          const result = await this.$store.dispatch('fabricante/adicionar');
          this.loading = false;
          if (!result) return;
        }

        this.$router.push({ name: 'fabricante' });
      }
    },

    cancelar() {
      this.$router.push({ name: 'fabricante' });
    },
  },
};
</script>
