import axios from '@/util/axios';

async function buscarDescricaoCfop(cfop) {
  return axios.get('/cfop/' + cfop + '/' + '1').then((response) => response.data);
}

async function tratarConteudo(objetos) {
  return axios.post('/cfop', objetos).then((response) => response.data);
}

async function carregarItens() {
  return axios.get('/cfop').then((response) => response.data);
}

export default {
  buscarDescricaoCfop,
  tratarConteudo,
  carregarItens,
};
