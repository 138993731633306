import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/ordens-servicos?` + qs.stringify(options)).then((response) => response.data);
}

function filtrar(options) {
  return axios.get(`/ordens-servicos/filtro?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(ordem_servico) {
  return axios.post(`/ordens-servicos`, ordem_servico).then((response) => response.data);
}

function atualizar(ordem_servico) {
  return axios.put(`/ordens-servicos/${ordem_servico._id}`, ordem_servico).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/ordens-servicos/${id}`).then((response) => response.data);
}

function alterarStatus(ordem_servico, status) {
  return axios.put(`/ordens-servicos/${ordem_servico._id}/status`, { status: status }).then((response) => response.data);
}

function fechamento(ordem_servico, fechamento) {
  return axios.put(`/ordens-servicos/${ordem_servico._id}/fechamento`, { ordem_servico: ordem_servico, fechamento: fechamento }).then((response) => response.data);
}

export default {
  listar,
  filtrar,
  salvar,
  atualizar,
  excluir,
  alterarStatus,
  fechamento,
};
