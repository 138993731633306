import moment from 'moment';

export function clone(objeto) {
  return JSON.parse(JSON.stringify(objeto));
}

export function deepClone(objeto) {
  return JSON.parse(JSON.stringify(objeto));
}

export function hoje(tipo = 'br') {
  if (tipo == 'us') {
    return new Date().toISOString().substring(0, 10);
  }

  return new Date().toLocaleDateString();
}

export function gerarTerminais() {
  const count = 32;
  const terminais = ['Todos'];

  for (let index = 1; index < count; index++) {
    terminais.push(index.toString().padStart(3, '0'));
  }

  return terminais;
}

export function valorBR(valor) {
  if (valor) {
    return valor.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return '0,00';
}

export function maiusculas(texto) {
  if (texto) {
    return texto.toUpperCase();
  }

  return '';
}

export function temPermissao(usuario, permissao) {
  if (permissao === true) {
    return true;
  }

  if (typeof permissao === 'undefined') {
    return true;
  }

  if (!usuario) {
    return false;
  }

  if (usuario.nivelAcesso === 'CONTADOR') {
    return true;
  }

  if (usuario.nivelAcesso === 'ADMINISTRADOR') {
    return true;
  }

  if (!permissao) {
    return false;
  }

  if (usuario.permissoes.length === 0) {
    return false;
  }

  return usuario.permissoes.includes(permissao);
}

export function diasCertificado(validade) {
  if (validade) {
    const dias = moment(validade).diff(moment(), 'days');
    if (dias < 0) {
      return 'Vencido';
    } else if (dias === 0) {
      return '1 dia';
    } else {
      return `${dias} dias`;
    }
  } else {
    return '0 dia';
  }
}

export function gerarIdentificador() {
  return Math.floor(Math.random() * (9999999999 - 0 + 1)) + 0;
}
