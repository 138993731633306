const colorArray = ['#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5'];

class Graficos {
  graficoProdutos(produtos, mesAtual) {
    let tamanhoDescricao = 15;
    let options = {
      tooltip: {
        trigger: 'item',
        formatter: '<strong>{b}</strong><br/>{c} Un ({d}%)',
      },
      legend: {
        show: true,
        type: 'scroll',
        orient: 'horizontal',
        left: 0,
        bottom: 0,
        right: 0,
        data: produtos.map((produto) => {
          return produto.descricao.length > tamanhoDescricao ? produto.descricao.substr(0, tamanhoDescricao) : produto.descricao;
        }),
      },
      series: [
        {
          type: 'pie',
          data: produtos.map((produto, idx) => {
            return {
              name: produto.descricao.length > tamanhoDescricao ? produto.descricao.substr(0, tamanhoDescricao) : produto.descricao,
              value: produto.quantidade,
              itemStyle: { color: colorArray[idx + 4] },
            };
          }),
          radius: '50%',
          center: ['50%', '50%'],
          label: {
            position: 'outer',
            alignTo: 'edge',
            margin: 0,
            color: '#000000',
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              borderWidth: 1,
              borderColor: '#FFFFFF',
            },
          },
        },
      ],
    };

    return options;
  }

  graficoBarra(dadosEixoX, dadosEixoY) {
    let option = {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        data: dadosEixoX,
      },
      yAxis: {},
      series: [
        {
          type: 'bar',
          barGap: '-300%',
          barCategoryGap: '10%',
          animation: true,
          data: dadosEixoY.map((item, idx) => ({ value: item, itemStyle: { color: colorArray[idx] } })),
        },
      ],
    };

    return option;
  }
}

let graficos = new Graficos();

export default graficos;
