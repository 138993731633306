<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" @keydown.up="goUp" @keydown.down="goDown" @keydown.enter="selecionar" persistent scrollable>
    <v-card>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>Buscar CEST</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-toolbar flat color="white">
        <v-text-field
          ref="input"
          flat
          dense
          single-line
          hide-details
          clearable
          v-model="busca"
          @input="onInput"
          max-width="300px"
          prepend-inner-icon="search"
          placeholder="Digite o código ou a descrição do CEST"
          @focus="onFocus"
          solo
          background-color="grey lighten-3"
          autofocus
          persistentPlaceholder
        />
      </v-toolbar>

      <v-divider class="mx-4" />

      <v-card-text style="height: 100%; min-height: 420px" class="pa-4 pt-1">
        <v-virtual-scroll v-if="dados.length > 0" :items="dados" item-height="48" :height="400" bench="2" id="scroller">
          <template v-slot="{ item, index: idx }">
            <div :id="'r-' + idx" :key="idx" :class="idx < dados.length - 1 ? 'row borda' : 'row'" :style="idx === index ? 'background-color: #C5E1A5' : ''" @click="setIndex(idx)" @dblclick="selecionar">
              <div style="width: 15%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.cest }}</div>
              <div style="width: 85%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ item.descricao }}</div>
            </div>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'dialogBuscarCest',

  props: {
    value: {
      type: [Number, String],
      default: '',
    },
  },

  data() {
    return {
      dados: [],
      busca: '',
      index: null,
      dialog: false,
    };
  },

  computed: {
    ...mapState({
      carregando: 'carregando',
    }),
  },

  methods: {
    onInput() {
      if (this.busca) {
        this.dados = this.$store.state.tabelas.cests.filter((row) => `${row.cest}${row.descricao}`.toLocaleLowerCase().includes(this.busca.toLocaleLowerCase()));
      } else {
        this.dados = this.$store.state.tabelas.cests;
      }
      this.index = this.$store.state.tabelas.cests.length ? 0 : null;
      this.scroll();
    },

    scroll() {
      const el = document.getElementById(`r-${this.index}`);
      if (el) {
        el.scrollIntoView(true);
      }
    },

    goUp(e) {
      e.preventDefault();
      e.stopPropagation();
      this.index = this.index > 0 ? this.index - 1 : 0;
      this.scroll();
    },

    goDown(e) {
      e.preventDefault();
      e.stopPropagation();
      this.index = this.index < this.dados.length - 1 ? this.index + 1 : this.dados.length - 1;
      this.scroll();
    },

    setIndex(idx) {
      this.index = idx;
    },

    selecionar(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.dados[this.index]) {
        this.$emit('onSelect', this.dados[this.index]);
        this.fechar();
      }
    },

    abrir() {
      this.dialog = true;
      this.busca = this.value;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });

      if (this.busca) {
        this.onInput(this.busca);
      }
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    onFocus(e) {
      e.target.selectionStart = 0;
      e.target.selectionEnd = e.target.value.length;
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  height: 48px;
  flex-direction: row;
  width: 100%;
  padding: 0px 12px;
  margin: 0px;
  align-items: center;
  cursor: default;
}
.borda {
  border-bottom: 1px solid silver;
}
.row:hover {
  background: #f1f8e9;
}
</style>
