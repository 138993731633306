import axios from '@/util/axios';

function listar() {
  return axios.get('/notificacoes/query?lida=false').then((response) => response.data);
}

function listarTodas() {
  axios.get('/notificacoes').then((response) => response.data);
}

function marcarLida(notificacao) {
  return axios.put('/notificacoes/' + notificacao._id).then((response) => response.data);
}

function marcarNaoLida(notificacao) {
  return axios.patch('/notificacoes/' + notificacao._id).then((response) => response.data);
}

export default {
  listar,
  listarTodas,
  marcarLida,
  marcarNaoLida,
};
