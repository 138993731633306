import constantes from '@/util/constantes';
import moment from 'moment';
import formatador from '@/util/formatador';
import conversor from '@/util/conversor';
import manifestosApi from '@/services/manifestosApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    cidadesCarga: [],
    cidadesDescarga: [],
    manifestos: [],
    manifestoAtual: {
      chave: '',
      modelo: 58,
      serie: '001',
      numero: '',
      modal: 1,
      data_emissao: '',
      hora_emissao: moment().format('HH:MM:SS'),
      data_viagem: '',
      hora_viagem: moment().format('HH:MM:SS'),
      uf_origem: '',
      uf_destino: '',
      codigo_mun_carga: '',
      municipio_carga: '',
      codigo_mun_descarga: '',
      municipio_descarga: '',
      valor_carga: '',
      codigo_unidade: '01',
      peso_carga: '',
      estados_rota: [],
      ciot: '',
      codAgPorto: '',
      versao_modal: '3.00',
      emitente_nao_proprietario: true,
      emissor: {
        rntrc: '',
        tipo_emitente: 2,
      },
      veiculo: {
        placa: '',
        uf: '',
        renavam: '',
        tara: '',
        capacidade_peso: '',
        capacidade_volume: '',
        tipo_rodado: '',
        tipo_carroceria: '',
      },
      proprietario_veiculo: {
        cpf: '',
        rntrc: '',
        razaoSocialNome: '',
        ie: '',
        uf: '',
        tipo_proprietario: '',
      },
      condutor: {
        nome: '',
        cpf: '',
      },
      notas: [],
      total_peso: '0,00',
    },
    constantes: constantes,
    estados: constantes.ESTADOS,
    modoEdicao: false,
    carregando: false,
    totalManifestos: 0,
  },

  //equivalente ao computed properties
  getters: {
    estadosDisponiveis(state) {
      let retornoEstados = state.estados;
      let ufOrigem = state.manifestoAtual.uf_origem;
      let ufDestino = state.manifestoAtual.uf_destino;
      if (ufOrigem) {
        retornoEstados = retornoEstados.filter((elemento) => elemento.text != ufOrigem);
      }
      if (ufDestino) {
        retornoEstados = retornoEstados.filter((elemento) => elemento.text != ufDestino);
      }
      return retornoEstados.sort((a, b) => {
        let textoA = a.text.toLowerCase();
        let textoB = b.text.toLowerCase();
        if (textoA < textoB) return -1;
        if (textoA > textoB) return 1;
        return 0;
      });
    },
    totalNotas(state) {
      return state.manifestoAtual.notas.length;
    },
    totalValor(state) {
      return state.manifestoAtual.notas.reduce((valorAnterior, nota) => valorAnterior + nota.valor, 0);
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregarManifestos({ commit }, options) {
      try {
        commit('setCarregando', true);
        let response = await manifestosApi.carregarManifestos(options);
        commit('setManifestos', response.manifestos);
        commit('setTotalManifestos', response.total);
      } catch (error) {
        commit('setManifestos', []);
        commit('setMensagemErro', 'Falha ao buscar manifestos', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async consultarCidadeCarga({ commit }, nome) {
      try {
        commit('setCarregando', true);
        let response = await manifestosApi.consultarCidadeCarga(nome);
        commit('setCidadesCarga', response);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async consultarCidadeDescarga({ commit }, nome) {
      try {
        commit('setCarregando', true);
        let response = await manifestosApi.consultarCidadeDescarga(nome);
        commit('setCidadesDescarga', response);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async reprocessarManifesto({ commit }, idManifesto) {
      try {
        commit('setCarregando', true);
        let response = await manifestosApi.reprocessarManifesto(idManifesto);
        if (response.autorizacao) {
          commit('atualizarManifesto', response);
          commit('setMensagemSucesso', 'Manifesto autorizado', { root: true });
        } else if (response.recibo_autorizacao) {
          commit('atualizarManifesto', response);
          commit('setMensagemSucesso', 'Lote processado', { root: true });
        } else if (response.retorno_sefaz) {
          commit('setMensagemErro', response.retorno_sefaz.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async cancelarManifesto({ commit }, manifesto) {
      try {
        commit('setCarregando', true);
        let cancelamento = {
          idManifesto: manifesto._id,
          chaveMDFe: manifesto.chave,
          tpEvento: 'CANCELAMENTO',
          seqEvento: '01',
          nProtocolo: manifesto.autorizacao.protocolo,
          justificativa: 'CANCELAMENTO DE MANIFESTO',
        };
        let response = await manifestosApi.cancelarManifesto(cancelamento);
        if (response.status == 135 || response.status == 136) {
          manifesto.status = constantes.STATUS_CANCELADA;
          response.manifesto = manifesto;
          commit('setEvento', response);
          commit('setMensagemSucesso', response.motivo, { root: true });
        } else {
          commit('setMensagemErro', response.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async adicionarCondutor({ commit }, condutor) {
      try {
        commit('setCarregando', true);
        let adicaoCondutor = {
          idManifesto: condutor.idManifesto,
          chaveMDFe: condutor.chave,
          tpEvento: 'INCLUSAO',
          seqEvento: condutor.seqEvento,
          nomeCondutor: condutor.nome,
          cpfCondutor: condutor.cpf,
        };
        let response = await manifestosApi.adicionarCondutor(adicaoCondutor);
        if (response.status == 135 || response.status == 136) {
          response.manifesto = {
            _id: condutor.idManifesto,
            status: 'Autorizada',
          };
          commit('setEvento', response);
          commit('setMensagemSucesso', response.motivo, { root: true });
        } else if (response.status == 200) {
          commit(setMensagemSucesso, response.motivo, { root: true });
        } else {
          commit('setMensagemErro', response.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async encerrarManifesto({ commit }, manifesto) {
      try {
        commit('setCarregando', true);
        let ufEncerramento = conversor.ufParaCodigo(manifesto.uf_destino);
        let encerrar = {
          idManifesto: manifesto._id,
          chaveMDFe: manifesto.chave,
          tpEvento: 'ENCERRAMENTO',
          seqEvento: '01',
          nProtocolo: manifesto.autorizacao.protocolo,
          justificativa: 'Encerramento de manifesto',
          ufEncerramento: ufEncerramento,
          cMunEncerramento: manifesto.codigo_mun_descarga,
        };
        let response = await manifestosApi.encerrarManifesto(encerrar);
        if (response.status == 135 || response.status == 136) {
          manifesto.status = constantes.STATUS_ENCERRADO;
          response.manifesto = manifesto;
          commit('setEvento', response);
          commit('setMensagemSucesso', response.motivo, { root: true });
        } else {
          commit('setMensagemErro', response.motivo, { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        let manifesto = Object.assign({}, state.manifestoAtual);

        manifesto.valor_carga = state.manifestoAtual.notas.reduce((valorAnterior, nota) => valorAnterior + nota.valor, 0);
        manifesto.peso_carga = conversor.stringParaNumero(state.manifestoAtual.total_peso);

        manifesto.veiculo.tara = conversor.stringParaNumero(manifesto.veiculo.tara);
        manifesto.veiculo.capacidade_peso = conversor.stringParaNumero(manifesto.veiculo.capacidade_peso);
        manifesto.veiculo.capacidade_volume = conversor.stringParaNumero(manifesto.veiculo.capacidade_volume);

        //removendo caracteres de máscara
        manifesto.proprietario_veiculo.cpf = manifesto.proprietario_veiculo.cpf.replace('.', '').replace('.', '').replace('-', '');
        manifesto.condutor.cpf = manifesto.condutor.cpf.replace('.', '').replace('.', '').replace('-', '');

        if (state.modoEdicao) {
          let response = await manifestosApi.atualizar(manifesto);
          commit('atualizarManifesto', response);
          commit('setMensagemSucesso', 'Manifesto atualizado com sucesso', { root: true });
        } else {
          delete manifesto._id;
          let response = await manifestosApi.salvar(manifesto);
          commit('adicionarManifesto', response);
          commit('setMensagemSucesso', 'Manifesto cadastrado com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setEvento(state, evento) {
      let manifesto = evento.manifesto;
      let manifestoEncontrado = state.manifestos.find((manif) => {
        return manif._id == manifesto._id;
      });
      if (manifestoEncontrado) {
        manifestoEncontrado.eventos.push(evento.evento);
        manifestoEncontrado.status = manifesto.status;
      }
    },
    setTotalManifestos(state, total) {
      state.totalManifestos = total;
    },
    setManifestos(state, manifestos) {
      state.manifestos = manifestos;
      state.carregando = false;
    },
    adicionarManifesto(state, manifesto) {
      state.manifestos.unshift(manifesto);
      state.carregando = false;
    },
    atualizarManifesto(state, atual) {
      let index = state.manifestos.findIndex((manifesto) => manifesto._id === atual._id);
      state.manifestos.splice(index, 1, atual);
      state.carregando = false;
    },
    adicionarNotas(state, novasNotas) {
      novasNotas.forEach((novaNota) => {
        let existe = state.manifestoAtual.notas.find((nota) => {
          return nota.chave == novaNota.chave;
        });
        if (!existe) {
          state.manifestoAtual.notas.push(novaNota);
        }
      });
    },
    removerNota(state, nota) {
      state.manifestoAtual.notas = state.manifestoAtual.notas.filter((n) => {
        return n.chave != nota.chave;
      });
    },
    adicionarEstadoRota(state, estado) {
      state.estados = state.estados.filter((est) => {
        return est.value !== estado.value;
      });
      state.manifestoAtual.estados_rota.push(estado);
    },
    removerEstadoRota(state, estado) {
      state.manifestoAtual.estados_rota = state.manifestoAtual.estados_rota.filter((est) => {
        return est.value !== estado.value;
      });
      state.estados.push(estado);
    },
    setCidadesCarga(state, cidades) {
      state.cidadesCarga = cidades;
    },
    setCidadesDescarga(state, cidades) {
      state.cidadesDescarga = cidades;
    },
    setLocalCarga(state, cidade) {
      state.manifestoAtual.codigo_mun_carga = cidade.codigo_cidade;
      state.manifestoAtual.municipio_carga = cidade.cidade;
      state.manifestoAtual.uf_origem = cidade.estado;
    },
    setLocalDescarga(state, cidade) {
      state.manifestoAtual.codigo_mun_descarga = cidade.codigo_cidade;
      state.manifestoAtual.municipio_descarga = cidade.cidade;
      state.manifestoAtual.uf_destino = cidade.estado;
    },
    visualizar(state, manifesto) {
      state.manifestoAtual = manifesto;
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      let editarManifesto = Object.assign({}, editar);
      editarManifesto.data_emissao = moment(editar.data_emissao).format('YYYY-MM-DD');
      editarManifesto.data_viagem = moment(editar.data_viagem).format('YYYY-MM-DD');
      editarManifesto.total_peso = formatador.formatarValor(editarManifesto.peso_carga);

      state.manifestoAtual = editarManifesto;
      state.carregando = false;
    },
    atualizarManifesto(state, { id, status, retornoSefaz }) {
      let manifesto = state.manifestos.find((manifesto) => manifesto._id == id);
      if (manifesto) {
        manifesto.status = status;
        manifesto.protocolo = retornoSefaz.protocolo;
        manifesto.autorizacao = retornoSefaz;
      }
    },
    adicionarEventoManifesto(state, { id, status, retornoSefaz }) {
      let manifesto = state.manifestos.find((manifesto) => manifesto._id == id);
      if (manifesto) {
        if (status) {
          manifesto.status = status;
        }
        manifesto.eventos.push(retornoSefaz.evento);
      }
    },
    estadoInicial(state) {
      state.manifestoAtual = {
        chave: '',
        modelo: 58,
        serie: '001',
        numero: '',
        modal: 1,
        data_emissao: '',
        hora_emissao: moment().format('HH:MM:SS'),
        data_viagem: '',
        hora_viagem: moment().add(1, 'hour').format('HH:MM:SS'),
        uf_origem: '',
        uf_destino: '',
        codigo_mun_carga: '',
        municipio_carga: '',
        codigo_mun_descarga: '',
        municipio_descarga: '',
        valor_carga: '',
        codigo_unidade: '01',
        peso_carga: '',
        estados_rota: [],
        ciot: '',
        codAgPorto: '',
        versao_modal: '3.00',
        emitente_nao_proprietario: true,
        emissor: {
          rntrc: '',
          tipo_emitente: 2,
        },
        veiculo: {
          placa: '',
          uf: '',
          renavam: '',
          tara: '',
          capacidade_peso: '',
          capacidade_volume: '',
          tipo_rodado: '',
          tipo_carroceria: '',
        },
        proprietario_veiculo: {
          cpf: '',
          rntrc: '',
          razaoSocialNome: '',
          ie: '',
          uf: '',
          tipo_proprietario: '',
        },
        condutor: {
          nome: '',
          cpf: '',
        },
        notas: [],
        total_peso: '0,00',
      };
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      (state.manifestoAtual = {
        chave: '',
        modelo: 58,
        serie: '001',
        numero: '',
        modal: 1,
        data_emissao: '',
        hora_emissao: moment().format('HH:MM:SS'),
        data_viagem: '',
        hora_viagem: moment().add(1, 'hour').format('HH:MM:SS'),
        uf_origem: '',
        uf_destino: '',
        codigo_mun_carga: '',
        municipio_carga: '',
        codigo_mun_descarga: '',
        municipio_descarga: '',
        valor_carga: '',
        codigo_unidade: '01',
        peso_carga: '',
        estados_rota: [],
        ciot: '',
        codAgPorto: '',
        versao_modal: '3.00',
        emitente_nao_proprietario: true,
        emissor: {
          rntrc: '',
          tipo_emitente: 2,
        },
        veiculo: {
          placa: '',
          uf: '',
          renavam: '',
          tara: '',
          capacidade_peso: '',
          capacidade_volume: '',
          tipo_rodado: '',
          tipo_carroceria: '',
        },
        proprietario_veiculo: {
          cpf: '',
          rntrc: '',
          razaoSocialNome: '',
          ie: '',
          uf: '',
          tipo_proprietario: '',
        },
        condutor: {
          nome: '',
          cpf: '',
        },
        notas: [],
        total_peso: '0,00',
      }),
        (state.carregando = false);
    },
  },
};
