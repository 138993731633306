import ListaContaCorrente from '@/views/contacorrente/ListaContaCorrente';
import FormularioContaCorrente from '@/views/contacorrente/FormularioContaCorrente';

export default [
  {
    path: '/tabelas/contas-correntes',
    name: 'contasCorrentes',
    component: ListaContaCorrente,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessarContasCorrentes' },
  },
  {
    path: '/tabelas/contas-correntes/nova',
    name: 'formularioContaCorrenteNova',
    component: FormularioContaCorrente,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionarContasCorrentes' },
  },
  {
    path: '/tabelas/contas/editar',
    name: 'formularioContaCorrenteEditar',
    component: FormularioContaCorrente,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterarContasCorrentes' },
  },
];
