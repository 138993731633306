import axios from '@/util/axios';

async function tratarConteudo(objetos) {
  return axios.post('/ibpt/PE', objetos).then((response) => response.data);
}

async function carregarItens() {
  return axios.get('/ibpt').then((response) => response.data);
}

export default {
  tratarConteudo,
  carregarItens,
};
