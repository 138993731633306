import axios from '@/util/axios';

function vendasPorMes(periodo) {
  return axios.post(`/graficos/vendas-por-mes`, periodo).then((response) => response.data);
}

function vendasPorIntervaloData(periodo) {
  return axios.post(`/graficos/vendas-por-intervalo-data`, periodo).then((response) => response.data);
}

export default {
  vendasPorMes,
  vendasPorIntervaloData,
};
