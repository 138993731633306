import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import empresasApi from '@/services/empresasApi';
import sistemaApi from '@/services/sistemaApi';

const novaEmpresa = {
  _id: null,
  matriz: null,
  cnpj: '',
  razao: '',
  fantasia: '',
  ie: '',
  im: '',
  cnae: '',
  descricaoCnae: '',
  status: 'Ativa',
  calculoCusto: constantes.CALCULO_MEDIA,
  fretePadrao: '9',
  formaPagamentoPadrao: '0',
  telefone: '',
  email: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  codigoCidade: '',
  estado: '',
  uf: '',
  pais: '',
  codigoPais: '',
  logotipo: '',
  versao: '4.00',
  formatoImpressao: '1',
  usuario: { nome: '', email: '', celular: '' },
  modulos: {
    cupomFiscal: false,
    notaFiscal: false,
    manifesto: false,
    notaFiscalDeposito: false,
    retaguarda: false,
    conhecimento: false,
  },
  configuracaoTributaria: {},
  configuracaoSistema: {},
};

export default {
  namespaced: true,

  state: {
    empresas: [],
    empresa: { ...novaEmpresa },
    carregando: false,
  },

  actions: {
    async consultarCnae({ commit }, cnae) {
      try {
        commit('setCarregando', true);
        const response = await empresasApi.consultarCnae(cnae);
        if (response.length > 0) {
          commit('setDescricaoCnae', response);
        } else {
          commit('setMensagemErro', 'CNAE não encontrado', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async carregarTodas({ commit }) {
      try {
        commit('setCarregando', true);
        const data = await empresasApi.carregarTodas();
        commit('setEmpresas', data);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async carregarEmpresa({ commit }, empresaId) {
      try {
        commit('setCarregando', true);
        const response = await empresasApi.carregarEmpresa(empresaId);
        commit('setEmpresa', response);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        const empresa = { ...state.empresa };

        empresa.cnpj = empresa.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

        empresa.cep = empresa.cep.replace('.', '').replace('-', '');
        empresa.cnae = empresa.cnae.replace('-', '').replace('/', '');
        empresa.telefone = empresa.telefone.replace('(', '').replace(')', '').replace('-', '');

        if (empresa._id) {
          const response = await empresasApi.atualizar(empresa);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'Empresa atualizada com sucesso', { root: true });
        } else {
          delete empresa._id;
          empresa.tema = 'green';
          const response = await empresasApi.salvar(empresa);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'Empresa cadastrada com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },

    async remover({ commit }, empresa) {
      try {
        commit('setCarregando', true);
        await empresasApi.excluir(empresa);
        commit('remover', empresa);
        commit('setMensagemSucesso', 'Empresa Removida com Sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, value) {
      state.carregando = value;
    },

    estadoInicial(state) {
      state.empresa = { ...novaEmpresa };
      state.cidades = [];
    },

    setEmpresas(state, empresas) {
      state.empresas = empresas;
      state.carregando = false;
    },

    setEmpresa(state, empresa) {
      state.empresa = empresa;
      state.carregando = false;
    },

    setCidade(state, codigoCidade) {
      const cidade = state.cidades.find((cidade) => cidade.codigoCidade == codigoCidade);

      state.empresa.cidade = cidade.cidade;
      state.empresa.codigo_cidade = cidade.codigoCidade;
      state.empresa.uf = cidade.codigoEstado;
      state.empresa.pais = 'Brasil';
      state.empresa.codigoPais = 1058;
    },

    setDadosEmpresa(state, empresa) {
      state.empresa.cnae = empresa.cnae;
      state.empresa.ie = empresa.ie + '-' + empresa.uf;
      state.empresa.razao = empresa.razao;
      state.empresa.fantasia = empresa.fantasia;
      state.empresa.numero = empresa.endereco.numero;
      state.empresa.cep = empresa.endereco.cep;

      const encontrado = constantes.REGIMES_TRIBUTARIOS.find((regime) => {
        return regime.text.toLowerCase().indexOf(empresa.regime.toLowerCase()) !== -1;
      });

      if (encontrado) {
        state.empresa.configuracao_tributaria.regime_tributario = encontrado.value;
      }
    },

    setLogotipo(state, logo) {
      state.empresa.logotipo = logo;
    },

    setEndereco(state, endereco) {
      state.empresa.logradouro = endereco.logradouro;
      state.empresa.bairro = endereco.bairro;
      state.empresa.uf = conversor.ufParaCodigo(endereco.uf);
      state.empresa.estado = endereco.uf;
      state.empresa.cidade = endereco.localidade;
      state.empresa.codigoCidade = endereco.ibge;
      state.empresa.pais = 'Brasil';
      state.empresa.codigoPais = 1058;
    },

    setDescricaoCnae(state, dadosCnae) {
      state.empresa.descricaoCnae = dadosCnae[0].descricao;
    },

    setInfoComplementar(state, infoComplementar) {
      state.empresa.configuracao_tributaria.info_complementar = infoComplementar;
    },

    remover(state, id) {
      state.empresas = state.empresas.filter((empresa) => empresa._id !== id);
      state.carregando = false;
    },

    adicionar(state) {
      state.empresa = { ...novaEmpresa };
      state.carregando = false;
    },

    editar(state, editar) {
      state.empresa = editar;
      state.carregando = false;
    },

    salvar(state, empresa) {
      state.empresas.push(empresa);
      state.carregando = false;
    },

    atualizar(state, atual) {
      state.empresas.forEach((empresa) => {
        if (empresa._id === atual._id) {
          empresa = atual;
        }
      });
      state.carregando = false;
    },

    preencherEmailPadrao(state) {
      state.empresa.usuario.email = state.empresa.email;
    },

    atualizarConfigCfop(state) {
      if (state.empresa.configuracao_tributaria.config_cfop_padrao === '1') {
        if (state.empresa.configuracao_tributaria.ramo_atividade === '1') {
          state.empresa.configuracao_tributaria.cfop_padrao = '5102';
        } else if (state.empresa.configuracao_tributaria.ramo_atividade === '3') {
          state.empresa.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },
  },
};
