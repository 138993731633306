<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="fechar">
    <v-form ref="formSangriaSuprimento" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title class="white--text" v-if="tipo === constantes.SANGRIA">Sangria</v-toolbar-title>
          <v-toolbar-title class="white--text" v-else-if="tipo === constantes.SUPRIMENTO">Suprimento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid class="pa-0 pt-3">
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select label="Forma de Pagamento" ref="forma_pagamento" :items="constantes.FORMAS_PAGAMENTO || []" persistent-placeholder outlined v-model="lancamento.forma_pagamento" @keypress.native.enter="$refs.valor.focus()"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <as-campo-valor
                  label="Valor"
                  ref="valor"
                  persistent-placeholder
                  outlined
                  v-model="lancamento.valor"
                  decimals="2"
                  :rules="[rules.obrigatorio, rules.numeroValido, rules.valorMaiorQueZero]"
                  @keypress.native.enter="$refs.descricao.focus()"
                  autofocus
                ></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field label="Descrição" ref="descricao" persistent-placeholder outlined v-model="lancamento.descricao" :rules="[rules.obrigatorio]" @keypress.enter="confirmar"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid>
            <v-row dense>
              <v-col class="text-right">
                <v-btn depressed class="mr-2" @click="fechar">Cancelar</v-btn>
                <v-btn depressed color="primary" :loading="loading" @click="confirmar">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'DialogoSangriaSuprimento',
  props: ['dialog', 'tipo'],

  data() {
    return {
      loading: false,
      rules: { ...regras },
      constantes: constantes,
      lancamento: {
        forma_pagamento: 'DINHEIRO',
        valor: '',
        tipo: '',
      },
    };
  },

  methods: {
    fechar() {
      this.$emit('fechar');
      this.limpar();
    },
    confirmar() {
      if (this.$refs.formSangriaSuprimento.validate()) {
        try {
          this.loading = true;

          if (this.tipo === constantes.SANGRIA) {
            this.lancamento.tipo = constantes.DEBITO;
          } else if (this.tipo === constantes.SUPRIMENTO) {
            this.lancamento.tipo = constantes.CREDITO;
          }

          this.$emit('adicionarLancamento', this.lancamento);
          this.limpar();
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        } finally {
          this.loading = false;
        }
      } else {
        setTimeout(() => {
          this.$refs.valor.focus();
        }, 1000);
      }
    },
    limpar() {
      this.lancamento = {
        forma_pagamento: 'DINHEIRO',
        valor: '',
        tipo: '',
      };
    },
  },
};
</script>
