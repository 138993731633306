import store from '@/store/store';
import { temPermissao } from '@/util/helpers';

const RouterGuards = (to, from, next) => {
  if (from.path.substring(0, 4) === '/pdv' && store.state.venda.venda.itens.length > 0) {
    next(false);
    return false;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.autenticacao.session.tokens.accessToken && !['/login'].includes(to.path)) {
      next({ path: '/login' });
      return false;
    }

    next();
  } else {
    next();
  }

  if (to.meta.permissao) {
    if (!temPermissao(store.state.autenticacao.session.usuario, to.meta.permissao)) {
      next({
        path: '/sem-permissao',
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let nivel = -1;

    if (store.state.autenticacao.session.usuario.nivelAcesso === 'NORMAL') {
      nivel = 1;
    } else if (store.state.autenticacao.session.usuario.nivelAcesso === 'ADMINISTRADOR') {
      nivel = 2;
    } else if (store.state.autenticacao.session.usuario.nivelAcesso === 'CONTADOR') {
      nivel = 3;
    } else if (store.state.autenticacao.session.usuario.nivelAcesso === 'ANRON') {
      nivel = 4;
    }

    if (to.matched.some((record) => record.meta.nivel > nivel)) {
      next(false);
    }
  }

  if (to.path.substring(0, 4) === '/pdv' && store.state.autenticacao.session.modulo !== 'cupomFiscal') {
    store.commit('autenticacao/setSession', { modulo: 'retaguarda' });
  } else if (from.path.substring(0, 4) === '/pdv' && to.path.substring(0, 4) !== '/pdv' && store.state.autenticacao.session.modulo === 'cupomFiscal') {
    store.commit('autenticacao/setSession', { modulo: 'retaguarda' });
  }
};

export default RouterGuards;
