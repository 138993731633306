import ListaCompras from '@/views/compras/ListaCompras';
import FormularioCompra from '@/views/compras/FormularioCompra';
import FormularioAcertoCompra from '@/views/compras/FormularioAcertoCompra';
import FormularioImportarXMLCompra from '@/views/compras/FormularioImportarXMLCompra';

export default [
  {
    path: '/movimentacoes/notasentrada',
    name: 'compras',
    component: ListaCompras,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_compras' },
  },
  {
    path: '/movimentacoes/notasentrada/editar',
    name: 'formularioCompraEditar',
    // component: FormularioCompra,
    component: FormularioImportarXMLCompra,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_compras' },
  },
  {
    path: '/movimentacoes/notasentrada/importar',
    name: 'formularioCompraImportar',
    component: FormularioImportarXMLCompra,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_compras' },
  },
];
