import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/cheques?` + qs.stringify(options)).then((response) => response.data);
}

function filtrar(options) {
  return axios.get(`/cheques/filtro?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(cheque) {
  return axios.post(`/cheques`, cheque).then((response) => response.data);
}

function alterarStatus(cheque, status) {
  return axios.put(`/cheques/${cheque._id}/status`, status).then((response) => response.data);
}

function atualizar(cheque) {
  return axios.put(`/cheques/${cheque._id}`, cheque).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/cheques/${id}`).then((response) => response.data);
}

function filtrarCidadesEstado(estado) {
  return axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
}

export default {
  listar,
  filtrar,
  salvar,
  atualizar,
  excluir,
  alterarStatus,
  filtrarCidadesEstado
};
