import axios from '@/util/axios';
import axiosCep from 'axios';

function alterarEmpresa(empresaId) {
  return axios.get('/empresas/' + empresaId + '/certificado').then((response) => response.data);
}

function carregarTodas() {
  return axios.get('/empresas').then((response) => response.data);
}

function carregarEmpresa() {
  return axios.get('/empresas').then((response) => response.data);
}

function salvar(empresa) {
  return axios.post('/empresas', empresa).then((response) => response.data);
}

function atualizar(empresa) {
  return axios.put('/empresas', empresa).then((response) => response.data);
}

function carregarContador() {
  return axios.get(`/empresas/contador`).then((response) => response.data);
}

function consultarCertificadoEmpresa() {
  return axios.get(`/empresas/certificado`).then((response) => response.data);
}

function processarCertificado(certificado) {
  return axios.put(`/empresas/certificado`, certificado).then((response) => response.data);
}

function consultarCnae(cnae) {
  return axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae).then((response) => response.data);
}

function excluir() {
  return axios.delete(`/empresas`).then((response) => response.data);
}

export default {
  carregarEmpresa,
  alterarEmpresa,
  salvar,
  atualizar,
  carregarTodas,
  consultarCertificadoEmpresa,
  processarCertificado,
  consultarCnae,
  excluir,
  carregarContador,
};
