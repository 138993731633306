export function formatarData(data) {
  return new Date(data).toLocaleDateString('pt-br', { timeZone: 'UTC' });
}

export function formatarValor(valor, decimais = 2) {
  return valor.toLocaleString('pt-br', { maximumFractionDigits: decimais, minimumFractionDigits: decimais });
}

export function formatarDataHora(value) {
  const date = new Date(value).toLocaleDateString('pt-br', { timeZone: 'America/Recife' });
  const time = new Date(value).toLocaleTimeString('pt-br', { timeZone: 'America/Recife' });
  const dateTime = `${date} - ${time}`;
  return dateTime;
}
