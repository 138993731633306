const Menu = [
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1,
    permissao: true,
  },
  { cabecalho: 'Cadastros', nivel: 1, permissao: 'exibir_cadastros' },
  { divider: true, nivel: 1 },
  {
    nome: 'Clientes / Fornecedores',
    titulo: 'Clientes / Fornecedores',
    path: '/cadastros/participantes',
    icone: 'mdi-account-multiple',
    grupo: 'Cadastros',
    nivel: 1,
    permissao: 'acessar_participantes',
  },
  {
    nome: 'Produto',
    titulo: 'Produtos',
    path: '/cadastros/produtos',
    icone: 'fas fa-tags',
    iconeSize: 18,
    grupo: 'Cadastros',
    nivel: 1,
    permissao: 'acessar_produtos',
  },
  {
    nome: 'Estoque',
    titulo: 'Estoque',
    path: '/movimentacoes/estoques',
    icone: 'fas fa-boxes',
    iconeSize: 22,
    grupo: 'Cadastros',
    nivel: 1,
    permissao: 'acessar_movimentosestoque',
  },
  {
    nome: 'Transportador',
    titulo: 'Transportadores',
    path: '/cadastros/transportadores',
    icone: 'local_shipping',
    grupo: 'Cadastros',
    nivel: 1,
    permissao: 'acessar_transportadores',
  },
  { cabecalho: 'Fiscal', nivel: 1, permissao: 'exibir_fiscal' },
  { divider: true, nivel: 1 },
  {
    titulo: 'Notas Fiscais',
    grupo: 'Fiscal',
    nome: 'Nota',
    icone: 'description',
    path: '/notas',
    nivel: 1,
    permissao: 'acessar_notasfiscais',
  },
  {
    titulo: 'Notas Destinadas',
    grupo: 'Fiscal',
    nome: 'NotasDestinadas',
    icone: 'archive',
    path: '/destinadas',
    nivel: 1,
    permissao: 'acessar_notas_destinadas',
  },
  {
    titulo: 'Arquivos Contabilidade',
    grupo: 'Fiscal',
    nome: 'Arquivos',
    icone: 'fas fa-file-archive',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/arquivos',
    nivel: 2,
  },
];

export default Menu;
