import axios from '@/util/axios';
import constantes from '@/util/constantes';
import moment from 'moment';
import conversor from '@/util/conversor';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    compras: [],
    compraAtual: {
      numero: '',
      data: moment().format('YYYY-MM-DD'),
      data_entrada: moment().format('YYYY-MM-DD'),
      fornecedor: {
        razaoSocialNome: '',
      },
      valor_produtos: '',
      valor_nfe: '',
      quantidade_total_produtos: 0,
      total: '',
      itens: [],
      pagamentos: [],
      estoque_nulo: false,
      cancelada: false,
      informativa: false,
    },
    produtoAtual: {
      produto: '',
      codigo_entrada: '',
      codigo: '',
      descricao: '',
      unidade: '',
      valor_custo: 0,
      quantidade: 0,
      ncm: '',
      cest: '',
      cfop: '',
      fator_conversao: {
        entrada: 1,
        saida: 1,
      },
      cst_csosn: '',
      base_calculo_icms: 0,
      aliquota_icms: 0,
      valor_icms: 0,
      base_calculo_ipi: 0,
      aliquota_ipi: 0,
      valor_ipi: 0,
      pis: '',
      valor_pis: 0,
      cofins: '',
      valor_cofins: 0,
      base_calculo_icms_st: 0,
      aliquota_icms_st: 0,
      valor_icms_st: 0,
      icms_sn: 0,
      base_calculo_ii: 0,
      valor_ii: 0,
      aduaneira: 0,
      informativo: false,
      fronteira: {
        frete: 0,
        outras: 0,
        subtotal: 0,
        aliquota_interna: 0,
        valor_icms_interno: 0,
        quantidade: 0,
        valor_icms: 0,
        custo_produto: 0,
        margem_lucro_percentual: 0,
        margem_lucro_valor: 0,
        das_percentual: 0,
        das_valor: 0,
        valor_venda: 0,
      },
    },
    arquivoXml: {
      chave: '',
      conteudo: '',
    },
    fornecedores: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    reprocessarDuplicatas: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    fornecedoresBusca(state) {
      let fornecedorFiltro = [];
      state.fornecedores.forEach((fornecedor) => {
        fornecedorFiltro.push({ text: fornecedor.razaoSocialNome, value: fornecedor._id });
      });
      return fornecedorFiltro;
    },
    dataFormatada(state) {
      if (state.compraAtual.data != '') {
        let data = moment(state.compraAtual.data, 'YYYY-MM-DD').format('DD/MM/YY');
        return data;
      } else {
        return '';
      }
    },
    totalProdutos(state) {
      if (state.compraAtual.itens && state.compraAtual.itens.length > 0) {
        return state.compraAtual.itens.reduce((total, item) => total + item.valor * item.quantidade, 0);
      } else {
        return 0;
      }
    },
    totalDescontos(state) {
      if (state.compraAtual.itens && state.compraAtual.itens.length > 0) {
        return state.compraAtual.itens.reduce((total, item) => total + item.desconto, 0);
      } else {
        return 0;
      }
    },
    totalLiquido(state) {
      if (state.compraAtual.itens && state.compraAtual.itens.length > 0) {
        return state.compraAtual.itens.reduce((total, item) => total + item.subtotal, 0);
      } else {
        return 0;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    carregar({ commit, rootState }) {
      commit('setCarregando', true);
      axios
        .get('/compras')
        .then((response) => {
          commit('setCompras', response.data);
        })
        .catch((error) => {
          commit('setCompras', []);
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    consultarFornecedores({ commit, rootState }, filtro) {
      commit('setCarregando', true);
      axios
        .get('/participantes/fornecedores/filtro/' + filtro)
        .then((response) => {
          commit('setFornecedores', response.data);
        })
        .catch((error) => {
          commit('setFornecedores', []);
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    buscarCodigoProduto({ commit, rootState }, codigo) {
      commit('setCarregando', true);
      axios
        .get('/produtos/codigos/' + codigo)
        .then((response) => {
          let retorno = response.data;
          commit('setProduto', retorno);
        })
        .catch((error) => {
          commit('setProduto', {});
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    /* salvar({commit, state, rootState}, dados){
            
            commit('setCarregando', true);

            if(state.modoEdicao){

                let compra = Object.assign({}, state.compraAtual);
                compra.fornecedor = compra.fornecedor._id;

                let sequencia = 1;
                let totalDesconto = 0;
                let totalCompra = 0;
                compra.itens.forEach(item => {
                    item.sequencia = sequencia;

                    totalDesconto += item.desconto;
                    totalCompra += item.subtotal;
                    sequencia++;
                });

                compra.desconto = totalDesconto;
                compra.total = totalCompra;

                axios.put('/compras', compra)
                .then((response) => {
                    let retorno = response.data;
                    if(retorno.status){
                        commit('setMensagemErro', retorno.motivo, {root: true});
                        commit('setCarregando', false);
                    }
                    else{
                        commit('atualizar', response.data);
                        commit('estadoInicial', dados.router);
                        commit('setMensagemSucesso', 'Compra Atualizada com Sucesso', {root: true});
                    }
                })
                .catch((error) => {
                    commit('setMensagemErro', error, {root: true});
                });
            }
            else{

                let compra = Object.assign({}, state.compraAtual);
                compra.fornecedor = compra.fornecedor._id;

                let sequencia = 1;
                let totalDesconto = 0;
                let totalCompra = 0;
                compra.itens.forEach(item => {
                    item.produto = item._id;
                    delete(item._id);
                    item.sequencia = sequencia;

                    totalDesconto += item.desconto;
                    totalCompra += item.subtotal;
                    sequencia++;
                });

                compra.desconto = totalDesconto;
                compra.total = totalCompra;
                
                delete(compra._id);
                axios.post('/compras', compra)
                .then((response) => {
                    let retorno = response.data;
                    if(retorno.status){
                        commit('setMensagemErro', retorno.motivo, {root: true});
                        commit('setCarregando', false);
                    }
                    else{
                        commit('adicionar', response.data);
                        commit('estadoInicial', dados.router);
                        commit('setMensagemSucesso', 'Compra Cadastrada com Sucesso', {root: true});
                    }
                })
                .catch((error) => {
                    commit('setMensagemErro', error, {root: true});
                });
            }
        }, */
    salvarNota({ commit, state, rootState }, dados) {
      let compra = Object.assign({}, state.compraAtual);
      compra.fornecedor = compra.fornecedor._id;

      axios
        .post('/compras/salvar', compra)
        .then((response) => {
          let retorno = response.data;
          commit('adicionar', retorno);
          commit('estadoInicial', dados.router);
          commit('setMensagemSucesso', 'Compra Cadastrada com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('setMensagemErro', error, { root: true });
        });
    },
    atualizarNota({ commit, state }, dados) {
      const compra = Object.assign({}, state.compraAtual);
      compra.reprocessarDuplicatas = state.reprocessarDuplicatas;

      axios
        .put('/compras', compra)
        .then((response) => {
          const retorno = response.data;
          commit('atualizar', retorno);
          commit('estadoInicial', dados.router);
          commit('setMensagemSucesso', 'Compra Alterada com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('setMensagemErro', error, { root: true });
        });
    },
    processarArquivo({ commit }, arquivo) {
      commit('setCarregando', true);
      arquivo.conteudo = btoa(arquivo.conteudo);

      axios
        .post('/compras/parseXml', arquivo)
        .then((response) => {
          const retorno = response.data;
          commit('preencher', retorno.compra);
          commit('setMensagemSucesso', 'Nota Processada com Sucesso', { root: true });
          commit('setCarregando', false);
        })
        .catch((error) => {
          commit('setMensagemErro', error, { root: true });
          commit('setCarregando', false);
        });
    },
    acertarCompra({ commit, state }, dados) {
      commit('setCarregando', true);

      const envio = {
        pagamentos: dados.pagamentos,
      };

      axios
        .post('/compras/' + state.compraAtual._id + '/acertar', envio)
        .then((response) => {
          const retorno = response.data;

          commit('atualizar', retorno);
          commit('estadoInicial', dados.router);
          commit('setMensagemSucesso', 'Compra Acertada com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('setMensagemErro', error, { root: true });
        });
    },
    editar({ commit }, dados) {
      commit('setCarregando', true);
      axios
        .get('/compras/contas/' + dados.compra._id)
        .then((response) => {
          const retorno = response.data;
          if (retorno.status) {
            commit('setMensagemErro', retorno.motivo, { root: true });
            commit('setCarregando', false);
          } else {
            const contasNota = response.data;

            const contasNotaProcessadas = contasNota.map((conta) => {
              return {
                valor: conta.valor,
                valor_restante: conta.valor_restante,
                parcela: conta.documento,
                vencimento: conta.data_vencimento,
                fornecedor: conta.participante,
                empresa: conta.empresa,
                nota_fiscal: conta.nota_fiscal,
              };
            });
            dados.compra.pagamentos = contasNotaProcessadas;
            commit('setModoEdicao', true);
            commit('editar', dados.compra);
          }
        })
        .catch((error) => {
          commit('setProduto', {});
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
      dados.router.push({ name: 'formularioCompraEditar' });
    },
    novo({ commit }, dados) {
      commit('setCarregando', true);
      dados.router.push({ name: 'formularioCompraNova' });
      commit('novo');
    },
    importar({ commit }, dados) {
      commit('setCarregando', true);
      dados.router.push({ name: 'formularioCompraImportar' });
      commit('novo');
    },
    acertar({ commit }, dados) {
      commit('setCarregando', true);
      dados.router.push({ name: 'formularioCompraAcerto' });
      commit('acerto', dados.compra);
    },
    remover({ commit, rootState }, remover) {
      commit('setCarregando', true);
      axios
        .delete('/compras', {
          data: { _id: remover._id },
        })
        .then((response) => {
          commit('remover', remover);
          commit('setMensagemSucesso', 'Compra Removida com Sucesso', { root: true });
        })
        .catch((error) => {
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
    cancelar({ commit }, dados) {
      commit('estadoInicial', dados.router);
    },
    buscarCfop({ commit }, cfop) {
      axios
        .get('/cfop/' + cfop)
        .then((response) => {
          commit('setCfopDescricao', response.data);
        })
        .catch((error) => {
          commit('setCompras', []);
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCompras(state, compras) {
      state.compras = compras;
      state.carregando = false;
    },
    setCompra(state, compra) {
      state.compraAtual = {};
      state.compraAtual = compra;
    },
    setProdutoAtual(state, produtoAtualAtualizado) {
      state.produtoAtual = produtoAtualAtualizado;
    },
    setFornecedores(state, fornecedores) {
      state.fornecedores = fornecedores;
      state.carregando = false;
    },
    setFornecedor(state, idFornecedor) {
      const fornecedorEncontrado = state.fornecedores.find((fornecedor) => fornecedor._id == idFornecedor);
      if (fornecedorEncontrado) {
        state.compraAtual.fornecedor = fornecedorEncontrado;
      }
    },
    setProduto(state, produto) {
      state.produtoAtual = produto;

      /* state.produtoAtual = {
                ...produto,
                quantidade: 1,
                desconto: 0,
                subtotal: produto.valor
            } */
      state.carregando = false;
    },
    calcularSubtotal(state) {
      const produto = state.produtoAtual;
      if (produto.quantidade != '') {
        const qtd = conversor.stringParaNumero(produto.quantidade);
        const desconto = conversor.stringParaNumero(produto.desconto);
        produto.subtotal = produto.valor * qtd - desconto;
      } else {
        produto.subtotal = 0;
      }
    },
    adicionarItem(state) {
      const novoProduto = Object.assign({}, state.produtoAtual);
      novoProduto.quantidade = conversor.stringParaNumero(novoProduto.quantidade);
      novoProduto.desconto = conversor.stringParaNumero(novoProduto.desconto);

      let existeProduto = state.compraAtual.itens.find((item) => item.codigo == state.produtoAtual.codigo);
      if (existeProduto) {
        existeProduto.quantidade += novoProduto.quantidade;
        existeProduto.desconto += novoProduto.desconto;
        existeProduto.subtotal = existeProduto.quantidade * existeProduto.valor - existeProduto.desconto;
      } else {
        state.compraAtual.itens.push(novoProduto);
      }

      state.produtoAtual = {
        produto: '',
        codigo_entrada: '',
        codigo: '',
        descricao: '',
        unidade: '',
        valor_custo: 0,
        quantidade: 0,
        ncm: '',
        cest: '',
        cfop: '',
        cst_csosn: '',
        base_calculo_icms: 0,
        aliquota_icms: 0,
        valor_icms: 0,
        base_calculo_ipi: 0,
        aliquota_ipi: 0,
        valor_ipi: 0,
        pis: '',
        valor_pis: 0,
        cofins: '',
        valor_cofins: 0,
      };
    },
    removerItem(state, index) {
      state.compraAtual.itens.splice(index, 1);
    },
    preencher(state, nota) {
      let produtos = [];
      nota.itens.forEach((produto_nota) => {
        let pis = state.constantes.PIS.find((pis_atual) => {
          return pis_atual.value == produto_nota.pis;
        });

        let cofins = state.constantes.COFINS.find((cofins_atual) => {
          return cofins_atual.value == produto_nota.cofins;
        });

        let novo = {
          produto: produto_nota.produto,
          codigo_entrada: produto_nota.codigo,
          codigo: produto_nota.codigo_produto,
          descricao: produto_nota.descricao,
          unidade: produto_nota.unidade,
          valor_custo: produto_nota.valor,
          subtotal: produto_nota.subtotal,
          quantidade: produto_nota.quantidade,
          ncm: produto_nota.ncm,
          cest: produto_nota.cest,
          cfop: produto_nota.cfop,
          cfop_descricao: produto_nota.cfop_descricao,
          cst_csosn: produto_nota.cst_csosn,
          base_calculo_icms: produto_nota.base_calculo_icms,
          aliquota_icms: produto_nota.aliquota_icms,
          valor_icms: produto_nota.valor_icms,
          base_calculo_icms_st: produto_nota.base_calculo_icms_st,
          aliquota_icms_st: produto_nota.aliquota_icms_st,
          valor_icms_st: produto_nota.valor_icms_st,
          base_calculo_ipi: produto_nota.base_calculo_ipi,
          aliquota_ipi: produto_nota.aliquota_ipi,
          valor_ipi: produto_nota.valor_ipi,
          pis: produto_nota.pis,
          pis_descricao: pis.text,
          valor_pis: produto_nota.valor_pis,
          cofins: produto_nota.cofins,
          cofins_descricao: cofins.text,
          valor_cofins: produto_nota.valor_cofins,
          informativo: state.compraAtual.informativa,
          //FRETE, DESEPESAS
        };

        let subtotal_fronteira = novo.subtotal + novo.valor_ipi;
        let valor_icms = 0;
        let valor_icms_st = 0;
        let custo_produto = 0;
        let margem_lucro_percentual = 0;
        let margem_lucro_valor = 0;
        let das_percentual = 10;
        let das_valor = 0;
        let valor_venda = 0;
        // let mva_percentual = 71.26;
        let mva_percentual = 0;
        let mva_valor = 0;
        let aliquota_interna = 18;
        let custo_produto_st = 0;
        let valor_icms_interno = 0;

        if (novo.base_calculo_icms_st == 0) {
          // PRODUTO NAO TEM SUBSTITUICAO TRIBUTARIA
          valor_icms = ((subtotal_fronteira * 18) / 100 - novo.valor_icms) / novo.quantidade;
          valor_icms_interno = (subtotal_fronteira * aliquota_interna) / 100;
          custo_produto = subtotal_fronteira / novo.quantidade + valor_icms;
          margem_lucro_percentual = 0;
          margem_lucro_valor = (custo_produto * margem_lucro_percentual) / 100;
          das_percentual = 10;
          das_valor = (custo_produto * das_percentual) / 100;
          valor_venda = custo_produto + margem_lucro_valor + das_valor;
        } else {
          // PRODUTO COM SUBSTITUICAO TRIBUTARIA
          mva_valor = (subtotal_fronteira * mva_percentual) / 100;
          valor_icms_interno = (novo.base_calculo_icms_st * aliquota_interna) / 100;
          valor_icms_st = (valor_icms_interno - novo.valor_icms) / novo.quantidade;
          custo_produto_st = subtotal_fronteira / novo.quantidade + valor_icms_st;
          margem_lucro_valor = (custo_produto_st * margem_lucro_percentual) / 100;
          das_valor = (custo_produto_st * das_percentual) / 100;
          valor_venda = custo_produto_st + margem_lucro_valor + das_valor;
        }

        novo.fronteira = {
          frete: 0,
          outras: 0,
          subtotal: subtotal_fronteira,
          aliquota_interna: aliquota_interna,
          valor_icms_interno: valor_icms_interno,
          quantidade: novo.quantidade,
          valor_icms: valor_icms,
          custo_produto: custo_produto,
          margem_lucro_percentual: margem_lucro_percentual,
          margem_lucro_valor: margem_lucro_valor,
          das_percentual: das_percentual,
          das_valor: das_valor,
          valor_venda: valor_venda,
          mva_percentual: mva_percentual,
          mva_valor: mva_valor,
          valor_icms_st: valor_icms_st,
          custo_produto_st: custo_produto_st,
        };

        produtos.push(novo);
      });

      state.compraAtual = {
        ...state.compraAtual,
        fornecedor: nota.fornecedor,
        // data: moment(nota.data).format('YYYY-MM-DD'),
        data: nota.data,
        itens: produtos,
        total: nota.total,
        pagamentos: nota.pagamentos,
        chave: nota.chave,
        numero: nota.numero,
        serie: nota.serie,
        data_entrada: moment().format('YYYY-MM-DD'),
        quantidade_total_produtos: nota.quantidade_total_produtos,

        valor_base_calculo_icms: nota.valor_base_calculo_icms,
        valor_icms: nota.valor_icms,
        valor_base_calculo_st: nota.valor_base_calculo_st,
        valor_icms_st: nota.valor_icms_st,
        valor_ipi: nota.valor_ipi,
        valor_pis: nota.valor_pis,
        valor_cofins: nota.valor_cofins,
        valor_seguro: nota.valor_seguro,
        valor_desconto: nota.valor_desconto,
        valor_frete: nota.valor_frete,
        valor_outro: nota.valor_outro,

        valor_produtos: nota.valor_produtos,
        valor_nfe: nota.valor_nfe,
        observacoes: nota.observacoes,

        // estoque_nulo: false,
        // cancelada: false,
      };
    },
    definirProdutoMapeamento(state, dados) {
      let produtoNota = dados.produtoNota;
      let produto = dados.produto;

      let produtoEncontrado = state.compraAtual.itens.find((item) => item.codigo_entrada == produtoNota.codigo_entrada);
      if (produtoEncontrado) {
        produtoEncontrado.produto = produto._id;
        produtoEncontrado.codigo = produto.codigo;
        produtoEncontrado.descricao = produto.descricao;
        produtoEncontrado.fator_conversao = produto.fator_conversao;

        let coeficienteConversao = parseInt(produtoEncontrado.fator_conversao.saida) / parseInt(produtoEncontrado.fator_conversao.entrada);
        produtoEncontrado.quantidade = parseFloat(produtoEncontrado.quantidade) * coeficienteConversao;
        produtoEncontrado.valor_custo = parseFloat(produtoEncontrado.valor_custo) / coeficienteConversao;
        produtoEncontrado.valor = parseFloat(produtoEncontrado.valor_custo) / coeficienteConversao;
      }
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    remover(state, removido) {
      state.compras = state.compras.filter((compra) => compra._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.compraAtual = {
        numero: '',
        data: moment().format('YYYY-MM-DD'),
        data_entrada: moment().format('YYYY-MM-DD'),
        fornecedor: {
          razaoSocialNome: '',
        },
        valor_produtos: '',
        valor_nfe: '',
        quantidade_total_produtos: 0,
        total: '',
        itens: [],
        pagamentos: [],
        estoque_nulo: false,
        cancelada: false,
        informativa: false,
      };
      state.carregando = false;
      state.reprocessarDuplicatas = false;
    },
    acerto(state, compra) {
      state.modoEdicao = false;
      state.compraAtual = compra;
      state.carregando = false;
    },
    editar(state, infoCompra) {
      state.modoEdicao = true;
      state.compraAtual = infoCompra;
      state.carregando = false;
    },
    adicionar(state, compra) {
      state.compras.push(compra);
      state.carregando = false;
    },
    atualizar(state, atual) {
      state.compras.forEach((compra) => {
        if (compra._id === atual._id) {
          compra = atual;
        }
      });
      state.carregando = false;
    },
    setReprocessarDuplicatas(state, valor) {
      state.reprocessarDuplicatas = valor;
    },
    estadoInicial(state, router) {
      state.compraAtual = {
        data: moment().format('YYYY-MM-DD'),
        fornecedor: {
          razaoSocialNome: '',
        },
        desconto: '',
        total: '',
        itens: [],
        pagamentos: [],
      };
      state.produtoAtual = {
        produto: '',
        codigo_entrada: '',
        codigo: '',
        descricao: '',
        unidade: '',
        valor_custo: 0,
        quantidade: 0,
        ncm: '',
        cest: '',
        cfop: '',
        cst_csosn: '',
        base_calculo_icms: 0,
        aliquota_icms: 0,
        valor_icms: 0,
        base_calculo_ipi: 0,
        aliquota_ipi: 0,
        valor_ipi: 0,
        pis: '',
        valor_pis: 0,
        cofins: '',
        valor_cofins: 0,
        fator_conversao: {
          entrada: 1,
          saida: 1,
        },
        fronteira: {
          frete: 0,
          outras: 0,
          subtotal: 0,
          aliquota_interna: 0,
          valor_icms_interno: 0,
          quantidade: 0,
          valor_icms: 0,
          custo_produto: 0,
          margem_lucro_percentual: 0,
          margem_lucro_valor: 0,
          das_percentual: 0,
          das_valor: 0,
          valor_venda: 0,
        },
      };
      router.push({ name: 'compras' });
      state.carregando = false;
      state.reprocessarDuplicatas = false;
    },
    setCfopDescricao(state, cfop_response) {
      state.produtoAtual.cfop_descricao = cfop_response.descricao;
    },
    recalcularImpostos(state) {
      let produto_atual = state.produtoAtual;

      produto_atual.valor_icms = (produto_atual.base_calculo_icms * produto_atual.aliquota_icms) / 100;
      produto_atual.valor_ipi = (produto_atual.base_calculo_ipi * produto_atual.aliquota_ipi) / 100;
      produto_atual.valor_icms_st = (produto_atual.base_calculo_icms_st * produto_atual.aliquota_icms_st) / 100 - produto_atual.valor_icms;

      if (produto_atual.base_calculo_icms_st > 0) {
        produto_atual.fronteira.mva_valor = (produto_atual.fronteira.subtotal * produto_atual.fronteira.mva_percentual) / 100;
        produto_atual.fronteira.valor_icms_interno = (produto_atual.base_calculo_icms_st * produto_atual.fronteira.aliquota_interna) / 100;
        produto_atual.fronteira.valor_icms_st = (produto_atual.fronteira.valor_icms_interno - produto_atual.valor_icms) / produto_atual.quantidade;
        produto_atual.fronteira.custo_produto_st = produto_atual.fronteira.subtotal / produto_atual.quantidade + produto_atual.fronteira.valor_icms_st;
        produto_atual.fronteira.margem_lucro_valor = (produto_atual.fronteira.custo_produto_st * produto_atual.fronteira.margem_lucro_percentual) / 100;
        produto_atual.fronteira.das_valor = (produto_atual.fronteira.custo_produto_st * produto_atual.fronteira.das_percentual) / 100;
        produto_atual.fronteira.valor_venda = produto_atual.fronteira.custo_produto_st + produto_atual.fronteira.margem_lucro_valor + produto_atual.fronteira.das_valor;
      } else {
        produto_atual.fronteira.subtotal = produto_atual.subtotal + produto_atual.valor_ipi;
        produto_atual.fronteira.valor_icms_interno = (produto_atual.fronteira.subtotal * produto_atual.fronteira.aliquota_interna) / 100;
        produto_atual.fronteira.valor_icms = (produto_atual.fronteira.valor_icms_interno - produto_atual.valor_icms) / produto_atual.quantidade;
        produto_atual.fronteira.custo_produto = produto_atual.fronteira.subtotal / produto_atual.quantidade + produto_atual.fronteira.valor_icms;
        produto_atual.fronteira.margem_lucro_valor = (produto_atual.fronteira.custo_produto * produto_atual.fronteira.margem_lucro_percentual) / 100;
        produto_atual.fronteira.das_valor = (produto_atual.fronteira.custo_produto * produto_atual.fronteira.das_percentual) / 100;
        produto_atual.fronteira.valor_venda = produto_atual.fronteira.custo_produto + produto_atual.fronteira.margem_lucro_valor + produto_atual.fronteira.das_valor;
      }
    },
    setPis(state, pisSelecionado) {
      state.produtoAtual.pis = pisSelecionado.codigo;
      state.produtoAtual.pis_descricao = pisSelecionado.descricao;
    },
    setPisTodosProdutos(state) {
      state.compraAtual.itens.forEach((produto) => {
        produto.pis = state.produtoAtual.pis;
        produto.pis_descricao = state.produtoAtual.pis_descricao;
      });
    },
    setCofins(state, cofinsSelecionado) {
      state.produtoAtual.cofins = cofinsSelecionado.codigo;
      state.produtoAtual.cofins_descricao = cofinsSelecionado.descricao;
    },
    setCofinsTodosProdutos(state) {
      state.compraAtual.itens.forEach((produto) => {
        produto.cofins = state.produtoAtual.cofins;
        produto.cofins_descricao = state.produtoAtual.cofins_descricao;
      });
    },
    setModoEdicao(state, modo) {
      state.modoEdicao = modo;
    },
  },
};
