import axios from '@/util/axios';

function listarDestinadas(options) {
  let url = `/destinadas/listar_destinadas?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url += `&query=${options.query}`;
  }
  if (options.status) {
    url += `&status=${options.status}`;
  }

  return axios.get(url).then((response) => response.data);
}

function downloadNfe(consulta) {
  return axios.post('/servicos/downloadNfe', consulta).then((response) => response.data);
}

function manifestacaoDestinatario(dadosManifestacao) {
  return axios.post('/servicos/recepcao_evento_manif_dest', dadosManifestacao).then((response) => response.data);
}

export default {
  listarDestinadas,
  downloadNfe,
  manifestacaoDestinatario,
};
