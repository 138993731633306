import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    ncms: [],
    constantes: constantes,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {},

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    buscarDescricaoNcm({ commit, rootState }, descricao) {
      commit('setCarregando', true);
      axios
        .get(`/ibpt/descricao/${descricao}`)
        .then((response) => {
          if (response.status === 200) {
            commit('setNcms', response.data);
          }
        })
        .catch((error) => {
          commit('setNcms', []);
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    setNcms(state, ncms) {
      state.ncms = ncms;
      state.carregando = false;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
  },
};
