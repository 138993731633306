<template>
  <v-navigation-drawer v-model="drawer" id="appDrawer" persistent fixed app enable-resize-watcher :mini-variant="mini" :clipped="clipped" :dark="$vuetify.dark" class="custom-scroll">
    <dialog-inutilizacao :dialog="mostrarInutilizarIntervalo" @fechar="mostrarInutilizarIntervalo = false" @inutilizar="inutilizar"></dialog-inutilizacao>

    <template v-slot:prepend>
      <v-toolbar color="primary darken-2" class="elevation-4" dark @click="$store.state.autenticacao.session.usuario.nivelAcesso === 'ANRON' ? mudarRota('/admin/empresas') : mudarRota('/home')" style="cursor: pointer; z-index: 5">
        <img src="@/assets/easyweb.svg" style="height: 32px; margin-right: 12px" />
        <v-toolbar-title
          >EasyPDV <b>Web</b>
          <span style="text-align: right; font-weight: 300; margin-left: 8px; font-size: 14px">v{{ $store.state.version }}</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-list dense>
        <v-list-group no-action group="minhaconta">
          <template v-slot:activator>
            <v-list-item-avatar v-if="$store.state.autenticacao.session.usuario.foto">
              <v-img v-bind:src="`${apiUrl}/images/${$store.state.autenticacao.session.usuario.foto}`"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar v-else color="primary" size="40">
              <span class="white--text subtitle-1">
                {{ $store.state.autenticacao.session.usuario.nome.charAt(0) }}
              </span>
            </v-list-item-avatar>

            <v-list-item-title>{{ $store.state.autenticacao.session.usuario.nome.toUpperCase() }}</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'minhaConta' }">
            <v-list-item-content>
              <v-list-item-title style="font-weight: normal">Minha Conta</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>account_box</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="sair">
            <v-list-item-content>
              <v-list-item-title style="font-weight: normal">Sair</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
    </template>

    <template v-slot:default>
      <v-list dense>
        <v-list-item-group color="primary">
          <!-- Usuário do sistema -->

          <template v-for="(item, i) in menusAcessiveis">
            <!--Elementos de Divisão e Subheaders-->
            <v-subheader v-if="item.cabecalho" :key="i" class="pl-4 pt-2 mt-1" v-show="temPermissao($store.state.autenticacao.session.usuario, item.permissao)">{{ item.cabecalho }}</v-subheader>
            <v-divider v-else-if="item.divider" :key="i" class="ml-4"></v-divider>

            <!-- Elementos com sublinks -->
            <v-list-group v-else-if="item.items" :key="item.nome" no-action :prepend-icon="item.icone" :group="item.grupo" color="primary" v-show="temPermissao($store.state.autenticacao.session.usuario, item.permissao)">
              <template v-slot:activator>
                <v-list-item-content :data-cy="item.titulo">
                  <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-divider></v-divider>

              <v-list-item v-for="(subItem, i) in item.items" :key="i" :to="subItem.path" :data-cy="subItem.titulo" :title="subItem.titulo" v-show="temPermissao($store.state.autenticacao.session.usuario, subItem.permissao)">
                <v-list-item-content>
                  <v-list-item-title>
                    <span style="font-weight: normal">{{ subItem.titulo }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="subItem.icone">
                  <v-icon>{{ subItem.icone }}</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </v-list-group>

            <v-list-item v-else-if="!item.hidden" :to="item.path" rel="noopener" :key="item.nome" :data-cy="item.titulo" v-show="temPermissao($store.state.autenticacao.session.usuario, item.permissao)">
              <v-list-item-icon v-if="item.icone">
                <v-icon :class="item.iconeClass" :size="item.iconeSize || 24">{{ item.icone }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="IS_PROD !== 'production' && $store.state.autenticacao.session.usuario.nivelAcesso !== 'ANRON' && $store.state.autenticacao.session.usuario.nivelAcesso !== 'CONTADOR'">
            <v-list-item-group color="red">
              <v-list-item :to="{ name: 'crudLista' }" color="black">
                <v-list-item-icon>
                  <v-icon color="red">dynamic_form</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Exemplo CRUD</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import menuRetaguarda from './menus/menuRetaguarda';
import menuNfe from './menus/menuNfe';
import menuNfeDeposito from './menus/menuNfeDeposito';
import menuMdfe from './menus/menuMdfe';
import menuCte from './menus/menuCte';

import menuUsuario from './menus/menuUsuario';
import menu from './menus/menu';

import menuAdmin from './menus/menuAdmin';
import menuContador from './menus/menuContador';

import { IS_PROD, URL_ANRON_API } from '@/util/config';
import { temPermissao } from '@/util/helpers';

import DialogInutilizacao from '@/views/notasfiscais/DialogInutilizacao';

export default {
  name: 'app-drawer',
  components: {
    DialogInutilizacao,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mostrarInutilizarIntervalo: false,
      mostrarMenuUsuario: false,
      mini: false,
      drawer: true,
      fixed: true,
      clipped: false,
      menuRetaguarda,
      menuNfe,
      menuNfeDeposito,
      menuMdfe,
      menuCte,
      menuUsuario,
      menuAdmin,
      menuContador,
      menu,
    };
  },

  computed: {
    IS_PROD() {
      return IS_PROD;
    },
    apiUrl() {
      return URL_ANRON_API;
    },
    temPermissao() {
      return temPermissao;
    },

    menusAcessiveis() {
      const usuario = this.$store.state.autenticacao.session.usuario;

      const modulo = this.$store.state.autenticacao.session.modulo;

      if (usuario.nivelAcesso === 'ANRON') {
        return menuAdmin;
      } else if (usuario.nivelAcesso === 'CONTADOR') {
        return menuContador;
      } else {
        if (modulo === 'retaguarda') {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuRetaguarda.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuRetaguarda;
          }

          menus = [...menus, ...menu];

          return menus;
        } else if (modulo === 'manifesto') {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuMdfe.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuMdfe;
          }

          menus = [...menus, ...menu];

          return menus;
        } else if (modulo === 'conhecimento') {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuCte.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuCte;
          }

          menus = [...menus, ...menu];

          return menus;
        } else if (modulo === 'notaFiscal') {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuNfe.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuNfe;
          }

          menus = [...menus, ...menu];

          return menus;
        } else if (modulo === 'notaFiscalDeposito') {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuNfeDeposito.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuNfeDeposito;
          }

          menus = [...menus, ...menu];

          return menus;
        } else {
          let menus = [];
          if (usuario.nivelAcesso === 'NORMAL') {
            menus = this.menuRetaguarda.filter((menu) => menu.nivel === 1);
          } else if (usuario.nivelAcesso === 'ADMINISTRADOR') {
            menus = this.menuRetaguarda.filter((menu) => menu.nivel === 1 || menu.nivel === 2);
          } else if (usuario.nivelAcesso === 'ANRON') {
            menus = this.menuRetaguarda.filter((menu) => menu.nivel === 3);
          }

          menus = [...menus, ...menu];

          return menus;
        }
      }
    },
  },

  created() {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = !this.drawer;
    });
  },

  methods: {
    inutilizar(inutilizar) {
      this.mostrarInutilizarIntervalo = false;
      this.$store.dispatch('nota/inutilizarIntervalo', inutilizar);
    },

    mudarRota(path) {
      this.$router.push(path);
    },

    alterarDrawer() {
      this.drawer = !this.drawer;
    },

    executeCallback(acao) {
      if (acao === 'inutilizar') {
        this.mostrarInutilizarIntervalo = true;
      }
    },

    sair() {
      this.$store.commit('sair');
    },

    setDrawerMenu(modulo) {
      this.$store.commit('autenticacao/setSession', { modulo });
    },
  },
};
</script>

<style scoped></style>
