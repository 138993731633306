const URL_ANRON_API = process.env.VUE_APP_API_URL || window.location.origin;
const URL_SOCKET_IO = process.env.VUE_APP_SOCKET_IO || window.location.origin;
const TIMEOUT = process.env.VUE_APP_TIMEOUT || 30000;
const IS_PROD = process.env.NODE_ENV === 'production';

module.exports = {
  URL_ANRON_API,
  IS_PROD,
  TIMEOUT,
  URL_SOCKET_IO,
};
