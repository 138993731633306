<template>
  <v-form v-model="valid" ref="formCompra">
    <v-container grid-list-md fluid>
      <v-row>
        <v-col cols="12">
          <v-widget title="Compra">
            <template v-slot:widget-content>
              <v-container>
                <v-row row wrap>
                  <v-col cols="12" sm3>
                    <v-autocomplete label="Buscar Fornecedor" :search-input.sync="filtroFornecedor" v-model="fornecedorSelecionado" @input="definirFornecedor(fornecedorSelecionado)" :items="fornecedoresBusca" append-icon="search">
                      <v-slide-x-reverse-transition mode="out-in" slot="append-outer"></v-slide-x-reverse-transition>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm9>
                    <v-text-field label="Razão/Nome do Fornecedor" persistent-placeholder outlined v-model="compra.fornecedor.razaoSocialNome" required readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm3>
                    <v-text-field label="Total Produtos" persistent-placeholder outlined :value="totalProdutos | formatarMoeda" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm3>
                    <v-text-field label="Descontos" persistent-placeholder outlined :value="totalDescontos | formatarMoeda" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm3>
                    <v-text-field label="Valor Líquido" persistent-placeholder outlined :value="totalLiquido | formatarMoeda" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm3>
                    <v-menu :close-on-content-click="false" v-model="menuData" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                      <v-text-field persistent-placeholder outlined v-model="dataCompra" slot="activator" label="Lançamento" prepend-icon="event" :rules="[() => !!compra.data || 'Data obrigatória']" readonly></v-text-field>
                      <v-date-picker locale="pt-BR" v-model="compra.data" no-title @input="menuData = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-widget>
        </v-col>
        <v-col cols="12" class="mt-2">
          <v-widget title="Itens da Compra">
            <template v-slot:widget-content>
              <v-container fluid>
                <v-layout row wrap>
                  <v-col cols="12" sm="1">
                    <v-text-field label="Código" persistent-placeholder outlined v-model="codigoProduto" @keypress.enter="buscarCodigoProduto(codigoProduto)"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm4>
                    <v-text-field label="Descrição do Produto" persistent-placeholder outlined v-model="produto.descricao" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm2>
                    <v-text-field label="Valor" persistent-placeholder outlined :value="produto.valor | formatarValor" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-text-field type="number" min="1" label="Qtd." persistent-placeholder outlined v-model="produto.quantidade" @input="calcularSubtotal()" :rules="[(v) => (v >= 1 ? true : false) || 'Qtd. inválida']" :readonly="produto.valor == ''"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-text-field label="Desc." persistent-placeholder outlined v-model="produto.desconto" @input="calcularSubtotal()" :rules="validarValor"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm2>
                    <v-text-field label="Subtotal" persistent-placeholder outlined :value="produto.subtotal | formatarValor" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-btn icon color="success" @click="adicionarProduto"><v-icon>add</v-icon></v-btn>
                  </v-col>
                </v-layout>
              </v-container>
              <v-divider></v-divider>
              <v-container grid-list-md fluid>
                <v-data-table hide-actions item-key="_id" :headers="headers" :items="compra.itens" :loading="carregando">
                  <template v-slot:item="props">
                    <td>{{ props.item.codigo | formatarPadZero(5) }}</td>
                    <td>{{ props.item.descricao }}</td>
                    <td>{{ props.item.unidade }}</td>
                    <td>{{ props.item.valor | formatarMoeda }}</td>
                    <td>{{ props.item.quantidade }}</td>
                    <td>{{ props.item.desconto | formatarMoeda }}</td>
                    <td>{{ props.item.subtotal | formatarMoeda }}</td>
                    <td class="text-xs-center">
                      <v-icon @click="removerItem(props.index)">delete</v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </template>
            <template v-slot:widget-footer-action>
              <v-spacer></v-spacer>
              <v-btn depressed class="mr-2" @click="limpar">{{ constantes.BOTAO_CANCELAR }}</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="enviar" :disabled="!valid || compra.itens.length == 0">{{ textoBtnConfirmar }}</v-btn>
            </template>
          </v-widget>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: 'FormularioCompra',

    data: () => ({
      headers: [
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Und.', value: 'unidade' },
        { text: 'Vlr. Unit.', value: 'valor' },
        { text: 'Qtd.', value: 'quantidade' },
        { text: 'Desc.', value: 'desconto' },
        { text: 'Total', value: 'subtotal' },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      valid: false,
      validarValor: [(v) => (v && v == 0) || v == '0' || (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
      constantes: constantes,
      fornecedorSelecionado: null,
      filtroFornecedor: null,
      menuData: false,
      codigoProduto: '',
    }),

    computed: {
      ...mapState('compra', {
        compra: 'compraAtual',
        produto: 'produtoAtual',
        carregando: 'carregando',
      }),

      ...mapGetters('compra', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar',
        fornecedoresBusca: 'fornecedoresBusca',
        dataCompra: 'dataFormatada',
        totalProdutos: 'totalProdutos',
        totalDescontos: 'totalDescontos',
        totalLiquido: 'totalLiquido',
      }),
    },

    watch: {
      filtroFornecedor(val) {
        if (!this.carregando && val && val.length > 3) {
          this.$store.dispatch('compra/consultarFornecedores', val);
        }
      },
    },

    methods: {
      enviar() {
        if (this.$refs.formCompra.validate()) {
          this.$store.dispatch('compra/salvar', { router: this.$router });
        }
      },

      definirFornecedor(idFornecedor) {
        this.$store.commit('compra/setFornecedor', idFornecedor);
      },

      limpar() {
        this.$store.dispatch('compra/cancelar', { router: this.$router });
      },

      buscarCodigoProduto(codigo) {
        this.$store.dispatch('compra/buscarCodigoProduto', codigo);
      },

      adicionarProduto() {
        this.codigoProduto = '';
        this.$store.commit('compra/adicionarItem');
      },

      calcularSubtotal() {
        this.$store.commit('compra/calcularSubtotal');
      },

      removerItem(index) {
        this.$store.commit('compra/removerItem', index);
      },
    },
  };
</script>

<style scoped>
  /*  */
</style>
