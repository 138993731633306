export default [
  {
    path: '/cruds',
    name: 'crudLista',
    component: () => import(/* webpackChunkName: "CrudLista" */ '@/views/crud/ListaCrud'),
    meta: { requiresAuth: true, nivel: 1, permissao: '' },
  },
  {
    path: '/cruds/novo',
    name: 'crudNovo',
    component: () => import(/* webpackChunkName: "CrudNovo" */ '@/views/crud/FormularioCrud'),
    meta: { requiresAuth: true, nivel: 1, permissao: '' },
  },
  {
    path: '/cruds/editar',
    name: 'crudEditar',
    component: () => import(/* webpackChunkName: "CrudEditar" */ '@/views/crud/FormularioCrud'),
    meta: { requiresAuth: true, nivel: 1, permissao: '' },
  },
];
