import axios from '@/util/axios';
import qs from 'qs';

function listar(data, options) {
  return axios.get(`/movimentos-caixa/${data}?` + qs.stringify(options)).then((response) => response.data);
}

function listarContasCorrentes() {
  return axios.get(`/movimentos-caixa/listar-contas-correntes`).then((response) => response.data);
}

function creditar(movimento) {
  return axios.post(`/movimentos-caixa/creditar`, movimento).then((response) => response.data);
}

function debitar(movimento) {
  return axios.post(`/movimentos-caixa/debitar`, movimento).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/movimentos-caixa/${id}`).then((response) => response.data);
}

function suprimento(lancamento) {
  return axios.post('/movimentos-caixa/suprimento', lancamento).then((response) => response.data);
}

function sangria(lancamento) {
  return axios.post('/movimentos-caixa/sangria', lancamento).then((response) => response.data);
}

export default {
  listar,
  listarContasCorrentes,
  creditar,
  debitar,
  excluir,
  suprimento,
  sangria,
};
