import constantes from '@/util/constantes';
import moment from 'moment';
import conversor from '@/util/conversor';

import produtosApi from '@/services/produtosApi';
import cupomApi from '@/services/cupomApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    cupomAtual: {
      data: moment().format('YYYY-MM-DD'),
      cliente: {
        razaoSocialNome: 'Cliente Padrão',
      },
      desconto: '',
      total: '',
      itens: [],
      pagamentos: [],
      identificador: Math.floor(Math.random() * (9999999999 - 0 + 1)) + 0,
    },
    cupons: [],
    comprovanteCliente: [],
    comprovanteEstabelecimento: [],
    produtoAtual: {
      codigo: '',
      descricao: '',
      valor: '',
      desconto: 0,
      quantidade: '',
      subtotal: '',
    },
    clientes: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    clientesBusca(state) {
      const clienteFiltro = [];
      state.clientes.forEach((cliente) => {
        clienteFiltro.push({ text: cliente.razaoSocialNome, value: cliente._id });
      });
      return clienteFiltro;
    },
    totalProdutos(state) {
      if (state.cupomAtual.itens && state.cupomAtual.itens.length > 0) {
        return state.cupomAtual.itens.reduce((total, item) => total + item.valor * item.quantidade, 0);
      } else {
        return 0;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async buscarCodigoProduto({ commit }, codigo) {
      try {
        commit('setCarregando', true);
        let resultado = await produtosApi.buscarCodigo(codigo);
        if (resultado.status) {
          commit('setMensagemErro', resultado.motivo, { root: true });
        } else {
          commit('setProduto', resultado);
        }
      } catch (error) {
        commit('setProduto', {});
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async carregar({ commit }) {
      try {
        commit('setCarregando', true);
        let resultado = await cupomApi.cuponsRecentes();
        commit('setCupons', resultado);
      } catch (error) {
        commit('setCupons', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvar({ commit, state }) {
      commit('setCarregando', true);

      let cupomAtual = state.cupomAtual;

      let produtos = [];
      let sequencia = 1;
      let totalCupom = 0;
      let totalTributos = 0;
      cupomAtual.itens.forEach((item) => {
        let icms = item.subtotal * (item.aliq_icms / 100);
        icms = parseFloat(icms.toFixed(2));

        let pis = item.subtotal * (item.aliq_pis_saida / 100);
        pis = parseFloat(pis.toFixed(2));

        let cofins = item.subtotal * (item.aliq_cofins_saida / 100);
        cofins = parseFloat(cofins.toFixed(2));

        let tributos = icms + pis + cofins;
        tributos = parseFloat(tributos.toFixed(2));

        let produto = {
          codigo: item.codigo,
          codigoBarra: item.codigo_barra,
          descricao: item.descricao,
          ncm: item.ncm.codigo,
          cest: item.cest.cest,
          unidade: item.unidade,
          valor: item.valor,
          origem: item.origem,
          csosn: item.csosn,
          cfop: '5102',
          produto: item._id,
          sequencia: sequencia,
          quantidade: item.quantidade,
          subtotal: item.subtotal,
          desconto: 0,
          cst_icms: item.cst_icms,
          cst_icms_st: '00',
          cst_pis: item.cst_pis_saida,
          cst_cofins: item.cst_cofins_saida,
          cst_ipi: '00',
          aliq_icms: item.aliq_icms,
          aliq_icms_st: 0,
          aliq_pis: item.aliq_pis_saida,
          aliq_cofins: item.aliq_cofins_saida,
          aliq_ipi: 0,
          base_calculo_icms: item.subtotal,
          base_calculo_icms_st: 0,
          base_calculo_pis: item.subtotal,
          base_calculo_cofins: item.subtotal,
          icms: icms,
          icms_st: 0,
          pis: pis,
          cofins: cofins,
          ipi: 0,
          valor_tributos: tributos,
        };

        produtos.push(produto);

        totalCupom += produto.subtotal;
        totalTributos += produto.valor_tributos;
        sequencia++;
      });

      let formaPagamento = 0;
      cupomAtual.pagamentos.forEach((pagamento) => {
        if (pagamento.forma == constantes.CARTAO) {
          formaPagamento = 1;
        }
      });

      let cupom = {
        numero: '',
        identificador: cupomAtual.identificador,
        serie: '001',
        tipo: '1',
        finalidade: 1,
        data_emissao: cupomAtual.data,
        natureza_operacao: 'VENDA',
        total: totalCupom,
        forma_pagamento: formaPagamento,
        desconto: 0,
        total_bc_icms: 0,
        total_icms: 0,
        total_icms_st: 0,
        total_ipi: 0,
        total_ii: 0,
        total_pis: 0,
        total_cofins: 0,
        total_produtos: totalCupom,
        total_tributos: totalTributos,
        total_cupom: totalCupom,
        situacao: 'pendente',
        produtos: produtos,
        pagamentos: cupomAtual.pagamentos,
        comprovanteCliente: state.comprovanteCliente,
        comprovanteEstabelecimento: state.comprovanteEstabelecimento,
      };

      //informações do cliente caso informado
      if (cupomAtual.cliente._id) {
        cupom.cliente = {
          _id: cupomAtual.cliente._id,
          cpf: cupomAtual.cliente.cnpjCpf,
          nome: cupomAtual.cliente.razaoSocialNome,
        };
      }

      delete cupom._id;
      try {
        let resultado = await cupomApi.cadastrar(cupom);
        if (resultado.status) {
          commit('setMensagemErro', resultado.motivo, { root: true });
          commit('setCarregando', false);
        } else {
          let resultadoDanfce = await cupomApi.gerarDanfce(resultado._id);
          let htmlCupom = Buffer.from(resultadoDanfce.htmlCupom, 'base64').toString('UTF-8');
          let htmlComprovante = Buffer.from(resultadoDanfce.htmlComprovanteCartao, 'base64').toString('UTF-8');

          ipcRenderer && ipcRenderer.send('print', htmlCupom);
          setTimeout(() => {
            ipcRenderer && ipcRenderer.send('print', htmlComprovante);
          }, 8000);

          commit('estadoInicial');
          commit('adicionarCupom', retorno);
          commit('setMensagemSucesso', 'Cupom Autorizado', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      }
    },

    async cancelarCupom({ commit }, cupom) {
      try {
        commit('setCarregando', true);
        let resultado = await cupomApi.cancelar(cupom._id);
        commit('removerCupom', resultado);
        commit('setMensagemSucesso', 'Cupom Cancelado com Sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setProduto(state, produto) {
      state.produtoAtual = {
        ...produto,
        quantidade: 1,
        desconto: 0,
        subtotal: produto.valor,
      };
      state.carregando = false;
    },
    setCupons(state, cupons) {
      state.cupons = cupons;
    },
    setCliente(state, cliente) {
      state.cupomAtual.cliente = cliente;
    },
    setPagamentos(state, pagamentos) {
      state.cupomAtual.pagamentos = pagamentos;
    },
    setComprovantes(state, comprovantes) {
      state.comprovanteCliente = comprovantes.comprovanteCliente;
      state.comprovanteEstabelecimento = comprovantes.comprovanteEstabelecimento;
    },
    adicionarCupom(state, cupom) {
      state.cupons.push(cupom);
    },
    calcularSubtotal(state) {
      let produto = state.produtoAtual;
      if (produto.quantidade != '') {
        let qtd = conversor.stringParaNumero(produto.quantidade);
        let desconto = conversor.stringParaNumero(produto.desconto);
        produto.subtotal = produto.valor * qtd - desconto;
      } else {
        produto.subtotal = 0;
      }
    },
    adicionarItem(state) {
      let novoProduto = Object.assign({}, state.produtoAtual);
      novoProduto.quantidade = conversor.stringParaNumero(novoProduto.quantidade);
      novoProduto.desconto = conversor.stringParaNumero(novoProduto.desconto);

      let existeProduto = state.cupomAtual.itens.find((item) => item.codigo == state.produtoAtual.codigo);
      if (existeProduto) {
        existeProduto.quantidade += novoProduto.quantidade;
        existeProduto.desconto += novoProduto.desconto;
        existeProduto.subtotal = existeProduto.quantidade * existeProduto.valor - existeProduto.desconto;
      } else {
        state.cupomAtual.itens.push(novoProduto);
      }

      state.produtoAtual = {
        codigo: '',
        descricao: '',
        valor: '',
        desconto: 0,
        quantidade: '',
        subtotal: '',
      };
    },
    removerItem(state, index) {
      state.cupomAtual.itens.splice(index, 1);
    },
    removerCupom(state, removido) {
      state.cupons = state.cupons.filter((cupom) => cupom._id !== removido._id);
      state.carregando = false;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    novo(state) {
      state.modoEdicao = false;
      state.cupomAtual = {
        data: moment().format('YYYY-MM-DD'),
        cliente: {
          razaoSocialNome: 'Cliente Padrão',
        },
        desconto: '',
        total: '',
        itens: [],
        pagamentos: [],
        identificador: Math.floor(Math.random() * (9999999999 - 0 + 1)) + 0,
      };
      state.carregando = false;
    },
    estadoInicial(state) {
      state.cupomAtual = {
        data: moment().format('YYYY-MM-DD'),
        cliente: {
          razaoSocialNome: 'Cliente Padrão',
        },
        desconto: '',
        total: '',
        itens: [],
        pagamentos: [],
        identificador: Math.floor(Math.random() * (9999999999 - 0 + 1)) + 0,
      };
      state.produtoAtual = {
        codigo: '',
        descricao: '',
        valor: '',
        desconto: 0,
        quantidade: '',
        subtotal: '',
      };
      state.carregando = false;
    },
  },
};
