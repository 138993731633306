<template>
  <div>
    <v-container fluid>
      <v-widget title="Movimentos de Estoque">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field flat solo single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right">
                <v-menu :close-on-content-click="false" v-model="menuDataInicio" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                  <v-text-field flat solo hide-details single-line v-model="dataInicioFormatada" slot="activator" label="Data Inícial" prepend-icon="event" readonly></v-text-field>
                  <v-date-picker locale="pt-BR" v-model="dataInicio" no-title @input="menuDataInicio = false"></v-date-picker>
                </v-menu>
                <v-menu :close-on-content-click="false" v-model="menuDataFim" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                  <v-text-field flat solo hide-details single-line v-model="dataFimFormatada" slot="activator" label="Data Final" prepend-icon="event" readonly></v-text-field>
                  <v-date-picker locale="pt-BR" :min="dataInicio" v-model="dataFim" no-title @input="menuDataFim = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table item-key="_id" :headers="headers" :items="movimentosEstoque" :search="busca" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <td>{{ props.item.usuario.nome }}</td>
              <td>{{ props.item.tipo }}</td>
              <td>{{ props.item.produto.descricao }}</td>
              <td v-if="props.item.compra">Entrada</td>
              <td v-else-if="props.item.venda || props.item.cupom">Saída</td>
              <td>{{ props.item.data | formatarDataHora }}</td>
              <td>{{ props.item.quantidade }}</td>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constantes from '@/util/constantes';
import moment from 'moment';

export default {
  name: 'ListaMovimentoEstoque',
  data() {
    return {
      headers: [
        { text: 'Usuário', value: 'usuario.nome' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Descrição do Produto', value: 'produto.descricao' },
        { text: 'Origem', value: 'origem' },
        { text: 'Data', value: 'data' },
        { text: 'Quantidade', value: 'quantidade' },
      ],
      constantes: constantes,
      busca: '',
      menuDataInicio: false,
      menuDataFim: false,
      dataInicio: '',
      dataInicioFormatada: '',
      dataFim: '',
      dataFimFormatada: '',
    };
  },

  computed: {
    ...mapState('movimentoEstoque', {
      movimentosEstoque: 'movimentosEstoque',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    dataInicio(v) {
      if (v && v != '') {
        this.dataInicioFormatada = moment(this.dataInicio).format('DD/MM/YY');
        if (!this.carregando && this.dataFim && this.dataFim != '') {
          if (moment(this.dataInicio).isBefore(this.dataFim)) {
            this.$store.dispatch('movimentoEstoque/carregar', { dataInicio: this.dataInicio, dataFim: this.dataFim });
          } else {
            this.dataFim = this.dataInicio;
          }
        }
      }
    },

    dataFim(v) {
      if (v && v != '') {
        this.dataFimFormatada = moment(this.dataFim).format('DD/MM/YY');
        if (!this.carregando) {
          this.$store.dispatch('movimentoEstoque/carregar', { dataInicio: this.dataInicio, dataFim: this.dataFim });
        }
      }
    },
  },

  methods: {
    ...mapActions('movimentoEstoque', {
      carregarMovimentosEstoque: 'carregar',
    }),
  },

  created() {
    //chamada a action através do dispatch
    let data = moment().format('YYYY-MM-DD');
    this.dataInicio = data;
    this.dataFim = data;
    this.carregarMovimentosEstoque({ dataInicio: data, dataFim: data });
  },
};
</script>
