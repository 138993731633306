<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaCorrente">
      <v-container fluid>
        <v-widget title="Conta Corrente">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Número"
                  ref="numero"
                  v-model="contaCorrente.numero"
                  :rules="[rules.obrigatorio]"
                  @keypress.enter="$refs.nome.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Nome"
                  ref="nome"
                  v-model="contaCorrente.nome"
                  :rules="[rules.obrigatorio]"
                  @keypress.enter="$refs.agencia.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3">
                <v-text-field
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="Agência"
                  ref="agencia"
                  v-model="contaCorrente.agencia"
                  :rules="[rules.obrigatorio]"
                  @keypress.enter="$refs.banco.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  ref="banco"
                  :rules="[rules.obrigatorio]"
                  label="Banco"
                  v-model="contaCorrente.banco.codigo"
                  :items="bancos"
                  placeholder=" "
                  autocomplete="off"
                  role="presentation"
                  required
                  persistent-placeholder
                  outlined
                  @change="setBanco"
                />
              </v-col>
            </v-row>

            <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
              <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="loading" @click="salvar" :disabled="!valid">Salvar</v-btn>
            </v-container>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioContaCorrente',
  data: () => ({
    valid: false,
    rules: { ...regras },
    loading: false,
  }),

  computed: {
    ...mapState('contaCorrente', {
      contaCorrente: 'contaCorrente',
    }),

    ...mapGetters({
      bancos: 'bancosSelect',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formContaCorrente.validate()) {
        this.loading = true;
        if (this.contaCorrente._id) {
          const result = await this.$store.dispatch('contaCorrente/editar');
          this.loading = false;
          if (!result) return;
        } else {
          const result = await this.$store.dispatch('contaCorrente/adicionar');
          this.loading = false;
          if (!result) return;
        }

        this.$router.push({ name: 'contasCorrentes' });
      }
    },

    setBanco(codigo) {
      const banco = this.bancos.find((banco) => banco.value === codigo);
      this.contaCorrente.banco.descricao = banco ? banco.text : null;
    },

    cancelar() {
      this.$router.push({ name: 'contasCorrentes' });
    },
  },
};
</script>
