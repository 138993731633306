const Menu = [
  {
    nome: 'DashboardContador',
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    path: '/contador/home',
    nivel: 3,
  },
  { cabecalho: 'Fiscal', nivel: 3 },
  { divider: true, nivel: 3 },
  {
    nome: 'ContadorNFCe',
    titulo: 'Cupoms Fiscais',
    grupo: 'Fiscal',
    icone: 'far fa-file',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/contador/nfce',
    nivel: 3,
  },
  {
    nome: 'ContadorNFe',
    titulo: 'Notas Fiscais',
    grupo: 'Fiscal',
    icone: 'description',
    path: '/contador/nfe',
    nivel: 3,
  },
  {
    nome: 'ContadorMDFe',
    titulo: 'Manifesto de Documentos',
    grupo: 'Fiscal',
    icone: 'far fa-file',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/contador/mdfe',
    nivel: 3,
  },
  {
    nome: 'ContadorCTe',
    titulo: 'Conhecimento de Transportes',
    grupo: 'Fiscal',
    icone: 'archive',
    path: '/contador/cte',
    nivel: 3,
  },
  {
    nome: 'ContadorNFeDestinadas',
    titulo: 'Notas Destinadas',
    grupo: 'Fiscal',
    icone: 'archive',
    path: '/contador/nfe-destinadas',
    nivel: 3,
  },
  {
    nome: 'ContadorRelatorios',
    titulo: 'Relatórios',
    grupo: 'Fiscal',
    icone: 'fas fa-file-archive',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/contador/relatorios',
    nivel: 3,
  },
  {
    nome: 'ContadorEFD',
    titulo: 'Arquivos Contabilidade',
    grupo: 'Fiscal',
    icone: 'fas fa-file-archive',
    iconeSize: 22,
    iconeClass: 'ml-1',
    path: '/contador/efd',
    nivel: 3,
  },
];

export default Menu;
