import axios from '@/util/axios';

function listar(options) {
  return axios.get('/contas-correntes', { params: options }).then((response) => response.data);
}

function adicionar(conta) {
  return axios.post('/contas-correntes', conta).then((response) => response.data);
}

function editar(conta) {
  return axios.put(`/contas-correntes`, conta).then((response) => response.data);
}

function remover(contaCorrenteId) {
  return axios.delete(`/contas-correntes/${contaCorrenteId}`).then((response) => response.data);
}

export default {
  listar,
  adicionar,
  editar,
  remover,
};
