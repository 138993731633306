import constantes from '@/util/constantes';
import vendedoresApi from '@/services/vendedoresApi';
import { maiusculas } from '../../util/helpers';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    vendedores: [],
    vendedorAtual: {
      nome: '',
      comissao: 0,
      ativo: true,
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalVendedores: 0,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await vendedoresApi.listar(options);
        commit('SET_VENDEDORES', response.vendedores);
        commit('SET_TOTAL_VENDEDORES', response.total);
      } catch (error) {
        commit('SET_VENDEDORES', []);
        commit('setMensagemErro', 'Não foi possível listar os vendedores', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO', true);
        let vendedor = Object.assign({}, state.vendedorAtual);
        vendedor.nome = maiusculas(vendedor.nome);
        let response = await vendedoresApi.salvar(vendedor);
        commit('ADICIONAR_VENDEDOR', response);
        commit('setMensagemSucesso', 'Vendedor cadastrado com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({ commit }, vendedor) {
      try {
        vendedor.nome = maiusculas(vendedor.nome);
        await vendedoresApi.atualizar(vendedor);
        commit('ATUALIZAR_VENDEDOR', vendedor);
        commit('setMensagemSucesso', 'Vendedor atualizado com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({ commit }, vendedor) {
      try {
        commit('SET_CARREGANDO', true);
        await vendedoresApi.excluir(vendedor._id);
        commit('EXCLUIR_VENDEDOR', vendedor._id);
        commit('setMensagemSucesso', 'Vendedor removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_VENDEDORES(state, vendedores) {
      state.vendedores = vendedores;
      state.carregando = false;
    },
    SET_TOTAL_VENDEDORES(state, total) {
      state.totalVendedores = total;
    },
    SET_VENDEDOR(state, vendedorAtual) {
      state.vendedorAtual = vendedorAtual;
    },
    EXCLUIR_VENDEDOR(state, id) {
      let index = state.vendedores.findIndex((vendedor) => vendedor._id == id);
      if (index > -1) {
        state.vendedores.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.vendedorAtual = {
        nome: '',
        comissao: 0,
        ativo: true,
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.vendedorAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_VENDEDOR(state, vendedor) {
      state.vendedores.unshift(vendedor);
      state.carregando = false;
    },
    ATUALIZAR_VENDEDOR(state, vendedor) {
      let vendedor_encontrado = state.vendedores.find((vendedor_) => vendedor_.id == vendedor.id);
      if (vendedor_encontrado) {
        state.vendedores.forEach((vendedor) => {
          if (vendedor._id === vendedor_encontrado._id) {
            vendedor = vendedor;
          }
        });
      }
      state.carregando = false;
    },
    estadoInicial(state) {
      state.vendedorAtual = {
        nome: '',
        comissao: '',
        ativo: true,
      };
      state.carregando = false;
    },
  },
};
