const Menu = [
  { cabecalho: 'Sistema', divider: true, nivel: 2, permissao: true },
  {
    titulo: 'Configurações',
    grupo: 'configuracoes',
    nome: 'Configuração',
    icone: 'mdi-cog',
    items: [
      {
        nome: 'Matriz',
        titulo: 'Matriz',
        path: '/matriz',
        icone: 'how_to_reg',
        grupo: 'configuracao',
        nivel: 2,
        permissao: 'acessar_matriz',
      },
      {
        nome: 'Filiais',
        titulo: 'Filiais',
        path: '/filiais',
        icone: 'domain',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_filiais',
      },
      {
        titulo: 'Certificado Digital',
        name: 'certificadoDigital',
        path: '/configuracoes/certificado-digital',
        icone: 'vpn_key',
        grupo: 'configuracao',
        nivel: 2,
        permissao: '',
      },
      { nome: 'Usuários', titulo: 'Usuários', path: '/configuracoes/usuarios', icone: 'mdi-account', grupo: 'configuracao', nivel: 2, permissao: '' },
      {
        nome: 'Dispositivos',
        titulo: 'Dispositivos',
        path: '/configuracoes/dispositivos',
        icone: 'mdi-devices',
        grupo: 'configuracao',
        nivel: 2,
        permissao: '',
      },
    ],
    nivel: 2,
  },
  { cabecalho: 'Avançado', nivel: 2, permissao: true },
  {
    titulo: 'Importacão de XML',
    grupo: 'Avançado',
    nome: 'Importação',
    icone: 'import_export',
    path: '/importacoes',
    nivel: 2,
    permissao: 'acessar_importacao',
  },
];

export default Menu;
