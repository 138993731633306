import ListaRemessasCheques from '@/views/remessas-cheques/ListaRemessasCheques';
import FormularioRemessasCheques from '@/views/remessas-cheques/FormularioRemessasCheques';

export default [
  {
    path: '/movimentacoes/remessas-cheques',
    name: 'RemessasChequesLista',
    component: ListaRemessasCheques,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_remessascheque' },
  },
  {
    path: '/movimentacoes/remessas-cheques/novo',
    name: 'RemessasChequesNovo',
    component: FormularioRemessasCheques,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_remessascheque' },
  },
  {
    path: '/movimentacoes/remessas-cheques/editar',
    name: 'RemessasChequesEditar',
    component: () => import(/* webpackChunkName: "CrudEditar" */ '@/views/remessas-cheques/FormularioRemessasCheques'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_remessascheque' },
  },
];
