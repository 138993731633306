import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/centrosCustos?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(centroCusto) {
  return axios.post(`/centrosCustos`, centroCusto).then((response) => response.data);
}

function atualizar(centroCusto) {
  return axios.put(`/centrosCustos/${centroCusto._id}`, centroCusto).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/centrosCustos/${id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
