<template>
  <div>
    {{ data_e_hora }}
  </div>
</template>

<script>
export default {
  name: 'relogio',

  props: {
    showSeconds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      data_e_hora: '',
      interval: null,
    };
  },

  mounted() {
    let data, hora;

    this.interval = setInterval(() => {
      data = new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
      if (this.showSeconds) {
        hora = new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      } else {
        hora = new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
      }

      this.data_e_hora = `${data} ${hora}`;
    }, 1000);
  },

  destroyed() {
    clearInterval(this.interval);
  },

  computed: {
    name() {
      return this.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
