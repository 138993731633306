<template>
  <div>
    <v-container fluid>
      <v-widget title="Vendas">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="6">
                <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca.numero" @click:clear="busca.numero = ''" max-width="100px" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca"></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3">
                <campo-data label="Data" v-model="busca.data" ref="data" filled flat single-line hide-details dense solo background-color="grey lighten-3"></campo-data>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :headers="headers" :items="cupons" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.numero }}</td>
                <td class="text-center">{{ item.data_emissao | formatarData }}</td>
                <td class="text-center">
                  <v-chip pill outlined
                    ><v-avatar left :color="definirCor(item.situacao)">{{ definirLetra(item.situacao) }}</v-avatar
                    >{{ item.situacao }}</v-chip
                  >
                </td>
                <td v-if="item.cliente">{{ item.cliente }}</td>
                <td v-else>CONSUMIDOR</td>
                <td class="text-center">{{ item.total_cupom | formatarMoeda }}</td>
                <td class="text-center">
                  <v-btn icon @click="detalhar(item)">
                    <v-icon data-cy="menu-acoes">mdi-eye</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { clone } from '@/util/helpers';
import moment from 'moment';

export default {
  name: 'ListaVendas',
  data() {
    return {
      headers: [
        { text: 'Número', value: 'numero', sortable: false, width: '5%', align: 'center' },
        { text: 'Data', value: 'data_emissao', sortable: false, width: '15%', align: 'center' },
        { text: 'Status', value: 'situacao', sortable: false, width: '10%', align: 'center' },
        { text: 'Cliente', value: 'cliente.razo_nome', sortable: false, width: '50%' },
        { text: 'Valor', value: 'total_cupom', sortable: false, width: '15%', align: 'center' },
        { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
      ],
    };
  },
  computed: {
    ...mapState('cupons', {
      cupons: 'cupons',
      cupom: 'cupom',
      carregando: 'carregando',
      busca: 'busca',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    buscarCupons() {
      this.$store.dispatch('cupons/buscar', this.busca);
    },
    detalhar(cupom_selecionado) {
      this.$store.commit('cupons/SET_ITEM', cupom_selecionado);
      this.$router.push({ name: 'vendasDetalhe' });
    },
    definirCor(status) {
      if (status == 'Autorizada') {
        return 'green';
      } else {
        return 'red';
      }
    },
    definirLetra(status) {
      if (status) {
        return status.charAt(0);
      } else {
        return '';
      }
    },
  },
  mounted() {
    this.buscarCupons();
  },
  watch: {
    busca: {
      handler() {
        this.buscarCupons();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
