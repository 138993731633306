<template>
  <div>
    <v-form v-model="valid" ref="formCompra">
      <v-container grid-list-md fluid>
        <v-row dense>
          <v-col cols="12">
            <v-widget title="Cadastro Compra">
              <div slot="widget-header-action">
                <input id="inputArquivo" type="file" v-show="false" :multiple="false" @change="carregarConteudo" :disabled="carregando" />
                <v-btn color="primary" depressed @click="escolherArquivo()" v-show="compra.numero == ''">Abrir Arquivo</v-btn>
              </div>
              <div slot="widget-content">
                <v-container fluid>
                  <v-tabs v-model="tab_atual">
                    <v-tab key="nfe">Nota Fiscal</v-tab>
                    <v-tab key="impostos">Impostos</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab_atual">
                    <v-tab-item key="nfe">
                      <v-row dense>
                        <v-col cols="12" sm="6" class="mt-3 mb-2">
                          <label>Dados gerais</label>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="2">
                          <v-text-field label="Número - Série" persistent-placeholder outlined :value="compra.numero ? compra.numero + ' - ' + compra.serie : ''" required readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-text-field label="Razão/Nome do Fornecedor" persistent-placeholder outlined v-model="compra.fornecedor.razaoSocialNome" required readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-text-field label="Chave de Acesso" persistent-placeholder outlined v-model="compra.chave" required readonly append-icon="mdi-content-copy" @click:append="copiarChaveAcesso"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">
                          <v-text-field label="CNPJ/CPF" persistent-placeholder outlined v-model="compra.fornecedor.cnpjCpf" required readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field label="Cidade" persistent-placeholder outlined v-model="compra.fornecedor.cidade" required readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field label="Estado" persistent-placeholder outlined v-model="compra.fornecedor.estado" required readonly></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="3">
                          <v-text-field label="Data Emissão" persistent-placeholder outlined :value="compra.data | formatarData" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <campo-data label="Data Entrada" persistent-placeholder outlined v-model="compra.data_entrada"></campo-data>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <as-campo-valor label="Total Produtos" persistent-placeholder outlined v-model="compra.valor_produtos" :decimais="2" ref="valor_produtos" readonly></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <as-campo-valor label="Total NFe" persistent-placeholder outlined v-model="compra.valor_nfe" :decimais="2" ref="valor_nfe" readonly></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="compra.quantidade_total_produtos" :decimais="2" ref="quantidade_total_produtos" readonly></as-campo-valor>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item key="impostos">
                      <v-row dense>
                        <v-col cols="12" sm="6" class="mt-3 mb-2">
                          <label>ICMS</label>
                        </v-col>
                        <v-col cols="12" sm="6" class="mt-3 mb-2">
                          <label>ICMS-ST</label>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Base de Cálculo" persistent-placeholder outlined v-model="compra.valor_base_calculo_icms" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_base_calculo_icms"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="ICMS" persistent-placeholder outlined v-model="compra.valor_icms" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_icms"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Base de Cálculo" persistent-placeholder outlined v-model="compra.valor_base_calculo_st" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_base_calculo_st"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="ICMS-ST" persistent-placeholder outlined v-model="compra.valor_icms_st" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_icms_st"></as-campo-valor>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" class="mb-2">
                          <label>IPI / PIS E COFINS</label>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="4">
                          <as-campo-valor label="IPI" persistent-placeholder outlined v-model="compra.valor_ipi" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_ipi"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <as-campo-valor label="PIS" persistent-placeholder outlined v-model="compra.valor_pis" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_pis"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <as-campo-valor label="COFINS" persistent-placeholder outlined v-model="compra.valor_cofins" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_cofins"></as-campo-valor>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="12" class="mb-2">
                          <label>OUTROS IMPOSTOS</label>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Seguro" persistent-placeholder outlined v-model="compra.valor_seguro" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_seguro"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Desconto" persistent-placeholder outlined v-model="compra.valor_desconto" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_desconto"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Frete" persistent-placeholder outlined v-model="compra.valor_frete" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_frete"></as-campo-valor>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <as-campo-valor label="Outros" persistent-placeholder outlined v-model="compra.valor_outro" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_outro"></as-campo-valor>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-container>
              </div>
            </v-widget>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="mt-2">
            <v-widget title="Produtos">
              <div slot="widget-header-action">
                <v-toolbar-title>
                  <span class="subtitle-2">{{ compra.itens.length }} Itens</span>
                </v-toolbar-title>
              </div>
              <div slot="widget-content">
                <v-container grid-list-md fluid>
                  <v-data-table hide-default-footer disable-pagination fixed-header :headers="headers" :items="compra.itens" :loading="carregando" :height="compra.itens.length > 9 ? '500px' : ''">
                    <template v-slot:item="{ item, index }">
                      <tr :class="definirCor(item)" style="cursor: pointer" @click="definirProdutoMapeamento(item)">
                        <td class="text-center">{{ item.codigo_entrada }}</td>
                        <td class="text-center">{{ item.codigo }}</td>
                        <td>{{ item.descricao }}</td>
                        <td class="text-center">{{ item.unidade }}</td>
                        <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                        <td class="text-center">{{ item.valor_custo | formatarMoeda }}</td>
                        <td class="text-center">{{ item.subtotal | formatarMoeda }}</td>
                        <td class="text-center">
                          <v-menu top right transition="slide-x-transition" @click.native.stop="">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                            </template>
                            <v-list dense>
                              <v-list-item data-cy="cadastrar" @click="abrirDialogoCadastroProdutoPontinhos(item)" v-if="!item.codigo">
                                <v-list-item-icon><v-icon>mdi-content-save</v-icon></v-list-item-icon>
                                <v-list-item-title>Cadastrar</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item data-cy="editar" @click="editarProduto(item, index)">
                                <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                                <v-list-item-title>Editar</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item data-cy="fronteira" @click="abrirFronteira(item)">
                                <v-list-item-icon><v-icon>mdi-calculator</v-icon></v-list-item-icon>
                                <v-list-item-title>Cálculo Fronteira</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item data-cy="excluir" @click="excluirProduto(index)">
                                <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                                <v-list-item-title>Excluir</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-container>
              </div>
            </v-widget>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" class="mt-2">
            <v-widget title="Contas a Pagar" altura="300px">
              <div slot="widget-header-action">
                <v-btn depressed color="primary" @click="abrirDialogoGerarDuplicatas">Gerar Duplicatas</v-btn>
              </div>
              <div slot="widget-content">
                <v-container grid-list-md fluid>
                  <v-data-table hide-default-footer disable-pagination fixed-header :headers="headerPagamento" :items="compra.pagamentos" :loading="carregando" :height="compra.pagamentos.length > 0 ? '250px' : ''">
                    <template v-slot:item="{ item, index }">
                      <tr>
                        <td>{{ item.parcela }}</td>
                        <td>{{ item.vencimento | formatarData }}</td>
                        <td>{{ item.valor | formatarMoeda }}</td>
                        <td class="text-center">
                          <v-menu top right transition="slide-x-transition" @click.native.stop="">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                            </template>
                            <v-list dense>
                              <v-list-item data-cy="editar" @click="editarDuplicata(item, index)">
                                <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                                <v-list-item-title>Editar</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item data-cy="editar" @click="excluirDuplicata(index)">
                                <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                                <v-list-item-title>Excluir</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-container>
              </div>
            </v-widget>
          </v-col>
          <v-col cols="12" sm="6" class="mt-2">
            <v-widget title="Observações / Outros" altura="300px">
              <div slot="widget-content">
                <v-textarea label="Observações" ref="observacoes" persistent-placeholder outlined v-model="compra.observacoes" rows="4" readonly></v-textarea>
                <v-row dense>
                  <v-col cols="12" sm="4">
                    <v-checkbox label="Estoque Nulo" persistent-placeholder outlined v-model="compra.estoque_nulo" color="primary" :disabled="compra.informativa"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-checkbox label="Cancelada" persistent-placeholder outlined v-model="compra.cancelada" color="primary"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-checkbox label="Informativa" persistent-placeholder outlined v-model="compra.informativa" color="primary" :disabled="compra.numero != ''" @click="ativarEstoqueNulo"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-widget>
          </v-col>
        </v-row>
        <v-card class="mt-3 pt-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed class="mr-2" @click="limpar">{{ constantes.BOTAO_CANCELAR }}</v-btn>
            <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid || compra.itens.length == 0">{{ textoBtnConfirmar }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-form>

    <dialog-buscar-produto ref="dialogoBuscarProduto" @produtoSelecionado="escolherProdutoMapeamento" :mostrar-botao-cadastro="true" @clicouBotaoCadastro="abrirDialogoCadastroProduto"></dialog-buscar-produto>

    <dialogo-editar-produto-compra ref="dialogoEditarProduto"></dialogo-editar-produto-compra>

    <v-dialog v-model="dialogoGerarDuplicatas" max-width="600px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Gerador de Duplicatas</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4">
              <campo-data label="Vencimento 1ª parcela" persistent-placeholder outlined v-model="gerador_duplicatas.vencimento_parcela1" ref="vencimento_parcela1"></campo-data>
            </v-col>
            <v-col cols="12" sm="4">
              <as-campo-valor label="Intervalo Parcelas" persistent-placeholder outlined v-model="gerador_duplicatas.intervalo_parcelas" :decimais="0" ref="intervalo_parcelas"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="4">
              <as-campo-valor label="Quantidade Parcelas" persistent-placeholder outlined v-model="gerador_duplicatas.parcelas" :decimais="0" ref="quantidade_parcelas"></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogoGerarDuplicatas = false">Cancelar</v-btn>
          <v-btn color="primary" @click="gerarDuplicatas">Gerar Duplicatas</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoEdicaoDuplicata" max-width="600px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Edição de Duplicata</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field label="Parcela" persistent-placeholder outlined v-model="duplicata.parcela" ref="duplicata_parcela"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <campo-data label="Vencimento" persistent-placeholder outlined v-model="duplicata.vencimento" ref="duplicata_vencimento"></campo-data>
            </v-col>
            <v-col cols="12" sm="4">
              <as-campo-valor label="Valor" persistent-placeholder outlined v-model="duplicata.valor" :decimais="2" ref="duplicata_valor"></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="this.dialogoEdicaoDuplicata = false">Cancelar</v-btn>
          <v-btn color="primary" @click="salvarDuplicata">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogo-fronteira ref="dialogoFronteira"></dialogo-fronteira>

    <v-dialog v-model="dialogoCadastroProduto" max-width="1000px" scrollable eager persistent>
      <formulario-produto ref="formCadastroProduto" :cadastro-nfe="true" @fecharDialogo="fecharDialogoCadastroProduto"></formulario-produto>
    </v-dialog>

    <v-dialog v-model="modalReprocessarDuplicata" max-width="600px" eager persistent>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Reprocessar Duplicatas</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <div class="body-1">Tem certeza que deseja reprocessar as duplicatas desta nota? (Algumas delas já podem ter sido pagas)</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch v-model="reprocessarDuplicatas" label="Reprocessar Duplicatas"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="modalReprocessarDuplicata = false">Cancelar</v-btn>
          <v-btn color="primary" @click="atualizarNota">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input type="hidden" id="input-chave" :value="compra.chave" />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import constantes from '@/util/constantes';
  import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
  import DialogoEditarProdutoCompra from './DialogoEditarProdutoCompra';
  import DialogoFronteira from './DialogoFronteira';
  import * as regras from '@/util/validacaoFormulario';
  import FormularioProduto from '@/views/produtos/FormularioProduto';
  import moment from 'moment';

  export default {
    name: 'FormularioImportarXMLCompra',

    components: {
      DialogBuscarProduto,
      DialogoEditarProdutoCompra,
      DialogoFronteira,
      FormularioProduto,
    },

    data: () => ({
      headers: [
        { text: 'Cód. Forn.', value: 'codigo_entrada', align: 'center', width: '10%' },
        { text: 'Código', value: 'codigo', align: 'center', width: '10%' },
        { text: 'Descrição', value: 'descricao', width: '35%' },
        { text: 'Unidade', value: 'unidade', align: 'center', width: '10%' },
        { text: 'Quantidade', value: 'quantidade', align: 'center', width: '10%' },
        { text: 'Valor', value: 'valor_custo', align: 'center', width: '10%' },
        { text: 'Subtotal', value: 'subtotal', align: 'center', width: '10%' },
        { text: 'Ações', value: 'acoes', align: 'center', width: '5%' },
      ],
      headerPagamento: [
        { text: 'Parcela', value: 'parcela', width: '40%' },
        { text: 'Vencimento', value: 'vencimento', width: '40%' },
        { text: 'Valor', value: 'valor', width: '15%' },
        { text: 'Ações', value: 'acoes', width: '5%' },
      ],
      valid: false,
      constantes: constantes,
      codigoProduto: '',
      exibirDialogBuscaProduto: false,
      produtoSelecionado: null,
      dialogEditarProduto: false,
      tab_atual: 'nfe',
      rules: { ...regras },
      dialogoGerarDuplicatas: false,
      gerador_duplicatas: {
        vencimento_parcela1: moment().format('YYYY-MM-DD'),
        intervalo_parcelas: 30,
        parcelas: 0,
      },
      dialogoEdicaoDuplicata: false,
      duplicata: {
        vencimento: '',
        valor: 0,
        parcela: '',
      },
      duplicata_indice: -1,
      dialogoCadastroProduto: false,
      reprocessarDuplicatas: false,
      modalReprocessarDuplicata: false,
    }),

    mounted() {
      setTimeout(() => {
        if (!this.modoEdicao) {
          let notaStorage = localStorage.getItem('notaFiscal') || null;
          if (notaStorage) {
            this.$root.$confirmacao.open('Recuperar Nota', 'Existe um nota salva temporariamente, deseja recuperar suas informações?').then((confirmado) => {
              if (confirmado) {
                this.$store.commit('compra/setCompra', JSON.parse(notaStorage));
              } else {
                localStorage.removeItem('notaFiscal');
              }
            });
          }
        }
      }, 500);
    },

    computed: {
      ...mapState('compra', {
        compra: 'compraAtual',
        carregando: 'carregando',
        produtoAtual: 'produtoAtual',
        modoEdicao: 'modoEdicao',
        // reprocessarDuplicatas: 'reprocessarDuplicatas',
      }),

      ...mapGetters('compra', {
        textoBtnConfirmar: 'textoBtnConfirmar',
        dataCompra: 'dataFormatada',
        totalProdutos: 'totalProdutos',
        totalDescontos: 'totalDescontos',
        totalLiquido: 'totalLiquido',
      }),

      ...mapState('produto', {
        produtoNfe: 'produtoNfe',
      }),
    },

    methods: {
      salvar() {
        if (this.modoEdicao) {
          this.reprocessarDuplicatas = false;
          this.modalReprocessarDuplicata = true;
        } else {
          this.salvarNota();
        }
      },
      atualizarNota() {
        this.$store.commit('compra/setReprocessarDuplicatas', this.reprocessarDuplicatas);
        this.$store.dispatch('compra/atualizarNota', { router: this.$router });

        localStorage.removeItem('notaFiscal');
      },
      salvarNota() {
        this.$store.dispatch('compra/salvarNota', { router: this.$router });

        localStorage.removeItem('notaFiscal');
      },

      definirFornecedor(idFornecedor) {
        this.$store.commit('compra/setFornecedor', idFornecedor);

        this.salvarNotaStorage();
      },

      limpar() {
        this.$store.dispatch('compra/cancelar', { router: this.$router });

        localStorage.removeItem('notaFiscal');
      },

      buscarCodigoProduto(codigo) {
        this.$store.dispatch('compra/buscarCodigoProduto', codigo);
      },

      adicionarProduto() {
        this.codigoProduto = '';
        this.$store.commit('compra/adicionarItem');

        this.salvarNotaStorage();
      },

      calcularSubtotal() {
        this.$store.commit('compra/calcularSubtotal');

        this.salvarNotaStorage();
      },

      removerItem(index) {
        this.$store.commit('compra/removerItem', index);
        this.salvarNotaStorage();
      },

      escolherArquivo() {
        document.getElementById('inputArquivo').click();
      },

      carregarConteudo(evento) {
        const arquivo = evento.target.files[0];
        if (arquivo) {
          var reader = new FileReader();
          reader.onload = (e) => {
            let arquivoLido = {
              chave: '',
              conteudo: e.target.result,
            };
            this.enviarConteudo(arquivoLido);
          };
          reader.readAsBinaryString(arquivo, 'ASCII');
        }
      },

      async enviarConteudo(arquivo) {
        await this.$store.dispatch('compra/processarArquivo', arquivo);
      },

      definirProdutoMapeamento(produto) {
        if (!produto.informativo) {
          this.$store.commit('compra/setProdutoAtual', produto);
          this.produtoSelecionado = produto;
          this.$refs.dialogoBuscarProduto.buscarProdutoNcm(produto.ncm);
        }

        this.salvarNotaStorage();
      },

      escolherProdutoMapeamento(produto_selecionado) {
        let produto = produto_selecionado;

        this.$refs.dialogoBuscarProduto.fechar();
        this.$store.commit('compra/definirProdutoMapeamento', { produtoNota: this.produtoSelecionado, produto: produto });

        this.salvarNotaStorage();
      },

      editarProduto(produto_selecionado, index) {
        produto_selecionado.indice_original = index;

        this.$store.commit('compra/setProduto', produto_selecionado);
        this.$refs.dialogoEditarProduto.abrir();
      },
      abrirDialogoGerarDuplicatas() {
        this.dialogoGerarDuplicatas = true;
      },
      gerarDuplicatas() {
        let valor_parcela = this.compra.valor_nfe / this.gerador_duplicatas.parcelas;
        let duplicatas = [];
        let ultimo_vencimento = this.gerador_duplicatas.vencimento_parcela1;

        for (let i = 1; i <= this.gerador_duplicatas.parcelas; i++) {
          duplicatas.push({
            parcela: this.compra.numero + ' - ' + i,
            valor: valor_parcela,
            vencimento: ultimo_vencimento,
          });
          ultimo_vencimento = moment(ultimo_vencimento, 'YYYY-MM-DD').add(this.gerador_duplicatas.intervalo_parcelas, 'd').format('YYYY-MM-DD');
        }

        this.compra.pagamentos = [];
        this.compra.pagamentos = duplicatas;

        this.dialogoGerarDuplicatas = false;

        this.salvarNotaStorage();
      },
      editarDuplicata(duplicata_selecionada, indice) {
        this.duplicata_indice = indice;
        this.duplicata = JSON.parse(JSON.stringify(duplicata_selecionada));
        this.dialogoEdicaoDuplicata = true;
      },
      salvarDuplicata() {
        this.compra.pagamentos[this.duplicata_indice].parcela = this.duplicata.parcela;
        this.compra.pagamentos[this.duplicata_indice].vencimento = this.duplicata.vencimento;
        this.compra.pagamentos[this.duplicata_indice].valor = this.duplicata.valor;
        this.compra.pagamentos[this.duplicata_indice].valor_restante = this.duplicata.valor;

        this.duplicata = {
          vencimento: '',
          valor: 0,
          parcela: '',
        };
        this.duplicata_indice = -1;
        this.dialogoEdicaoDuplicata = false;

        this.salvarNotaStorage();
      },
      excluirDuplicata(indice) {
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover esta duplicata?').then((confirmado) => {
          if (confirmado) {
            this.compra.pagamentos.splice(indice, 1);
            this.duplicata_indice = -1;
          }
        });

        this.salvarNotaStorage();
      },
      excluirProduto(indice) {
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este produto?').then((confirmado) => {
          if (confirmado) {
            this.compra.itens.splice(indice, 1);
            this.salvarNotaStorage();
          }
        });
      },
      definirCor(produto) {
        if (produto.informativo) {
          return 'blue--text';
        } else if (!produto.informativo && !produto.codigo) {
          return 'red--text';
        } else {
          return '';
        }
      },
      ativarEstoqueNulo() {
        this.compra.estoque_nulo = this.compra.informativa;

        this.salvarNotaStorage();
      },
      abrirFronteira(produto_selecionado) {
        this.$store.commit('compra/setProduto', produto_selecionado);
        this.$refs.dialogoFronteira.abrir();
      },
      copiarChaveAcesso() {
        let inputLinkToCopy = document.querySelector('#input-chave');

        inputLinkToCopy.setAttribute('type', 'text');
        inputLinkToCopy.select();
        document.execCommand('copy');
        inputLinkToCopy.setAttribute('type', 'hidden');
        window.getSelection().removeAllRanges();

        this.$store.commit('setMensagemSucesso', 'Chave de Acesso copiada para a área de transferência', { root: true });
      },
      abrirDialogoCadastroProduto() {
        this.$refs.formCadastroProduto.preencherDadosProdutoNfe(this.produtoAtual);
        this.dialogoCadastroProduto = true;
      },
      abrirDialogoCadastroProdutoPontinhos(produto) {
        this.$store.commit('compra/setProdutoAtual', produto);
        this.abrirDialogoCadastroProduto();
      },
      fecharDialogoCadastroProduto(status) {
        if (status == 'cadastrado') {
          this.produtoAtual.codigo = this.produtoNfe.codigo;
          this.produtoAtual.descricao = this.produtoNfe.descricao;
          this.produtoAtual.unidade = this.produtoNfe.unidade;
          this.produtoAtual.ncm = this.produtoNfe.ncm.codigo;
          this.produtoAtual.produto = this.produtoNfe._id;
        }

        this.$refs.dialogoBuscarProduto.fechar();
        this.dialogoCadastroProduto = false;

        this.salvarNotaStorage();
      },
      salvarNotaStorage() {
        localStorage.setItem('notaFiscal', JSON.stringify(this.compra));
      },
    },
  };
</script>

<style scoped>
  .container.grid-list-md .layout .flex {
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
  }
</style>
