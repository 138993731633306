export default [
  {
    path: '/configuracoes/usuarios',
    name: 'usuarios',
    component: () => import(/* webpackChunkName: "listaEmpresas" */ '@/views/usuarios/ListaUsuarios'),
    meta: { requiresAuth: true, nivel: 2 },
  },
  {
    path: '/configuracoes/usuarios/adicionar',
    name: 'usuarioAdicionar',
    component: () => import(/* webpackChunkName: "formularioEmpresa" */ '@/views/usuarios/FormularioUsuario'),
    meta: { requiresAuth: true, nivel: 2 },
  },
  {
    path: '/configuracoes/usuarios/editar',
    name: 'usuarioEditar',
    component: () => import(/* webpackChunkName: "formularioEmpresa" */ '@/views/usuarios/FormularioUsuario'),
    meta: { requiresAuth: true, nivel: 2 },
  },
];
