<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent>
    <v-form v-model="valid" ref="formStatusCheque" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Status do Cheque</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col xs="12" sm="12">
                <v-radio-group v-model="status.status" row>
                  <v-radio label="Em Caixa" :value="constantes.EM_CAIXA"></v-radio>
                  <v-radio label="Compensado" :value="constantes.COMPENSADO"></v-radio>
                  <v-radio label="Repassado" :value="constantes.REPASSADO"></v-radio>
                  <v-radio label="Devolvido" :value="constantes.DEVOLVIDO"></v-radio>
                  <v-radio label="Devolvido Pago" :value="constantes.DEVOLVIDO_PAGO"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" sm="6" v-if="status.status === constantes.COMPENSADO">
                <v-text-field label="Banco / Conta" v-model="status.bancoConta" @keypress.enter="$refs.data_deposito.focus()"></v-text-field>
              </v-col>
              <v-col xs="12" sm="6" v-if="status.status === constantes.COMPENSADO">
                <campo-data label="Data Depósito" ref="data_deposito" v-model="status.data" :rules="[rules.obrigatorio]" @keypress.enter="confirmar"></campo-data>
              </v-col>
              <v-col xs="12" sm="6" v-if="status.status === constantes.REPASSADO">
                <v-text-field label="Destino" v-model="status.destino" @keypress.enter="$refs.data_repasse.focus()"></v-text-field>
              </v-col>
              <v-col xs="12" sm="6" v-if="status.status === constantes.REPASSADO">
                <campo-data label="Data Repasse" ref="data_repasse" v-model="status.data" :rules="[rules.obrigatorio]" @keypress.enter="confirmar"></campo-data>
              </v-col>
              <v-col xs="12" sm="6" v-if="status.status === constantes.DEVOLVIDO">
                <v-text-field label="Motivo" v-model="status.motivo" @keypress.enter="$refs.data_devolucao.focus()"></v-text-field>
              </v-col>
              <v-col xs="12" sm="6" v-if="status.status === constantes.DEVOLVIDO || status.status === constantes.DEVOLVIDO_PAGO">
                <campo-data label="Data Devolução" ref="data_devolucao" v-model="status.data" :rules="[rules.obrigatorio]" @keypress.enter="confirmar"></campo-data>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="confirmar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';
import moment from 'moment';

export default {
  name: 'DialogoStatusCheque',
  props: ['dialog', 'cheque'],
  data() {
    return {
      valid: false,
      rules: { ...regras },
      constantes: constantes,
      status: {
        cheque: '',
        status: '',
        bancoConta: '',
        data: '',
        destino: '',
        motivo: '',
      },
    };
  },

  watch: {
    dialog(val) {
      if (val) {
        this.status.status = this.cheque.status;
        switch (this.cheque.status) {
          case 'Compensado':
            this.status.data = this.cheque.data_compensacao;
            this.status.bancoConta = this.cheque.banco_compensado;
            break;
          case 'Repassado':
            this.status.data = this.cheque.data_repasse;
            this.status.destino = this.cheque.destino;
            break;
          case 'Devolvido':
            this.status.data = this.cheque.data_devolucao;
            this.status.motivo = this.cheque.motivo;
            break;
          case 'Devolvido Pago':
            this.status.data = this.cheque.data_devolucao;
            break;
          default:
          //
        }
      }
    },
  },

  computed: {
    data() {
      if (this.status.data != '') {
        let data = moment(this.status.data).format('DD/MM/YY');
        return data;
      } else {
        return '';
      }
    },
  },

  methods: {
    fechar() {
      this.$emit('fechar');
      this.limpar();
    },

    confirmar() {
      if (this.$refs.formStatusCheque.validate()) {
        this.$emit('alterarStatus', this.status);
        this.limpar();
      }
    },

    limpar() {
      this.status = {
        status: '',
        cheque: '',
        bancoConta: '',
        data: '',
        destino: '',
        motivo: '',
      };
    },
  },
};
</script>
