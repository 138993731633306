import { formatarDataHora } from '../commonFunctions.mjs';

const getFooter = (footerData = { author: 'Anron Software' }) => {
  return function (currentPage, pageCount) {
    return [
      {
        margin: [20, 0, 20, 0],
        table: {
          widths: ['*'],
          body: [[{ text: '', border: [false, true, false, false] }]],
        },
      },

      {
        columns: [
          { text: footerData.author, margin: [40, 0, 0, 0], alignment: 'left', style: 'small', bold: true },
          [
            { text: formatarDataHora(new Date()), margin: [0, 0, 40, 0], alignment: 'right', style: 'small' },
            { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, margin: [0, 0, 40, 0], alignment: 'right', style: 'small' },
          ],
        ],
      },
    ];
  };
};

export default getFooter;
