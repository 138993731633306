import axios from '@/util/axios';

function listar() {
  return axios.get(`/usuarios`).then((response) => response.data);
}

function carregarEmpresas() {
  return axios.get('/empresas').then((response) => response.data);
}

function salvar(usuario) {
  return axios.post(`/usuarios`, usuario).then((response) => response.data);
}

function editar(usuarioId, dados) {
  return axios.put(`/usuarios/${usuarioId}`, dados).then((response) => response.data);
}

function remover(usuarioId) {
  return axios
    .delete(`/usuarios`, {
      data: { usuarioId },
    })
    .then((response) => response.data);
}

function alterarSenha(usuarioId, dados) {
  return axios.patch(`/usuarios/${usuarioId}/senha`, dados).then((response) => response.data);
}

export default {
  listar,
  carregarEmpresas,
  salvar,
  editar,
  remover,
  alterarSenha,
};
