import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import filiaisApi from '@/services/filiaisApi';
import sistemaApi from '@/services/sistemaApi';

export default {
  namespaced: true,

  state: {
    filiais: [],
    filialAtual: {
      cnpj: '',
      fantasia: '',
      razao: '',
      ie: '',
      im: '',
      cnae: '',
      descricao_cnae: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      uf: '',
      estado: '',
      pais: '',
      codigo_pais: '',
      logotipo: '',
      versao: '4.00',
      ambiente: '',
      certificadoTipo: 'A1',
      formato_impressao: '1',
      certificado: '',
      frete_padrao: '9',
      forma_pagamento_padrao: '0',
      senha: '',
      telefone: '',
      email: '',
      status: 'Ativa',
      plano: 'nfe',
      calculo_custo: constantes.CALCULO_MEDIA,
      configuracao_tributaria: {
        ramo_atividade: '',
        config_cfop_padrao: '1',
        cfop_padrao: '',
        regime_tributario: '',
        csosn: '102',
        serie_padrao: '',
        usa_icms_padrao: true,
        usa_icms_st_padrao: true,
        usa_pis_entrada_padrao: true,
        usa_pis_saida_padrao: true,
        usa_cofins_entrada_padrao: true,
        usa_cofins_saida_padrao: true,
        cst_icms: '00',
        cst_icms_st: '00',
        cst_pis_entrada: '01',
        cst_pis_saida: '01',
        cst_cofins_entrada: '01',
        cst_cofins_saida: '01',
        aliq_icms: 18.0,
        aliq_icms_st: 0,
        aliq_pis_entrada: 1.65,
        aliq_pis_saida: 1.65,
        aliq_cofins_entrada: 7.6,
        aliq_cofins_saida: 7.6,
        aliq_cred_sn: 18,
        info_complementar: '',
        empresa: null,
      },
    },
    cidades: [],
    constantes: constantes,
    conversor: conversor,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false,
  },

  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },

    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },

    listaCidades(state) {
      return state.cidades.map((cidade) => {
        return {
          text: cidade.cidade,
          value: cidade.codigo_cidade,
        };
      });
    },
  },

  actions: {
    async carregar({ commit }, options) {
      try {
        commit('setCarregando', true);
        const response = await filiaisApi.carregar(options);
        commit('setFiliais', response.empresas);
      } catch (error) {
        commit('setFiliais', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async filtrarCidadesEstado({ commit }, estado) {
      try {
        commit('setCarregandoCidades', true);
        const cidades = await filiaisApi.filtrarCidadesEstado(estado);
        commit('setCidades', cidades);
      } catch (error) {
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    async consultarCnae({ commit }, cnae) {
      try {
        commit('setCarregando', true);
        const response = await filiaisApi.consultarCnae(cnae);
        if (response.length > 0) {
          commit('setDescricaoCnae', response);
        } else {
          commit('setMensagemErro', 'CNAE não encontrado', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', 'Falha ao tentar consultar CNAE', { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        const filial = Object.assign({}, state.filialAtual);
        filial.cnpj = filial.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
        filial.cep = filial.cep.replace('.', '').replace('-', '');
        filial.cnae = filial.cnae.replace('-', '').replace('/', '');
        filial.telefone = filial.telefone.replace('(', '').replace(')', '').replace('-', '');
        if (state.modoEdicao) {
          const response = await filiaisApi.atualizar(filial);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'Filial atualizada com sucesso', { root: true });
        } else {
          delete filial._id;
          const response = await filiaisApi.salvar(filial);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'Filial cadastrada com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },

    setCarregandoCidades(state, valor) {
      state.carregando_cidade = valor;
    },

    setCidades(state, cidades) {
      state.cidades = cidades;
    },

    setDadosFilial(state, filial) {
      state.filialAtual.ie = filial.ie;
      state.filialAtual.razao = filial.razao;
      state.filialAtual.numero = filial.endereco.numero;
      state.filialAtual.cep = filial.endereco.cep;
      state.filialAtual.cnae = filial.cnae;
    },

    setCidade(state, codigo_cidade) {
      const cidade = state.cidades.find((cidade) => cidade.codigo_cidade == codigo_cidade);

      state.filialAtual.cidade = cidade.cidade;
      state.filialAtual.codigo_cidade = cidade.codigo_cidade;
      state.filialAtual.uf = cidade.codigo_estado;
      state.filialAtual.pais = 'Brasil';
      state.filialAtual.codigo_pais = 1058;
    },

    setEndereco(state, endereco) {
      state.filialAtual.logradouro = endereco.logradouro;
      state.filialAtual.bairro = endereco.bairro;
      state.filialAtual.uf = conversor.ufParaCodigo(endereco.uf);
      state.filialAtual.estado = endereco.uf;
      state.filialAtual.cidade = endereco.localidade;
      state.filialAtual.codigo_cidade = endereco.ibge;
      state.filialAtual.pais = 'Brasil';
      state.filialAtual.codigo_pais = 1058;
    },

    setDescricaoCnae(state, dadosCnae) {
      state.filialAtual.descricao_cnae = dadosCnae[0].descricao;
    },

    setInfoComplementar(state, infoComplementar) {
      state.filialAtual.configuracao_tributaria.info_complementar = infoComplementar;
    },

    setFiliais(state, filiais) {
      state.filiais = filiais;
      state.carregando = false;
    },

    atualizarConfigCfop(state) {
      if (state.filialAtual.configuracao_tributaria.config_cfop_padrao === '1') {
        // cfop definido pelo ramo de atividade
        if (state.filialAtual.configuracao_tributaria.ramo_atividade === '1') {
          // comércio
          state.filialAtual.configuracao_tributaria.cfop_padrao = '5102';
        } else if (state.filialAtual.configuracao_tributaria.ramo_atividade === '3') {
          //industria
          state.filialAtual.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },

    adicionar(state, filial) {
      state.filiais.push(filial);
      state.carregando = false;
    },

    atualizar(state, atual) {
      state.filiais.forEach((filial) => {
        if (filial._id === atual._id) {
          filial = atual;
        }
      });
      state.carregando = false;
    },

    remover(state, removido) {
      state.filiais = state.filiais.filter((filial) => filial._id !== removido._id);
      state.carregando = false;
    },

    nova(state) {
      state.modoEdicao = false;
      state.filialAtual = {
        cnpj: '',
        fantasia: '',
        razao: '',
        ie: '',
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        versao: '4.00',
        ambiente: '',
        certificadoTipo: 'A1',
        formato_impressao: '1',
        certificado: '',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        senha: '',
        telefone: '',
        email: '',
        status: 'Ativa',
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.0,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.6,
          aliq_cofins_saida: 7.6,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null,
        },
      };
      state.carregando = false;
    },

    editar(state, editar) {
      state.modoEdicao = true;
      state.filialAtual = editar;
      state.carregando = false;
    },

    estadoInicial(state) {
      state.filialAtual = {
        cnpj: '',
        fantasia: '',
        razao: '',
        ie: '',
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        versao: '4.00',
        ambiente: '',
        certificadoTipo: 'A1',
        formato_impressao: '1',
        certificado: '',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        senha: '',
        telefone: '',
        email: '',
        status: 'Ativa',
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.0,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.6,
          aliq_cofins_saida: 7.6,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null,
        },
      };
    },
  },
};
