import localforage from 'localforage';

import fabricantesApi from '@/services/fabricantesApi';

const getFabricantes = (fabricantes, options) => {
  let fabricantesOk = fabricantes;
  fabricantesOk.sort((a, b) => (a.razaoSocialNome > b.razaoSocialNome ? 1 : -1));

  localforage.setItem('fabricantes', fabricantesOk);

  if (options && options.busca) {
    fabricantesOk = fabricantesOk.filter((fabricante) => fabricante.nome.toLocaleUpperCase().includes(options.busca.toLocaleUpperCase()));
  }

  if (options && options.itemsPerPage && options.page) {
    const from = options.itemsPerPage * (options.page - 1);
    const to = options.itemsPerPage * (options.page - 1) + options.itemsPerPage;
    fabricantesOk = fabricantesOk.filter((fabricante, idx) => idx >= from && idx <= to);
  }

  return fabricantesOk;
};

export default {
  namespaced: true,

  state: {
    fabricantes: [],
    fabricante: {
      nome: '',
    },
    carregando: false,
  },

  actions: {
    listar({ commit }, options) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });

          localforage.getItem('timestampFabricantes').then((timestamp = null) => {
            fabricantesApi.listar({ timestamp: timestamp || undefined }).then((response) => {
              localforage.getItem('fabricantes').then((fabricantes) => {
                let fabricantesOk = [...(fabricantes || [])];

                response.fabricantes.forEach((fabricante) => {
                  const index = fabricantesOk.findIndex((p) => p._id === fabricante._id);

                  if (index < 0) {
                    fabricantesOk.push(fabricante);
                  } else {
                    fabricantesOk[index] = fabricante;
                  }
                });

                localforage.setItem('timestampFabricantes', response.timestamp);
                fabricantesOk = fabricantesOk.filter((fabricante) => !response.deletados.includes(fabricante._id));
                fabricantesOk = getFabricantes(fabricantesOk, options);
                commit('setState', { fabricantes: fabricantesOk });
                resolve();
              });
            });
          });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          resolve();
        } finally {
          commit('setState', { carregando: false });
        }
      });
    },

    async adicionar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          fabricantesApi
            .adicionar({
              ...state.fabricante,
            })
            .then((fabricante) => {
              localforage.getItem('fabricantes').then((fabricantes) => {
                const fabricantesOk = fabricantes.concat([fabricante]);
                getFabricantes(fabricantesOk);
                commit('setState', { fabricantes: state.fabricantes.concat([fabricante]), carregando: false });
                commit('setMensagemSucesso', 'Fabricante cadastrado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async editar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          fabricantesApi
            .editar({
              ...state.fabricante,
            })
            .then((fabricante) => {
              localforage.getItem('fabricantes').then((fabricantes) => {
                const fabricantesOk = fabricantes;
                const index = fabricantesOk.findIndex((p) => p._id === fabricante._id);

                if (index >= 0) fabricantesOk[index] = fabricante;

                getFabricantes(fabricantesOk);
                commit('setState', { fabricantes: state.fabricantes.map((part) => (part._id === fabricante._id ? fabricante : part)), carregando: false });
                commit('setMensagemSucesso', 'Fabricante atualizado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async remover({ commit }, fabricanteId, options) {
      try {
        commit('setState', { carregando: true });
        await fabricantesApi.remover(fabricanteId);

        localforage.getItem('fabricantes').then((fabricantes) => {
          let fabricantesOk = fabricantes;
          fabricantesOk = fabricantesOk.filter((p) => p._id !== fabricanteId);
          fabricantesOk = getFabricantes(fabricantesOk, options);
          commit('setState', { fabricantes: fabricantesOk });
          commit('setMensagemSucesso', 'Fabricante removido com sucesso', { root: true });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    novo({ commit }) {
      commit('setState', { fabricante: { nome: '' } });
    },
  },

  mutations: {
    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
