<template>
  <v-btn-toggle small :value="plano" @change="setPlano" mandatory>
    <v-btn small value="easy" id="easy">
      <v-icon>mdi-numeric-1</v-icon>
      <v-tooltip bottom activator="#easy">
        Easy
      </v-tooltip>
    </v-btn>
    <v-btn small value="nfe" id="nfe">
      <v-icon>mdi-numeric-2</v-icon>
      <v-tooltip bottom activator="#nfe">
        NFE
      </v-tooltip>
    </v-btn>
    <v-btn small value="deposito" id="deposito">
      <v-icon>mdi-numeric-3</v-icon>
      <v-tooltip bottom activator="#deposito">
        Depósito
      </v-tooltip>
    </v-btn>
    <v-btn small value="pdv" id="pdv" @click="$router.push('pdv')">
      <v-icon>mdi-numeric-4</v-icon>
      <v-tooltip bottom activator="#pdv">
        PDV
      </v-tooltip>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'MenuButtons',

  props: ['plano'],

  methods: {
    setPlano(plano) {
      this.$emit('update:plano', plano);
    },
  },
};
</script>

<style lang="scss" scoped></style>
