<template>
  <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Recebimentos</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pa-2">
        <v-container fluid>
          <v-row dense>
            <v-col cols="12" sm="3">
              <campo-valor v-model="conta.valor_restante" label="Valor Restante" dense filled hide-details disabled></campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="pagamento.juros_multa" label="Juros/Multa" dense filled hide-details @input="calcularValorPago"></campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="pagamento.desconto" label="Desconto" dense filled hide-details @input="calcularValorPago"></campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="pagamento.valor_pago" label="Pagamento" dense filled hide-details @input="onValorPagoChange"></campo-valor>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="pagamento.tipo" :items="tipos_pagamento" label="Tipo" dense filled hide-details @change="onTipoChange"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="pagamento.cartao" :items="bandeiras" :disabled="['Cartão de Crédito', 'Cartão de Débito'].indexOf(pagamento.tipo) == -1" label="Cartão" dense filled hide-details></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-textarea v-model="pagamento.observacoes" label="Observações" rows="3" dense filled hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fechar">Cancelar</v-btn>
        <v-btn color="primary" :loading="carregando" @click="confirmar">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapState } from 'vuex';
import { hoje } from '@/util/helpers';

export default {
  data() {
    return {
      dialog: false,
      pagamento: {
        data: null,
        juros_multa: 0,
        desconto: 0,
        valor_pago: 0,
        tipo: 'Dinheiro',
        cartao: '',
        observacoes: '',
      },
      // temp: { id: 1, data: '2020-04-09', desconto: 0, valor: 100.00, tipo: 'Dinheiro' },
      conta: {},
      tipos_pagamento: [],
      bandeiras: [],
      carregando: false,
    };
  },

  // computed: {
  //   ...mapState('contareceber', {
  //     conta: 'contaAtual',
  //     tipos_pagamento: 'tipos_pagamento',
  //     bandeiras: 'bandeiras',
  //     carregando: 'carregando',
  //   }),
  // },

  methods: {
    abrir() {
      this.zerar();
      this.calcularValorPago();
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    calcularValorPago() {
      this.pagamento.valor_pago = this.conta.valor_restante + this.pagamento.juros_multa - this.pagamento.desconto;
    },

    async confirmar() {
      await this.$store.dispatch('contareceber/salvarBaixa', this.pagamento);
      this.$store.commit('contareceber/ADICIONAR_PAGAMENTO', this.pagamento);
      this.fechar();
    },

    onTipoChange(tipo) {
      if (['Cartão de Crédito', 'Cartão de Débito'].indexOf(tipo) == -1) {
        this.pagamento.cartao = '';
      }
    },

    onValorPagoChange() {
      this.pagamento.juros_multa = 0;
      this.pagamento.desconto = 0;
    },

    zerar() {
      this.pagamento = {
        data: hoje(),
        juros_multa: 0,
        desconto: 0,
        valor_pago: 0,
        tipo: 'Dinheiro',
        cartao: '',
        observacoes: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
