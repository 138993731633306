<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col md="8">
        <v-card flat style="background-color: transparent !important">
          <v-container>
            <h5 class="text-h5 red--text">Acesso restrito!</h5>

            <v-divider class="my-3"></v-divider>

            <div class="grey--text mb-5">A página que você tentou acessar tem acesso restrito</div>

            <v-btn style="float: right" depressed color="primary" @click="$router.push('/')"> <v-icon left>mdi-arrow-left</v-icon>Início</v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
