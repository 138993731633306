export default [
  {
    path: '/tabelas/pis',
    name: 'pis',
    component: () => import(/* webpackChunkName: "listaPis" */ '@/views/pis/ListaPIS'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_pis' },
  },
  {
    path: '/tabelas/pis/adicionar',
    name: 'pisAdicionar',
    component: () => import(/* webpackChunkName: "formularioPis" */ '@/views/pis/FormularioPIS'),
    meta: { requiresAuth: true, nivel: 4 },
  },
  {
    path: '/tabelas/pis/editar',
    name: 'pisEditar',
    component: () => import(/* webpackChunkName: "formularioPis" */ '@/views/pis/FormularioPIS'),
    meta: { requiresAuth: true, nivel: 4 },
  },
];
