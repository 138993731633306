import localforage from 'localforage';

import contasCorrentesApi from '@/services/contasCorrentesApi';

const getContasCorrentes = (contasCorrentes, options) => {
  let contasCorrentesOk = contasCorrentes;
  contasCorrentesOk.sort((a, b) => (a.razaoSocialNome > b.razaoSocialNome ? 1 : -1));

  localforage.setItem('contasCorrentes', contasCorrentesOk);

  if (options && options.busca) {
    contasCorrentesOk = contasCorrentesOk.filter((contaCorrente) => contaCorrente.nome.toLocaleUpperCase().includes(options.busca.toLocaleUpperCase()));
  }

  if (options && options.itemsPerPage && options.page) {
    const from = options.itemsPerPage * (options.page - 1);
    const to = options.itemsPerPage * (options.page - 1) + options.itemsPerPage;
    contasCorrentesOk = contasCorrentesOk.filter((contaCorrente, idx) => idx >= from && idx <= to);
  }

  return contasCorrentesOk;
};

const contaCorrenteBlank = {
  numero: '',
  nome: '',
  agencia: '',
  banco: {
    codigo: '',
    descricao: '',
  },
};

export default {
  namespaced: true,

  state: {
    contasCorrentes: [],
    contaCorrente: structuredClone(contaCorrenteBlank),
    carregando: false,
  },

  getters: {
    selectContasCorrentes({ contasCorrentes }) {
      return contasCorrentes.map((contaCorrente) => {
        return {
          text: contaCorrente.nome,
          value: `${contaCorrente.numero} - ${contaCorrente.banco.descricao}`,
        };
      });
    },
  },

  actions: {
    listar({ commit }, options) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });

          contasCorrentesApi.listar({ timestamp: undefined }).then((response) => {
            localforage.getItem('contasCorrentes').then((contasCorrentes) => {
              let contasCorrentesOk = [...(contasCorrentes || [])];

              response.contasCorrentes.forEach((contaCorrente) => {
                const index = contasCorrentesOk.findIndex((p) => p._id === contaCorrente._id);

                if (index < 0) {
                  contasCorrentesOk.push(contaCorrente);
                } else {
                  contasCorrentesOk[index] = contaCorrente;
                }
              });

              contasCorrentesOk = getContasCorrentes(contasCorrentesOk, options);
              commit('setState', { contasCorrentes: contasCorrentesOk });
              resolve();
            });
          });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          resolve();
        } finally {
          commit('setState', { carregando: false });
        }
      });
    },

    async adicionar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          contasCorrentesApi
            .adicionar({
              ...state.contaCorrente,
            })
            .then((contaCorrente) => {
              localforage.getItem('contasCorrentes').then((contasCorrentes) => {
                const contasCorrentesOk = contasCorrentes.concat([contaCorrente]);
                getContasCorrentes(contasCorrentesOk);
                commit('setState', { contasCorrentes: state.contasCorrentes.concat([contaCorrente]), carregando: false });
                commit('setMensagemSucesso', 'Conta Corrente cadastrada com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async editar({ commit, state }) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          contasCorrentesApi
            .editar({
              ...state.contaCorrente,
            })
            .then((contaCorrente) => {
              localforage.getItem('contasCorrentes').then((contasCorrentes) => {
                const contasCorrentesOk = contasCorrentes;
                const index = contasCorrentesOk.findIndex((p) => p._id === contaCorrente._id);

                if (index >= 0) contasCorrentesOk[index] = contaCorrente;

                getContasCorrentes(contasCorrentesOk);
                commit('setState', { contasCorrentes: state.contasCorrentes.map((part) => (part._id === contaCorrente._id ? contaCorrente : part)), carregando: false });
                commit('setMensagemSucesso', 'Conta Corrente atualizado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async remover({ commit }, contaCorrenteId, options) {
      try {
        commit('setState', { carregando: true });
        await contasCorrentesApi.remover(contaCorrenteId);

        localforage.getItem('contasCorrentes').then((contasCorrentes) => {
          let contasCorrentesOk = contasCorrentes;
          contasCorrentesOk = contasCorrentesOk.filter((p) => p._id !== contaCorrenteId);
          contasCorrentesOk = getContasCorrentes(contasCorrentesOk, options);
          commit('setState', { contasCorrentes: contasCorrentesOk });
          commit('setMensagemSucesso', 'Conta Corrente removido com sucesso', { root: true });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    novo({ commit }) {
      commit('setState', { contaCorrente: structuredClone(contaCorrenteBlank) });
    },
  },

  mutations: {
    setState(state, newState) {
      console.log(state, newState);
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
