import localforage from 'localforage';

import participantesApi from '@/services/participantesApi';

const getParticipantes = (participantes, options) => {
  let participantesOk = participantes;
  participantesOk.sort((a, b) => (a.razaoSocialNome > b.razaoSocialNome ? 1 : -1));

  localforage.setItem('participantes', participantesOk);

  if (options && options.busca) {
    participantesOk = participantesOk.filter((participante) => `${participante.razaoSocialNome} ${participante.nomeFantasia} ${participante.cnpjCpf}`.toLocaleUpperCase().includes(options.busca.toLocaleUpperCase()));
  }

  if (options && options.itemsPerPage && options.page) {
    const from = options.itemsPerPage * (options.page - 1);
    const to = options.itemsPerPage * (options.page - 1) + options.itemsPerPage;
    participantesOk = participantesOk.filter((participante, idx) => idx >= from && idx <= to);
  }

  return participantesOk;
};

const participanteBlank = {
  tipo: 'Cliente',
  cnpjCpf: '',
  razaoSocialNome: '',
  nomeFantasiaApelido: '',
  rgIe: '',
  suframa: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  codigoCidade: null,
  cidade: '',
  codigoEstado: null,
  estado: '',
  pais: '',
  codigoPais: 1058,
  telefone: '',
  celular: '',
  limiteCredito: 0,
  email: '',
  nascimento: null,
  foto: '',
  produtosContrato: [],
  referencias: [],
  dadosAdicionais: {
    contato: '',
    profissao: '',
    tipoResidencia: '',
    filiacao: '',
    estadoCivil: '',
    conjuge: '',
    localTrabalho: '',
  },
  prazoNotaPromissoria: 30,
  restricoes: '',
  observacoes: '',
  ativo: true,
};

export default {
  namespaced: true,

  state: {
    participantes: [],
    participante: structuredClone(participanteBlank),
    carregando: false,
  },

  getters: {
    partcipantesSelect() {
      return this.participantes.map((participante) => ({ text: participante.razaoSocialNome, value: participante._id }));
    },
  },

  actions: {
    listar({ commit }, options) {
      try {
        commit('setState', { carregando: true });

        localforage.getItem('timestampParticipantes').then((timestamp = null) => {
          participantesApi.listar({ timestamp: timestamp || undefined }).then((response) => {
            localforage.getItem('participantes').then((participantes) => {
              let participantesOk = [...(participantes || [])];

              response.participantes.forEach((participante) => {
                const index = participantesOk.findIndex((p) => p._id === participante._id);

                if (index < 0) {
                  participantesOk.push(participante);
                } else {
                  participantesOk[index] = participante;
                }
              });

              localforage.setItem('timestampParticipantes', response.timestamp);
              participantesOk = participantesOk.filter((participante) => !response.deletados.includes(participante._id));
              participantesOk = getParticipantes(participantesOk, options);
              commit('setState', { participantes: participantesOk });
            });
          });
        });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setState', { carregando: false });
      }
    },

    async adicionar({ commit, state }, foto) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          participantesApi
            .adicionar({
              ...state.participante,
              referencias: state.participante.referencias.filter((ref) => ref.nome.trim() || ref.observacoes.trim()),
              produtosContrato: state.participante.produtosContrato.filter((pro) => pro.produto.trim() || pro.numeroSerie.trim()),
              foto: foto || undefined,
            })
            .then((participante) => {
              localforage.getItem('participantes').then((participantes) => {
                const participantesOk = participantes.concat([participante]);
                getParticipantes(participantesOk);
                commit('setState', { participantes: state.participantes.concat([participante]), carregando: false });
                commit('setMensagemSucesso', 'Cliente/Fornecedor cadastrado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    async editar({ commit, state }, foto) {
      return new Promise((resolve) => {
        try {
          commit('setState', { carregando: true });
          participantesApi
            .editar({
              ...state.participante,
              referencias: state.participante.referencias.filter((ref) => ref.nome.trim() || ref.observacoes.trim()),
              produtosContrato: state.participante.produtosContrato.filter((pro) => pro.produto.trim() || pro.numeroSerie.trim()),
              foto: foto || undefined,
            })
            .then((participante) => {
              localforage.getItem('participantes').then((participantes) => {
                const participantesOk = participantes;
                const index = participantesOk.findIndex((p) => p._id === participante._id);

                if (index >= 0) participantesOk[index] = participante;

                getParticipantes(participantesOk);
                commit('setState', { participantes: state.participantes.map((part) => (part._id === participante._id ? participante : part)), carregando: false });
                commit('setMensagemSucesso', 'Cliente/Fornecedor atualizado com sucesso', { root: true });
                resolve(true);
              });
            });
        } catch (error) {
          commit('setMensagemErro', error, { root: true });
          commit('setState', { carregando: false });
          resolve(false);
        }
      });
    },

    // async remover({ commit }, participanteId, options) {
    //   try {
    //     commit('setState', { carregando: true });
    //     await participantesApi.remover(participanteId);

    //     localforage.getItem('participantes').then((participantes) => {
    //       let participantesOk = participantes;
    //       participantesOk.filter((p) => p._id !== participanteId);
    //       participantesOk = getParticipantes(participantesOk, options);
    //       commit('setState', { participantes: participantesOk });
    //       commit('setMensagemSucesso', 'Cliente/Fornecedor removido com sucesso', { root: true });
    //     });
    //   } catch (error) {
    //     commit('setMensagemErro', error, { root: true });
    //   } finally {
    //     commit('setState', { carregando: false });
    //   }
    // },

    novo({ commit }) {
      commit('setState', { participante: structuredClone(participanteBlank) });
    },
  },

  mutations: {
    setState(state, newState) {
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
