import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    contador: {
      cpf: '',
      nome: '',
      crc: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      pais: '',
      codigo_pais: '',
      telefone: '',
      email: '',
    },
    periodo: {
      data_inicial: '',
      data_final: '',
    },
    notas: [],
    autorizadas: [],
    canceladas: [],
    inutilizadas: [],
    denegadas: [],
    empresa: {},
    contadorDefinido: false,
    carregando: false,
    cidades: [],
    carregando_cidade: false,
  },

  //equivalente ao computed properties
  getters: {
    listaCidades(state) {
      return state.cidades.map((cidade) => {
        return {
          text: cidade.cidade,
          value: cidade.codigo_cidade,
        };
      });
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async filtrarCidadesEstado({ commit }, estado) {
      try {
        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
        commit('setCidades', cidades);
      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    enviarArquivos({ commit, rootState, state }) {
      commit('setCarregando', true);
      axios
        .post('/contadores/arquivos', state.periodo)
        .then((response) => {
          let result = response.data;
          if (result.status == 200) {
            commit('setMensagemSucesso', 'Arquivos Gerados com Sucesso', { root: true });
            commit('setEstadoInicial');
          } else {
            commit('setMensagemErro', result.motivo, { root: true });
          }
          commit('setCarregando', false);
        })
        .catch((error) => {
          commit('setCarregando', false);
          commit('setMensagemErro', error, { root: true });
        });
    },

    async salvarContador({ commit, state, rootState }) {
      let dadosContador = Object.assign({}, state.contador);
      dadosContador.cep = dadosContador.cep.replace('.', '').replace('-', '');
      dadosContador.cpf = dadosContador.cpf
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
      dadosContador.telefone = dadosContador.telefone
        .replace('(', '')
        .replace(')', '')
        .replace('-', '');

      commit('setCarregando', true);

      try {
        let response = await axios.post(`/contadores`, dadosContador);
        let retorno = response.data;
        if (!retorno.status) {
          commit('setContador', retorno);
          commit('setMensagemSucesso', 'Contador Cadastrado com Sucesso', { root: true });
        } else {
          commit('setMensagemErro', retorno.motivo, { root: true });
          commit('setCarregando', false);
        }
      } catch (error) {
        commit('setCarregando', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },

    async editarContador({ commit, state, rootState }) {
      let dadosContador = Object.assign({}, state.contador);
      dadosContador.cep = dadosContador.cep.replace('.', '').replace('-', '');
      dadosContador.cpf = dadosContador.cpf
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
      dadosContador.telefone = dadosContador.telefone
        .replace('(', '')
        .replace(')', '')
        .replace('-', '');

      commit('setCarregando', true);
      try {
        let response = await axios.put('/contadores', dadosContador);
        let retorno = response.data;
        if (!retorno.status) {
          commit('setMensagemSucesso', 'Contador Atualizado com Sucesso', { root: true });
        } else {
          commit('setMensagemErro', retorno.motivo, { root: true });
        }
        commit('setCarregando', false);
      } catch (error) {
        commit('setCarregando', false);
        commit('setMensagemErro', error, { root: true });
        throw error;
      }
    },

    async carregarContador({ commit, rootState }) {
      commit('setCarregando', true);
      try {
        let response = await axios.get('/contadores');
        if (response.status === 200) {
          let retorno = response.data;
          if (retorno) {
            commit('setContador', retorno);
          } else {
            commit('setContadorVazio');
          }
        } else {
          commit('setMensagemErro', response.data.motivo, { root: true });
        }
      } catch (error) {
        if (error.response) {
          commit('setMensagemErro', error, { root: true });
        } else {
          commit('setMensagemErro', error.message, { root: true });
        }
        commit('setCarregando', false);
      }
    },
    cancelar({ commit }, dados) {
      commit('estadoInicial', dados.router);
    },
  },

  //responsável por alterar o state.
  mutations: {
    setNotas(state, notas) {
      state.notas = notas;

      state.autorizadas = notas.filter((nota) => {
        return nota.status == constantes.STATUS_AUTORIZADA;
      });
      state.inutilizadas = notas.filter((nota) => {
        return nota.status == constantes.STATUS_INUTILIZADA;
      });
      state.denegadas = notas.filter((nota) => {
        return nota.status == constantes.STATUS_DENEGADA;
      });
      state.canceladas = notas.filter((nota) => {
        return nota.status == constantes.STATUS_CANCELADA;
      });
      state.carregando = false;
    },

    setCidades(state, cidades) {
      state.cidades = cidades;
    },

    setCidade(state, codigo_cidade) {
      let cidade = state.cidades.find((cidade) => cidade.codigo_cidade == codigo_cidade);

      state.contador.cidade = cidade.cidade;
      state.contador.codigo_cidade = cidade.codigo_cidade;
      state.contador.codigo_estado = cidade.codigo_estado;
      state.contador.pais = 'Brasil';
      state.contador.codigo_pais = 1058;
    },

    setEndereco(state, endereco) {
      state.contador.logradouro = endereco.logradouro;
      state.contador.bairro = endereco.bairro;
      state.contador.estado = endereco.uf;
      state.contador.cidade = endereco.localidade;
      state.contador.codigo_cidade = endereco.ibge;
      state.contador.pais = 'Brasil';
      state.contador.codigo_pais = 1058;
    },

    setEmpresa(state, empresa) {
      state.empresa = empresa;
    },

    setCarregando(state, valor) {
      state.carregando = valor;
    },

    setCarregandoCidades(state, valor) {
      state.carregando_cidade = valor;
    },

    setContador(state, contador) {
      state.contador = contador;
      state.contadorDefinido = true;
      state.carregando = false;
    },

    setContadorVazio(state) {
      state.contador = {
        cpf: '',
        nome: '',
        crc: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        pais: '',
        codigo_pais: '',
        telefone: '',
        email: '',
      };
      state.contadorDefinido = false;
      state.carregando = false;
    },

    setEstadoInicial(state) {
      state.periodo = {
        data_inicial: '',
        data_final: '',
      };
    },

    estadoInicial(state, router) {
      state.periodo = {
        data_inicial: '',
        data_final: '',
      };
      router.push('/home');
      state.carregando = false;
    },
  },
};
