import notificacoesApi from '@/services/notificacoesApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    notificacoes: [],
    notificacaoAtual: {
      titulo: '',
      conteudo: '',
    },
    todasNotificacoes: [],
    carregando: false,
    visualizar: false,
    visualizarTodas: false,
  },

  //equivalente ao computed properties
  getters: {
    notificacoesNaoLidas(state) {
      let naoLidas = [];
      state.notificacoes.forEach((notificacao) => {
        if (notificacao.lida == false) {
          naoLidas.push(notificacao);
        }
      });
      return naoLidas;
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregarNaoLidas({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await notificacoesApi.listar();
        commit('setNotificacoes', response);
      } catch (error) {
        commit('setNotificacoes', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async carregarTodas({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await notificacoesApi.listarTodas();
        commit('setTodasNotificacoes', response);
        commit('visualizarTodas');
      } catch (error) {
        commit('setTodasNotificacoes', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async marcarLida({ commit, state }, notificacao) {
      try {
        commit('setCarregando', true);
        await notificacoesApi.marcarLida(notificacao);
        if (state.visualizar) {
          commit('marcarLida', notificacao);
        } else if (state.visualizarTodas) {
          commit('marcarLidaJanelaTodas', notificacao);
        }
      } catch (error) {
        commit('fechar');
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },

    async marcarNaoLida({ commit, state }, notificacao) {
      try {
        commit('setCarregando', true);
        await notificacoesApi.marcarNaoLida(notificacao);
        if (state.visualizar) {
          commit('marcarNaoLida', notificacao);
        } else if (state.visualizarTodas) {
          commit('marcarNaoLidaJanelaTodas', notificacao);
        }
      } catch (error) {
        commit('fechar');
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setNotificacoes(state, notificacoes) {
      state.notificacoes = notificacoes;
      state.carregando = false;
    },
    setTodasNotificacoes(state, notificacoes) {
      state.todasNotificacoes = notificacoes;
      if (notificacoes.length > 0) {
        state.notificacaoAtual = notificacoes[0];
      }
      state.carregando = false;
    },
    setNotificacao(state, notificacao) {
      state.notificacaoAtual = notificacao;
      state.visualizar = true;
    },
    detalharNotificacao(state, notificacao) {
      state.notificacaoAtual = notificacao;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    fechar(state) {
      state.visualizar = false;
    },
    fecharTodas(state) {
      state.visualizarTodas = false;
    },
    marcarLida(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
    },
    marcarNaoLida(state, notificacao) {
      state.notificacaoAtual.lida = false;
      state.visualizar = true;
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
    },
    marcarLidaJanelaTodas(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.todasNotificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
    },
    marcarNaoLidaJanelaTodas(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.todasNotificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
    },
    visualizarTodas(state) {
      state.visualizarTodas = true;
    },
  },
};
