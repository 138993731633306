import FormularioConfiguracoes from '@/views/configuracoes/FormularioConfiguracoes';

export default [
  {
    path: '/configuracoes',
    name: 'Configurações',
    component: FormularioConfiguracoes,
    meta: { requiresAuth: true, nivel: 2 },
  },
];
