<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Menu</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-card-text class="pa-4 pb-3">
        <v-row dense>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoRecebimentos')" depressed>Recebimentos</v-btn>
            <span>Receber promissórias ou crediários de clientes</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoCaixa')" depressed>Caixa</v-btn>
            <span>Movimento de caixa por data e terminal</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoVendas')" depressed>Vendas</v-btn>
            <span>Listagem de vendas por data e terminal</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoClientes')" depressed>Clientes / Fornecedores</v-btn>
            <span>Cadastro de clientes e fornecedores</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoCheques')" depressed>Cheques</v-btn>
            <span>Listagem de cheques recebidos</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoDevolucoes')" depressed disabled>Devoluções</v-btn>
            <span>Devolução de mercadorias</span>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-3" style="align-items: center">
            <v-btn color="primary" @click="abrirDialogo('dialogoRelatorios')" depressed disabled>Relatórios</v-btn>
            <span>Relatórios diversos</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogoMenu',

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    abrirDialogo(dialogo) {
      this.$emit('abrir:dialogo', dialogo);
    },
  },
};
</script>

<style scoped></style>
