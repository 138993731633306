import DashboardAdmin from '@/views/DashboardAdmin';

export default [
  {
    path: '/admin/home',
    name: 'admin-home',
    component: DashboardAdmin,
    meta: { requiresAuth: true, nivel: 4 },
  },
];
