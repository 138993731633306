<template>
  <v-container fluid>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formVendedor" lazy-validation>
      <v-widget title="Vendedor">
        <template v-slot:widget-content>
          <v-row dense>
            <v-col cols="12" sm="10">
              <v-text-field label="Nome" autofocus outlined v-model.trim="vendedor.nome" :rules="[rules.obrigatorio]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor :label="'Comissão'" suffix="%" outlined v-model="vendedor.comissao" :rules="[rules.obrigatorio, rules.valorPositivo, rules.percentual100]" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="5">
              <v-checkbox label="Ativo" v-model="vendedor.ativo" hideDetails />
            </v-col>
            <v-col cols="7" style="text-align: end; display: flex; align-items: end">
              <v-btn depressed class="mr-4 ml-auto" @click="cancelar">Cancelar</v-btn>
              <v-btn depressed color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-widget>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioVendedor',
  data: () => ({
    valid: false,
    constantes: constantes,
    rules: { ...regras },
    loading: false,
  }),

  computed: {
    ...mapState('vendedor', {
      vendedor: 'vendedorAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('vendedor', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),
  },

  methods: {
    async salvar() {
      if (this.$refs.formVendedor.validate()) {
        try {
          if (this.vendedor._id) {
            await this.$store.dispatch('vendedor/atualizar', this.vendedor);
          } else {
            await this.$store.dispatch('vendedor/salvar', this.vendedor);
          }
          this.$router.push({ name: 'vendedores' });
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$store.commit('vendedor/estadoInicial');
      this.$router.push({ name: 'vendedores' });
    },
  },

  async created() {
    try {
      this.loading = true;
      const vendedor = this.$store.state.vendedor.vendedorAtual;
      const estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;

      if (estaEditando && (!vendedor._id || vendedor._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um vendedor para editar');
        this.$router.push({ name: 'vendedores' });
      }

      if (!estaEditando) {
        this.$store.commit('vendedor/estadoInicial');
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
