import ListaCFOPCompraEntrada from '@/views/cfopscompra/ListaCFOPCompraEntrada';
import FormularioCFOPCompraEntrada from '@/views/cfopscompra/FormularioCFOPCompraEntrada';

export default [
  {
    path: '/tabelas/cfop/entrada',
    name: 'cfopCompraEntrada',
    component: ListaCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1, permissao: 'cfop_compra_entrada_acessar' },
  },
  {
    path: '/tabelas/cfop/entrada/adicionar',
    name: 'cfopCompraEntradaAdicionar',
    component: FormularioCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1, permissao: 'cfop_compra_entrada_adicionar' },
  },
  {
    path: '/tabelas/cfop/entrada/editar',
    name: 'cfopCompraEntradaEditar',
    component: FormularioCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1, permissao: 'cfop_compra_entrada_editar' },
  },
];
