import axios from '@/util/axios';

function listar(options) {
  return axios.get('/pedidos-clientes', { params: options }).then((response) => response.data);
}

function salvar(item) {
  return axios.post('/pedidos-clientes', item).then((response) => response.data);
}

function atualizar(pedido_cliente) {
  // return axios.put(`/pedidosclientes`, pedido_cliente).then((response) => response.data);
  return axios.put(`/pedidos-clientes/${pedido_cliente._id}`, pedido_cliente).then((response) => response.data);

}

function excluir(id) {
  return axios.delete(`/pedidos-clientes/${id}`).then((response) => response.data);
}

export default {
  salvar,
  listar,
  atualizar,
  excluir,
};
