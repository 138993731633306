import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/cofins?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(cofins) {
  return axios.post(`/cofins`, cofins).then((response) => response.data);
}

function atualizar(cofins) {
  return axios.put(`/cofins/${cofins._id}`, cofins).then((response) => response.data);
}

function excluir(cofins) {
  return axios.delete(`/cofins/${cofins._id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
