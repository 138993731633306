import moment from 'moment';

import usuariosApi from '@/services/usuariosApi';
import empresasApi from '@/services/empresasApi';

export default {
  namespaced: true,

  state: {
    session: {
      modulo: null,
      usuario: {},
      tokens: {},
      empresa: {},
      empresas: [],

      ...JSON.parse(localStorage.getItem('session') || '{}'),

      certificadoVencido: false,
    },
  },

  actions: {
    async definirEmpresa({ commit }, empresa) {
      try {
        commit('setCarregando', true, { root: true });

        const data = usuariosApi.alterarEmpresa(empresa.id);

        commit('setSession', data);
        commit('setMensagemSucesso', 'Empresa alterada', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false, { root: true });
      }
    },

    async alterarEmpresa({ commit }, empresa) {
      try {
        commit('setCarregando', true, { root: true });
        const { validade } = await empresasApi.alterarEmpresa(empresa.id);
        const dias = moment(validade, 'MMMM DD hh:mm:ss YYYY GMT').diff(moment(), 'days');

        if (dias <= 30) {
          let mensagem = '';
          if (dias == 0) {
            mensagem = 'Seu certificado digital vence hoje';
          } else if (dias < 0) {
            mensagem = 'Seu certificado digital já venceu';
          } else {
            mensagem = 'Seu certificado digital se vence em ' + dias + ' dias';
          }
          commit('setMensagemErro', mensagem, { root: true });
        }

        if (dias < 0) {
          commit('setCertificadoVencido', true);
        } else {
          commit('setCertificadoVencido', false);
        }
      } catch (error) {
        commit('setMensagemErro', 'Falha ao consultar o certificado digital', { root: true });
      } finally {
        commit('setCarregando', false, { root: true });
      }
    },

    logout({ commit }, router) {
      const session = {
        modulo: null,
        usuario: {},
        tokens: {},
        empresa: {},
        empresas: [],
        certificadoVencido: false,
        statusSefazNFe: false,
        statusSefazNFCe: false,
        statusSefazMDFe: false,
      };

      commit('setSession', session);

      localStorage.removeItem('session');

      commit('sair', false, { root: true });

      router.push('/login');
    },
  },

  mutations: {
    setCertificadoVencido(state, status) {
      state.session.certificadoVencido = status;
    },

    setSession(state, data) {
      state.session = { ...state.session, ...data };
      localStorage.setItem('session', JSON.stringify(state.session));
    },

    setAmbiente(state, reload) {
      let ambiente = state.session.ambiente === 2 ? 1 : 2;

      if (this.state.isDevelopment) {
        ambiente = 2;
      }

      state.session = { ...state.session, ambiente };
      localStorage.setItem('session', JSON.stringify(state.session));

      if (reload) {
        const el = document.getElementById('appRoot');
        el.style.transition = 'all 1s';
        el.style.filter = 'brightness(0)';
        window.location.reload();
      }
    },
  },
};
