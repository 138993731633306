import axios from '@/util/axios';

function paises() {
  return axios.get(`/paises`).then((response) => response.data);
}

function estados() {
  return axios.get(`/estados`).then((response) => response.data);
}

function cidades() {
  return axios.get(`/cidades`).then((response) => response.data);
}

export default {
  paises,
  estados,
  cidades,
};
