<template>
  <div>
    <v-dialog v-model="dialog" max-width="780px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Filtro</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-form ref="formFiltro" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <buscar-participante persistent-placeholder outlined label="Cliente" ref="busca_participante" v-model="cliente.cnpjCpf" @participanteSelecionado="clienteSelecionado" />
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field persistent-placeholder outlined label="'Nome'" placeholder=" " ref="nome_participante" v-model="cliente.razaoSocialNome" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select persistent-placeholder outlined label="Exibir" ref="tipos" :items="itens_exibir" v-model="filtro.exibir"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn depressed class="mr-2" @click="limpar">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fechar" :disabled="carregando">Cancelar</v-btn>
          <v-btn depressed color="primary" :loading="carregando" @click="filtrar">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DialogoFiltro',
  data() {
    return {
      dialog: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      itens_exibir: ['Todos', 'Em Aberto', 'Vencidos', 'Pagos', 'Pagos Parcialmente'],
      filtro: {
        cliente: '',
        exibir: 'Todos',
      },
    };
  },
  computed: {
    ...mapState('contaReceber', {
      contas: 'contasReceber',
      cliente: 'clienteAtual',
      carregando: 'carregando',
    }),
  },
  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
    },

    limpar() {
      this.$store.commit('contaReceber/SET_CLIENTE', {});
      (this.filtro = {
        cliente: {},
        exibir: 'Todos',
      }),
        this.$emit('limpar');
      this.dialog = false;
    },

    async filtrar() {
      if (this.cliente._id) {
        let newOptions = {
          ...this.options,
          ...this.filtro,
        };
        this.$emit('filtrar', newOptions);
        this.fechar();
      } else {
        this.$store.commit('setMensagemErro', 'Escolha um cliente');
      }
    },

    clienteSelecionado(cliente) {
      this.$store.commit('contaReceber/SET_CLIENTE', cliente);
    },
  },
};
</script>

<style></style>
