import constantes from '@/util/constantes';
import mvasApi from '@/services/mvasApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    mvas: [],
    mvaAtual: {
      ncm: '',
      descricao: '',
      mercado_interno: '',
      mercado_externo_4: '',
      mercado_externo_7: '',
      mercado_externo_12: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalMvas: 0,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await mvasApi.listar(options);
        commit('SET_MVAS', response.mvas);
        commit('SET_TOTAL_MVAS', response.total);
      } catch (error) {
        commit('SET_MVAS', []);
        commit('setMensagemErro', 'Não foi possível listar os mvas', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('SET_CARREGANDO');
        let mva = Object.assign({}, state.mvaAtual);
        let response = await mvasApi.salvar(mva);
        commit('ATUALIZAR_MVA', response);
        commit('setMensagemSucesso', 'MVA Atualizado com Sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({ commit }, mva) {
      try {
        commit('SET_CARREGANDO');
        let response = await mvasApi.atualizar(mva);
        commit('ATUALIZAR_MVA', response);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({ commit }, mva) {
      try {
        commit('SET_CARREGANDO');
        await mvasApi.excluir(mva);
        commit('EXCLUIR_MVA', mva._id);
        commit('setMensagemSucesso', 'MVA removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', 'error ao excluir o MVA', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_MVAS(state, mvas) {
      state.mvas = mvas;
      state.carregando = false;
    },
    SET_TOTAL_MVAS(state, total) {
      state.totalMvas = total;
    },
    SET_MVA(state, mvaAtual) {
      state.mvaAtual = mvaAtual;
    },
    SET_NCM(state, ncm) {
      state.mvaAtual.ncm = ncm;
      state.carregando = false;
    },
    EXCLUIR_MVA(state, id) {
      let index = state.mvas.findIndex((mva) => mva._id == id);
      if (index > -1) {
        state.mvas.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.mvaAtual = {
        ncm: '',
        mercado_interno: '',
        mercado_externo_4: '',
        mercado_externo_7: '',
        mercado_externo_12: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.mvaAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_MVA(state, mva) {
      state.mvas.unshift(mva);
      state.carregando = false;
    },
    ATUALIZAR_MVA(state, mva) {
      let mva_encontrado = state.mvas.find((mva_) => mva_.id == mva.id);
      if (mva_encontrado) {
        mva_encontrado = mva;
      }
      state.carregando = false;
    },
    estadoInicial(state) {
      state.mvaAtual = {
        ncm: '',
        mercado_interno: '',
        mercado_externo_4: '',
        mercado_externo_7: '',
        mercado_externo_12: '',
      };
      state.carregando = false;
    },
  },
};
