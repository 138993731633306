<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="fechar" persistent scrollable eager :retain-focus="false">
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Informar Pagamento</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row dense v-if="!['CARTAOCREDITO', 'CARTAODEBITO', 'CREDIARIO', 'BOLETO', 'PROMISSORIA'].includes(tipoPagamento)">
          <v-col cols="12" sm="8" class="nofocus">
            <v-text-field placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem; user-select: none !important" persistent-placeholder hide-details readonly label="Forma Pagamento" :value="getDescricao()" />
          </v-col>

          <v-col cols="12" sm="4">
            <campo-valor
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="2"
              label="Valor"
              ref="valor"
              v-model="pagamento.valor"
              @keyup.native.enter="confirmar"
              @input="onValorChange"
              :rules="validarValor()"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="['PROMISSORIA', 'BOLETO'].includes(tipoPagamento)">
          <v-col cols="12" class="nofocus">
            <v-text-field placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem; user-select: none !important" persistent-placeholder hide-details readonly label="Forma Pagamento" :value="getDescricao()" />
          </v-col>
          <v-col cols="12" sm="5">
            <campo-valor
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="2"
              label="Valor"
              v-model="pagamento.valor"
              ref="valor2"
              :rules="validarValor()"
              @keydown.enter.native="$refs.prazo.focus()"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <campo-valor
              v-model="pagamento.prazo"
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="0"
              label="Prazo"
              ref="prazo"
              @keydown.native.enter="$refs.vencimento.focus()"
              @input="calcularVencimento"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <campo-data
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              v-model="pagamento.vencimento"
              label="Vencimento"
              ref="vencimento"
              @keydown.native.enter="confirmar"
              @input="calcularDias"
              :allowedDates="dataMinima"
            />
          </v-col>
        </v-row>

        <v-row dense v-if="tipoPagamento === 'CREDIARIO'">
          <v-col cols="12" :sm="!tef.active ? 8 : 12" class="nofocus">
            <v-text-field placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem; user-select: none !important" persistent-placeholder hide-details readonly label="Forma Pagamento" :value="getDescricao()" />
          </v-col>
          <v-col cols="12" sm="4">
            <campo-valor
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="2"
              label="Valor"
              v-model="pagamento.valor"
              ref="valor3"
              @input="calcularParcelas"
              :rules="validarValor()"
              @keydown.enter.native="$refs.parcelas.focus()"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <campo-valor
              v-model="pagamento.parcelas"
              style="font-weight: 400; font-size: 1.4rem"
              hide-details
              placeholder=" "
              outlined
              persistent-placeholder
              fixarDecimais
              decimais="0"
              label="Parcelas"
              ref="parcelas"
              @input="calcularParcelas"
              @keydown.enter.native="confirmar"
            />
          </v-col>
          <v-col cols="12" sm="5" class="nofocus">
            <campo-valor placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem" persistent-placeholder hide-details fixarDecimais decimais="2" label="Valor Parcela" v-model="pagamento.valorParcela" readonly />
          </v-col>
          <v-col cols="12" sm="5" class="nofocus">
            <campo-valor placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem" persistent-placeholder hide-details fixarDecimais decimais="2" v-model="pagamento.ultimaParcela" label="Última Parcela" readonly />
          </v-col>
        </v-row>

        <v-row dense v-if="tipoPagamento === 'CARTAOCREDITO'">
          <v-col cols="12" :sm="!tef.active ? 7 : 12" class="nofocus">
            <v-text-field placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem; user-select: none !important" persistent-placeholder hide-details readonly label="Forma Pagamento" :value="getDescricao()" />
          </v-col>
          <v-col v-if="!tef.active" cols="12" sm="5">
            <v-select hide-details placeholder=" " outlined background-color="white" persistent-placeholder label="Bandeira" v-model="pagamento.bandeira" :items="tabelas.bandeirasCartoes.map((bc) => ({ value: bc.codigo, text: bc.descricao }))" />
          </v-col>
          <v-col cols="12" sm="5">
            <campo-valor
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="2"
              label="Valor"
              v-model="pagamento.valor"
              ref="valor4"
              @input="onValorChange"
              @keydown.enter.native="$refs.parcelas4.focus()"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <campo-valor
              v-model="pagamento.parcelas"
              style="font-weight: 400; font-size: 1.4rem"
              hide-details
              placeholder=" "
              outlined
              persistent-placeholder
              fixarDecimais
              decimais="0"
              label="Parcelas"
              @input="calcularParcelas"
              ref="parcelas4"
              @keydown.enter.native="confirmar"
            />
          </v-col>
          <v-col cols="12" sm="5" class="nofocus">
            <campo-valor placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem" persistent-placeholder hide-details fixarDecimais decimais="2" label="Valor Parcela" v-model="pagamento.valorParcela" readonly />
          </v-col>
        </v-row>

        <v-row dense v-if="tipoPagamento === 'CARTAODEBITO'">
          <v-col cols="12" :sm="!tef.active ? 12 : 8" class="nofocus">
            <v-text-field placeholder=" " outlined style="font-weight: 400; font-size: 1.4rem; user-select: none !important" persistent-placeholder hide-details readonly label="Forma Pagamento" :value="getDescricao()" />
          </v-col>
          <v-col v-if="!tef.active" cols="12" sm="8">
            <v-select hide-details placeholder=" " outlined background-color="white" persistent-placeholder label="Bandeira" v-model="pagamento.bandeira" :items="tabelas.bandeirasCartoes.map((bc) => ({ value: bc.codigo, text: bc.descricao }))" />
          </v-col>
          <v-col cols="12" sm="4">
            <campo-valor
              placeholder=" "
              outlined
              style="font-weight: 400; font-size: 1.4rem"
              persistent-placeholder
              hide-details
              fixarDecimais
              decimais="2"
              label="Valor Parcela"
              v-model="pagamento.valor"
              ref="valor5"
              @keyup.native.enter="confirmar"
              @input="onValorChange"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4 pt-0">
        <v-chip v-if="troco > 0" label style="font-size: 20px; height: 36px !important" class="py-4" color="green lighten-4">
          Troco <b class="pl-4">{{ troco | formatarValor2 }}</b>
        </v-chip>
        <v-spacer />
        <v-btn depressed class="mr-2 px-4" @click="fechar">Cancelar</v-btn>
        <v-btn color="primary" depressed class="px-4" @click="confirmar" :disabled="validarPagamento()">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import tef from '@/util/tef';
import { mapGetters, mapState, mapActions } from 'vuex';
import formatador from '@/util/formatador';
import * as dayjs from 'dayjs';

export default {
  props: {
    tipoPagamento: {
      type: String,
      default: 'DINHEIRO',
    },
  },

  data() {
    return {
      dialog: false,
      pagamento: {
        valor: 0,
        tipo: 'DINHEIRO',
        parcelas: 0,
        valorParcela: 0.0,
        ultimaParcela: 0.0,
        vencimento: '',
        prazo: 0,
        bandeira: 'MASTERCARD',
      },
      troco: 0,
    };
  },

  updated() {
    this.pagamento.tipo = this.tipoPagamento;
  },

  computed: {
    ...mapState('venda', ['venda', 'formas_pagamento', 'bandeiras_cartoes', 'tef']),
    ...mapState(['tabelas']),
    ...mapGetters('venda', ['saldo', 'totalPagamentos', 'totalLiquido']),
  },

  methods: {
    ...mapActions('venda', {
      TEFCrt: 'TEFCrt',
      TEFChq: 'TEFChq',
    }),

    validarPagamento() {
      if (['DINHEIRO', 'CARTAOCREDITO', 'CARTAODEBITO'].includes(this.tipoPagamento)) {
        return this.pagamento.valor <= 0;
      }
      return this.pagamento.valor <= 0 || this.pagamento.valor > this.saldo;
    },

    validarValor() {
      return [(v) => (v && formatador.formatarValorUS(v) > 0 && (this.tipoPagamento === 'DINHEIRO' ? true : formatador.formatarValorUS(v) <= this.saldo)) || false];
    },

    dataMinima(val) {
      const hoje = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0');
      return dayjs(val).diff(dayjs(hoje), 'days') > 0;
    },

    abrir() {
      this.zerar();
      this.pagamento.valor = this.saldo;

      this.onValorChange();

      this.dialog = true;

      setTimeout(() => {
        if (!['CARTAOCREDITO', 'CARTAODEBITO', 'CREDIARIO', 'BOLETO', 'PROMISSORIA'].includes(this.tipoPagamento)) {
          this.$refs.valor.focus();
        } else if (['PROMISSORIA', 'BOLETO'].includes(this.tipoPagamento)) {
          this.$refs.valor2.focus();
        } else if (this.tipoPagamento === 'CREDIARIO') {
          this.$refs.valor3.focus();
        } else if (this.tipoPagamento === 'CARTAOCREDITO') {
          this.$refs.valor4.focus();
        } else if (this.tipoPagamento === 'CARTAODEBITO') {
          this.$refs.valor5.focus();
        }
      }, 100);
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    getDescricao() {
      const fp = this.tabelas.formasPagamentos.find((fp) => fp.codigo === this.tipoPagamento) || { descricao: '' };
      return fp.descricao.toUpperCase();
    },

    async confirmar() {
      if (['CARTAOCREDITO', 'CARTAODEBITO'].includes(this.tipoPagamento) && this.tef.active) {
        this.$store.commit('venda/pagamentoPendente', this.pagamento);
        this.TEFCrt({ valor: this.pagamento.valor, parcelas: this.pagamento.parcelas, totalPago: this.totalPagamentos, tipo: this.tipoPagamento === 'CARTAOCREDITO' ? 'C' : 'D', valorVenda: this.totalLiquido });
        this.fechar();
      } else if (['CONSULTACHEQUE'].includes(this.tipoPagamento) && this.tef.active) {
        this.$store.commit('venda/pagamentoPendente', this.pagamento);
        this.TEFChq({ valor: this.pagamento.valor, totalPago: this.totalPagamentos, valorVenda: this.totalLiquido });
        this.fechar();
      } else {
        this.$store.commit('venda/adicionarPagamento', this.pagamento);
        this.fechar();
      }
    },

    // async autorizacaoTef() {
    //   const razao = formatador.formatarTamanho(this.$store.state.autenticacao.session.empresa.fantasia, 40);

    //   const parametros = {
    //     identificador: this.$store.state.vendas.venda.identificador,
    //     valor: this.pagamento.valor,
    //     parcelas: this.pagamento.cartao_credito_parcelas,
    //     cartao: this.pagamento.tipo,
    //     razao: razao,
    //     data: Date.now(),
    //   };

    //   const resultado = await tef.vendaSimples(parametros);

    //   if (resultado['009-000'] == 0) {
    //     this.pagamento.adiquirente = resultado['010-000'];
    //     this.pagamento.nsu = resultado['012-000'];
    //     this.pagamento.autorizacao = resultado['013-000'];
    //     this.pagamento.data = resultado['022-000'];
    //     this.pagamento.hora = resultado['023-000'];
    //     this.pagamento.controle = resultado['027-000'];

    //     const linhasViaCliente = resultado['712-000'];
    //     const linhasViaEstabelecimento = resultado['714-000'];
    //     const viaCliente = [];
    //     const viaEstabelecimento = [];

    //     for (let i = 1; i < linhasViaCliente; i++) {
    //       const numero = formatador.formatarPadZero(i, 3);
    //       const linha = `713-${numero}`;
    //       const conteudoLinha = resultado[linha];
    //       viaCliente.push(conteudoLinha);
    //     }

    //     for (let i = 0; i < linhasViaEstabelecimento; i++) {
    //       const numero = formatador.formatarPadZero(i, 3);
    //       const linha = `715-${numero}`;
    //       const conteudoLinha = resultado[linha];
    //       viaEstabelecimento.push(conteudoLinha);
    //     }

    //     if (resultado['729-000'] == 2) {
    //       await tef.confirmarOperacao();

    //       this.pagamento.comprovanteCliente = viaCliente;
    //       this.pagamento.comprovanteEstabelecimento = viaEstabelecimento;
    //     }

    //     tef.estadoInicial();
    //     this.$store.commit('venda/adicionarPagamento', this.pagamento);
    //     this.fechar();
    //   } else {
    //     this.$store.commit('setMensagemErro', 'Falha na Autorização');
    //     tef.estadoInicial();
    //   }
    // },

    onValorChange() {
      this.calcularTroco();
      this.calcularParcelas();
    },

    calcularVencimento() {
      if (this.pagamento.prazo <= 0) this.pagamento.prazo = 1;
      this.pagamento.vencimento = dayjs().add(this.pagamento.prazo, 'day').format('YYYY-MM-DD');
    },

    calcularDias() {
      this.pagamento.prazo = dayjs(this.pagamento.vencimento).diff(new Date(), 'days') + 1;
    },

    calcularParcelas() {
      const parcelas = this.pagamento.parcelas > 0 ? this.pagamento.parcelas : 1;
      this.pagamento.valorParcela = parseFloat((this.pagamento.valor / parcelas).toFixed(2));
      this.pagamento.ultimaParcela = parseFloat((this.pagamento.valorParcela + (this.pagamento.valor - this.pagamento.valorParcela * parcelas)).toFixed(2));

      if (this.pagamento.parcelas <= 0) this.pagamento.parcelas = 1;
    },

    calcularTroco() {
      if (!['DINHEIRO', 'CARTAOCREDITO', 'CARTAODEBITO'].includes(this.tipoPagamento)) {
        this.troco = 0;
      } else {
        const isValorMaiorQueSaldo = this.pagamento.valor > this.saldo;
        this.troco = isValorMaiorQueSaldo ? this.pagamento.valor - this.saldo : 0;
      }
    },

    zerar() {
      this.pagamento = {
        valor: this.pagamento.valor,
        tipo: 'DINHEIRO',
        parcelas: 1,
        valorParcela: this.pagamento.valor,
        ultimaParcela: this.pagamento.valor,
        vencimento: dayjs().add(30, 'day').format('YYYY-MM-DD'),
        prazo: 30,
        bandeira: null,
      };
      this.troco = 0;
    },
  },
};
</script>

<style lang="css" scoped>
.nofocus {
  pointer-events: none !important;
  user-select: none !important;
}
.nofocus >>> input {
  opacity: 0.7;
}
.nofocus >>> input::selection {
  background: transparent;
}
</style>
