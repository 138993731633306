import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/contas-receber?` + qs.stringify(options)).then((response) => response.data);
}

function filtrar(options, cliente) {
  return axios.get(`/contas-receber/${cliente._id}/filtrar?` + qs.stringify(options)).then((response) => response.data);
}

function filtrarPorCliente(options, cliente) {
  return axios.get(`/contas-receber/${cliente._id}/filtrar?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(conta) {
  return axios.post(`/contas-receber`, conta).then((response) => response.data);
}

function atualizar(conta) {
  return axios.put(`/contas-receber/${conta._id}`, conta).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/contas-receber/${id}`).then((response) => response.data);
}

function salvarBaixa(conta, recebimento) {
  return axios.post(`/contas-receber/${conta._id}/recebimentos/baixar`, recebimento).then((response) => response.data);
}

function excluirRecebimento(conta, recebimento) {
  return axios.delete(`/contas-receber/${conta._id}/recebimentos/${recebimento._id}`).then((response) => response.data);
}

export default {
  listar,
  filtrar,
  filtrarPorCliente,
  salvar,
  atualizar,
  excluir,
  salvarBaixa,
  excluirRecebimento,
};
