import axios from '@/util/axios';
import axiosCep from 'axios';

function carregar() {
  return axios.get('/empresas').then((response) => response.data);
}

function salvar(filial) {
  return axios.post('/empresas/filiais', filial).then((response) => response.data);
}

function atualizar(filial) {
  return axios.put('/empresas/filiais', filial).then((response) => response.data);
}

function filtrarCidadesEstado(estado) {
  return axios.get(`/cidades/estado/${estado}`).then((response) => response.data);
}

function consultarCnae(cnae) {
  return axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae).then((response) => response.data);
}

export default {
  carregar,
  salvar,
  atualizar,
  filtrarCidadesEstado,
  consultarCnae,
};
