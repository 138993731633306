const Menu = [
  {
    nome: 'DashboardAdmin',
    titulo: 'Dashboard',
    grupo: 'Início',
    path: '/admin/home',
    icone: 'dashboard',
    nivel: 4,
  },
  {
    nome: 'Empresas',
    titulo: 'Empresas',
    grupo: 'Cadastros',
    path: '/admin/empresas',
    icone: 'mdi-account-multiple',
    nivel: 4,
  },
  {
    nome: 'Contadores',
    titulo: 'Contadores',
    grupo: 'Cadastros',
    path: '/admin/contadores',
    icone: 'mdi-account-multiple',
    nivel: 4,
  },
  {
    nome: 'UsuariosAdmin',
    titulo: 'Usuários',
    grupo: 'Cadastros',
    path: '/admin/usuarios',
    icone: 'mdi-account-multiple',
    nivel: 4,
  },
];

export default Menu;
