const Menu = [
  { cabecalho: 'Início', nivel: 1, permissao: true },
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1,
    permissao: true,
  },
  { cabecalho: 'Gerenciar', nivel: 4 },
  { cabecalho: 'Gerencial', nivel: 1, permissao: true },
  {
    titulo: 'Cadastros',
    grupo: 'cadastros',
    nome: 'Gerencial',
    icone: 'store',
    items: [
      {
        nome: 'Clientes/Fornecedores',
        titulo: 'Clientes/Fornecedores',
        path: '/cadastros/participantes',
        icone: 'people_outline',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_participantes',
      },
      {
        nome: 'Produto',
        titulo: 'Produtos',
        path: '/cadastros/produtos',
        icone: 'kitchen',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_produtos',
      },
      {
        nome: 'Transportador',
        titulo: 'Transportadores',
        path: '/cadastros/transportadores',
        icone: 'event_seat',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_transportadores',
      },
      { nome: 'Grupo', titulo: 'Grupos', path: '/cadastros/grupos', icone: 'group_work', grupo: 'Cadastros', nivel: 1, permissao: 'acessar_grupos' },
      {
        nome: 'Fabricante',
        titulo: 'Fabricantes',
        path: '/cadastros/fabricantes',
        icone: 'gavel',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_fabricantes',
      },
      {
        nome: 'Vendedor',
        titulo: 'Vendedores',
        path: '/cadastros/vendedores',
        icone: 'sentiment_very_satisfied',
        grupo: 'Cadastros',
        nivel: 1,
        permissao: 'acessar_vendedores',
      },
    ],
    nivel: 1,
    permissao: 'exibir_cadastros',
  },

  {
    titulo: 'Movimentações',
    grupo: 'movimentacoes',
    nome: 'Movimentações',
    icone: 'import_export',
    items: [
      {
        nome: 'Vendas',
        titulo: 'Vendas',
        path: '/movimentacoes/vendas',
        icone: 'shopping_cart',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_vendas',
      },
      {
        nome: 'Contas a Receber',
        titulo: 'Contas a Receber',
        path: '/movimentacoes/contas-receber',
        icone: 'vertical_align_bottom',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_contas_receber',
      },
      {
        nome: 'Contas a Pagar',
        titulo: 'Contas a Pagar',
        path: '/movimentacoes/contas-pagar',
        icone: 'vertical_align_top',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_contas_pagar',
      },
      {
        nome: 'Cheques',
        titulo: 'Cheques',
        path: '/movimentacoes/cheques',
        icone: 'local_atm',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_cheques',
      },
      {
        nome: 'Movimentos de Estoque',
        titulo: 'Movimento de Estoque',
        path: '/movimentacoes/estoques',
        icone: 'rv_hookup',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_movimentoestoque',
      },
      {
        nome: 'Caixa',
        titulo: 'Caixa',
        path: '/movimentacoes/caixa',
        icone: 'swap_horiz',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_caixa',
      },
      {
        nome: 'Ordens de Serviço',
        titulo: 'Ordens de Serviço',
        path: '/movimentacoes/ordens-servicos',
        icone: 'mdi-checkbox-marked-outline',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_ordensservico',
      },
      {
        nome: 'NFEs de Entrada',
        titulo: 'NFEs de Entrada',
        path: '/movimentacoes/notasentrada',
        icone: 'business_center',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_compras',
      },
      {
        nome: 'Pedidos de Clientes',
        titulo: 'Pedidos de Clientes',
        path: '/movimentacoes/pedidosclientes',
        icone: 'mdi-application',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_pedidosclientes',
      },
      {
        nome: 'Pedidos de Compras',
        titulo: 'Pedidos de Compras',
        path: '/movimentacoes/pedidoscompra',
        icone: 'mdi-card-plus',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_pedidoscompras',
      },
      {
        nome: 'Movimento de Produção',
        titulo: 'Movimento de Produção',
        path: '/movimentacoes/producao',
        icone: 'mdi-factory',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_movimentoproducao',
      },
      {
        nome: 'Remessas de Cheques',
        titulo: 'Remessas de Cheques',
        path: '/movimentacoes/remessas-cheques',
        icone: 'mdi-send-outline',
        grupo: 'Movimentações',
        nivel: 1,
        permissao: 'acessar_remessacheques',
      },
    ],
    nivel: 1,
    permissao: 'exibir_movimentacoes',
  },

  {
    titulo: 'Tabelas',
    grupo: 'tabelas',
    nome: 'Tabela',
    icone: 'table_chart',
    items: [
      { nome: 'Banco', titulo: 'Bancos', path: '/tabelas/bancos', icone: 'account_balance', grupo: 'Tabelas', nivel: 1, permissao: 'acessar_bancos' },
      {
        nome: 'Conta Corrente',
        titulo: 'Conta Corrente',
        path: '/tabelas/contas-correntes',
        icone: 'monetization_on',
        grupo: 'Tabelas',
        nivel: 1,
        permissao: 'acessarContasCorrente',
      },
      { nome: 'PIS', titulo: 'PIS', path: '/tabelas/pis', icone: 'money_off', grupo: 'Tabelas', nivel: 1, permissao: 'acessar_pis' },
      { nome: 'COFINS', titulo: 'COFINS', path: '/tabelas/cofins', icone: 'money_off', grupo: 'Tabelas', nivel: 1, permissao: 'acessar_cofins' },
      {
        nome: 'Estados e Cidades',
        titulo: 'Estados e Cidades',
        path: '/tabelas/cidadesEstados',
        icone: 'location_city',
        grupo: 'Tabelas',
        nivel: 1,
        permissao: 'acessar_cidadesestados',
      },
      // { nome: 'Cor', titulo: 'Cores', path: '/tabelas/cores', icone: 'format_color_fill', grupo: 'Tabelas', nivel: 1, permissao: 'cores' },
      // {
      //   nome: 'Tamanho',
      //   titulo: 'Tamanhos',
      //   path: '/tabelas/tamanhos',
      //   icone: 'format_size',
      //   grupo: 'Tabelas',
      //   nivel: 1,
      //   permissao: 'acessar_tamanhos',
      // },
      { nome: 'MVA', titulo: 'MVAs', path: '/tabelas/mvas', icone: 'broken_image', grupo: 'Tabelas', nivel: 1, permissao: 'acessar_mvas' },
      {
        nome: 'Centro de Custo',
        titulo: 'Centros de Custos',
        path: '/tabelas/centrosCustos',
        icone: 'list_alt',
        grupo: 'Tabelas',
        nivel: 1,
        permissao: 'acessar_centrocustos',
      },
      {
        nome: 'CFOP Compra x Entrada',
        titulo: 'Mapeamento CFOPs',
        path: '/tabelas/mapeamentos',
        icone: 'zoom_out_map',
        grupo: 'Tabelas',
        nivel: 1,
        permissao: 'acessar_cfopcompraentrada',
      },
      {
        nome: 'Plano de Conta',
        titulo: 'Planos de Conta',
        path: '/tabelas/planosConta',
        icone: 'notes',
        grupo: 'Tabelas',
        nivel: 1,
        permissao: 'acessar_planosconta',
      },
      // {
      //   nome: 'Local de Estoque',
      //   titulo: 'Locais de Estoque',
      //   path: '/tabelas/locaisEstoque',
      //   icone: 'place',
      //   grupo: 'Tabelas',
      //   nivel: 1,
      //   permissao: 'acessar_locaisestoque',
      // },
    ],
    nivel: 1,
    permissao: 'exibir_tabelas',
  },

  {
    titulo: 'Gráficos',
    grupo: 'Graficos',
    nome: 'Graficos',
    icone: 'mdi-chart-bar',
    path: '/graficos',
    nivel: 1,
    permissao: 'acessar_graficos',
  },
  {
    titulo: 'Relatórios',
    grupo: 'Relatorios',
    nome: 'Relatorios',
    icone: 'description',
    path: '/relatorios',
    nivel: 1,
    permissao: 'acessar_relatorios',
  },
  {
    titulo: 'Arquivos Contabilidade',
    grupo: 'Fiscal',
    nome: 'Arquivos',
    icone: 'file_copy',
    path: '/arquivos',
    nivel: 2,
  },
];

export default Menu;
