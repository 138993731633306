import constantes from '@/util/constantes';
import pisApi from '@/services/pisApi';

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    pis: [],
    pisAtual: {
      codigo: '',
      descricao: '',
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar(state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async carregar({ commit }) {
      try {
        commit('setCarregando', true);
        let response = await pisApi.listar();
        commit('setPis', response);
      } catch (error) {
        commit('setPis', []);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async salvar({ commit, state }) {
      try {
        commit('setCarregando', true);
        let pis = Object.assign({}, state.pisAtual);

        if (state.modoEdicao) {
          let response = await pisApi.atualizar(pis);
          commit('atualizar', response);
          commit('setMensagemSucesso', 'PIS atualizado com sucesso', { root: true });
        } else {
          delete pis._id;
          let response = await pisApi.salvar(pis);
          commit('adicionar', response);
          commit('setMensagemSucesso', 'PIS cadastrado com sucesso', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async remover({ commit }, pis) {
      try {
        commit('setCarregando', true);
        await pisApi.excluir(pis);
        commit('remover', remover);
        commit('setMensagemSucesso', 'PIS removido com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async buscar({ commit }, pis_busca) {
      commit('setCarregando', true);

      try {
        let response = await axios.get(`/pis/buscar?busca=${pis_busca}`);
        if (response.status === 200) {
          commit('setPis', response.data);
        }
      } catch (error) {
        commit('setPis', []);
        commit('setMensagemErro', error, { root: true });
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setPis(state, pis) {
      state.pis = pis;
      state.carregando = false;
    },
    remover(state, removido) {
      state.pis = state.pis.filter((p) => p._id !== removido._id);
      state.carregando = false;
    },
    novo(state) {
      state.modoEdicao = false;
      state.pisAtual = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
    editar(state, editar) {
      state.modoEdicao = true;
      state.pisAtual = editar;
      state.carregando = false;
    },
    adicionar(state, pis) {
      state.pis.push(pis);
      state.carregando = false;
    },
    atualizar(state, atual) {
      state.pis.forEach((p) => {
        if (p._id === atual._id) {
          p = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state) {
      state.pisAtual = {
        codigo: '',
        descricao: '',
      };
      state.carregando = false;
    },
  },
};
