import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import moment from 'moment';
import emitentesApi from '@/services/emitentesApi';

export default {
  namespaced: true,

  state: {
    emitente: {
      cnpj: '',
      fantasia: '',
      razao: '',
      ie: '',
      im: '',
      cnae: '',
      descricaoCnae: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigoCidade: '',
      uf: '',
      estado: '',
      pais: '',
      codigoPais: '',
      logotipo: '',
      versao: '4.00',
      ambiente: '',
      certificadoTipo: 'A1',
      formatoImpressao: 1,
      certificado: '',
      fretePadrao: '9',
      formaPagamentoPadrao: '0',
      senha: '',
      telefone: '',
      email: '',
      status: 'Ativa',
      plano: 'nfe',
      calculoCusto: constantes.CALCULOMEDIA,
      configuracaoTributaria: {
        ramoAtividade: '',
        configCfopPadrao: '1',
        cfopPadrao: '',
        regimeTributario: '',
        csosn: '102',
        seriePadrao: '',
        usaIcmsPadrao: true,
        usaIcmsStPadrao: true,
        usaPisEntradaPadrao: true,
        usaPisSaidaPadrao: true,
        usaCofinsEntradaPadrao: true,
        usaCofinsSaidaPadrao: true,
        cstIcms: '00',
        cstIcmsSt: '00',
        cstPisEntrada: '01',
        cstPisSaida: '01',
        cstCofinsEntrada: '01',
        cstCofinsSaida: '01',
        aliqIcms: 18.0,
        aliqIcmsSt: 0,
        aliqPisEntrada: 1.65,
        aliqPisSaida: 1.65,
        aliqCofinsEntrada: 7.6,
        aliqCofinsSaida: 7.6,
        aliqCredSn: 18,
        infoComplementar: '',
        empresa: null,
      },
    },
    certificado: null,
    cidades: [],
    constantes: constantes,
    conversor: conversor,
    modoEdicao: false,
    carregando: false,
    carregandoCidade: false,
    certificadoCarregado: false,
  },

  getters: {
    diasRestantesCertificado(state) {
      let dataValidade = state.certificado.validade;
      if (dataValidade && dataValidade !== '') {
        let dias = moment(dataValidade).diff(moment(), 'days');
        if (dias < 0) {
          return 'Vencido';
        } else if (dias === 0) {
          return '1';
        } else {
          return dias;
        }
      }
    },
    listaCidades(state) {
      return state.cidades.map((cidade) => {
        return {
          text: cidade.cidade,
          value: cidade.codigo_cidade,
        };
      });
    },
  },

  actions: {
    async filtrarCidadesEstado({ commit }, estado) {
      try {
        commit('setCarregandoCidades', true);
        let response = await emitentesApi.filtrarCidadesEstado(estado);
        commit('setCidades', response);
      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', { root: true });
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },
    async consultarCertificadoEmitente({ commit, rootState }) {
      try {
        commit('setCarregando', true);
        let response = await emitentesApi.consultarCertificadoEmitente(rootState.usuario.empresa._id);
        if (response.status === 200) {
          commit('setCertificado', response);
          commit('setCarregando', false);
        } else {
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async processarCertificado({ commit }, certificado) {
      try {
        commit('setCarregando', true);
        let response = await emitentesApi.processarCertificado(certificado);
        if (response.status === 200) {
          commit('setCertificado', response);
          commit('setMensagemSucesso', 'Certificado Atualizado com Sucesso', { root: true });
        } else {
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao processar certificado', { root: true });
        }
      } catch (error) {
        commit('setCarregando', false);
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    async consultarCnae({ commit }, cnae) {
      try {
        commit('setCarregando', true);
        let dadosCnae = await emitentesApi.consultarCnae(cnae);
        if (dadosCnae.length > 0) {
          commit('setDescricaoCnae', dadosCnae);
        } else {
          commit('setMensagemErro', 'CNAE não encontrado', { root: true });
        }
      } catch (error) {
        commit('setMensagemErro', 'Falha ao tentar consultar CNAE', { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
    async carregarEmitente({ commit }, idEmitente) {
      try {
        commit('setCarregando', true);
        let response = await emitentesApi.carregarEmitente(idEmitente);
        commit('setEmitente', response);
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
      } finally {
        commit('setCarregando', false);
      }
    },
    carregarLogo({ commit }, logotipo) {
      commit('setCarregando', true);
      var reader = new FileReader();
      var vm = commit;
      reader.onload = (e) => {
        vm('setLogotipo', e.target.result);
        vm('setCarregando', false);
      };
      reader.readAsDataURL(logotipo);
    },
    async salvar({ commit, state }) {
      const emitente = Object.assign({}, state.emitente);
      emitente.cnpj = emitente.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      emitente.cep = emitente.cep.replace('.', '').replace('-', '');
      emitente.cnae = emitente.cnae.replace('-', '').replace('/', '');
      emitente.telefone = emitente.telefone.replace('(', '').replace(')', '').replace('-', '');

      try {
        commit('setCarregando', true);
        const response = await emitentesApi.salvar(emitente);

        commit('autenticacao/setSession', { empresa: { ambiente: response.ambiente } }, { root: true });

        commit('setMensagemSucesso', 'Emitente atualizado com sucesso', { root: true });
      } catch (error) {
        commit('setMensagemErro', error, { root: true });
        throw error;
      } finally {
        commit('setCarregando', false);
      }
    },
  },

  mutations: {
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setCarregandoCidades(state, valor) {
      state.carregando_cidade = valor;
    },
    setCidades(state, cidades) {
      state.cidades = cidades;
    },
    setCidade(state, codigo_cidade) {
      let cidade = state.cidades.find((cidade) => cidade.codigo_cidade == codigo_cidade);

      state.emitente.cidade = cidade.cidade;
      state.emitente.codigo_cidade = cidade.codigo_cidade;
      state.emitente.uf = cidade.codigo_estado;
      state.emitente.pais = 'Brasil';
      state.emitente.codigo_pais = 1058;
    },
    setCertificado(state, certificado) {
      state.certificado = certificado;
    },
    setEmitente(state, emitente) {
      state.emitente = emitente;
      state.carregando = false;
    },
    setDadosEmpresa(state, empresa) {
      state.emitente.cnae = empresa.cnae;
      state.emitente.ie = empresa.ie + '-' + empresa.uf;
      state.emitente.razao = empresa.razao;
      state.emitente.fantasia = empresa.fantasia;
      state.emitente.numero = empresa.endereco.numero;
      state.emitente.cep = empresa.endereco.cep;

      let encontrado = constantes.REGIMES_TRIBUTARIOS.find((regime) => {
        return regime.text.toLowerCase().indexOf(empresa.regime.toLowerCase()) !== -1;
      });
      if (encontrado) {
        state.empresa.configuracao_tributaria.regime_tributario = encontrado.value;
      }
    },
    setLogotipo(state, logo) {
      state.empresa.logotipo = logo;
    },
    setEndereco(state, endereco) {
      state.emitente.logradouro = endereco.logradouro;
      state.emitente.bairro = endereco.bairro;
      state.emitente.uf = conversor.ufParaCodigo(endereco.uf);
      state.emitente.estado = endereco.uf;
      state.emitente.cidade = endereco.localidade;
      state.emitente.codigo_cidade = endereco.ibge;
      state.emitente.pais = 'Brasil';
      state.emitente.codigo_pais = 1058;
    },
    setDescricaoCnae(state, dadosCnae) {
      state.emitente.descricao_cnae = dadosCnae[0].descricao;
    },
    setInfoComplementar(state, infoComplementar) {
      state.emitente.configuracao_tributaria.info_complementar = infoComplementar;
    },
    atualizarConfigCfop(state) {
      if (state.emitente.configuracao_tributaria.config_cfop_padrao === '1') {
        // cfop definido pelo ramo de atividade
        if (state.emitente.configuracao_tributaria.ramo_atividade === '1') {
          // comércio
          state.emitente.configuracao_tributaria.cfop_padrao = '5102';
        } else if (state.emitente.configuracao_tributaria.ramo_atividade === '3') {
          //industria
          state.emitente.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },
    remover(state, removido) {
      state.empresas = state.empresas.filter((empresa) => empresa._id !== removido._id);
      state.carregando = false;
    },
  },
};
