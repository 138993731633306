<template>
  <v-dialog v-model="dialog" width="750px" @keydown.esc="fechar" persistent scrollable eager>
    <v-card>
      <v-toolbar dense dark color="primary" flat>
        <v-toolbar-title>Cancelar Item</v-toolbar-title>
        <v-spacer />
        <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
      </v-toolbar>

      <v-card-text class="pa-4">
        <v-row dense>
          <v-col cols="12" sm="3">
            <v-text-field ref="itemNumero" outlined persistent-placeholder label="Item Número" v-model="produto.item" @input="buscarItem" @keydown.enter="confirmar" dense hide-details />
          </v-col>
          <v-col cols="12" sm="9">
            <v-text-field outlined persistent-placeholder label="Descrição" v-model="produto.descricao" readonly dense hide-details />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="3" />

          <v-col cols="12" sm="3">
            <v-text-field outlined persistent-placeholder label="Quantidade" :value="(produto.item && valorBR(produto.quantidade, 0)) || ''" readonly dense hide-details class="direita" />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined persistent-placeholder label="Valor" :value="(produto.item && valorBR(produto.valor)) || ''" readonly dense hide-details class="direita" />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field outlined persistent-placeholder label="SubTotal" :value="(produto.item && valorBR(produto.subtotal)) || ''" readonly dense hide-details class="direita" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn depressed class="mr-2" @click.native="fechar">Cancelar</v-btn>
        <v-btn depressed color="primary darken-1" @click.native="confirmar" width="100px" :disabled="!produto.quantidade">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DialogoCancelarItem',

  // props: {
  //   rowClick: {
  //     type: Function,
  //     default: () => {},
  //   },
  // },

  data() {
    return {
      produto: {
        item: '',
        descricao: '',
        quantidade: 0,
        valor: 0,
        subtotal: 0,
      },
      dialog: false,
    };
  },

  computed: {
    ...mapState('venda', ['venda', 'item']),
  },

  methods: {
    abrir() {
      if (this.venda.itens.length === 0) return;

      this.dialog = true;
      this.produto = {
        item: '',
        descricao: '',
        quantidade: 0,
        valor: 0,
        subtotal: 0,
      };
      this.$nextTick(() => {
        this.$refs.itemNumero.focus();
      });
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    confirmar() {
      if (this.produto.quantidade) {
        // Index
        const index = parseInt(this.produto.item) - 1;
        if (index > 0) {
          this.$emit('rowClick', index - 1);
        } else if (this.venda.itens > 1) {
          this.$emit('rowClick', index + 1);
        }
        this.$store.commit('venda/removerItem', index);
        this.dialog = false;
        this.$emit('fechar');
      }
    },

    buscarItem() {
      if (this.produto.item === '') {
        this.produto.descricao = '';
        return;
      }

      const produto = this.venda.itens.find((prod) => String(prod.item) === this.produto.item);

      if (produto) {
        const index = parseInt(this.produto.item) - 1;
        this.$emit('rowClick', index);
        this.produto.descricao = produto.descricao;
        this.produto.quantidade = produto.quantidade;
        this.produto.valor = produto.valor;
        this.produto.subtotal = produto.subtotal;
      } else {
        this.produto.item = '';
        this.produto.descricao = 'Item não encontrado';
      }
    },

    valorBR(valor, decimais = 2) {
      if (valor) {
        return valor.toLocaleString('pt-BR', {
          style: 'decimal',
          minimumFractionDigits: decimais,
          maximumFractionDigits: decimais,
        });
      }

      return '0,00';
    },
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}
</style>
