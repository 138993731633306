import axios from '@/util/axios';

function listar(params) {
  return axios.get('/fabricantes', { params }).then((response) => response.data);
}

function adicionar(fabricante) {
  return axios.post('/fabricantes', fabricante).then((response) => response.data);
}

function editar(fabricante) {
  return axios.patch(`/fabricantes`, fabricante).then((response) => response.data);
}

function remover(fabricanteId) {
  return axios.delete(`/fabricantes/${fabricanteId}`).then((response) => response.data);
}

export default {
  listar,
  adicionar,
  editar,
  remover,
};
