<template>
  <v-form ref="formUsuario" lazy-validation>
    <v-container grid-list-md fluid :style="isDialog ? 'padding: 0px' : ''">
      <v-widget title="Minha Conta" noDivider>
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndUp ? 'pr-3' : 'pb-3'">
              <v-container fluid class="pa-0" style="border: 1px solid silver; border-radius: 4px">
                <v-img height="170" v-bind:src="fotoSrc" @click="escolherArquivo()" style="cursor: pointer; background-color: whitesmoke; background-size: cover !important"> </v-img>
                <v-container class="px-2 pt-2" fluid style="height: 54px; display: flex; flex-direction: row; border-radius: 0px 0px 4px 4px">
                  <input id="inputArquivo" type="file" :multiple="false" hidden @change="onFileSelected" accept=".jpg,.png,.jpeg,.bmp" />
                  <v-btn icon @click="limparFoto">
                    <v-icon>clear</v-icon>
                  </v-btn>
                  <v-spacer />
                  <v-btn text depressed @click="camera" class="px-2">Câmera<v-icon class="ml-3">photo_camera</v-icon></v-btn>
                  <v-btn text depressed @click="escolherArquivo" class="px-2">Arquivo<v-icon class="ml-3">image</v-icon></v-btn>
                </v-container>
              </v-container>
            </v-col>

            <v-col cols="12" sm="8">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field outlined disabled label="E-mail" v-model="session.usuario.email" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field outlined disabled label="Nível de Acesso" v-model="session.usuario.nivelAcesso" readonly></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined persistent-placeholder label="Nome" v-model="nome" @input="onChange"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="!editado" depressed class="mr-auto" @click="dialogoSenha = true">Alterar Senha</v-btn>
                  <v-btn v-if="!editado" depressed color="primary" style="float: right" @click="cancelar">Fechar</v-btn>
                  <v-btn v-if="editado" depressed color="primary" style="float: right" :loading="carregando" :disabled="!valido" @click="editar">Salvar</v-btn>
                  <v-btn v-if="editado" depressed class="mr-2" style="float: right" @click="cancelar">Cancelar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-widget>
    </v-container>

    <v-dialog v-model="dialogoSenha" max-width="600px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Alterar Senha</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-text-field outlined hide-details persistent-placeholder type="password" label="Senha Atual" v-model="senhaAtual"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined hide-details persistent-placeholder type="password" label="Nova Senha" v-model="senhaNova"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined hide-details persistent-placeholder type="password" label="Confirmar Nova Senha" v-model="senhaConfirmacao"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="fecharDialogoSenha" :disabled="carregando">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="alterarSenha" :loading="carregando" :disabled="!senhasValidas">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoCamera" width="auto" persistent>
      <v-widget title="Capturar Foto" noDivider clo>
        <div slot="widget-content" style="position: relative">
          <video id="player" autoplay width="640px" height="480px" style="border-radius: 4px" poster="" />
          <canvas id="canvas" hidden />
          <v-btn large depressed style="position: absolute; bottom: 16px; right: 16px; opacity: 0.6" @click="capturarFoto">Capturar Foto</v-btn>
        </div>
        <div slot="widget-header-action">
          <v-btn icon @click="cancelarFoto" style="margin-right: -12px">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </v-widget>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import { URL_ANRON_API } from '@/util/config';

export default {
  data: () => ({
    constantes: constantes,
    nome: '',
    senhaAtual: '',
    senhaNova: '',
    senhaConfirmacao: '',
    foto: '',
    editado: false,
    dialogoSenha: false,
    dialogoCamera: false,
    isDialog: false,
    fotoSrc: '',
  }),

  computed: {
    ...mapState('autenticacao', {
      session: 'session',
    }),

    ...mapState('usuario', {
      carregando: 'carregando',
    }),

    valido() {
      return this.editado || (this.nome.trim() !== '' && this.nome !== this.session.usuario.nome);
    },

    senhasValidas() {
      return this.senhaAtual !== '' && this.senhaNova !== '' && this.senhaNova === this.senhaConfirmacao;
    },
  },

  mounted() {
    this.fotoSrc = this.session.usuario.foto ? `${URL_ANRON_API}/images/${this.session.usuario.foto}` : '';
    this.nome = this.session.usuario.nome;
    this.isDialog = this.$router.currentRoute.fullPath !== '/minha-conta';
  },

  methods: {
    editar() {
      this.$store.dispatch('usuario/editar', { router: this.$router, usuarioId: this.session.usuario.id, foto: this.foto, nome: this.nome, usuario: this.session.usuario });
    },

    fecharDialogoSenha() {
      this.senhaAtual = '';
      this.senhaNova = '';
      this.senhaConfirmacao = '';
      this.dialogoSenha = false;
    },

    alterarSenha() {
      this.$store.dispatch('usuario/alterarSenha', { usuarioId: this.session.usuario.id, senhaAtual: this.senhaAtual, senhaNova: this.senhaNova }).then((resultOk) => {
        this.dialogoSenha = !resultOk;
        if (resultOk) {
          this.senhaAtual = '';
          this.senhaNova = '';
          this.senhaConfirmacao = '';
        }
      });
    },

    cancelar() {
      if (this.isDialog) {
        this.$emit('fechar');
      } else {
        this.$router.go(-1);
      }
    },

    onFileSelected(evento) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.processarFoto(e.target.result);
      };

      reader.readAsDataURL(evento.target.files[0]);
    },

    escolherArquivo() {
      document.getElementById('inputArquivo').click();
    },

    limparFoto() {
      document.getElementById('inputArquivo').value = '';
      this.foto = null;
      this.fotoSrc = '';
      this.editado = this.nome !== this.session.usuario.nome || this.foto !== this.session.usuario.foto;
    },

    onChange(value) {
      this.editado = value !== this.session.usuario.nome || this.foto !== this.session.usuario.foto;
    },

    processarFoto(foto) {
      const image = new Image();
      const context = this;

      image.onload = function () {
        const canvas = document.createElement('canvas');
        const max_size = 640;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;

        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');

        canvas.remove();

        context.foto = dataUrl;
        context.fotoSrc = dataUrl;
        context.editado = context.nome !== context.session.usuario.nome || context.foto !== context.session.usuario.foto;
      };

      image.src = foto;
    },

    camera() {
      this.dialogoCamera = true;
      this.$nextTick(() => {
        const player = document.getElementById('player');
        player.style.display = 'block';

        navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          player.srcObject = stream;
        });
      });
    },

    capturarFoto() {
      const player = document.getElementById('player');
      const canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');

      const { height, width } = player.getBoundingClientRect();
      canvas.height = height;
      canvas.width = width;
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      this.processarFoto(canvas.toDataURL('image/jpeg'));

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });

      player.style.display = 'none';
      this.dialogoCamera = false;
    },

    cancelarFoto() {
      this.dialogoCamera = false;
      const player = document.getElementById('player');

      player.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
