import ListaCentroCustos from '@/views/centrocustos/ListaCentroCustos';
import FormularioCentroCusto from '@/views/centrocustos/FormularioCentroCusto';

export default [
  {
    path: '/tabelas/centro-custos',
    name: 'centroCustos',
    component: ListaCentroCustos,
    meta: { requiresAuth: true, nivel: 1, permissao: 'centro_custos_acessar' },
  },
  {
    path: '/tabelas/centro-custos/adicionar',
    name: 'centroCustoAdicionar',
    component: FormularioCentroCusto,
    meta: { requiresAuth: true, nivel: 1, permissao: 'centro_custos_adicionar' },
  },
  {
    path: '/tabelas/centro-custos/editar',
    name: 'centroCustoEditar',
    component: FormularioCentroCusto,
    meta: { requiresAuth: true, nivel: 1, permissao: 'centro_custos_editar' },
  },
];
