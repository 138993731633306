import Vue from 'vue';

import BuscarProduto from './BuscarProduto';
import BuscarProdutoDeposito from './BuscarProdutoDeposito';
import BuscarTransportador from './BuscarTransportador';
import BuscarParticipante from './BuscarParticipante';
import BuscarParticipanteNome from './BuscarParticipanteNome';
import BuscarBanco from './BuscarBanco';
import BuscarNcm from './BuscarNcm';
import BuscarCfop from './BuscarCfop';
import BuscarFabricante from './BuscarFabricante';
import BuscarCest from './BuscarCest';
import CampoCep from './CampoCep';
import CampoData from './CampoData';
import CampoValor from './CampoValor';
import CampoCpfCnpj from './CampoCpfCnpj';
import AsCampoValor from './AsCampoValor';
import AsDatePicker from './AsDatePicker';
import MenuButtons from './MenuButtons';
import VWidget from '../components/VWidget';
import DialogConfirmacao from '@/components/dialogs/DialogConfirmacao.vue';

Vue.component('BuscarProduto', BuscarProduto);
Vue.component('BuscarProdutoDeposito', BuscarProdutoDeposito);
Vue.component('BuscarTransportador', BuscarTransportador);
Vue.component('BuscarParticipante', BuscarParticipante);
Vue.component('BuscarParticipanteNome', BuscarParticipanteNome);
Vue.component('BuscarBanco', BuscarBanco);
Vue.component('BuscarNcm', BuscarNcm);
Vue.component('BuscarCfop', BuscarCfop);
Vue.component('BuscarFabricante', BuscarFabricante);
Vue.component('BuscarCest', BuscarCest);
Vue.component('CampoCep', CampoCep);
Vue.component('CampoData', CampoData);
Vue.component('CampoValor', CampoValor);
Vue.component('CampoCpfCnpj', CampoCpfCnpj);
Vue.component('AsCampoValor', AsCampoValor);
Vue.component('AsDatePicker', AsDatePicker);
Vue.component('MenuButtons', MenuButtons);
Vue.component('VWidget', VWidget);
Vue.component('DialogConfirmacao', DialogConfirmacao);
