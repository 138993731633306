import moment from 'moment';
import ordensServicosApi from '@/services/ordensServicosApi';

export default {
  namespaced: true,

  state: {
    ordens_servicos: [],
    ordemServicoAtual: {
      numero: '',
      data: moment().format('YYYY-MM-DD'),
      agendamento: '',
      placa: '',
      participante: {},
      quantidade: 0,
      status: 'Pendente',
      descricao: '',
      valor: 0,
      produtos: [],
      produtoscontrato: [],
    },
    carregando: false,
    totalOrdensServicos: 0,
    visualizar: false,
  },

  getters: {
    //
  },

  actions: {
    async listar({ commit }, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await ordensServicosApi.listar(options);
        commit('SET_ORDENS_SERVICOS', response.ordens_servicos);
        commit('SET_TOTAL_ORDENS_SERVICOS', response.total);
      } catch (error) {
        commit('SET_ORDENS_SERVICOS', []);
        commit('setMensagemErro', 'Não foi possível listar os ordens de serviços', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({ commit }, ordem_servico) {
      try {
        commit('SET_CARREGANDO');
        let ordemServicoNova = await ordensServicosApi.salvar(ordem_servico);
        commit('ADICIONAR_ORDEM_SERVICO', ordemServicoNova);
      } catch (error) {
        commit('setMensagemErro', 'Houve um error ao salvar a Ordem de Serviço', { root: true });
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({ commit }, ordem_servico) {
      commit('SET_CARREGANDO');
      let ordem_servico_atualizar = await ordensServicosApi.atualizar(ordem_servico);
      commit('ATUALIZAR_ORDEM_SERVICO', ordem_servico_atualizar);
      commit('SET_CARREGANDO', false);
    },
    async excluir({ commit }, ordem_servico_id) {
      commit('SET_CARREGANDO');
      await ordensServicosApi.excluir(ordem_servico_id);
      commit('EXCLUIR_ORDEM_SERVICO', ordem_servico_id);
      commit('SET_CARREGANDO', false);
    },
    async alterarStatus({ commit, state }, status) {
      try {
        commit('SET_CARREGANDO');
        let ordem_servico = Object.assign({}, state.ordemServicoAtual);
        let response = await ordensServicosApi.alterarStatus(ordem_servico, status);
        commit('ATUALIZAR_STATUS', response);
        commit('setMensagemSucesso', 'Status da Ordem de Serviço atualizado com sucesso', { root: true });
      } catch (error) {
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', 'error ao alterar o status da Ordem de Serviço', { root: true });
        throw error;
      }
    },
    async fechamento({ commit, state }, fechamento) {
      try {
        commit('SET_CARREGANDO');
        let ordem_servico = Object.assign({}, state.ordemServicoAtual);
        let response = await ordensServicosApi.fechamento(ordem_servico, fechamento);
        commit('ATUALIZAR_ORDEM_SERVICO', response);
        commit('setMensagemSucesso', 'Ordem de Serviço fechada com sucesso', { root: true });
      } catch (error) {
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', 'error ao alterar o status da Ordem de Serviço', { root: true });
        throw error;
      }
    },
  },

  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_ORDENS_SERVICOS(state, ordens_servicos) {
      state.ordens_servicos = ordens_servicos;
    },
    SET_TOTAL_ORDENS_SERVICOS(state, total) {
      state.totalOrdensServicos = total;
    },
    SET_ORDEM_SERVICO(state, ordem_servico) {
      state.ordemServicoAtual = ordem_servico;
    },
    ADICIONAR_ORDEM_SERVICO(state, ordem_servico) {
      state.ordens_servicos.unshift(ordem_servico);
    },
    ATUALIZAR_ORDEM_SERVICO(state, ordem_servico_atualizar) {
      let index = state.ordens_servicos.findIndex((ordem_servico) => {
        return ordem_servico._id == ordem_servico_atualizar._id;
      });
      if (index > -1) {
        state.ordens_servicos.splice(index, 1, ordem_servico_atualizar);
      }
      state.ordemServicoAtual = ordem_servico_atualizar;
      state.carregando = false;
    },
    EXCLUIR_ORDEM_SERVICO(state, id) {
      let index = state.ordens_servicos.findIndex((ordem_servico) => ordem_servico._id == id);
      if (index > -1) {
        state.ordens_servicos.splice(index, 1);
      }
    },
    ZERAR(state) {
      state.ordemServicoAtual = {
        numero: '',
        data: moment().format('YYYY-MM-DD'),
        agendamento: '',
        placa: '',
        participante: {},
        quantidade: 0,
        status: 'Pendente',
        descricao: '',
        valor: 0,
        produtos: [],
        produtoscontrato: [],
      };
      state.visualizar = false;
      state.carregando = false;
    },
    SET_PARTICIPANTE(state, participante) {
      state.ordemServicoAtual.participante = participante;
    },
    SET_PRODUTO(state, produto_novo) {
      state.ordemServicoAtual.produtos.push(produto_novo);
    },
    UPDATE_PRODUTO(state, produto_atualizado) {
      let index = state.ordemServicoAtual.produtos.findIndex((produto) => produto.codigo == produto_atualizado.codigo);
      if (index > -1) {
        state.ordemServicoAtual.produtos.splice(index, 1, produto_atualizado);
      }
    },
    SET_TOTAIS(state, totais) {
      state.ordemServicoAtual.valor = totais.valor;
      state.ordemServicoAtual.quantidade = totais.quantidade;
    },
    EXCLUIR_PRODUTO(state, produto_remover) {
      let index = state.ordemServicoAtual.produtos.findIndex((produto) => produto.codigo == produto_remover.codigo && produto.quantidade == produto_remover.quantidade);
      if (index > -1) {
        state.ordemServicoAtual.produtos.splice(index, 1);
      }
    },
    SET_VISUALIZAR(state, visualizar = true) {
      state.visualizar = visualizar;
    },
    ALTERAR_STATUS(state, status_novo) {
      state.ordemServicoAtual.status = status_novo;
    },
    ATUALIZAR_STATUS(state, atual) {
      state.ordens_servicos.forEach((ordem_servico) => {
        if (ordem_servico._id == atual._id) {
          ordem_servico.status = atual.status;
        }
      });
      state.carregando = false;
    },
  },
};
