<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent scrollable eager>
      <v-card>
        <v-toolbar dense dark color="primary" flat>
          <v-toolbar-title>Liquidação</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field v-model="conta.documento" label="Documento" dense filled hide-details readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-data v-model="conta.data_cadastro" label="Emissão" dense filled hide-details readonly></campo-data>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-data v-model="conta.data_vencimento" label="Vencimento" dense filled hide-details readonly></campo-data>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-data v-model="conta.data_pagamento" label="Pagamento" dense filled hide-details readonly></campo-data>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="conta.valor" label="Valor" dense filled hide-details readonly></campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="conta.valor_pago" label="Pago" dense filled hide-details readonly></campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <campo-valor v-model="conta.valor_restante" label="Restante" dense filled hide-details readonly></campo-valor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="text-body-2 text--primary font-weight-bold">Pagamentos Efetuados</span>
              <v-data-table :headers="headers" :items="conta.recebimentos" disable-sort hide-default-footer disable-pagination fixed-header height="300px" item-key="documento">
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.data | formatarData }}</td>
                    <td>{{ item.tipo }}</td>
                    <td class="text-right">{{ item.desconto | formatarValor }}</td>
                    <td class="text-right font-weight-bold">{{ item.valor_pago | formatarValor }}</td>
                    <td class="text-center">
                      <v-btn icon @click="removerPagamento(item)">
                        <v-icon color="primary">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="informarPagamento">Informar Pagamento</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fechar">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogo-informar-pagamento ref="dialogoInformarPagamento"></dialogo-informar-pagamento>
    <dialog-confirmacao ref="dialogoConfirmacao"></dialog-confirmacao>
  </div>
</template>

<script>
import DialogoInformarPagamento from './DialogoInformarPagamento';
// import { mapState} from 'vuex';

export default {
  name: 'DialogoLiquidacao',
  components: {
    DialogoInformarPagamento,
  },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Data', value: 'data', width: '12%' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Desconto', value: 'desconto', align: 'right' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'Opções', value: 'opcoes', width: '60px', align: 'center' },
      ],
      conta: {},
    };
  },

  // computed: {
  //   ...mapState('contareceber', {
  //     conta: 'contaAtual',
  //     pagamentos: 'pagamentos',
  //   }),
  // },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
    },

    informarPagamento() {
      this.$refs.dialogoInformarPagamento.abrir();
    },

    async removerPagamento(pagamento) {
      const response = await this.$refs.dialogoConfirmacao.open('Atenção!', 'Deseja realmente cancelar este pagamento?');
      if (response) {
        this.$store.commit('contareceber/REMOVER_PAGAMENTO', pagamento);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
