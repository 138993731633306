<template>
  <div>
    <v-container fluid>
      <v-widget title="Contas a Receber">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col :cols="dynamicCol" sm="5">
                <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" background-color="grey lighten-3" prepend-inner-icon="search" label="Busca"></v-text-field>
              </v-col>
              <!-- Condicional - Existe o mesmo código abaixo -->
              <v-col v-if="isSm" class="text-right" cols="5" sm="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="limparFiltro"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="" cols="12" sm="4">
                <v-select v-show="!filtro_ativo" filled flat single-line hide-details dense solo v-model="filtro.exibir" :items="itens_exibir" @change="listarPorTipo" background-color="grey lighten-3"></v-select>
              </v-col>
              <v-col v-if="!isSm" class="text-right" cols="12" sm="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="limparFiltro"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVA </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="totalContasReceber" :headers="headers" :items="contasReceber" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.documento }}</td>
                <td class="text-center">{{ item.participante.cnpjCpf | formatarCnpj }}</td>
                <td>{{ item.participante.razaoSocialNome }}</td>
                <td class="text-center">
                  <v-chip :color="verificarVencimento(item.data_vencimento)">{{ item.data_vencimento | formatarData }}</v-chip>
                </td>
                <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                <td class="text-center">{{ item.valor_pago | formatarMoeda }}</td>
                <td class="text-center">{{ item.valor_restante | formatarMoeda }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 10" @click="baixar(item)">
                        <v-list-item-icon><v-icon>monetization_on</v-icon></v-list-item-icon>
                        <v-list-item-title>Baixar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 20" @click="editar(item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="item._id + 30" @click="excluir(item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
      <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
    </v-container>

    <dialogo-recebimento ref="dialogoRecebimento" @finalizar="finalizarRecebimento"></dialogo-recebimento>
    <dialogo-filtro ref="dialogoFiltro" @filtrar="filtrar" @limpar="limparFiltro"></dialogo-filtro>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import moment from 'moment';
import DialogoRecebimento from './DialogoRecebimento';
import DialogoFiltro from './DialogoFiltro';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  name: 'ListaContasReceber',
  components: {
    DialogoRecebimento,
    DialogoFiltro,
    DialogConfirmacao,
  },

  data() {
    return {
      headers: [
        { text: 'Documento', value: 'documento', sortable: false, align: 'center' },
        { text: 'CNPJ/CPF', value: 'participante.cnpjCpf', align: 'center' },
        { text: 'Cliente', value: 'participante.razaoSocialNome' },
        { text: 'Vencimento', value: 'data_vencimento', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'center' },
        { text: 'Valor Pago', value: 'valor_pago', align: 'center' },
        { text: 'Valor Restante', value: 'valor_restante', align: 'center' },
        { text: 'Ações', value: 'name', align: 'center', width: '7%', sortable: false },
      ],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      constantes: constantes,
      busca: '',
      itens_exibir: ['Todas', 'Em Aberto', 'Vencidos', 'Pagos', 'Pagos Parcialmente'],
      filtro: {
        cliente: {},
        exibir: 'Todas',
      },
      filtro_ativo: false,
      tipo: { text: 'Duplicata', value: constantes.DUPLICATA },
    };
  },

  computed: {
    ...mapState('contaReceber', {
      contasReceber: 'contasReceber',
      totalContasReceber: 'totalContasReceber',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    dynamicCol() {
      return !this.isSm ? '12' : '7';
    },
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  methods: {
    abrirDialogoFiltro() {
      this.filtro_ativo = true;
      this.$refs.dialogoFiltro.abrir();
    },

    async listar() {
      this.filtro.exibir = 'Todas';
      this.filtro_ativo = false;
      this.$store.dispatch('contaReceber/listar', this.options);
    },

    async listarPorTipo() {
      this.busca = '';
      let newOptions = {
        ...this.options,
        query: this.filtro,
      };
      this.$store.dispatch('contaReceber/listar', newOptions);
    },

    async filtrarBusca(event) {
      let newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('contaReceber/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    async filtrar(filtro) {
      let newOptions = {
        ...this.options,
        query: filtro,
      };
      this.$store.dispatch('contaReceber/filtrar', newOptions);
    },

    excluir(conta) {
      this.$refs.dialogConfirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('contaReceber/excluir', conta);
        }
      });
    },

    verificarVencimento(vencimento) {
      let emDia = moment().isBefore(vencimento);
      if (emDia) {
        return 'secondary';
      } else {
        return 'error';
      }
    },

    editar(conta) {
      this.$store.commit('contaReceber/EDITAR', conta);
      this.$router.push({ name: 'formularioContaReceberEditar' });
    },

    novo() {
      this.$store.commit('contaReceber/NOVO');
      this.$router.push({ name: 'formularioContaReceberNova' });
    },

    baixar(conta) {
      this.$store.commit('contaReceber/EDITAR', conta);
      this.$refs.dialogoRecebimento.abrir();
    },

    finalizarRecebimento() {
      this.filtro.exibir = 'Todas';
      this.listar();
    },

    limparFiltro() {
      this.listar();
    },

    limparBusca() {
      this.listar();
    },
  },
};
</script>
