<template>
  <div>
    <v-text-field
      v-mask="['########']"
      placeholder=" "
      persistent-placeholder
      outlined
      autocomplete="off"
      role="presentation"
      label="NCM"
      :value="value"
      append-icon="search"
      @keyup.enter="abrirDialogo(valid)"
      @input="onInput"
      @click:append="abrirDialogo()"
      maxlength="8"
      ref="ncm"
    />

    <dialog-buscar-ncm ref="dialogo" @onSelect="onSelect" :value="value" />
  </div>
</template>

<script>
import DialogBuscarNcm from '@/components/dialogs/DialogBuscarNcm';

export default {
  name: 'BuscarNcm',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DialogBuscarNcm,
  },

  methods: {
    onInput(value) {
      if (value !== this.value) {
        this.$emit('input', value);
      }
    },

    abrirDialogo(valid) {
      if (!valid) {
        this.$refs.dialogo.abrir();
      } else {
        this.$emit('onSelect', null);
      }
    },

    onSelect(row) {
      this.$emit('onSelect', row);
    },
  },
};
</script>
