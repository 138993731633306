<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024px" @keydown.esc="fechar" persistent eager>
      <lista-participantes v-if="dialog" float @fechar="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import ListaParticipantes from '../../participantes/ListaParticipantes.vue';

export default {
  name: 'DialogoClientes',
  components: {
    ListaParticipantes,
  },

  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    abrir() {
      this.dialog = true;
    },

    fechar() {
      this.dialog = false;
    },
  },
};
</script>
