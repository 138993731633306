import axios from '@/util/axios';

function listar(options) {
  let url = `/estoques?page=${options.page}&pageSize=${options.itemsPerPage}`;
  if (options.query && options.query !== '') {
    url += `&query=${options.query}`;
  }
  return axios.get(url).then((response) => response.data);
}

function salvar(movimento) {
  return axios.post('/estoques', movimento).then((response) => response.data);
}

function atualizar(movimento) {
  return axios.put(`/estoques`, movimento).then((response) => response.data);
}

function excluir(movimento) {
  return axios.delete(`/estoques`, { data: { _id: movimento._id } }).then((response) => response.data);
}

export default {
  listar,
  salvar,
  atualizar,
  excluir,
};
