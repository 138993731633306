import FormularioProduto from '@/views/produtos/FormularioProduto';

export default [
  {
    path: '/cadastros/produtos',
    name: 'produtos',
    component: () => import(/* webpackChunkName: "listaProdutos" */ '@/views/produtos/ListaProdutos'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessar_produtos' },
  },
  {
    path: '/cadastros/produtosNfe/novo',
    name: 'formularioProdutoNfeNovo',
    component: () => import(/* webpackChunkName: "formularioProdutoSimples" */ '@/views/produtos/FormularioProdutoSimples'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_produtosnfe' },
  },
  {
    path: '/cadastros/produtosNfe/editar',
    name: 'formularioProdutoNfeEditar',
    component: () => import(/* webpackChunkName: "formularioProdutoSimples" */ '@/views/produtos/FormularioProdutoSimples'),
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_produtosnfe' },
  },
  {
    path: '/cadastros/produtos/novo',
    name: 'formularioProdutoNovo',
    component: FormularioProduto,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionar_produtos' },
  },
  {
    path: '/cadastros/produtos/editar',
    name: 'formularioProdutoEditar',
    component: FormularioProduto,
    meta: { requiresAuth: true, nivel: 1, permissao: 'alterar_produtos' },
  },
];
