<template>
  <div>
    <v-container fluid>
      <v-widget title="Contas a Pagar">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="5">
                <v-text-field v-model="busca" flat single-line hide-details dense clearable solo prepend-inner-icon="search" label="Busca" @click:clear="limparBusca" @keyup.enter="listar" background-color="grey lighten-3"></v-text-field>
              </v-col>
              <!-- Condicional - Existe o mesmo código abaixo -->
              <!-- <v-spacer v-if="isSm"></v-spacer>
              <v-col v-if="isSm" class="text-right" cols="6" sm="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="limparFiltro"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col> -->
              <v-col cols="12" sm="4">
                <v-select v-show="!filtro_ativo" flat single-line hide-details dense solo v-model="filtro.exibir" :items="itens_exibir" @change="listarPorTipo" background-color="grey lighten-3"></v-select>
              </v-col>
              <v-col class="text-right" cols="12" sm="3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark class="mr-4" v-bind="attrs" v-on="on" v-show="filtro_ativo" @click="limparFiltro"> mdi-notification-clear-all </v-icon>
                  </template>
                  <span>Limpar filtro</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon depressed fab color="primary" class="mr-4" v-bind="attrs" v-on="on" :loading="carregando" @click="abrirDialogoFiltro"> mdi-filter </v-icon>
                  </template>
                  <span>Filtro</span>
                </v-tooltip>
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVA </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="contas" :search="busca" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr>
                <td class="text-center">{{ props.item.documento }}</td>
                <td class="text-center">
                  <span v-if="props.item.participante">{{ props.item.participante.cnpjCpf | formatarCnpj }}</span>
                  <span>&nbsp;</span>
                </td>
                <td>
                  <span v-if="props.item.participante">{{ props.item.participante.razaoSocialNome }}</span>
                  <span>&nbsp;</span>
                </td>
                <td class="text-center">
                  <v-chip :color="verificarVencimento(props.item.data_vencimento)">{{ props.item.data_vencimento | formatarData }}</v-chip>
                </td>
                <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                <td class="text-center">{{ props.item.valor_pago | formatarMoeda }}</td>
                <td class="text-center">{{ props.item.valor_restante | formatarMoeda }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="props.item._id + 10" @click="baixar(props.item)">
                        <v-list-item-icon><v-icon>monetization_on</v-icon></v-list-item-icon>
                        <v-list-item-title>Pagar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
      <dialog-confirmacao ref="dialogConfirmacao"></dialog-confirmacao>
    </v-container>

    <dialogo-filtro ref="dialogoFiltro" @filtrar="filtrar" @limpar="limparFiltro"></dialogo-filtro>
    <dialog-pagamento ref="dialogoPagamento"></dialog-pagamento>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import moment from 'moment';
import DialogoFiltro from './DialogoFiltro';
import DialogPagamento from './DialogPagamento';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  name: 'ListaContasPagar',
  components: {
    DialogoFiltro,
    DialogPagamento,
    DialogConfirmacao,
  },
  data() {
    return {
      constantes: constantes,
      headers: [
        { text: 'Documento', value: 'documento', sortable: false, align: 'center' },
        { text: 'CNPJ/CPF', value: 'participante.cnpjCpf', align: 'center' },
        { text: 'Fornecedor', value: 'participante.razaoSocialNome' },
        { text: 'Vencimento', value: 'data_vencimento', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'center' },
        { text: 'Valor Pago', value: 'valor_pago', align: 'center' },
        { text: 'Valor Restante', value: 'valor_restante', align: 'center' },
        { text: 'Ações', value: 'name', align: 'center', width: '7%', sortable: false },
      ],
      headerHistorico: [
        { text: 'Data do Pagamento', value: 'pagamento.data' },
        { text: 'Forma de Pagamento', value: 'forma' },
        { text: 'Valor', value: 'valor' },
      ],
      busca: '',
      itens_exibir: ['Todas', 'Boleto', 'Duplicata', 'Cheque', 'Nota Promissória', 'Vale', 'Imposto', 'Outros'],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtro: {
        cliente: {},
        exibir: 'Todas',
      },
      filtro_ativo: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('contaPagar', {
      contas: 'contasPagar',
      total: 'totalContasPagar',
      carregando: 'carregando',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async listar() {
      let options = {
        ...this.options,
        query: this.busca || null,
      };
      this.$store.dispatch('contaPagar/listar', options);
    },

    limparBusca() {
      this.busca = null;
      this.options.page = 1;
      this.listar();
    },

    async listarPorTipo() {
      this.busca = '';
      let newOptions = {
        ...this.options,
        query: this.filtro.exibir,
      };
      this.$store.dispatch('contaPagar/listarPorTipo', newOptions);
    },

    abrirDialogoFiltro() {
      this.filtro_ativo = true;
      this.$refs.dialogoFiltro.abrir();
    },

    async filtrar(filtro) {
      let newOptions = {
        ...this.options,
        query: filtro,
      };
      this.$store.dispatch('contaPagar/filtrar', newOptions);
    },

    limparFiltro() {
      this.filtro_ativo = false;
      this.$store.commit('contaPagar/SET_FORNECEDOR', '');
      this.listar();
    },

    remover(conta) {
      this.$refs.dialogConfirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('contaPagar/remover', conta);
        }
      });
    },

    verificarVencimento(vencimento) {
      let emDia = moment().isBefore(vencimento);
      if (emDia) {
        return 'secondary';
      } else {
        return 'error';
      }
    },

    editar(conta) {
      this.$store.commit('contaPagar/editar', conta);
      this.$router.push({ name: 'formularioContaPagarEditar' });
    },

    novo() {
      this.$store.commit('contaPagar/novo');
      this.$router.push({ name: 'formularioContaPagarNova' });
    },

    baixar(conta) {
      this.$store.commit('contaPagar/editar', conta);
      this.$refs.dialogoPagamento.abrir();
    },
  },
};
</script>
