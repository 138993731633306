import ListaMovimentosCaixa from '@/views/caixa/ListaMovimentosCaixa';

export default [
  {
    path: '/movimentacoes/caixa',
    name: 'caixa',
    component: ListaMovimentosCaixa,
    meta: { requiresAuth: true, nivel: 1, permissao: 'caixa_acessar' },
  },
];
