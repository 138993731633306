import axios from '@/util/axios';
import qs from 'qs';

function listar(options) {
  return axios.get(`/remessas-cheques?` + qs.stringify(options)).then((response) => response.data);
}

function listarContasCorrentes() {
  return axios.get(`/remessas-cheques/listar-contas-correntes`).then((response) => response.data);
}

function listarChequesEmCaixa(options) {
  return axios.get(`/remessas-cheques/cheques-em-caixa?` + qs.stringify(options)).then((response) => response.data);
}

function salvar(remessa) {
  return axios.post(`/remessas-cheques`, remessa).then((response) => response.data);
}

function atualizar(remessa) {
  return axios.put(`/remessas-cheques/${remessa._id}`, remessa).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/remessas-cheques/${id}`).then((response) => response.data);
}

export default {
  listar,
  listarContasCorrentes,
  listarChequesEmCaixa,
  salvar,
  atualizar,
  excluir,
};
