<template>
  <v-card class="green darken-1 mb-3" style="height: 100px; width: 100%">
    <v-card-text class="fill-height white--text">
      <div style="font-size: 14px; line-height: 1 !important" class="mb-2">Produto</div>

      <div v-if="!showInput" class="text-h4 font-weight-bold text-uppercase" style="line-height_: 1 !important; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 100%; height: calc(100% - 10px)" @click="mostrarInput">
        {{ produtoDescricao }}
      </div>

      <v-text-field
        v-if="showInput && modo !== 'Automático'"
        v-bind="$attrs"
        place-holder=" "
        ref="input"
        autocomplete="off"
        :value="busca"
        @blur="ocultarInput"
        @input="onInput"
        @keydown="onKeyDown"
        solo
        flat
        dark
        background-color="transparent"
        class="white--text custom-input custom-input2 text-h4 font-weight-bold"
        id="inputBuscaProdutoVenda"
      />

      <div
        v-if="produto && produto.codigo"
        class="text-h4 font-weight-bold"
        style="line-height_: 1 !important; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; width: 100%; height: calc(100% - 10px); opacity: 0.3; user-select: none; pointer-events: none; position: absolute; top: 38px"
        @click="mostrarInput"
      >
        {{ (produto && produto.descricao) || '' }}
      </div>
    </v-card-text>

    <v-card v-if="dialogo" class="white darken-1 mb-3 ma-0" style="max-height: 390px; width: 100%; margin: 0px !important; z-index: 9; display: flex; margin-top: -10px !important" elevation="16">
      <recycle-scroller class="scroller" :items="produtosFiltrados" :item-size="78" key-field="_id" v-slot="{ item, index }" id="scroller" style="max-height: 390px; width: 100%">
        <div class="cell" :style="produtoIndex !== null && produtoIndex === index ? 'background-color: #C5E1A5' : ''" @click.stop="selecionarProduto(item)">
          <div style="flex: 1; display: flex; flex-direction: column; overflow-x: clip">
            <span style="font-size: 1.4rem; white-space: nowrap" v-html="$options.filters.highlight(item.descricao, busca)" />

            <div style="display: flex; flex-direction: row; gap: 8px; max-height: 24px; margin-top: 6px">
              <span class="caption codigo">
                <v-icon small>mdi-tag-text-outline</v-icon>
                <span v-html="$options.filters.highlight(item.codigo, busca)" />
              </span>

              <span v-for="codigobarras in item.codigosBarras" :key="codigobarras" class="caption codigobarras">
                <v-icon>mdi-barcode</v-icon>
                <span v-html="$options.filters.highlight(codigobarras, busca)" />
              </span>
            </div>

            <span class="caption" v-html="$options.filters.highlight(item.descricaoAdicional ? item.descricaoAdicional : descricaoAdicional, busca)" />
          </div>

          <div style="flex: 0; display: flex; flex-direction: column; justify-content: space-between; margin-top: 2px; font-size: 1.1rem; min-width: 150px">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div class="estoquevalor" v-bind="attrs" v-on="on">
                  <v-icon style="transform: scale(1.3)" small>mdi-package-variant-closed</v-icon>
                  <span class="caption mr-auto">{{ item.unidade }}</span>
                  <span v-html="$options.filters.highlight($options.filters.formatarValor(item.estoque, 3, 0, true), busca)" />
                </div>
              </template>
              <span>Estoque</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div class="estoquevalor" v-bind="attrs" v-on="on">
                  <v-icon style="transform: scale(1.2)" small>mdi-currency-brl</v-icon>
                  <span v-html="$options.filters.highlight($options.filters.formatarValor(item.valor, 2, 2, true), busca)" />
                </div>
              </template>
              <span>Valor</span>
            </v-tooltip>
          </div>

          <img :src="imagemSrc(item.imagem)" style="border-radius: 4px; flex: 0; min-height: 64px; max-height: 64px; min-width: 106px; max-width: 106px; margin-top: 2px; object-fit: cover" alt="Image não disponível" @error="fallbackImage" />
        </div>
      </recycle-scroller>
    </v-card>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { URL_ANRON_API } from '@/util/config';

export default {
  name: 'BuscarProdutoPdv',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    modo: {
      type: String,
      default: 'Manual',
    },
    tabela: {
      type: String,
      default: 'A Vista',
    },
    produtoDescricao: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      busca: '',
      showInput: false,
      dialogo: false,
      descricaoAdicional: 'Sem descrição adicional',
      blankImage: null,
    };
  },

  mounted() {
    this.$store.dispatch('produto/listar');
    this.blankImage = this.createImage(640, 480, 'whitesmoke');
  },

  computed: {
    ...mapState('produto', ['produtos', 'produto', 'produtoIndex', 'produtosFiltrados', 'carregando']),
  },

  methods: {
    imagemSrc(imagem) {
      return imagem ? `${URL_ANRON_API}/images/${imagem}` : this.blankImage;
    },

    createImage(width, height, backgroundColor = 'white') {
      const blankImage = document.createElement('canvas');
      blankImage.width = width;
      blankImage.height = height;
      const ctx = blankImage.getContext('2d');
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, width, height);
      ctx.textAlign = 'center';
      ctx.font = '40px Roboto';
      ctx.fillStyle = 'silver';
      ctx.fillText('Imagem não disponível', Math.floor(width / 2), Math.floor(height / 2));
      return blankImage.toDataURL();
    },

    mostrarInput() {
      this.showInput = true;
      this.$store.commit('produto/setState', { produto: null, produtoIndex: null });

      this.$nextTick(() => {
        this.$refs.input?.focus();
      });
    },

    ocultarInput() {
      this.$store.commit('produto/setState', { produto: null, produtoIndex: null });
      setTimeout(() => {
        this.showInput = false;
        this.dialogo = false;
        this.$store.commit('produto/setState', { produtosFiltrados: this.produtos });
        this.busca = '';
      }, 100);
    },

    onKeyDown(e) {
      if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
        e.preventDefault();
        e.stopPropagation();

        if (this.produtosFiltrados.length === 0) return;

        if (this.produtoIndex === null) {
          const index = 0;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 78 - el.scrollTop);
          });
          return;
        }

        if (e.code === 'ArrowDown' && this.produtosFiltrados.length - 1 >= this.produtoIndex + 1) {
          const index = this.produtoIndex + 1;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 78 - el.scrollTop);
          });
        }

        if (e.code === 'ArrowUp' && this.produtoIndex - 1 >= 0) {
          const index = this.produtoIndex - 1;
          this.$store.commit('produto/setState', { produtoIndex: index });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, index * 78 - el.scrollTop);
          });
        }
      } else if (['Enter', 'NumpadEnter'].includes(e.code)) {
        e.preventDefault();
        e.stopPropagation();
        if (this.produtoIndex >= 0) {
          this.selecionarProduto(this.produtosFiltrados[this.produtoIndex]);
        }
      } else if (e.code === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        this.selecionarProduto(null);
      }
    },

    onInput(value) {
      this.busca = value;

      if (value.trim()) {
        this.dialogo = true;
        const produtosFiltrados = this.produtos.filter((pro) => `${pro.descricao} ${pro.descricaoAdicional} ${pro.valor} ${pro.codigo} ${pro.codigosBarras.join(' ')}`.includes(value));
        this.$store.commit('produto/setState', { produtosFiltrados });
        const indexFound = produtosFiltrados.findIndex((pro) => pro.descricao.startsWith(this.busca) || (pro.descricaoAdicional || '').startsWith(this.busca) || String(pro.codigo).startsWith(this.busca));

        if (indexFound >= 0) {
          this.$store.commit('produto/setState', { produtoIndex: indexFound, produto: produtosFiltrados[indexFound] });

          this.$nextTick(() => {
            const el = document.getElementById('scroller');
            el?.scrollBy(0, indexFound * 78 - el.scrollTop);
          });
        } else {
          this.$store.commit('produto/setState', { produtoIndex: null, produto: null });
        }
      } else {
        this.dialogo = false;
        this.$store.commit('produto/setState', { produtosFiltrados: this.produtos, produtoIndex: null, produto: null });
      }
    },

    fallbackImage(e) {
      e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==';
    },

    selecionarProduto(produto) {
      this.$store.commit('produto/setState', { produto: null, produtosFiltrados: this.produtos, produtoIndex: null });
      this.showInput = false;
      this.dialogo = false;
      this.busca = '';
      this.$emit('resultado', produto ? { ...produto, quantidade: 1 } : null);
    },
  },
};
</script>

<style scoped>
.custom-input >>> input {
  padding: 0px;
  max-height: fit-content;
}
.custom-input2 >>> div {
  padding: 0px !important;
  margin-top: -2px !important;
}
.cell {
  padding: 4px 8px 8px 8px;
  cursor: pointer;
  line-height: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-height: 78px;
  max-height: 78px;
  border-bottom: 1px solid silver;
}
.cell:hover {
  background-color: #f1f8e9;
}
.codigo {
  align-items: center;
  background-color: lightgrey;
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  gap: 4px;
}
.codigobarras {
  align-items: center;
  display: flex;
  gap: 4px;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0px 4px;
}
.estoquevalor {
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 4px;
  padding: 4px 6px;
  display: flex;
  gap: 8px;
}
</style>
