import axios from '@/util/axios';

function listar(params) {
  return axios.get('/crud', { params }).then((response) => response.data);
}

function encontrarPeloId(id) {
  return axios.get(`/crud/${id}`).then((response) => response.data);
}

function salvar(item) {
  return axios.post('/crud', item).then((response) => response.data);
}

function atualizar(item, id) {
  return axios.put(`/crud/${id}`, item).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/crud/${id}`).then((response) => response.data);
}

export default {
  listar,
  encontrarPeloId,
  salvar,
  atualizar,
  excluir,
};
