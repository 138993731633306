<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="fechar">
    <v-form ref="formSangriaSuprimento" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text" v-if="tipo === constantes.SANGRIA">Sangria</v-toolbar-title>
          <v-toolbar-title class="white--text" v-else-if="tipo === constantes.SUPRIMENTO">Suprimento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field label="Descrição" ref="descricao" v-model="lancamento.descricao" :rules="[rules.obrigatorio]" @keypress.enter="$refs.valor.focus()" autofocus></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <as-campo-valor label="Valor" ref="valor" v-model="lancamento.valor" decimals="2" :rules="[rules.obrigatorio, rules.numeroValido, rules.valorMaiorQueZero]" @keypress.native.enter="$refs.formaPagamento.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select label="Forma de Pagamento" ref="formaPagamento" :items="constantes.FORMAS_PAGAMENTO || []" v-model="lancamento.formaPagamento" @keypress.enter="confirmar"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar()" text>Cancelar</v-btn>
          <v-btn color="primary" @click="confirmar">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'DialogoSangriaSuprimento',
  props: ['dialog', 'tipo'],

  data() {
    return {
      rules: { ...regras },
      constantes: constantes,
      lancamento: {
        formaPagamento: 'Dinheiro',
        valor: '',
        tipo: '',
      },
    };
  },

  methods: {
    fechar() {
      this.$emit('fechar');
      this.limpar();
    },
    confirmar() {
      if (this.$refs.formSangriaSuprimento.validate()) {
        if (this.tipo === constantes.SANGRIA) {
          this.lancamento.tipo = constantes.DEBITO;
        } else if (this.tipo === constantes.SUPRIMENTO) {
          this.lancamento.tipo = constantes.CREDITO;
        }
        this.$emit('adicionarLancamento', this.lancamento);
        this.limpar();
      } else {
        setTimeout(() => {
          this.$refs.descricao.focus();
        }, 1000);
      }
    },
    limpar() {
      this.lancamento = {
        formaPagamento: 'Dinheiro',
        valor: '',
        tipo: '',
      };
    },
  },
};
</script>
