import ListaFabricantes from '@/views/fabricantes/ListaFabricantes';
import FormularioFabricante from '@/views/fabricantes/FormularioFabricante';

export default [
  {
    path: '/cadastros/fabricantes',
    name: 'fabricantes',
    component: ListaFabricantes,
    meta: { requiresAuth: true, nivel: 1, permissao: 'acessarFabricantes' },
  },
  {
    path: '/cadastros/fabricantes/novo',
    name: 'formularioFabricanteNovo',
    component: FormularioFabricante,
    meta: { requiresAuth: true, nivel: 1, permissao: 'adicionarFabricantes' },
  },
  {
    path: '/cadastros/fabricantes/editar',
    name: 'formularioFabricanteEditar',
    component: FormularioFabricante,
    meta: { requiresAuth: true, nivel: 1, permissao: 'editarFabricantes' },
  },
];
