<template>
  <v-dialog v-model="dialog" max-width="600px" @keydown.esc="fechar" persistent>
    <v-form v-model="valid" ref="formSangriaSuprimento">
      <v-card>
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title class="white--text" v-if="tipo === constantes.DEBITO">Sangria</v-toolbar-title>
          <v-toolbar-title class="white--text" v-else-if="tipo === constantes.CREDITO">Suprimento</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select label="Forma de Pagamento" :items="constantes.FORMAS_PAGAMENTO || []" v-model="lancamento.formaPagamento"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Valor" v-model="lancamento.valor" :rules="validarValor"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn @click="fechar()">Cancelar</v-btn>
          <v-btn color="primary" @click="enviar()" :disabled="!valid">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import constantes from '@/util/constantes';

export default {
  data() {
    return {
      valid: false,
      validarValor: [(v) => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || 'Valor Inválido'],
      constantes: constantes,
      lancamento: {
        formaPagamento: '',
        valor: '',
        tipo: '',
      },
      tipo: '',
      dialog: false,
    };
  },

  methods: {
    abrir(tipo) {
      if (tipo && tipo !== '') {
        this.tipo = tipo;
        this.dialog = true;
      } else {
        this.$store.commit('setMensagemErro', 'Tipo de operação não informado');
      }
    },

    fechar() {
      this.dialog = false;
      this.$emit('fechar');
      this.limpar();
    },

    enviar() {
      if (this.$refs.formSangriaSuprimento.validate()) {
        let valor = conversor.stringParaNumero(this.lancamento.valor);
        this.lancamento.valor = conversor.stringParaNumero(this.lancamento.valor);
        this.lancamento.tipo = this.tipo;

        if (this.tipo === constantes.CREDITO) {
          this.$store.dispatch('movimentoCaixa/suprimento', this.lancamento);
        } else if (this.tipo === constantes.DEBITO) {
          this.$store.dispatch('movimentoCaixa/sangria', this.lancamento);
        }

        this.dialog = false;
        this.limpar();
      }
    },

    limpar() {
      this.lancamento = {
        formaPagamento: '',
        valor: '',
        tipo: '',
      };
    },
  },
};
</script>
