<template>
  <v-text-field
    v-model="valor"
    v-mask="customMask"
    @keydown="(e) => sanitizeValor(e, valor)"
    autocomplete="off"
    v-bind="$attrs"
    ref="input"
    :class="{ direita: direita }"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @keyup="$emit('keyup', $event)"
    :readonly="readonly"
  />
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'campo-valor',
  props: {
    value: {
      type: [String, Number],
    },
    separadorMilhares: {
      type: Boolean,
      default: true,
    },
    decimais: {
      type: [String, Number],
      default: 2,
    },
    fixarDecimais: {
      type: Boolean,
      default: false,
    },
    truncarDecimais: {
      type: Boolean,
      default: false,
    },
    selectOnFocus: {
      type: Boolean,
      default: true,
    },
    direita: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  directives: {
    mask,
  },

  data() {
    return {
      valor: '0,00',
      isFocused: false,
      customMask: {
        mask: 'FFFFFFFFFFFFFFFFFFFFF',
        tokens: {
          F: {
            pattern: /[0-9,.]/,
          },
        },
      },
    };
  },

  mounted() {
    this.valor = this.formataNumero(this.value);
  },

  updated() {
    if (!this.isFocused && typeof this.value === 'number') {
      this.valor = this.formataNumero(String(this.value).replace(',', '').replace('.', ','));
    }
  },

  methods: {
    onInput(value) {
      if (!this.isFocused) return;

      const decimais = value.split(',')[1];

      if (this.fixarDecimais && decimais && decimais.length > parseInt(this.decimais, 10)) {
        this.$refs.input.$el.querySelector('input').value = value.substring(0, value.length - 1);
      }

      this.$emit('input', this.valorNumero(this.formataNumero(this.valor)));
    },

    onBlur() {
      this.isFocused = false;
      this.valor = this.formataNumero(this.value);
      this.$emit('blur', this.valorNumero(this.valor));
    },

    onFocus(e) {
      this.isFocused = true;

      if (this.selectOnFocus) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;
      } else {
        setTimeout(() => {
          if (!this.disabled || !this.readonly) {
            e.target.setSelectionRange(e.target.value.length, e.target.value.length);
          }
        }, 0);
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    sanitizeValor(e, valor) {
      if (!valor) return;

      if (!/[0-9,]/.test(e.key) && !['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 'Escape', 'Ctrl', 'Alt'].includes(e.key)) {
        e.preventDefault();
        return;
      }
      if (!valor && [','].includes(e.key)) {
        e.preventDefault();
        return;
      }
      if (valor && [','].includes(e.key) && this.decimais && parseInt(this.decimais, 10) === 0) {
        e.preventDefault();
        return;
      }
      if (valor && valor[e.target.selectionStart - 1] === ',' && [','].includes(e.key)) {
        e.preventDefault();
        return;
      }
      if (valor.includes(',') && [','].includes(e.key)) {
        e.preventDefault();
        return;
      }
    },

    trunc(valor, decimais) {
      const numPower = Math.pow(10, decimais);
      return String(~~(valor * numPower) / numPower);
    },

    valorNumero(valor) {
      return parseFloat(valor.replace('.', '').replace(',', '.'));
    },

    formataNumero(valor) {
      let formatted = String(valor).replace('.', '').replace(',', '.') || '0';

      if (this.decimais) {
        if (this.truncarDecimais) {
          formatted = this.trunc(parseFloat(formatted), this.decimais);
        } else {
          formatted = parseFloat(formatted).toFixed(this.decimais);
        }
        if (!this.fixarDecimais) {
          formatted = String(parseFloat(formatted));
        }
      }

      formatted = formatted.replace('.', ',');

      if (this.separadorMilhares) {
        let count = 0;
        let commaPosition = formatted.search(',');
        let tmp = commaPosition < 0 ? '' : formatted.substring(commaPosition);
        commaPosition = commaPosition < 0 ? formatted.length - 1 : commaPosition - 1;
        for (let index = commaPosition; index >= 0; index--) {
          count += 1;
          tmp = formatted[index] + tmp;
          if (count === 3 && index !== 0) {
            tmp = '.' + tmp;
            count = 0;
          }
        }
        formatted = tmp;
      }

      return formatted === 'NaN' ? (0).toFixed(this.decimais).replaceAll('.', ',') : formatted;
    },
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}

.v-input--is-readonly >>> input {
  cursor: not-allowed;
}
</style>
