import axios from '@/util/axios';

function listar(options) {
  return axios.get('/contadores', { params: options }).then((response) => response.data);
}

function consultar(contadorId) {
  return axios.get(`/contadores/${contadorId}`).then((response) => response.data);
}

function adicionar(contador) {
  return axios.post('/contadores', contador).then((response) => response.data);
}

function editar(dados, contadorId) {
  return axios.put(`/contadores/${contadorId}`, dados).then((response) => response.data);
}

function remover(contadorId) {
  return axios.delete(`/contadores/${contadorId}`).then((response) => response.data);
}

export default {
  listar,
  consultar,
  adicionar,
  editar,
  remover,
};
