import axios from '@/util/axios';

function listar(params) {
  return axios.get('/participantes', { params }).then((response) => response.data);
}

function adicionar(participante) {
  return axios.post('/participantes', participante).then((response) => response.data);
}

function editar(participante) {
  return axios.patch(`/participantes`, participante).then((response) => response.data);
}

function remover(participanteId) {
  return axios.delete(`/participantes/${participanteId}`).then((response) => response.data);
}

export default {
  listar,
  adicionar,
  editar,
  remover,
};
