import axios from 'axios';
import { ClientJS } from 'clientjs';

import config from './config';
import router from '../router/index';
import store from '../store/store';

const axiosConfig = {
  baseURL: config.URL_ANRON_API,
  timeout: config.TIMEOUT,
  withCredentials: true,
};

const client = new ClientJS();
const dispositivo = client.getFingerprint();

const axiosInstance = axios.create(axiosConfig);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['authorization'] = JSON.parse(localStorage.getItem('session') || '{"tokens": {}}').tokens.accessToken;
    config.headers['ambiente'] = JSON.parse(localStorage.getItem('session') || '{}').ambiente || 2;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/login') {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((accessToken) => {
            originalRequest.headers['authorization'] = accessToken;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve) {
        axios
          .post(
            '/refresh-token',
            {
              refreshToken: JSON.parse(localStorage.getItem('session') || '{"tokens": {}}').tokens.refreshToken,
              dispositivo,
            },
            axiosConfig
          )
          .then(({ data }) => {
            originalRequest.headers['authorization'] = data.accessToken;

            store.commit('autenticacao/setSession', { tokens: { accessToken: data.accessToken, refreshToken: data.refreshToken } });

            processQueue(null, data.accessToken);
            resolve(axios(originalRequest));
          })
          .catch(() => {
            failedQueue = [];
            store.commit('autenticacao/setSession', { tokens: {}, usuario: {}, empresas: [], empresa: {}, certificadoVencido: false });
            store.dispatch('descarregarTabelas');
            router.push({ name: 'login' });
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    const responseError = {
      status: error.response ? error.response.status : 500,
      statusText: error.response ? error.response.statusText : error.message,
      data: error.response ? error.response.data : { message: error.message },
    };

    return Promise.reject(responseError);
  }
);

export default axiosInstance;
