import axios from '@/util/axios';
import qs from 'qs';

function listar(options, empresa) {
  return axios.get(`/movimento-producao`, { params: options }).then((response) => response.data);
}

function salvar(novo_movimento, empresa) {
  return axios.post(`/movimento-producao`, novo_movimento).then((response) => response.data);
}

function excluir(movimento_id, empresa) {
  return axios.delete(`/movimento-producao/${movimento_id}`).then((response) => response.data);
}

export default {
  listar,
  salvar,
  // atualizar,
  excluir,
};
