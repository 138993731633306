<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Informar Recebimento</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="CNPJ/CPF" v-model="contaReceber.participante.cnpjCpf" v-mask="['###.###.###-##', '##.###.###/####-##']" disabled hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Fornecedor" v-model="contaReceber.participante.razaoSocialNome" disabled hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="Documento" v-model="contaReceber.documento" disabled hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <campo-data label="Cadastro" v-model="contaReceber.data_cadastro" disabled hide-details></campo-data>
            </v-col>
            <v-col cols="12" sm="2">
              <campo-data label="Vencimento" v-model="contaReceber.data_vencimento" disabled hide-details></campo-data>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" v-model="contaReceber.valor" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor Pago" v-model="contaReceber.valor_pago" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor Restante" v-model="contaReceber.valor_restante" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-card>
            <v-toolbar flat dark color="primary" dense>
              <v-toolbar-title>Recebimentos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text dark class="ml-2" depressed @click="abrirDialogNovoRecebimento" :disabled="contaReceber.valor_restante == 0">Novo Recebimento</v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- <v-data-table :headers="headers" :items="contaReceber.recebimentos" hide-default-footer disable-pagination> -->
              <v-data-table hide-default-footer :server-items-length="contaReceber.recebimentos.length" :headers="headers" :items="contaReceber.recebimentos" :loading="carregando" disable-pagination>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.data | formatarData }}</td>
                    <td>{{ item.valor_pago | formatarMoeda }}</td>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.observacoes }}</td>
                    <td class="text-center">
                      <v-menu top right transition="slide-x-transition">
                        <template v-slot:activator="{ on }">
                          <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                        </template>
                        <v-list dense>
                          <v-list-item :key="item._id" @click="abrirDialogoExcluirRecebimento(item)">
                            <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                            <v-list-item-title>Remover</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialog = false" text>Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNovoRecebimento" max-width="800px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Novo Recebimento</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-form v-model="valid" ref="formPagamento" lazy-validation>
            <v-row dense>
              <v-col cols="12" sm="3">
                <campo-data label="Data" v-model="recebimento.data" ref="data" @keypress.native.enter="$refs.desc_valor.focus()" :rules="[rules.obrigatorio]"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Desconto Valor" v-model="recebimento.desconto" @blur="calcularDescontoPercentual" :decimais="2" ref="desc_valor" @keypress.native.enter="$refs.desc_porc.focus()" :rules="[rules.valorPositivo]"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Desconto %" v-model="recebimento.desconto_percentual" @blur="calcularDescontoValor" :decimais="2" ref="desc_porc" @keypress.native.enter="$refs.valor_pago.focus()" :rules="[rules.valorPositivo]"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor Pago" v-model="recebimento.valor_pago" :decimais="2" ref="valor_pago" @keypress.native.enter="$refs.tipo.focus()" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]"></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select :items="tipos" v-model="recebimento.tipo" label="Tipo" ref="tipo" @keypress.native.enter="$refs.cartao.focus()" :rules="[rules.obrigatorio]"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="cartoes" v-model="recebimento.cartao" label="Cartões" ref="cartao" :disabled="campoCartoesDisabled" @keypress.native.enter="$refs.observacoes.focus()"></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea label="Observação" v-model="recebimento.observacoes" ref="observacoes"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="mr-2" @click="dialogNovoRecebimento = false">Cancelar</v-btn>
          <v-btn depressed color="primary" @click="salvarRecebimento" :loading="carregando" :disabled="valid == false">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import * as regras from '@/util/validacaoFormulario';
import axios from '@/util/axios';

export default {
  name: 'DialogoRecebimento',
  data() {
    return {
      dialog: false,
      dialogNovoRecebimento: false,
      headers: [
        { text: 'Data', value: 'data', width: '20%', sortable: false },
        { text: 'Valor', value: 'valor', width: '20%', sortable: false },
        { text: 'Tipo', value: 'tipo', width: '20%', sortable: false },
        { text: 'Observações', width: '35%', value: 'observacoes', sortable: false },
        { text: 'Ações', width: '5%', value: 'acoes', sortable: false },
      ],
      recebimento: {
        data: moment().format('YYYY-MM-DD'),
        desconto_percentual: 0,
        desconto: 0,
        valor_pago: 0,
        tipo: 'Dinheiro',
        cartão: '',
        observacoes: '',
        conta_a_receber: '',
      },
      valid: true,
      tipos: ['Dinheiro', 'Boleto', 'Cheque', 'Cartão de Crédito', 'Cartão de Débito', 'Outros'],
      cartoes: ['Visa', 'MasterCard', 'HiperCard', 'American Express', 'Outros'],
      rules: { ...regras },
    };
  },
  computed: {
    ...mapState('contaReceber', {
      contaReceber: 'contaAtual',
      carregando: 'carregando',
    }),

    ...mapGetters('contaReceber', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),

    campoCartoesDisabled() {
      if (this.recebimento.tipo == 'Cartão de Crédito' || this.recebimento.tipo == 'Cartão de Débito') {
        return false;
      } else {
        this.recebimento.cartao = '';
        return true;
      }
    },
  },
  methods: {
    abrir() {
      this.dialog = true;
    },
    fechar() {
      this.dialog = false;
    },
    abrirDialogNovoRecebimento() {
      this.recebimento = {
        data: moment().format('YYYY-MM-DD'),
        desconto_percentual: 0,
        desconto: 0,
        valor_pago: this.contaReceber.valor_restante,
        tipo: 'Dinheiro',
        cartao: '',
        observacoes: '',
        conta_a_receber: this.contaReceber._id,
      };
      this.dialogNovoRecebimento = true;
      setTimeout(() => {
        this.$refs.valor_pago.focus();
      }, 500);
    },
    async salvarRecebimento() {
      if (this.$refs.formPagamento.validate()) {
        await this.$store.dispatch('contaReceber/salvarBaixa', this.recebimento);
        this.dialogNovoRecebimento = false;
        this.$emit('finalizar');
        this.fechar();
      }
    },
    abrirDialogoExcluirRecebimento(recebimento_selecionado) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este recebimento?').then((confirmado) => {
        if (confirmado) {
          this.excluirRecebimento(recebimento_selecionado);
        }
      });
    },
    async excluirRecebimento(recebimento_selecionado) {
      await this.$store.dispatch('contaReceber/excluirRecebimento', recebimento_selecionado);
    },
    calcularDescontoPercentual() {
      if (this.recebimento.desconto) {
        this.recebimento.desconto_percentual = (this.recebimento.desconto / this.contaReceber.valor_restante) * 100;
      } else {
        this.recebimento.desconto_percentual = 0;
        this.recebimento.desconto = 0;
      }

      this.recebimento.valor_pago = this.contaReceber.valor_restante - this.recebimento.desconto;
    },
    calcularDescontoValor() {
      if (this.recebimento.desconto_percentual) {
        this.recebimento.desconto = (this.contaReceber.valor_restante * this.recebimento.desconto_percentual) / 100;
      } else {
        this.recebimento.desconto = 0;
        this.recebimento.desconto_percentual = 0;
      }

      this.recebimento.valor_pago = this.contaReceber.valor_restante - this.recebimento.desconto;
    },
  },
};
</script>

<style></style>
